const copy = {
    nl: {
        portraitmessage: "Deze pagina vereist landscape mode",
        title: "Poort tot de wereld",
        start: "start",
        initiative: "Een initiatief van Port of Antwerp-Bruges",
        back: "terug naar begin",
        belgiumToWorldScroller: {
            zeebrugge: "Zeebrugge",
            antwerp: "Antwerpen",
            brussel: "Brussel",
            ratio: "Verhouding maritiem goederenverkeer",
            poab: "",
            benin: "Benin",
            brazil: "Brazilië",
            chile: "Chili",
            us: "Verenigde Staten",
            uk: "Verenigd Koninkrijk",
            france: "Frankrijk",
            germany: "Duitsland",
            poland: "Polen",
            czechia: "Tsjechië",
            austria: "Oostenrijk",
            hungary: "Hongarije",
            egypt: "Egypte",
            russia: "Rusland",
            oman: "Oman",
            india: "India",
            china: "China",
            japan: "Japan",
            ireland: "Ierland",
            slovakia: "Slovakije",
            switzerland: "Zwitserland",
            canada: "Canada",
            namibia: "Namibië",
            morocco: "Marokko",
            europe: "Europa",
            middleeast: "Nabije Oosten",
            africa: "Afrika",
            southamerica: "Zuid-Amerika",
            northamerica: "Noord- en Midden-Amerika",
            asia: "Midden- en Verre Oosten",
            oceania: "Oceanië",
            ton: "M ton",
            export: "Export",
            import: "Import",
            partner: "Partnerschappen",
            representative: "Vertegenwoordigers",
            investment: "Participatieprojecten",
            scenes: [
                {
                    id: "one-port",
                    title: "Eén haven, twee locaties",
                    text: "Met een doorvoer van 289 miljoen ton per jaar vormt Port of Antwerp-Bruges met zijn twee sites een belangrijk knooppunt in de wereldwijde handel. De ambitie is om de eerste wereldhaven te worden die economie, mens en klimaat verzoent."
                },
                {
                    id: "heart-europe",
                    title: "In het hart van Europa",
                    text: "Geen enkele haven ligt zo centraal in Europa. 60 procent van de Europese consumptiecentra ligt binnen een straal van 500 km. Daarmee vormt Port of Antwerp-Bruges de snelste en goedkoopste verbinding met het Europese achterland."
                },
                {
                    id: "connected",
                    title: "Sterk verbonden met de wereld",
                    text: `De haven is met meer dan 300 lijndiensten en 800 bestemmingen verbonden met alle continenten, van het Westen tot het Verre Oosten. Port of Antwerp-Bruges is een even belangrijke import- als exporthaven.<br /><br /><a href="https://www.portofantwerpbruges.com/onze-haven/wereldhaven" class="cta" target="_blank">Lees meer</a>`
                },
                {
                    id: "global",
                    title: "Think global, act local",
                    text: `Port of Antwerp-Bruges werkt met <span class="dot"></span> lokale vertegenwoordigers om de nauwe banden met belangrijke markten te onderhouden. Strategische <span class="dot filled"></span> partnerschappen rond onder andere waterstof en <span style="white-space: nowrap;"><img src="/icons/investment.svg" width="14px"> participatieprojecten</span> versterken de internationale positionering en verankering.`
                },
                {
                    id: "european",
                    title: "Europeaan op Aziatische lijst",
                    text: `Port of Antwerp-Bruges ontvangt vlot ‘s werelds grootste containerschepen, tot zo’n 24.000 Twenty foot Equivalent Unit (TEU), en staat in de top 15 van de grootste containerhavens. Daarmee neemt het een opvallende plek in op de lijst van wereldhavens, die wordt gedomineerd door spelers in Azië, met Shanghai op kop.`
                },
            ]
        },
        goodsScroller: {
            title: "Goederentransport",
            text: "Als essentiële schakel in de wereldwijde logistieke keten is Port of Antwerp-Bruges uitgegroeid tot een vooraanstaande Europese haven met een sterk en uitgebreid aanbod van goederenstromen. Elk van deze producten vraagt een eigen manier van transport, of noem het een eigen verschepingsvorm.",
            scenes: [
                {
                    id: "containers",
                    label: "Containers",
                    quantity: "159",
                    units: "miljoen ton",
                    iconUrl : "/icons/container.svg",
                    topImgSrc : "images/containers_1600.jpg"
                },
                {
                    id: "liquid",
                    label: "Vloeibaar massagoed",
                    quantity: "82,4",
                    units: "miljoen ton",
                    iconUrl : "./icons/liquid.svg",
                    topImgSrc : "images/Vopak_1600.jpg"
                },
                {
                    id: "rolling",
                    label: "Rollend materiaal",
                    quantity: "19,2",
                    units: "miljoen ton",
                    iconUrl : "/icons/car.svg", 
                    topImgSrc : "images/cars_1600.jpg"
                },
                {
                    id: "dry",
                    label: "Droog massagoed",
                    quantity: "15,1",
                    units: "miljoen ton",
                    iconUrl : "/icons/drybulk.svg", 
                    topImgSrc : "images/stoneterminal_1600.jpg"
                },
                {
                    id: "breakbulk",
                    label: "Breakbulk",
                    quantity: "13,2",
                    units: "miljoen ton",
                    iconUrl : "/icons/breakbulk.svg", 
                    topImgSrc : "images/breakbulk_1600.jpg"
                },
                {
                    id: "passengers",
                    label: "Passagiers-bewegingen",
                    quantity: "114.000",
                    units: "",
                    iconUrl : "/icons/passengers.svg", 
                    topImgSrc : "images/cruise_1600.jpg"
                },
            ]
        },
        motorGraphic: {
            title: "Grootste economische motor van België",
            text: "Port of Antwerp-Bruges beslaat 14.322 hectare, dat zijn 20.820 voetbalvelden. Daarop zijn 1.400 bedrijven gevestigd, die 164.000 directe en indirecte banen opleveren. Samen levert dat een toegevoegde waarde van 20,8 miljard euro op, goed voor 4,5 procent van het Belgische bbp.",
            "zeebrugge": "Zeebrugge",
            "antwerp": "Antwerp",
            mapUrl: "images/flanders-map-nl.svg",
            tiles: [
                {
                    quantity1: "14.322",
                    units1: "hectare of",
                    quantity2: "20.820",
                    units2: "voetbalvelden",
                    iconUrl : "/icons/football.svg"
                },
                {
                    quantity2: "1.400",
                    units2: "bedrijven",
                    iconUrl : "/icons/buildings.svg"
                },
                {
                    quantity2: "164.000",
                    units2: "directe en indirecte banen",
                    iconUrl : "/icons/people.svg"                  
                },
                {
                    quantity1: "20,8",
                    units1: "miljard euro of",
                    quantity2: "4,5%",
                    units2: "van het Belgische bbp",
                    iconUrl : "/icons/pie.svg"                    
                },
            ]
        },
        satimageScroller: {
            scenes: [
                {
                    id: "antwerp",
                    title: "In Antwerpen",
                    text: "Vrijwel alle producten die u in het dagelijks leven gebruikt, passeren de haven. Zo staat Antwerpen bekend voor de trafiek en opslag van containers, breakbulk en chemische producten.",
                    img: "antwerp_1600"
                },
                {
                    id: "containers",
                    title: "Containers" ,
                    text: "Je kunt zowat alles in een container vervoeren: van tuinmeubelen tot kleding, van elektronica tot bouwmaterialen. Reefers of koelcontainers zorgen ervoor dat diepvriesproducten, groenten en fruit of medicijnen onder de juiste temperatuur hun eindbestemming bereiken. Zo is Antwerpen de grootste bananenhaven in Europa.",
                    img: "boxes_1600"
                },
                {
                    id: "breakbulk",
                    title: "Breakbulk",
                    text: "Antwerpen is het uitgelezen platform voor het verschepen van staal, woudproducten en projectlading zoals windturbines en machines. Dankzij de eeuwenlange ervaring en de gespecialiseerde bedrijven in de haven verscheep je de meest diverse breakbulk ladingen via Port of Antwerp-Bruges.",
                    img: "breakbulk_1600"
                },
                {
                    id: "chemicalcluster",
                    title: "Chemiecluster",
                    text: "In Antwerpen bevindt zich de grootste geïntegreerde chemiecluster van Europa , een van de grootste in de wereld. Acht van de tien grootste chemiebedrijven ter wereld hebben er een productievestiging. Dat gaat om olie, en gas, maar ook om chemicaliën zoals plastic pellets. NextGen District ligt in een industriële omgeving vlak bij deze cluster en wordt de hotspot voor circulaire economie.",
                    img: "chemiecluster_1600"
                },
                {
                    id: "zeebrugge",
                    title: "In Zeebrugge",
                    text: "Zeebrugge is dan weer een belangrijke haven voor roll-on-roll-off-verkeer, de overslag van vloeibaar aardgas en cruises.",
                    img: "zeebrugge2_1600"
                },
                {
                    id: "cars",
                    title: "Auto's",
                    text: "Port of Antwerp-Bruges is wereldleider in roll-on-roll-off-trafieken, met Zeebrugge als grootste autohaven ter wereld. Auto’s van alle grote merken uit de automobielsector passeren er de revue.",
                    img: "cars2_1600"
                },
                {
                    id: "kiwis",
                    title: "Kiwi's",
                    text: "Elk jaar komen tussen mei en oktober 1,5 miljard kiwi’s aan in Zeebrugge, de grootste kiwihaven in Europa. Die worden daar verpakt en verdeeld naar de West-Europese markt.",
                    img: "kiwis_1600"
                },
                {
                    id: "gas",
                    title: "Vloeibaar gas",
                    text: "In Zeebrugge komt 15 procent van het Europese gas aan en is daarmee een van de belangrijkste toegangspunten voor lng-aanvoer. LNG of vloeibaar aardgas is de perfecte brandstof als opstap naar een koolstofarme toekomst.",
                    img: "gas_1600"
                }
            ]
        },
        transportScroller: {
            title: "Transportmodi",
            text: "Port of Antwerp-Bruges beschikt over een strategische ligging, dicht bij de grote Europese consumptie- en productiecentra. Bovendien beschikt het over een unieke mulitmodale mix voor duurzaam goederenvervoer van, naar en in de havensites.",
            antwerp: "Antwerpen",
            zeebrugge: "Zeebrugge",
            countryLabels: [
                {
                    id: "uk",
                    name: "Verenigd Koninkrijk",
                    coordinates: [1.18429, 51.25142]
                },
                {
                    id: "scandinavia",
                    name: "Scandinavië",
                    coordinates: [2.76144, 51.66144]
                },
                {
                    id: "netherlands",
                    name: "Nederland",
                    coordinates: [5.16806, 51.66264]
                },
                {
                    id: "germany",
                    name: "Duitsland",
                    coordinates: [6.80299, 51.51191]
                },
                {
                    id: "france",
                    name: "Frankrijk",
                    coordinates: [3.36286, 49.95462]
                },
                {
                    id: "luxembourg",
                    name: "Luxemburg",
                    coordinates: [6.104298, 49.78978]
                },
            ],
            scenes: [
                {
                    id: "rail",
                    label: "Spoor",
                    icon: "train"
                },
                {
                    id: "road",
                    label: "Wegtransport",
                    icon: "truck"
                },
                {
                    id: "pipelines",
                    label: "Pijpleidingen",
                    icon: "pipeline"
                },
                {
                    id: "water",
                    label: "Waterwegen",
                    icon: "boat"
                }
            ],
            waterTypes: [
                {
                    id: "estuary",
                    label: "Estuaire vaart",
                    icon: "boat-dark"
                },
                {
                    id: "binnenvaart",
                    label: "Binnenvaart",
                    icon: "boat-dark"
                },
                {
                    id: "shortsea",
                    label: "Shortsea",
                    icon: "shortsea"
                },
                {
                    id: "sea",
                    label: "Zeevaart",
                    icon: "containership"
                },
            ]
        },
        environmentIllo: {
            title: "Mens en omgeving",
            text: "Het draait niet alleen om economie. Port of Antwerp-Bruges wil méér doen en betekenen. De haven is zich bewust van haar impact op de omgeving en de omwonenden. Daarom investeert de haven in veiligheid, omgevingskwaliteit en mobiliteit.",
            scenes: [
                {
                    id: "empty step",
                    title: "",
                    text: "",
                    ctatext: "",
                    ctalink: "",
                    bottom: "3%",
                    left: "40px",
                    top: "unset",
                    right: "unset"
                },
                {
                    id: "plastic",
                    title: "Patje Plastic",
                    text: "In het Doeldok in Antwerpen vist ‘Patje Plastic’ drijfafval en plastics op zodat die niet verder verspreid worden via de waterwegen. De installatie bestaat uit een drijvende arm van 100 meter lang en 1,5 meter diep, die zwerfvuil naar een grote afvalbak duwt. Daar scheidt een reeks filters het grotere van het kleinere afval. Buikje vol? Een kraanwagen hijst de bakken op en brengt het afval weg. ‘Patje Plastic’ heeft geen elektriciteit nodig, maar werkt op wind-, golf-, en zwaartekracht. Het circulaire ontwerp van het Nederlandse bedrijf Allseas is gemaakt uit 75 procent gerecycleerde kunststof.",
                    ctatext: "Lees meer",
                    ctalink: "https://www.portofantwerpbruges.com/plasticvanger-patje-plastic",
                    bottom: "3%",
                    left: "40px",
                    top: "unset",
                    right: "unset"
                },
                {
                    id: "inoses",
                    title: "iNoses",
                    text: "In het Antwerpse havengebied staan er maar liefst 70 iNoses of virtuele neuzen. Die innovatieve iNoses detecteren schadelijke of hinderlijke gassen in de haven. Enerzijds geven ze realtime informatie waardoor we kunnen optreden bij incidenten of wanneer er ongeoorloofd ontgast wordt. Anderzijds kunnen we omwonenden waarschuwen zodra hinderlijke geurtjes hun richting uitwaaien.",
                    ctatext: "Lees meer",
                    ctalink: "https://www.portofantwerpbruges.com/onze-haven/mens-en-omgeving/luchtkwaliteit-en-geur",
                    bottom: "unset",
                    left: "40%",
                    top: "10%",
                    right: "unset"
                },
                {
                    id: "bridge",
                    title: "Verbindingsbrug",
                    text: "Met een lengte van 130 meter en een doorvaart van 55 meter geldt deze beweegbare brug over het Verbindingsdok in Zeebrugge als de langste draaibrug van Europa. De Verbindingsbrug staat in voor een betere ontsluiting tussen achter- en voorhaven en is bovendien een onmisbare schakel in de (fiets)infrastructuur voor veilig woon-werkverkeer.",
                    ctatext: "Lees meer",
                    ctalink: "https://www.portofantwerpbruges.com/onze-haven/vlot-en-veilig-verkeer",
                    bottom: "3%",
                    right: "40px",
                    top: "unset",
                    left: "unset"
                },
                {
                    id: "empty step",
                    title: "",
                    text: "",
                    ctatext: "",
                    ctalink: "",
                    bottom: "3%",
                    left: "40px",
                    top: "unset",
                    right: "unset"
                }
            ]
        },
        energyGraphic: {
            title: "Haven als energiehub",
            text: "Port of Antwerp-Bruges wil, in lijn met de Europese Green Deal, een klimaatneutrale haven zijn tegen 2050. Daarom werkt het aan diverse pioniersprojecten rond circulaire economie, alternatieve scheepsbrandstoffen, walstroom, CO₂ afvang en opslag en waterstof. Zo verankeren we ons als de groene energiehub van Europa.",
            chemicals: "Chemicaliën",
            hydrogen: "Waterstof",
            hydrocarriers: "Waterstofdragers",
            green: "Groene stroom",
            annotations: [
                {
                    text: `<bold>Windparken</bold> op zee wekken groene stroom op. Een deel van de opgewekte groene stroom wordt omgezet naar waterstof.`,
                    location: "left",
                    y: 100
                },
                {
                    text: `De lokale productie van waterstof wordt <bold>aangevuld met buitenlandse import</bold> van waterstof en waterstofdragers via de Noordzee.`,
                    location: "right",
                    y: 400
                },
                {
                    text: `In Zeebrugge wordt de <bold>groene waterstof overgeslagen en omgezet</bold>. Zo wil Port of Antwerp-Bruges een Europese voortrekker zijn in de waterstofeconomie.`,
                    location: "left",
                    y: 600
                },
                {
                    text: `Via een uitgebreide pijpleidinginfrastructuur worden de groene moleculen getransporteerd naar industriële clusters in Antwerpen en Europa.`,
                    location: "right",
                    y: 850
                },
                {
                    text: `Waterstof en waterstofdragers worden <bold>ingevoerd, opgeslagen en omgezet</bold> naar bouwstenen voor de chemische cluster in Antwerpen, de grootste in Europa.`,
                    location: "left",
                    y: 1300
                },
                {
                    text: `Vanuit Antwerpen worden <bold>waterstof, waterstofdragers en chemieproducten</bold> geëxporteerd naar het Europese hinterland via diverse transportmodi als pijpleiding, spoor en binnenvaart.`,
                    location: "right",
                    y: 1500
                },
                {
                    text: `Afgevangen CO<sub>2</sub>, uit de Antwerpse en Europese industrie, wordt per schip via de Oosterschelde en per pijpleiding via Rotterdam getransporteerd om bewaard te worden in lege gasvelden in de Noordzee.`,
                    location: "left",
                    y: 1700
                }
            ]
        }
    },
    en: {
        portraitmessage: "This page requires landscape mode",
        title: "Gateway to the world",
        start: "start",
        initiative: "An initiative of Port of Antwerp-Bruges",
        back: "back to start",
        belgiumToWorldScroller: {
            zeebrugge: "Zeebrugge",
            antwerp: "Antwerp",
            brussel: "Brussels",
            ratio: "Seagoing goods traffic ratio",
            benin: "Benin",
            brazil: "Brazil",
            chile: "Chile",
            us: "United States",
            uk: "United Kingdom",
            france: "France",
            germany: "Germany",
            poland: "Poland",
            czechia: "Czechia",
            austria: "Austria",
            hungary: "Hungary",
            egypt: "Egypt",
            russia: "Russia",
            oman: "Oman",
            india: "India",
            china: "China",
            japan: "Japan",
            ireland: "Ireland",
            slovakia: "Slovakia",
            switzerland: "Switzerland",
            canada: "Canada",
            namibia: "Namibia",
            morocco: "Morocco",
            europe: "Europe",
            middleeast: "Near East",
            africa: "Africa",
            southamerica: "Southern America",
            northamerica: "Northern and Central America",
            asia: "Middle and Far East",
            oceania: "Oceania",
            ton: "M tonnes",
            export: "Export",
            import: "Import",
            partner: "Partnerships",
            representative: "Representatives",
            investment: "Participation projects",
            scenes: [
                {
                    id: "one-port",
                    title: "One port, two locations",
                    text: "With 289 million tonnes of traffic per year passing through its two sites, Port of Antwerp-Bruges is a major global trade hub. Its goal is to become the first world port reconciling economy, people and climate."
                },
                {
                    id: "heart-europe",
                    title: "At the heart of Europe",
                    text: "No port is located so centrally in Europe, with 60 percent of European consumption centres within a 500 km radius. This makes Port of Antwerp-Bruges the fastest and cheapest connection to the European hinterland."
                },
                {
                    id: "connected",
                    title: "Strong connections to the whole world",
                    text: `With more than 300 liner services and 800 destinations, the port is connected to all continents, from the West to the Far East. In addition to imports, Port of Antwerp-Bruges is just as much an important export port.<br /><br /><a href="https://www.portofantwerpbruges.com/en/our-port/world-port" class="cta" target="_blank">Read more</a>`
                },
                {
                    id: "global",
                    title: "Think global, act local",
                    text: `Port of Antwerp-Bruges works with <span class="dot"></span> local representatives to maintain close ties with key markets. Strategic <span class="dot filled"></span> partnerships and <span style="white-space: nowrap;"><img src="/icons/investment.svg" width="14px"> participation</span> projects with ports around the world strengthen its international positioning and anchoring.`
                },
                {
                    id: "european",
                    title: "A European player on an Asia-dominated list",
                    text: `Ranking as one of the world’s top 15 largest container ports, Port of Antwerp-Bruges is easily able to host the world’s largest container vessels, carrying up to 24,000 Twenty foot Equivalent Unit (TEU) containers. This ranks it high on the list of world ports, a list dominated by players from Asia and headed by Shanghai.`
                },
            ]
        },
        goodsScroller: {
            title: "Goods transport",
            text: "A key link in the global logistics chain, Port of Antwerp-Bruges has become a leading European port for an extensive range of cargoes, each of which requires its own method of transportation, or more precisely its own form of shipping.",
            scenes: [
                {
                    id: "containers",
                    label: "Containers",
                    quantity: 159,
                    units: "million tonnes",
                    iconUrl : "/icons/container.svg",
                    topImgSrc : "images/containers_1600.jpg"
                },
                {
                    id: "liquid",
                    label: "Liquid bulk",
                    quantity: 82.4,
                    units: "million tonnes",
                    iconUrl : "./icons/liquid.svg",
                    topImgSrc : "images/Vopak_1600.jpg"
                },
                {
                    id: "rolling",
                    label: "Ro/Ro",
                    quantity: 19.2,
                    units: "million tonnes",
                    units: "miljoen ton",
                    iconUrl : "/icons/car.svg", 
                    topImgSrc : "images/cars_1600.jpg"
                },
                {
                    id: "dry",
                    label: "Dry bulk",
                    quantity: 15.1,
                    units: "million tonnes",
                    iconUrl : "/icons/drybulk.svg", 
                    topImgSrc : "images/stoneterminal_1600.jpg"
                },
                {
                    id: "breakbulk",
                    label: "Breakbulk",
                    quantity: 13.2,
                    units: "million tonnes",
                    iconUrl : "/icons/breakbulk.svg", 
                    topImgSrc : "images/breakbulk_1600.jpg"
                },
                {
                    id: "passengers",
                    label: "Passenger movements",
                    quantity: "114,000",
                    units: "",
                    iconUrl : "/icons/passengers.svg", 
                    topImgSrc : "images/cruise_1600.jpg"           
                },
            ]
        },
        motorGraphic: {
            title: "Belgium’s largest economic driver",
            text: "Port of Antwerp-Bruges covers 14,322 hectares, equivalent to 20,820 football pitches. Home to 1,400 companies, it provides 164,000 direct and indirect jobs. All in all, this generates added value of 20.8 billion euros, equivalent to 4.5 percent of Belgium’s GDP.",
            "zeebrugge": "Zeebrugge",
            "antwerp": "Antwerp",
            mapUrl: "images/flanders-map-en.svg",
            tiles: [
                {
                    quantity1: "14,322",
                    units1: "hectares or",
                    quantity2: "20.820",
                    units2: "football pitches",
                    iconUrl : "/icons/football.svg"
                },
                {
                    quantity1: " ",
                    units1: " ",
                    quantity2: "1,400",
                    units2: "companies",
                    iconUrl : "/icons/companies.svg"
                },
                {
                    quantity1: " ",
                    units1: " ",
                    quantity2: "164,000",
                    units2: "direct and indirect jobs",
                    iconUrl : "/icons/people.svg"     
                },
                {
                    quantity1: "20,8",
                    units1: "billion euros or",
                    quantity2: "4.5%",
                    units2: "of Belgium's GDP",
                    iconUrl : "/icons/pie.svg"                    
                },
            ]
        },
        satimageScroller: {
            scenes: [
                {
                    id: "antwerp",
                    title: "In Antwerp",
                    text: "Nearly all products you use in day-to-day life pass through the port. For example, Antwerp is known for handling and storing containers, breakbulk and chemical products.",
                    img: "antwerp_1600"
                },
                {
                    id: "containers",
                    title: "Containers" ,
                    text: "You can transport almost everything in a container: from garden furniture to clothing, from electronics to building materials. Reefers or refrigerated containers ensure that frozen foods, fruit and vegetables or medicines reach their final destination at the right temperature. For example, Antwerp is the largest port for bananas in Europe.",
                    img: "boxes_1600"
                },
                {
                    id: "breakbulk",
                    title: "Breakbulk",
                    text: "Antwerp is the platform of choice for shipping steel, forestry products and project cargo such as wind turbines and machinery. Thanks to the port’s centuries of experience and its specialised companies, you can ship all kinds of breakbulk cargoes through Port of Antwerp-Bruges.",
                    img: "breakbulk_1600"
                },
                {
                    id: "chemicalcluster",
                    title: "Chemical cluster",
                    text: "Antwerp is home to Europe’s largest integrated chemical cluster, one of the largest in the world. Eight of the world’s ten largest chemical companies have production facilities there, for example for oil and gas, as well as chemicals such as plastic pellets. Located in an industrial park close to this cluster, NextGen District is set to become the hotspot for the circular economy.",
                    img: "chemiecluster_1600"
                },
                {
                    id: "zeebrugge",
                    title: "In Zeebrugge",
                    text: "Turning to Zeebrugge, it is an important port for roll-on-roll-off traffic, the transshipment of liquefied natural gas and cruises.",
                    img: "zeebrugge2_1600"
                },
                {
                    id: "cars",
                    title: "Cars",
                    text: "Port of Antwerp-Bruges is a world leader in roll-on-roll-off traffic, with Zeebrugge the world’s largest car port. Cars from all major manufacturers pass through the port.",
                    img: "cars2_1600"
                },
                {
                    id: "kiwis",
                    title: "Kiwis",
                    text: "Every year between May and October, 1.5 billion kiwis arrive in Zeebrugge, Europe’s largest kiwi port. There, they are packaged and distributed to the Western European market.",
                    img: "kiwis_1600"
                },
                {
                    id: "gas",
                    title: "Liquified natural gas (LNG)",
                    text: "Zeebrugge is where 15 percent of European gas arrives, making it one of the main entry points for LNG supplies. LNG is the perfect fuel in the transition to a low-carbon future.",
                    img: "gas_1600"
                }
            ]
        },
        transportScroller: {
            title: "Transport modes",
            text: "Port of Antwerp-Bruges boasts a strategic location close to key European consumption and production centres. In addition, it features a unique multimodal mix for sustainable freight transport to, from and within the port sites.",
            antwerp: "Antwerp",
            zeebrugge: "Zeebrugge",
            countryLabels: [
                {
                    id: "uk",
                    name: "United Kingdom",
                    coordinates: [1.18429, 51.25142]
                },
                {
                    id: "scandinavia",
                    name: "Scandinavia",
                    coordinates: [2.76144, 51.66144]
                },
                {
                    id: "netherlands",
                    name: "Netherlands",
                    coordinates: [5.16806, 51.66264]
                },
                {
                    id: "germany",
                    name: "Germany",
                    coordinates: [6.80299, 51.51191]
                },
                {
                    id: "france",
                    name: "France",
                    coordinates: [3.36286, 49.95462]
                },
                {
                    id: "luxembourg",
                    name: "Luxembourg",
                    coordinates: [6.104298, 49.78978]
                },
            ],
            scenes: [
                {
                    id: "rail",
                    label: "Rail",
                    icon: "train"
                },
                {
                    id: "road",
                    label: "Road",
                    icon: "truck"
                },
                {
                    id: "pipelines",
                    label: "Pipelines",
                    icon: "pipeline"
                },
                {
                    id: "water",
                    label: "Waterways",
                    icon: "boat"
                }
            ],
            waterTypes: [
                {
                    id: "estuary",
                    label: "Estuary navigation",
                    icon: "boat-dark"
                },
                {
                    id: "binnenvaart",
                    label: "Inland navigation",
                    icon: "boat-dark"
                },
                {
                    id: "shortsea",
                    label: "Shortsea",
                    icon: "shortsea"
                },
                {
                    id: "sea",
                    label: "Maritime shipping",
                    icon: "containership"
                },
            ]
        },
        environmentIllo: {
            title: "People and the environment",
            text: "Business is not the only focus. Port of Antwerp-Bruges wants to go the extra mile, doing more and meaning more. Well aware of its impact on the environment and local residents, the port is investing in safety, environmental quality and mobility.",
            scenes: [
                {
                    id: "empty step",
                    title: "",
                    text: "",
                    ctatext: "",
                    ctalink: "",
                    bottom: "3%",
                    left: "40px",
                    top: "unset",
                    right: "unset"
                },
                {
                    id: "plastic",
                    title: "Patje Plastic",
                    text: "In Antwerp’s Doeldok, ‘Patje Plastic’ is a facility designed to fish up floating waste and plastics, preventing them being spread further throughout the waterway network. It consists of a floating arm 100 metres long and 1.5 metres deep which moves waste into a large bin where a series of filters separate the larger articles from the smaller ones. Full up? A crane truck hoists up the bins and takes the waste away. ‘Patje Plastic’ does without electricity, working with wind, waves and gravity. Designed by Dutch company Allseas, the circular installation is made of 75 percent recycled plastic.",
                    ctatext: "Read more",
                    ctalink: "https://www.portofantwerpbruges.com/en/plastic-catcher-patje-plastic",
                    bottom: "3%",
                    left: "40px",
                    top: "unset",
                    right: "unset"
                },
                {
                    id: "inoses",
                    title: "iNoses",
                    text: "The Antwerp port area boasts no less than 70 iNoses, or virtual noses. These innovative devices detect harmful or irritating gases in the port. On the one hand, they provide real-time information allowing us to react to incidents or when any unauthorised degassing occurs. On the other hand, we can alert local residents as soon as any irritating smells head in their direction.",
                    ctatext: "Read more",
                    ctalink: "https://www.portofantwerpbruges.com/en/our-port/people-and-environment/air-quality-and-odour",
                    bottom: "unset",
                    left: "40%",
                    top: "10%",
                    right: "unset"
                },
                {
                    id: "bridge",
                    title: "Connecting bridge",
                    text: "130 metres long and 55 metres above water level, this movable bridge over the Verbindingsdok in Zeebrugge is considered the longest swing bridge in Europe. The bridge will provide better access between the inner and outer port and is also an indispensable link in the (bicycle) infrastructure for safe commuting.",
                    ctatext: "Read more",
                    ctalink: "https://www.portofantwerpbruges.com/en/our-port/smooth-and-safe-traffic",
                    bottom: "3%",
                    right: "40px",
                    top: "unset",
                    left: "unset"
                },
                {
                    id: "empty step",
                    title: "",
                    text: "",
                    ctatext: "",
                    ctalink: "",
                    bottom: "3%",
                    left: "40px",
                    top: "unset",
                    right: "unset"
                }
            ]
        },
        energyGraphic: {
            title: "The port as an energy hub",
            text: "Taking up the European Green Deal, Port of Antwerp-Bruges wants to become a climate-neutral port by 2050. It is working on several pioneering projects related to the circular economy, alternative marine fuels, shore power, CO₂ capture and storage (CCS) and hydrogen, all contributing to making the port Europe’s green energy hub.",
            chemicals: "Chemicals",
            hydrogen: "Hydrogen",
            hydrocarriers: "Hydrogen carriers",
            green: "Green power",
            annotations: [
                {
                    text: `Offshore wind farms generate green power, some of which is then converted to hydrogen.`,
                    location: "left"
                },
                {
                    text: `Local production of hydrogen is supplemented by imports of hydrogen and hydrogen carriers from abroad via the North Sea.`,
                    location: "right"
                },
                {
                    text: `In Zeebrugge, the green hydrogen is transshipped and converted, in line with Port of Antwerp-Bruges’ aim to become a European pioneer in the hydrogen economy.`,
                    location: "left"
                },
                {
                    text: `Via an extensive network of pipelines, the green molecules are transported to industrial clusters in Antwerp and Europe.`,
                    location: "right"
                },
                {
                    text: `Hydrogen and hydrogen carriers are imported, stored and converted into building blocks for the chemical cluster in Antwerp, the largest in Europe.`,
                    location: "left"
                },
                {
                    text: `From Antwerp, hydrogen, hydrogen carriers and chemical products are exported to the European hinterland via various transport modes such as pipelines, railways and barges.`,
                    location: "right"
                },
                {
                    text: `CO<sub>2</sub> captured from industrial companies in Antwerp and Europe is transported by ship via the Scheldt or by pipeline through Rotterdam to be stored in empty gas fields in the North Sea.`,
                    location: "left"
                }
            ]
        }
    }
}

export default copy;