<script>
    import { tweened } from 'svelte/motion';
    import { cubicOut } from 'svelte/easing';

    export let moved;

    const xTranslation = tweened(0, {
        duration: 1000,
        easing: cubicOut
    })
    
    const yTranslation = tweened(0, {
        duration: 1000,
        easing: cubicOut
    })

    $: if(moved){
        xTranslation.set(Math.random()*100)
        yTranslation.set(-Math.random()*150)
    }
    $: if(!moved){
        xTranslation.set(0)
        yTranslation.set(0)
    }

</script>

<g transform={`translate(${$xTranslation}, ${$yTranslation})`}>
    <slot></slot>
</g>