<script>
  import { line } from "d3-shape";
  import pipelines from "./pipelines.json";

  export let width;
  export let height;
  export let projection;
  export let scrollIndex;
  export let ports;

  $: nodes = pipelines.features.filter((d) => d.geometry.type == "Point");
  $: lines = pipelines.features.filter((d) => d.geometry.type == "LineString");
</script>

<svg {width} {height}>
  {#if scrollIndex == 2}
    {#each lines as pipeline}
      <path
        d={line()(pipeline.geometry.coordinates.map((d) => projection(d)))}
        fill={"none"}
        stroke={"white"}
        stroke-width={4}
      />
      <path
        d={line()(pipeline.geometry.coordinates.map((d) => projection(d)))}
        fill={"none"}
        stroke={"black"}
        stroke-width={2}
      />
    {/each}
    {#each nodes as piplineNode}
      <circle
        cx={projection(piplineNode.geometry.coordinates)[0]}
        cy={projection(piplineNode.geometry.coordinates)[1]}
        r={4}
        fill={"black"}
        stroke={"white"}
        stroke-width={1}
      />
    {/each}
  {/if}
  {#if projection(ports[0].coordinates)[0]}
    {#each ports as port}
      <circle
        cx={projection(port.coordinates)[0]}
        cy={projection(port.coordinates)[1]}
        r={10}
        fill={"#D02F35"}
        stroke={"white"}
        stroke-width={6}
      />
    {/each}
    {/if}
</svg>
