<script>
  export let message;
</script>

<div class="overlay-container">
  <div class="message">{message}</div>
</div>

<style>
  .overlay-container {
    background-color: black;
    opacity: 0.8;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .message {
    color: white;
    font-size: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
