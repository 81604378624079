<script>
  export let copy;
</script>

<div class="graphic-container">
  <div class="text-container">
    <h2>{copy.title}</h2>
    <p>{copy.text}</p>
  </div>

  <div class="tiles-container-grid">

    <div class="tile tile1">
      <div class="icon"><img src={copy.tiles[0].iconUrl} alt="" /></div>
      <div class="quant">
        <span class="quantity">{copy.tiles[0].quantity1}</span><br/>
      {copy.tiles[0].units1}<br/>
        <span class="quantity">{copy.tiles[0].quantity2}</span><br />
        {copy.tiles[0].units2}
      </div>
    </div>

    <div class="tile tile2">
      <div class="icon"><img src={copy.tiles[1].iconUrl} alt="" /></div>
      <div class="quant">
        <span class="quantity">{copy.tiles[1].quantity2}</span><br />
        {copy.tiles[1].units2}
      </div>
    </div>

    <div class="tile tile3">
      <div class="icon"><img src={copy.tiles[2].iconUrl} alt="" /></div>
      <div class="quant">
        <span class="quantity">{copy.tiles[2].quantity2}</span><br />
        {copy.tiles[2].units2}
      </div>
    </div>

    <div class="tile tile4">
      <div class="icon"><img src={copy.tiles[3].iconUrl} alt="" /></div>
      <div class="quant">
        <span class="quantity">{copy.tiles[3].quantity2}</span><br />
        {copy.tiles[3].units2}<br />
        <span class="quantity">{copy.tiles[3].quantity1}</span><br/>
      {copy.tiles[3].units1}
      </div>
    </div>

  </div>

  <div class="bottom-container">
    <div class="map-container">
      <img class="map" src={copy.mapUrl} alt="" />
    </div>
  </div>
</div>

<style>
  .text-container {
    font-size: 1.3rem;
    font-weight: 300;
  }
  .tiles-container-grid {
    color: #97cdf2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "tile1 tile2 tile3 tile4";
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 24px;
    align-items: end;
  }
  .tile {
    display: flex;
    align-items: end;
    flex-wrap: wrap;
  }
  .tile1 {
    grid-area: tile1;
  }
  .tile2 {
    grid-area: tile2;
  }
  .tile3 {
    grid-area: tile3;
  }
  .tile4 {
    grid-area: tile4;
  }
  .icon {
    width: 80px;
    float: left;
  }
  .icon img {
    width: 100%;
  }

  .graphic-container {
    width: 100%;
    min-height: 100vh;
    padding-top: 100px;
    background-color: #434a59;
    position: relative;
    padding-bottom: 300px;
  }
  .quant {
    padding-left: 8px;
    float: left;
  }

  .text-container {
    max-width: 600px;
    margin: auto;
    text-align: center;
    color: white;
  }
  span.quantity {
    font-size: 1.5em;
    font-weight: 700;
  }
  .bottom-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    min-height: 300px;
  }
  .map-container {
    position: relative;
    bottom: -64px;
  }
  img.map {
    max-width: 1000px;
    width: 65%;
  }
</style>
