<script>
  import { onDestroy, setContext } from "svelte";
  import { mapbox, key } from "./mapbox.js";

  export let scrollIndex;
  export let geoJsonToFit;

  setContext(key, {
    getMap: () => map,
  });

  let container;
  let map;

  function load() {
    map = new mapbox.Map({
      container,
      style: "mapbox://styles/mediafin/cl94iakqn006514n3cjkllqwa",
      bounds: [1.56970, 50.67869, 4.87819, 51.59850]
    });

    map.on("load", (f) => {
      map.setRenderWorldCopies(true);
      updateBounds()
      map.on("viewreset", updateBounds);
      map.on("zoom", updateBounds);
      map.on("drag", updateBounds);
      map.on("move", updateBounds);
      //D3's fitExtent handles longitudes above and below -180, but not if one is above 180 and the other is above -180
      map.on("moveend", () => {
        if(geoJsonToFit.features[0].geometry.coordinates[0] > 180 && geoJsonToFit.features[1].geometry.coordinates[0] > -180){
          map.fitBounds([-181, -60, 181, 70])
        }
      })
    });
  }

  function updateBounds() {
    const bounds = map.getBounds()
    geoJsonToFit.features[0].geometry.coordinates = [bounds._ne.lng, bounds._ne.lat]
    geoJsonToFit.features[1].geometry.coordinates = [bounds._sw.lng, bounds._sw.lat]
  }

  $: if (scrollIndex == 0 && map) {
    map.fitBounds([1.56970, 50.67869, 4.87819, 51.59850])
  }
  $: if (scrollIndex == 1 && map) {
    map.fitBounds([-14, 35, 19, 62])
  }
  $: if (scrollIndex == 2 && map) {
    map.fitBounds([-145, -60, 170, 70])
  }

  onDestroy(() => {
    if (map) map.remove();
  });
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://unpkg.com/mapbox-gl/dist/mapbox-gl.css"
    on:load={load}
  />
</svelte:head>

<div bind:this={container}>
  {#if map}
    <slot />
  {/if}
</div>

<style>
  div {
    width: 100%;
    height: 100%;
  }
</style>
