<script>
  import Scroller from "@sveltejs/svelte-scroller";
  import ScrollerScene from "./ScrollerScene.svelte";
  import {fade} from 'svelte/transition'

  export let copy;
  export let smallScreen;

  let index = 0;
  let offset, progress;

  $: images = copy.scenes.map(d => topImgSrc)
  let topImgSrc = "";

  $: topImgSrc = copy.scenes[index].topImgSrc;

  let width, height;
</script>

<Scroller top={0} bottom={1} bind:index bind:offset bind:progress>
  <div
    class="scroll-graphic"
    slot="background"
    bind:clientWidth={width}
    bind:clientHeight={height}
  >

    <div class={smallScreen ? "content-container small-screen" : "content-container"}>
        <div class="text">
            <h2>{copy.title}</h2>
            <p>{copy.text}</p>
        </div>
        {#if !smallScreen}
        <div class={smallScreen ? "image small-screen" : "image"}>
          {#each [images[index]] as src (index)}
            <img in:fade {src} alt=""/>
          {/each}
        </div>
        {/if}

        <!--div class="tiles"-->
        {#each copy.scenes as product, i}
        <div class={i == index ? "tile active" : "tile"}>
          {#if !smallScreen}
            <div class={smallScreen ? "icon-container small-screen" : "icon-container"}>
                <img class='icon' src={product.iconUrl} alt=""/>
            </div>
            {/if}
            <div class={smallScreen ? "title-container small-screen"  : "title-container"}>
            <div class="tile-title">{product.label}</div>
        </div>
            <div><span class="quantity">{product.quantity}</span> {product.units}</div>
        </div>
    {/each}
    </div>
  </div>

  <div slot="foreground">
    {#each [1, 2, 3, 4, 5, 6] as scene, i}
      <ScrollerScene sceneTitle={""} sceneText={""} invisible={true} />
    {/each}
  </div>
</Scroller>

<style>
  .scroll-graphic {
    width: 100%;
    height: 100vh;
    background-color: #434a59;
    color: white;
    padding: 70px 20px 20px 20px;
  }
  .content-container {
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 1600px;
    max-height: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "text text image image image image"
      "tile tile tile tile tile tile";
  }
  .content-container.small-screen {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "text text text tile tile tile"
      "text text text tile tile tile";
  }
  .text {
    grid-area: text;
    padding-right: 8px;
    font-size: 1.1rem;
    font-weight: 300;
  }
  .image {
    grid-area: image;
    overflow: hidden;
    margin: 4px;
  }
  .image.small-screen {
    align-self: end;
  }
  .image img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
  }
  .tile {
    background-color: #daecfb;
    color: #434a59;
    padding: 8px;
    display: inline-block;
    margin: 4px;
    font-size: 1.3rem;
    font-weight: 300;
  }
  .tile.active {
    background-color: #97cdf2;
  }
  .icon-container {
    height: 60px;
    text-align: center;
  }
  .icon-container.small-screen {
    height: 40px;
    text-align: center;
  }
  .icon {
    height: 100%;
  }
  .title-container {
    height: 40px;
  }
  .title-container.small-screen {
    height: auto;
    margin-bottom: 6px;
  }
  .tile-title{
    font-size: 1.1rem;
    font-weight: bold;
  }
  .quantity {
    font-size: calc(15px + 0.390625vw);
    font-weight: 900;
  }
</style>
