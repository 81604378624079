<script>
import AnimatedG from './AnimatedG.svelte'
import { cubicOut } from 'svelte/easing';
import { tweened } from 'svelte/motion';
export let width
export let height
export let scrollIndex;

const armY = tweened(100, {
    duration: 1000,
    easing: cubicOut
})
const bridgeX = tweened(0, {
    duration: 1000,
    easing: cubicOut
})
$: if(scrollIndex > 0){
    armY.set(0)
}
$: if(scrollIndex == 0){
    armY.set(100)
}
/*$: if(scrollIndex != 1){
    armY.set(0)
}*/
$: if(scrollIndex == 3){
    bridgeX.set(300)
}
$: if(scrollIndex != 3){
    bridgeX.set(0)
}

</script>

<style>
    #sliertjes {
        transition: opacity 0.5s;
    }
</style>

<svg {width} {height} viewBox="0 0 1924 1079">
    <defs>
        <style>
            .cls-1,
            .cls-2,
            .cls-3 {
                fill: none;
            }

            .cls-4,
            .cls-5,
            .cls-6,
            .cls-7,
            .cls-8,
            .cls-9,
            .cls-10,
            .cls-11,
            .cls-12,
            .cls-13,
            .cls-14,
            .cls-15,
            .cls-16,
            .cls-17,
            .cls-18,
            .cls-19,
            .cls-20,
            .cls-21,
            .cls-22,
            .cls-23,
            .cls-24,
            .cls-25,
            .cls-26 {
                mix-blend-mode: screen;
            }

            .cls-4,
            .cls-5,
            .cls-6,
            .cls-16,
            .cls-17,
            .cls-18,
            .cls-19,
            .cls-27,
            .cls-28,
            .cls-29,
            .cls-26,
            .cls-30,
            .cls-31 {
                opacity: .3;
            }

            .cls-4,
            .cls-9,
            .cls-18,
            .cls-32,
            .cls-33,
            .cls-34,
            .cls-35 {
                fill: #f1f2f2;
            }

            .cls-5,
            .cls-36 {
                fill: #ccc479;
            }

            .cls-6,
            .cls-16,
            .cls-37 {
                fill: #8197d4;
            }

            .cls-7,
            .cls-8,
            .cls-9,
            .cls-10,
            .cls-11,
            .cls-22,
            .cls-38,
            .cls-39,
            .cls-40,
            .cls-41,
            .cls-42,
            .cls-43,
            .cls-44,
            .cls-34,
            .cls-45 {
                opacity: .5;
            }

            .cls-7,
            .cls-46,
            .cls-47 {
                fill: #bc2b09;
            }

            .cls-8,
            .cls-48,
            .cls-49,
            .cls-27,
            .cls-50,
            .cls-51,
            .cls-52,
            .cls-24 {
                fill: #333e46;
            }

            .cls-10,
            .cls-22 {
                fill: #aed3e8;
            }

            .cls-11,
            .cls-53,
            .cls-41 {
                fill: #d5e1e2;
            }

            .cls-12,
            .cls-13,
            .cls-52,
            .cls-54,
            .cls-55,
            .cls-56,
            .cls-57 {
                opacity: .7;
            }

            .cls-12,
            .cls-58 {
                fill: #028e3c;
            }

            .cls-13,
            .cls-59,
            .cls-60 {
                fill: #ea5b21;
            }

            .cls-14,
            .cls-15,
            .cls-20,
            .cls-21,
            .cls-61,
            .cls-62,
            .cls-32,
            .cls-63 {
                opacity: .15;
            }

            .cls-14,
            .cls-64 {
                fill: #abc27a;
            }

            .cls-15,
            .cls-65 {
                fill: #93979e;
            }

            .cls-66 {
                clip-path: url(#clippath);
            }

            .cls-16,
            .cls-17,
            .cls-18,
            .cls-19,
            .cls-20,
            .cls-21,
            .cls-22,
            .cls-23,
            .cls-32,
            .cls-63,
            .cls-67,
            .cls-33,
            .cls-29,
            .cls-68,
            .cls-69,
            .cls-70,
            .cls-71,
            .cls-72,
            .cls-73,
            .cls-74,
            .cls-75,
            .cls-76,
            .cls-77,
            .cls-78,
            .cls-79,
            .cls-37,
            .cls-80,
            .cls-81,
            .cls-82,
            .cls-83,
            .cls-84,
            .cls-30 {
                fill-rule: evenodd;
            }

            .cls-17,
            .cls-85,
            .cls-63,
            .cls-67,
            .cls-74,
            .cls-86 {
                fill: #d8e3e3;
            }

            .cls-19,
            .cls-20,
            .cls-23,
            .cls-79,
            .cls-30 {
                fill: #a7a9ac;
            }

            .cls-21,
            .cls-72 {
                fill: #93d1be;
            }

            .cls-87 {
                fill: #f4cd96;
            }

            .cls-88 {
                fill: #f8a4e4;
            }

            .cls-89 {
                fill: #f8ca7e;
            }

            .cls-90 {
                fill: #f39c11;
            }

            .cls-91 {
                fill: #027a34;
            }

            .cls-92 {
                fill: #6695c5;
            }

            .cls-93 {
                fill: #eca70d;
            }

            .cls-94 {
                fill: #f5cbb1;
            }

            .cls-95 {
                fill: #edfaff;
            }

            .cls-96 {
                fill: #f49900;
            }

            .cls-97,
            .cls-45 {
                fill: #fff;
            }

            .cls-98,
            .cls-99,
            .cls-38,
            .cls-55,
            .cls-25 {
                fill: #ffce00;
            }

            .cls-100 {
                fill: #fef0d6;
            }

            .cls-101 {
                fill: #e2fafb;
            }

            .cls-102 {
                fill: #f0b0a7;
            }

            .cls-103 {
                fill: #e5d5b9;
            }

            .cls-104 {
                fill: #969797;
            }

            .cls-105 {
                fill: #7f8487;
            }

            .cls-106,
            .cls-107,
            .cls-54 {
                fill: #a1a2aa;
            }

            .cls-108,
            .cls-82 {
                fill: #939598;
            }

            .cls-109,
            .cls-81 {
                fill: #875c52;
            }

            .cls-110,
            .cls-39 {
                fill: #b7b7b7;
            }

            .cls-111 {
                fill: #9cdbfd;
            }

            .cls-112 {
                fill: #ba6b5b;
            }

            .cls-113,
            .cls-83 {
                fill: #8bdce8;
            }

            .cls-114 {
                fill: #909295;
            }

            .cls-115 {
                fill: #b5d4e2;
            }

            .cls-116 {
                fill: #babbc6;
            }

            .cls-117 {
                fill: #babcbd;
            }

            .cls-118 {
                fill: #cf7b5f;
            }

            .cls-119 {
                fill: #cde3ef;
            }

            .cls-120 {
                fill: #cdced3;
            }

            .cls-121 {
                fill: #da251d;
            }

            .cls-122 {
                fill: #a5a7a9;
            }

            .cls-123,
            .cls-40 {
                fill: #bddadc;
            }

            .cls-124 {
                fill: #ababab;
            }

            .cls-125 {
                fill: #cfd0d1;
            }

            .cls-126 {
                fill: #dedede;
            }

            .cls-127,
            .cls-76 {
                fill: #d1d3d4;
            }

            .cls-128 {
                fill: #c5730d;
            }

            .cls-129,
            .cls-73 {
                fill: #d68080;
            }

            .cls-130 {
                fill: #daeaf2;
            }

            .cls-131,
            .cls-75 {
                fill: #e0e0e0;
            }

            .cls-132 {
                fill: #daebf2;
            }

            .cls-133 {
                fill: #231f20;
            }

            .cls-134 {
                fill: #21ad65;
            }

            .cls-135 {
                fill: #072f60;
            }

            .cls-136 {
                fill: #0e3b81;
            }

            .cls-137 {
                fill: #35ba74;
            }

            .cls-138 {
                fill: #a51c1c;
            }

            .cls-139 {
                fill: #6eb9d4;
            }

            .cls-140,
            .cls-141,
            .cls-142,
            .cls-42 {
                fill: #88ccea;
            }

            .cls-143,
            .cls-57 {
                fill: #5387b3;
            }

            .cls-144 {
                fill: #88bfad;
            }

            .cls-145 {
                fill: #6d6e6f;
            }

            .cls-146 {
                fill: #4c5d69;
            }

            .cls-147 {
                fill: #7897ea;
            }

            .cls-148 {
                fill: #7a7a7a;
            }

            .cls-149,
            .cls-70 {
                fill: #82a6f5;
            }

            .cls-150,
            .cls-71 {
                fill: #7aaabd;
            }

            .cls-23,
            .cls-50,
            .cls-107,
            .cls-67,
            .cls-33,
            .cls-151,
            .cls-152,
            .cls-86,
            .cls-35 {
                opacity: .4;
            }

            .cls-141,
            .cls-47,
            .cls-49,
            .cls-99,
            .cls-153,
            .cls-27,
            .cls-28,
            .cls-50,
            .cls-107,
            .cls-142,
            .cls-51,
            .cls-38,
            .cls-39,
            .cls-40,
            .cls-41,
            .cls-42,
            .cls-52,
            .cls-54,
            .cls-55,
            .cls-30,
            .cls-151,
            .cls-154,
            .cls-43,
            .cls-155 {
                mix-blend-mode: multiply;
            }

            .cls-153 {
                fill: #e6e6e6;
            }

            .cls-28 {
                fill: #41999e;
            }

            .cls-142,
            .cls-155,
            .cls-156 {
                opacity: .6;
            }

            .cls-51,
            .cls-68 {
                opacity: .8;
            }

            .cls-61,
            .cls-29,
            .cls-156,
            .cls-31 {
                fill: #d6d4d4;
            }

            .cls-62,
            .cls-152 {
                fill: #f7f5f4;
            }

            .cls-60 {
                opacity: .2;
            }

            .cls-68,
            .cls-84 {
                fill: #e6e7e8;
            }

            .cls-69 {
                fill: #7786b0;
            }

            .cls-77 {
                fill: #b2bcbd;
            }

            .cls-78 {
                fill: #bcbec0;
            }

            .cls-80 {
                fill: #7f848a;
            }

            .cls-2 {
                stroke: #fff;
                stroke-width: 2.83px;
            }

            .cls-2,
            .cls-3 {
                stroke-miterlimit: 10;
            }

            .cls-157 {
                isolation: isolate;
            }

            .cls-154 {
                opacity: .9;
            }

            .cls-44 {
                fill: #8d0e02;
            }

            .cls-3 {
                stroke: #cdced3;
                stroke-width: .25px;
            }
        </style>
        <clipPath id="clippath">
            <polygon class="cls-48"
                points="1517.33 430.05 1509.15 451.48 1474.86 464.34 1483.02 444.75 1517.33 430.05" />
        </clipPath>
    </defs>
    {#if height}
    <g class="background">
        <rect x="0" y="-400" width={'100%'} height={height/2} style:fill="#f4cd96"></rect>
        <rect x="0" y={height/2 + 300} width={'100%'} height={1200} style:fill="#9cdbfd"></rect>
    </g>
    {/if}
    <g class="cls-157">
        <g id="Layer_2">
            <g id="Back">
                <g>
                    <rect class="cls-87" x=".5" width="1920" height="673.03" />
                    <rect class="cls-111" x=".5" y="591.44" width="1920" height="487.56" />
                </g>
            </g>
            <g id="Base">
                <g id="zee">
                    <rect class="cls-142" x="540" y="627.76" width="935.76" height="32.14" rx="3.25" ry="3.25" />
                    <rect class="cls-141" x="1066.23" y="731.53" width="93.38" height="13.65" rx="1.38" ry="1.38" />
                    <rect class="cls-42" x="993.71" y="747.23" width="53.77" height="7.86" rx=".79" ry=".79" />
                    <rect class="cls-42" x="485.69" y="726.9" width="87.29" height="12.76" rx="1.29" ry="1.29" />
                    <rect class="cls-141" x="1521.05" y="729.23" width="119.96" height="10.88" rx="1.1" ry="1.1" />
                    <rect class="cls-42" x="1655.76" y="720.44" width="46.17" height="7.5" rx=".76" ry=".76" />
                    <rect class="cls-42" x="1421.11" y="737.78" width="37.15" height="6.03" rx=".61" ry=".61" />
                    <rect class="cls-42" x="589.34" y="717.34" width="53.77" height="7.86" rx=".79" ry=".79" />
                    <rect class="cls-42" x="771.65" y="669.79" width="73.3" height="10.72" rx="1.08" ry="1.08" />
                    <rect class="cls-141" x="170.93" y="685.26" width="121.95" height="15.03" rx="1.52" ry="1.52" />
                    <path class="cls-42"
                        d="M1921,710.73h-47.8c-.54,0-.98,.44-.98,.98v7.75c0,.54,.44,.98,.98,.98h47.8v-9.72Z" />
                </g>
                <g id="app">
                    <g>
                        <rect class="cls-123" x="599.65" y="411.39" width="191.45" height="177.11" />
                        <rect class="cls-105" x="769.99" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="769.99" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="769.99" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="769.99" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="769.99" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="769.99" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="769.99" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="769.99" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="749.97" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="729.95" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="709.93" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="689.91" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="420.23" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="441.68" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="463.14" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="483.81" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="505.27" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="526.72" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="547.71" width="8.69" height="10.61" />
                        <rect class="cls-105" x="671.43" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="651.41" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="631.39" y="569.16" width="8.69" height="10.61" />
                        <rect class="cls-105" x="611.37" y="569.16" width="8.69" height="10.61" />
                    </g>
                    <g>
                        <polygon class="cls-103"
                            points="777.37 423.39 777.37 354.4 879.36 354.4 879.36 588.5 808.23 588.5 773.96 588.5 702.83 588.5 702.83 423.39 777.37 423.39" />
                        <rect class="cls-105" x="863.57" y="370.63" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="370.63" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="370.63" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="370.63" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="370.63" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="391.51" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="391.51" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="391.51" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="391.51" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="391.51" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="412.38" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="412.38" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="412.38" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="412.38" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="412.38" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="767.03" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="747.55" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="728.07" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="708.59" y="432.5" width="8.46" height="10.32" />
                        <rect class="cls-105" x="767.03" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="747.55" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="728.07" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="708.59" y="453.37" width="8.46" height="10.32" />
                        <rect class="cls-105" x="767.03" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="747.55" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="728.07" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="708.59" y="474.25" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="767.03" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="747.55" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="728.07" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="708.59" y="494.66" width="8.46" height="10.32" />
                        <rect class="cls-105" x="767.03" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="747.55" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="728.07" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="708.59" y="515.54" width="8.46" height="10.32" />
                        <rect class="cls-105" x="767.03" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="747.55" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="728.07" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="708.59" y="536.41" width="8.46" height="10.32" />
                        <rect class="cls-105" x="863.57" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="844.09" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="824.61" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="805.13" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="785.65" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="767.03" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="747.55" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="728.07" y="557.1" width="8.46" height="10.32" />
                        <rect class="cls-105" x="708.59" y="557.1" width="8.46" height="10.32" />
                    </g>
                    <polygon class="cls-40"
                        points="599.63 588.53 599.63 521.02 702.83 423.78 702.83 588.53 599.63 588.53" />
                </g>
                <g id="bos1">
                    <path class="cls-91"
                        d="M1152.11,588.84c.94-2.55,1.45-5.27,1.45-8.11,0-8.39-4.45-15.82-11.29-20.42,6.12-3.25,10.24-9.33,10.24-16.29,0-10.39-9.16-18.81-20.47-18.81s-20.47,8.42-20.47,18.81c0,.33,0,.65,.03,.98-3.36-2.97-7.93-4.81-12.96-4.81-6.98,0-13.06,3.52-16.27,8.72-3.29-7.59-11.35-12.96-20.78-12.96-10.71,0-19.66,6.92-21.85,16.16-3.26-1.25-6.84-1.94-10.59-1.94-7.64,0-14.56,2.86-19.54,7.48-2.14-2.26-5.3-3.69-8.82-3.69-6.46,0-11.7,4.81-11.7,10.75,0,.33,.02,.65,.05,.97-.6-.05-1.2-.08-1.81-.08-2.47,0-4.83,.4-7.02,1.13-2.14-2.56-5.5-4.22-9.29-4.22-1.96,0-3.81,.45-5.43,1.23-4.2-4.13-10.17-6.71-16.8-6.71-2.39,0-4.7,.34-6.87,.96,0-.21,.02-.42,.02-.63,0-11.35-10.01-20.55-22.37-20.55s-21.51,8.45-22.31,19.14c-2.85-1.11-5.99-1.73-9.28-1.73-13.15,0-23.8,9.79-23.8,21.87,0,4.76,1.66,9.16,4.47,12.76h283.45Z" />
                    <path class="cls-58"
                        d="M962.39,542.53c0,2.27-2.01,4.12-4.48,4.12h-9.72c-2.47,0-4.48-1.84-4.48-4.12h0c0-2.27,2.01-4.12,4.48-4.12h9.72c2.47,0,4.48,1.84,4.48,4.12h0Z" />
                    <path class="cls-134"
                        d="M958.76,537.93c0,1.98-1.75,3.59-3.91,3.59h-6.28c-2.16,0-3.91-1.61-3.91-3.59h0c0-1.98,1.75-3.59,3.91-3.59h6.28c2.16,0,3.91,1.61,3.91,3.59h0Z" />
                    <path class="cls-137"
                        d="M953.59,534.98c0,1.22-1.07,2.21-2.4,2.21h-3.86c-1.33,0-2.4-.99-2.4-2.21h0c0-1.22,1.08-2.21,2.4-2.21h3.86c1.33,0,2.4,.99,2.4,2.21h0Z" />
                    <path class="cls-137"
                        d="M930.89,541.92c0,1.22-1.08,2.21-2.4,2.21h-3.86c-1.33,0-2.4-.99-2.4-2.21h0c0-1.22,1.07-2.21,2.4-2.21h3.86c1.33,0,2.4,.99,2.4,2.21h0Z" />
                    <path class="cls-134"
                        d="M947.49,539.99c0,1.76-1.56,3.19-3.48,3.19h-5.58c-1.92,0-3.48-1.43-3.48-3.19h0c0-1.76,1.56-3.19,3.48-3.19h5.58c1.92,0,3.48,1.43,3.48,3.19h0Z" />
                    <path class="cls-137"
                        d="M942.89,537.37c0,1.08-.96,1.96-2.14,1.96h-3.43c-1.18,0-2.14-.88-2.14-1.96h0c0-1.08,.96-1.96,2.14-1.96h3.43c1.18,0,2.14,.88,2.14,1.96h0Z" />
                    <path class="cls-134"
                        d="M892.13,540.33c0,1.89-1.67,3.42-3.72,3.42h-5.98c-2.06,0-3.72-1.53-3.72-3.42h0c0-1.89,1.67-3.42,3.72-3.42h5.98c2.06,0,3.72,1.53,3.72,3.42h0Z" />
                    <path class="cls-137"
                        d="M887.2,537.52c0,1.16-1.02,2.1-2.29,2.1h-3.68c-1.26,0-2.29-.94-2.29-2.1h0c0-1.16,1.02-2.1,2.29-2.1h3.68c1.26,0,2.29,.94,2.29,2.1h0Z" />
                    <path class="cls-137"
                        d="M894.09,541.69c0,1.03-.91,1.87-2.04,1.87h-3.27c-1.12,0-2.04-.84-2.04-1.87h0c0-1.03,.91-1.87,2.04-1.87h3.27c1.12,0,2.04,.84,2.04,1.87h0Z" />
                    <path class="cls-134"
                        d="M881.39,542.29c0,1.68-1.48,3.04-3.31,3.04h-5.32c-1.83,0-3.31-1.36-3.31-3.04h0c0-1.68,1.48-3.04,3.31-3.04h5.32c1.83,0,3.31,1.36,3.31,3.04h0Z" />
                    <path class="cls-137"
                        d="M877.01,539.79c0,1.03-.91,1.87-2.03,1.87h-3.27c-1.12,0-2.04-.84-2.04-1.87h0c0-1.03,.91-1.87,2.04-1.87h3.27c1.12,0,2.03,.84,2.03,1.87h0Z" />
                    <path class="cls-58"
                        d="M1103.81,514.36c0,3.66-3.23,6.63-7.21,6.63h-15.66c-3.98,0-7.21-2.97-7.21-6.63h0c0-3.66,3.23-6.63,7.21-6.63h15.66c3.98,0,7.21,2.97,7.21,6.63h0Z" />
                    <path class="cls-134"
                        d="M1097.97,506.95c0,3.19-2.82,5.78-6.29,5.78h-10.11c-3.47,0-6.29-2.59-6.29-5.78h0c0-3.19,2.82-5.78,6.29-5.78h10.11c3.47,0,6.29,2.59,6.29,5.78h0Z" />
                    <path class="cls-137"
                        d="M1089.64,502.2c0,1.96-1.73,3.55-3.87,3.55h-6.21c-2.14,0-3.87-1.59-3.87-3.55h0c0-1.96,1.73-3.55,3.87-3.55h6.21c2.14,0,3.87,1.59,3.87,3.55h0Z" />
                    <path class="cls-58"
                        d="M1067.27,525.52c0,3.66-3.23,6.63-7.21,6.63h-15.66c-3.98,0-7.21-2.97-7.21-6.63h0c0-3.66,3.23-6.63,7.21-6.63h15.66c3.98,0,7.21,2.97,7.21,6.63h0Z" />
                    <path class="cls-134"
                        d="M1061.43,518.12c0,3.19-2.82,5.78-6.29,5.78h-10.11c-3.47,0-6.29-2.59-6.29-5.78h0c0-3.19,2.82-5.78,6.29-5.78h10.11c3.47,0,6.29,2.59,6.29,5.78h0Z" />
                    <path class="cls-137"
                        d="M1053.1,513.37c0,1.96-1.73,3.55-3.87,3.55h-6.21c-2.14,0-3.87-1.59-3.87-3.55h0c0-1.96,1.73-3.55,3.87-3.55h6.21c2.14,0,3.87,1.59,3.87,3.55h0Z" />
                    <path class="cls-58"
                        d="M1092.83,560.46c0,2.72-2.4,4.92-5.36,4.92h-11.63c-2.96,0-5.36-2.2-5.36-4.92h0c0-2.72,2.4-4.92,5.36-4.92h11.63c2.96,0,5.36,2.2,5.36,4.92h0Z" />
                    <path class="cls-134"
                        d="M1088.49,554.96c0,2.37-2.09,4.29-4.67,4.29h-7.51c-2.58,0-4.67-1.92-4.67-4.29h0c0-2.37,2.09-4.29,4.67-4.29h7.51c2.58,0,4.67,1.92,4.67,4.29h0Z" />
                    <path class="cls-137"
                        d="M1082.31,551.43c0,1.46-1.29,2.64-2.87,2.64h-4.62c-1.59,0-2.87-1.18-2.87-2.64h0c0-1.46,1.29-2.64,2.87-2.64h4.62c1.59,0,2.87,1.18,2.87,2.64h0Z" />
                    <path class="cls-58"
                        d="M1114.7,535.61c0,2.17-1.91,3.93-4.27,3.93h-9.28c-2.36,0-4.27-1.76-4.27-3.93h0c0-2.17,1.91-3.93,4.27-3.93h9.28c2.36,0,4.27,1.76,4.27,3.93h0Z" />
                    <path class="cls-134"
                        d="M1111.23,531.22c0,1.89-1.67,3.43-3.73,3.43h-5.99c-2.06,0-3.73-1.53-3.73-3.43h0c0-1.89,1.67-3.43,3.73-3.43h5.99c2.06,0,3.73,1.53,3.73,3.43h0Z" />
                    <path class="cls-137"
                        d="M1106.3,528.41c0,1.16-1.03,2.11-2.29,2.11h-3.68c-1.27,0-2.29-.94-2.29-2.11h0c0-1.16,1.03-2.11,2.29-2.11h3.68c1.27,0,2.29,.94,2.29,2.11h0Z" />
                    <path class="cls-46"
                        d="M1090.63,588.71h-4.28v-72.3c0-1.07,.95-1.95,2.12-1.95h.04c1.17,0,2.12,.87,2.12,1.95v72.3Z" />
                    <path class="cls-46"
                        d="M1088.49,573.69c-10.68,0-19.38-7.99-19.38-17.81v-7.24c0-1.31,1.16-2.37,2.58-2.37s2.58,1.06,2.58,2.37v7.24c0,7.2,6.38,13.06,14.21,13.06s14.21-5.86,14.21-13.06v-17.46c0-1.31,1.16-2.37,2.58-2.37s2.58,1.06,2.58,2.37v17.46c0,9.82-8.69,17.81-19.38,17.81Z" />
                    <path class="cls-46"
                        d="M1104.98,552.45c-6.43,0-11.67-4.81-11.67-10.72v-4.25c0-.92,.81-1.66,1.81-1.66s1.81,.74,1.81,1.66v4.25c0,4.08,3.61,7.4,8.05,7.4s8.05-3.32,8.05-7.4v-10.25c0-.92,.81-1.66,1.81-1.66s1.81,.74,1.81,1.66v10.25c0,5.91-5.23,10.72-11.67,10.72Z" />
                    <path class="cls-46"
                        d="M1071.69,565.51c-6.43,0-11.67-4.81-11.67-10.72v-24.06c0-.92,.81-1.66,1.81-1.66s1.81,.74,1.81,1.66v24.06c0,4.08,3.61,7.4,8.05,7.4s8.05-3.32,8.05-7.4v-10.25c0-.92,.81-1.66,1.81-1.66s1.81,.74,1.81,1.66v10.25c0,5.91-5.23,10.72-11.66,10.72Z" />
                    <path class="cls-46"
                        d="M1061.05,547.16c-6.43,0-11.66-4.81-11.66-10.72v-8.58c0-.92,.81-1.66,1.81-1.66s1.81,.74,1.81,1.66v8.58c0,4.08,3.61,7.4,8.05,7.4s8.05-3.32,8.05-7.4v-10.25c0-.92,.81-1.66,1.81-1.66s1.81,.74,1.81,1.66v10.25c0,5.91-5.23,10.72-11.67,10.72Z" />
                    <path class="cls-46"
                        d="M1070.91,529.49c-4.38,0-7.94-3.27-7.94-7.3v-4.6c0-.52,.46-.95,1.03-.95s1.03,.43,1.03,.95v4.6c0,2.98,2.64,5.4,5.88,5.4s5.87-2.42,5.87-5.4v-2.49c0-.52,.46-.95,1.03-.95s1.03,.43,1.03,.95v2.49c0,4.02-3.56,7.3-7.94,7.3Z" />
                    <path class="cls-46"
                        d="M1051.19,536.29c-3.41,0-6.18-2.55-6.18-5.68v-4.48c0-.52,.46-.95,1.03-.95s1.03,.43,1.03,.95v4.48c0,2.09,1.85,3.78,4.11,3.78,1.48,0,2.86-.74,3.59-1.93,.28-.46,.91-.62,1.41-.36,.5,.26,.67,.84,.39,1.29-1.09,1.78-3.16,2.89-5.39,2.89Z" />
                    <path class="cls-46"
                        d="M1108.4,530.9c-4.38,0-7.94-3.27-7.94-7.3v-6.01c0-.52,.46-.95,1.03-.95s1.03,.43,1.03,.95v6.01c0,2.98,2.64,5.4,5.88,5.4s5.88-2.42,5.88-5.4v-2.49c0-.52,.46-.95,1.03-.95s1.03,.43,1.03,.95v2.49c0,4.02-3.56,7.3-7.94,7.3Z" />
                    <path class="cls-58"
                        d="M1110.3,564.76c0,2.59-2.28,4.68-5.09,4.68h-11.06c-2.81,0-5.09-2.1-5.09-4.68h0c0-2.59,2.28-4.68,5.09-4.68h11.06c2.81,0,5.09,2.1,5.09,4.68h0Z" />
                    <path class="cls-134"
                        d="M1106.18,559.53c0,2.25-1.99,4.08-4.44,4.08h-7.14c-2.45,0-4.44-1.83-4.44-4.08h0c0-2.25,1.99-4.08,4.44-4.08h7.14c2.45,0,4.44,1.83,4.44,4.08h0Z" />
                    <path class="cls-137"
                        d="M1100.29,556.18c0,1.39-1.22,2.51-2.73,2.51h-4.39c-1.51,0-2.73-1.12-2.73-2.51h0c0-1.39,1.22-2.51,2.73-2.51h4.39c1.51,0,2.73,1.12,2.73,2.51h0Z" />
                    <path class="cls-46"
                        d="M1114.84,537.57c-4.26,0-7.73-3.19-7.73-7.1v-2.77c0-.66,.58-1.19,1.29-1.19s1.29,.53,1.29,1.19v2.77c0,2.61,2.31,4.73,5.15,4.73s5.15-2.12,5.15-4.73v-6.69c0-.66,.58-1.19,1.29-1.19s1.29,.53,1.29,1.19v6.69c0,3.92-3.47,7.1-7.73,7.1Z" />
                    <path class="cls-58"
                        d="M1102.4,540.86c0,3.59-3.17,6.5-7.07,6.5h-15.36c-3.91,0-7.07-2.91-7.07-6.5h0c0-3.59,3.17-6.5,7.07-6.5h15.36c3.91,0,7.07,2.91,7.07,6.5h0Z" />
                    <path class="cls-134"
                        d="M1096.67,533.59c0,3.13-2.76,5.67-6.17,5.67h-9.91c-3.41,0-6.17-2.54-6.17-5.67h0c0-3.13,2.76-5.67,6.17-5.67h9.91c3.41,0,6.17,2.54,6.17,5.67h0Z" />
                    <path class="cls-137"
                        d="M1088.5,528.94c0,1.93-1.7,3.49-3.79,3.49h-6.09c-2.09,0-3.79-1.56-3.79-3.49h0c0-1.92,1.7-3.48,3.79-3.48h6.09c2.09,0,3.79,1.56,3.79,3.48h0Z" />
                    <path class="cls-58"
                        d="M1118.41,556.06c0,2.28-2.01,4.13-4.49,4.13h-9.75c-2.48,0-4.49-1.85-4.49-4.13h0c0-2.28,2.01-4.13,4.49-4.13h9.75c2.48,0,4.49,1.85,4.49,4.13h0Z" />
                    <path class="cls-134"
                        d="M1114.77,551.44c0,1.99-1.75,3.6-3.92,3.6h-6.29c-2.16,0-3.92-1.61-3.92-3.6h0c0-1.99,1.75-3.6,3.92-3.6h6.29c2.16,0,3.92,1.61,3.92,3.6h0Z" />
                    <path class="cls-137"
                        d="M1109.59,548.49c0,1.22-1.08,2.21-2.41,2.21h-3.87c-1.33,0-2.41-.99-2.41-2.21h0c0-1.22,1.08-2.21,2.41-2.21h3.87c1.33,0,2.41,.99,2.41,2.21h0Z" />
                    <path class="cls-58"
                        d="M1055.68,540.37c0,1.57-1.39,2.85-3.1,2.85h-6.73c-1.71,0-3.1-1.28-3.1-2.85h0c0-1.57,1.39-2.85,3.1-2.85h6.73c1.71,0,3.1,1.28,3.1,2.85h0Z" />
                    <path class="cls-134"
                        d="M1053.17,537.19c0,1.37-1.21,2.48-2.7,2.48h-4.34c-1.49,0-2.7-1.11-2.7-2.48h0c0-1.37,1.21-2.48,2.7-2.48h4.34c1.49,0,2.7,1.11,2.7,2.48h0Z" />
                    <path class="cls-137"
                        d="M1049.59,535.15c0,.84-.74,1.53-1.66,1.53h-2.67c-.92,0-1.66-.68-1.66-1.53h0c0-.84,.74-1.53,1.66-1.53h2.67c.92,0,1.66,.68,1.66,1.53h0Z" />
                    <path class="cls-58"
                        d="M1088.87,568.18c0,1.89-1.67,3.42-3.72,3.42h-8.08c-2.06,0-3.72-1.53-3.72-3.42h0c0-1.89,1.67-3.42,3.72-3.42h8.08c2.06,0,3.72,1.53,3.72,3.42h0Z" />
                    <path class="cls-134"
                        d="M1085.85,564.36c0,1.65-1.45,2.98-3.24,2.98h-5.22c-1.79,0-3.24-1.33-3.24-2.98h0c0-1.65,1.45-2.98,3.24-2.98h5.22c1.79,0,3.24,1.34,3.24,2.98h0Z" />
                    <path class="cls-137"
                        d="M1081.56,561.91c0,1.01-.89,1.83-1.99,1.83h-3.21c-1.1,0-2-.82-2-1.83h0c0-1.01,.89-1.83,2-1.83h3.21c1.1,0,1.99,.82,1.99,1.83h0Z" />
                    <path class="cls-58"
                        d="M1076.21,553.02c0,3.26-2.87,5.9-6.42,5.9h-13.93c-3.54,0-6.42-2.64-6.42-5.9h0c0-3.26,2.87-5.9,6.42-5.9h13.93c3.54,0,6.42,2.64,6.42,5.9h0Z" />
                    <path class="cls-134"
                        d="M1071.01,546.43c0,2.84-2.5,5.14-5.59,5.14h-8.99c-3.09,0-5.59-2.3-5.59-5.14h0c0-2.84,2.51-5.14,5.59-5.14h8.99c3.09,0,5.59,2.3,5.59,5.14h0Z" />
                    <path class="cls-137"
                        d="M1063.6,542.21c0,1.75-1.54,3.16-3.44,3.16h-5.53c-1.9,0-3.44-1.41-3.44-3.16h0c0-1.75,1.54-3.16,3.44-3.16h5.53c1.9,0,3.44,1.41,3.44,3.16h0Z" />
                    <path class="cls-58"
                        d="M1073.93,534.06c0,1.57-1.39,2.85-3.1,2.85h-6.73c-1.71,0-3.1-1.28-3.1-2.85h0c0-1.57,1.39-2.85,3.1-2.85h6.73c1.71,0,3.1,1.28,3.1,2.85h0Z" />
                    <path class="cls-134"
                        d="M1071.42,530.87c0,1.37-1.21,2.48-2.7,2.48h-4.34c-1.49,0-2.7-1.11-2.7-2.48h0c0-1.37,1.21-2.48,2.7-2.48h4.34c1.49,0,2.7,1.11,2.7,2.48h0Z" />
                    <path class="cls-137"
                        d="M1067.84,528.83c0,.84-.74,1.53-1.66,1.53h-2.67c-.92,0-1.66-.68-1.66-1.53h0c0-.84,.74-1.53,1.66-1.53h2.67c.92,0,1.66,.68,1.66,1.53h0Z" />
                    <path class="cls-58"
                        d="M1113.88,520.07c0,3.26-2.87,5.9-6.42,5.9h-13.93c-3.54,0-6.42-2.64-6.42-5.9h0c0-3.26,2.87-5.9,6.42-5.9h13.93c3.54,0,6.42,2.64,6.42,5.9h0Z" />
                    <path class="cls-134"
                        d="M1108.68,513.48c0,2.84-2.5,5.14-5.6,5.14h-8.99c-3.09,0-5.59-2.3-5.59-5.14h0c0-2.84,2.5-5.14,5.59-5.14h8.99c3.09,0,5.6,2.3,5.6,5.14h0Z" />
                    <path class="cls-137"
                        d="M1101.28,509.26c0,1.75-1.54,3.16-3.44,3.16h-5.53c-1.9,0-3.44-1.41-3.44-3.16h0c0-1.75,1.54-3.16,3.44-3.16h5.53c1.9,0,3.44,1.42,3.44,3.16h0Z" />
                    <path class="cls-58"
                        d="M1134.59,526.94c0,3.03-2.67,5.49-5.97,5.49h-12.96c-3.3,0-5.97-2.46-5.97-5.49h0c0-3.03,2.67-5.49,5.97-5.49h12.96c3.3,0,5.97,2.46,5.97,5.49h0Z" />
                    <path class="cls-134"
                        d="M1129.75,520.82c0,2.64-2.33,4.78-5.21,4.78h-8.37c-2.88,0-5.21-2.14-5.21-4.78h0c0-2.64,2.33-4.78,5.21-4.78h8.37c2.88,0,5.21,2.14,5.21,4.78h0Z" />
                    <path class="cls-137"
                        d="M1122.86,516.89c0,1.62-1.43,2.94-3.2,2.94h-5.14c-1.77,0-3.2-1.32-3.2-2.94h0c0-1.62,1.43-2.94,3.2-2.94h5.14c1.77,0,3.2,1.32,3.2,2.94h0Z" />
                    <path class="cls-58"
                        d="M1085.02,516.86c0,3.26-2.87,5.9-6.42,5.9h-13.93c-3.54,0-6.42-2.64-6.42-5.9h0c0-3.26,2.87-5.9,6.42-5.9h13.93c3.54,0,6.42,2.64,6.42,5.9h0Z" />
                    <path class="cls-134"
                        d="M1079.82,510.27c0,2.84-2.5,5.14-5.6,5.14h-8.99c-3.09,0-5.59-2.3-5.59-5.14h0c0-2.84,2.5-5.14,5.59-5.14h8.99c3.09,0,5.6,2.3,5.6,5.14h0Z" />
                    <path class="cls-137"
                        d="M1072.41,506.05c0,1.75-1.54,3.16-3.44,3.16h-5.53c-1.9,0-3.44-1.41-3.44-3.16h0c0-1.75,1.54-3.16,3.44-3.16h5.53c1.9,0,3.44,1.42,3.44,3.16h0Z" />
                    <path class="cls-58"
                        d="M1017.36,534.16c0,2.69-2.37,4.86-5.29,4.86h-11.49c-2.92,0-5.29-2.18-5.29-4.86h0c0-2.69,2.37-4.86,5.29-4.86h11.49c2.92,0,5.29,2.18,5.29,4.86h0Z" />
                    <path class="cls-134"
                        d="M1013.07,528.73c0,2.34-2.07,4.24-4.61,4.24h-7.42c-2.55,0-4.61-1.9-4.61-4.24h0c0-2.34,2.07-4.24,4.61-4.24h7.42c2.55,0,4.61,1.9,4.61,4.24h0Z" />
                    <path class="cls-137"
                        d="M1006.96,525.25c0,1.44-1.27,2.61-2.84,2.61h-4.56c-1.57,0-2.84-1.17-2.84-2.61h0c0-1.44,1.27-2.61,2.84-2.61h4.56c1.57,0,2.84,1.17,2.84,2.61h0Z" />
                    <path class="cls-58"
                        d="M990.55,542.36c0,2.69-2.37,4.86-5.29,4.86h-11.49c-2.92,0-5.29-2.18-5.29-4.86h0c0-2.69,2.37-4.86,5.29-4.86h11.49c2.92,0,5.29,2.18,5.29,4.86h0Z" />
                    <path class="cls-134"
                        d="M986.26,536.92c0,2.34-2.07,4.24-4.61,4.24h-7.42c-2.55,0-4.61-1.9-4.61-4.24h0c0-2.34,2.07-4.24,4.61-4.24h7.42c2.55,0,4.61,1.9,4.61,4.24h0Z" />
                    <path class="cls-137"
                        d="M980.16,533.44c0,1.44-1.27,2.61-2.84,2.61h-4.56c-1.57,0-2.84-1.17-2.84-2.61h0c0-1.44,1.27-2.61,2.84-2.61h4.56c1.57,0,2.84,1.17,2.84,2.61h0Z" />
                    <path class="cls-58"
                        d="M1009.3,567.99c0,1.99-1.76,3.61-3.93,3.61h-8.53c-2.17,0-3.93-1.62-3.93-3.61h0c0-2,1.76-3.61,3.93-3.61h8.53c2.17,0,3.93,1.62,3.93,3.61h0Z" />
                    <path class="cls-134"
                        d="M1006.12,563.95c0,1.74-1.53,3.15-3.43,3.15h-5.51c-1.89,0-3.43-1.41-3.43-3.15h0c0-1.74,1.53-3.15,3.43-3.15h5.51c1.89,0,3.43,1.41,3.43,3.15h0Z" />
                    <path class="cls-137"
                        d="M1001.58,561.36c0,1.07-.94,1.94-2.11,1.94h-3.39c-1.16,0-2.11-.87-2.11-1.94h0c0-1.07,.94-1.94,2.11-1.94h3.39c1.16,0,2.11,.87,2.11,1.94h0Z" />
                    <path class="cls-58"
                        d="M1025.34,549.75c0,1.59-1.4,2.88-3.14,2.88h-6.81c-1.73,0-3.14-1.29-3.14-2.88h0c0-1.59,1.4-2.88,3.14-2.88h6.81c1.73,0,3.14,1.29,3.14,2.88h0Z" />
                    <path class="cls-134"
                        d="M1022.8,546.53c0,1.39-1.22,2.51-2.73,2.51h-4.39c-1.51,0-2.73-1.13-2.73-2.51h0c0-1.39,1.22-2.51,2.73-2.51h4.39c1.51,0,2.73,1.12,2.73,2.51h0Z" />
                    <path class="cls-137"
                        d="M1019.18,544.47c0,.85-.75,1.54-1.68,1.54h-2.7c-.93,0-1.68-.69-1.68-1.54h0c0-.85,.75-1.54,1.68-1.54h2.7c.93,0,1.68,.69,1.68,1.54h0Z" />
                    <path class="cls-46"
                        d="M1007.68,588.71h-3.14v-53.04c0-.79,.7-1.43,1.55-1.43h.03c.86,0,1.55,.64,1.55,1.43v53.04Z" />
                    <path class="cls-46"
                        d="M1006.12,577.69c-7.84,0-14.22-5.86-14.22-13.06v-5.31c0-.96,.85-1.74,1.89-1.74s1.89,.78,1.89,1.74v5.31c0,5.28,4.68,9.58,10.43,9.58s10.43-4.3,10.43-9.58v-12.81c0-.96,.85-1.74,1.9-1.74s1.9,.78,1.9,1.74v12.81c0,7.2-6.38,13.06-14.22,13.06Z" />
                    <path class="cls-46"
                        d="M1018.21,562.11c-4.72,0-8.56-3.53-8.56-7.86v-3.12c0-.67,.59-1.22,1.33-1.22s1.33,.55,1.33,1.22v3.12c0,2.99,2.65,5.43,5.91,5.43s5.9-2.43,5.9-5.43v-7.52c0-.67,.59-1.22,1.33-1.22s1.33,.55,1.33,1.22v7.52c0,4.34-3.84,7.86-8.56,7.86Z" />
                    <path class="cls-46"
                        d="M993.79,571.69c-4.72,0-8.56-3.53-8.56-7.87v-17.65c0-.67,.59-1.22,1.33-1.22s1.33,.55,1.33,1.22v17.65c0,2.99,2.65,5.43,5.91,5.43s5.9-2.43,5.9-5.43v-7.52c0-.67,.59-1.22,1.33-1.22s1.33,.55,1.33,1.22v7.52c0,4.34-3.84,7.87-8.56,7.87Z" />
                    <path class="cls-46"
                        d="M985.99,558.23c-4.72,0-8.56-3.53-8.56-7.87v-6.29c0-.67,.59-1.22,1.33-1.22s1.33,.55,1.33,1.22v6.29c0,2.99,2.65,5.43,5.9,5.43s5.9-2.43,5.9-5.43v-7.52c0-.67,.59-1.22,1.33-1.22s1.33,.55,1.33,1.22v7.52c0,4.34-3.84,7.87-8.56,7.87Z" />
                    <path class="cls-46"
                        d="M993.22,545.27c-3.21,0-5.83-2.4-5.83-5.35v-3.37c0-.38,.34-.7,.76-.7s.76,.31,.76,.7v3.37c0,2.18,1.93,3.96,4.31,3.96s4.31-1.78,4.31-3.96v-1.83c0-.38,.34-.7,.76-.7s.76,.31,.76,.7v1.83c0,2.95-2.61,5.35-5.83,5.35Z" />
                    <path class="cls-46"
                        d="M978.75,550.26c-2.5,0-4.53-1.87-4.53-4.17v-3.29c0-.38,.34-.7,.76-.7s.76,.31,.76,.7v3.29c0,1.53,1.35,2.77,3.02,2.77,1.09,0,2.1-.54,2.63-1.41,.21-.33,.67-.45,1.03-.26,.36,.19,.49,.61,.29,.95-.8,1.31-2.32,2.12-3.95,2.12Z" />
                    <path class="cls-46"
                        d="M1020.72,546.3c-3.21,0-5.83-2.4-5.83-5.35v-4.41c0-.38,.34-.7,.76-.7s.76,.31,.76,.7v4.41c0,2.18,1.93,3.96,4.31,3.96s4.31-1.78,4.31-3.96v-1.83c0-.38,.34-.7,.76-.7s.76,.31,.76,.7v1.83c0,2.95-2.61,5.35-5.83,5.35Z" />
                    <path class="cls-58"
                        d="M1022.12,571.14c0,1.9-1.67,3.43-3.74,3.43h-8.11c-2.06,0-3.74-1.54-3.74-3.43h0c0-1.9,1.67-3.43,3.74-3.43h8.11c2.06,0,3.74,1.54,3.74,3.43h0Z" />
                    <path class="cls-134"
                        d="M1019.09,567.3c0,1.65-1.46,2.99-3.26,2.99h-5.24c-1.8,0-3.26-1.34-3.26-2.99h0c0-1.65,1.46-2.99,3.26-2.99h5.24c1.8,0,3.26,1.34,3.26,2.99h0Z" />
                    <path class="cls-137"
                        d="M1014.78,564.84c0,1.02-.9,1.84-2,1.84h-3.22c-1.11,0-2-.82-2-1.84h0c0-1.02,.9-1.84,2-1.84h3.22c1.11,0,2,.82,2,1.84h0Z" />
                    <path class="cls-46"
                        d="M1025.45,551.19c-3.13,0-5.67-2.34-5.67-5.21v-2.03c0-.48,.42-.87,.95-.87s.95,.39,.95,.87v2.03c0,1.91,1.69,3.47,3.78,3.47s3.78-1.56,3.78-3.47v-4.91c0-.48,.42-.87,.95-.87s.95,.39,.95,.87v4.91c0,2.87-2.54,5.21-5.67,5.21Z" />
                    <path class="cls-58"
                        d="M1016.32,553.6c0,2.63-2.32,4.77-5.19,4.77h-11.27c-2.87,0-5.19-2.14-5.19-4.77h0c0-2.63,2.32-4.77,5.19-4.77h11.27c2.87,0,5.19,2.14,5.19,4.77h0Z" />
                    <path class="cls-134"
                        d="M1012.11,548.28c0,2.3-2.03,4.16-4.53,4.16h-7.27c-2.5,0-4.53-1.86-4.53-4.16h0c0-2.3,2.03-4.16,4.53-4.16h7.27c2.5,0,4.53,1.86,4.53,4.16h0Z" />
                    <path class="cls-137"
                        d="M1006.12,544.86c0,1.41-1.25,2.56-2.78,2.56h-4.47c-1.54,0-2.78-1.14-2.78-2.56h0c0-1.41,1.25-2.56,2.78-2.56h4.47c1.54,0,2.78,1.15,2.78,2.56h0Z" />
                    <path class="cls-58"
                        d="M1028.07,564.76c0,1.67-1.48,3.03-3.29,3.03h-7.15c-1.82,0-3.29-1.36-3.29-3.03h0c0-1.67,1.48-3.03,3.29-3.03h7.15c1.82,0,3.29,1.36,3.29,3.03h0Z" />
                    <path class="cls-134"
                        d="M1025.4,561.37c0,1.46-1.29,2.64-2.87,2.64h-4.62c-1.59,0-2.87-1.18-2.87-2.64h0c0-1.46,1.29-2.64,2.87-2.64h4.62c1.59,0,2.87,1.18,2.87,2.64h0Z" />
                    <path class="cls-137"
                        d="M1021.59,559.2c0,.9-.79,1.62-1.77,1.62h-2.84c-.98,0-1.77-.73-1.77-1.62h0c0-.9,.79-1.62,1.77-1.62h2.84c.97,0,1.77,.73,1.77,1.62h0Z" />
                    <path class="cls-58"
                        d="M982.05,553.25c0,1.15-1.02,2.09-2.27,2.09h-4.94c-1.26,0-2.27-.94-2.27-2.09h0c0-1.15,1.02-2.09,2.27-2.09h4.94c1.26,0,2.27,.94,2.27,2.09h0Z" />
                    <path class="cls-134"
                        d="M980.2,550.91c0,1.01-.89,1.82-1.98,1.82h-3.19c-1.1,0-1.98-.82-1.98-1.82h0c0-1.01,.89-1.82,1.98-1.82h3.19c1.09,0,1.98,.82,1.98,1.82h0Z" />
                    <path class="cls-137"
                        d="M977.58,549.42c0,.62-.55,1.12-1.22,1.12h-1.96c-.67,0-1.22-.5-1.22-1.12h0c0-.62,.55-1.12,1.22-1.12h1.96c.67,0,1.22,.5,1.22,1.12h0Z" />
                    <path class="cls-58"
                        d="M1006.4,573.65c0,1.39-1.22,2.51-2.73,2.51h-5.93c-1.51,0-2.73-1.12-2.73-2.51h0c0-1.39,1.22-2.51,2.73-2.51h5.93c1.51,0,2.73,1.12,2.73,2.51h0Z" />
                    <path class="cls-134"
                        d="M1004.18,570.85c0,1.21-1.07,2.19-2.38,2.19h-3.83c-1.31,0-2.38-.98-2.38-2.19h0c0-1.21,1.07-2.19,2.38-2.19h3.83c1.31,0,2.38,.98,2.38,2.19h0Z" />
                    <path class="cls-137"
                        d="M1001.03,569.05c0,.74-.66,1.34-1.46,1.34h-2.35c-.81,0-1.46-.6-1.46-1.34h0c0-.74,.65-1.35,1.46-1.35h2.35c.81,0,1.46,.6,1.46,1.35h0Z" />
                    <path class="cls-58"
                        d="M997.11,562.53c0,2.39-2.11,4.33-4.71,4.33h-10.22c-2.6,0-4.71-1.94-4.71-4.33h0c0-2.39,2.11-4.33,4.71-4.33h10.22c2.6,0,4.71,1.94,4.71,4.33h0Z" />
                    <path class="cls-134"
                        d="M993.29,557.7c0,2.08-1.84,3.77-4.1,3.77h-6.6c-2.27,0-4.1-1.69-4.1-3.77h0c0-2.08,1.84-3.77,4.1-3.77h6.6c2.27,0,4.1,1.69,4.1,3.77h0Z" />
                    <path class="cls-137"
                        d="M987.86,554.6c0,1.28-1.13,2.32-2.52,2.32h-4.05c-1.39,0-2.52-1.04-2.52-2.32h0c0-1.28,1.13-2.32,2.52-2.32h4.05c1.39,0,2.52,1.04,2.52,2.32h0Z" />
                    <path class="cls-58"
                        d="M995.43,548.62c0,1.15-1.02,2.09-2.27,2.09h-4.94c-1.26,0-2.27-.94-2.27-2.09h0c0-1.15,1.02-2.09,2.27-2.09h4.94c1.26,0,2.27,.94,2.27,2.09h0Z" />
                    <path class="cls-134"
                        d="M993.59,546.28c0,1.01-.89,1.82-1.98,1.82h-3.19c-1.1,0-1.98-.82-1.98-1.82h0c0-1.01,.89-1.82,1.98-1.82h3.19c1.1,0,1.98,.82,1.98,1.82h0Z" />
                    <path class="cls-137"
                        d="M990.97,544.78c0,.62-.55,1.12-1.22,1.12h-1.96c-.67,0-1.22-.5-1.22-1.12h0c0-.62,.55-1.12,1.22-1.12h1.96c.67,0,1.22,.5,1.22,1.12h0Z" />
                    <path class="cls-58"
                        d="M1024.75,538.35c0,2.39-2.11,4.33-4.71,4.33h-10.22c-2.6,0-4.71-1.94-4.71-4.33h0c0-2.39,2.11-4.33,4.71-4.33h10.22c2.6,0,4.71,1.94,4.71,4.33h0Z" />
                    <path class="cls-134"
                        d="M1020.93,533.52c0,2.08-1.84,3.77-4.1,3.77h-6.6c-2.27,0-4.1-1.69-4.1-3.77h0c0-2.08,1.84-3.77,4.1-3.77h6.6c2.27,0,4.1,1.69,4.1,3.77h0Z" />
                    <path class="cls-137"
                        d="M1015.5,530.42c0,1.28-1.13,2.32-2.52,2.32h-4.05c-1.39,0-2.52-1.04-2.52-2.32h0c0-1.28,1.13-2.32,2.52-2.32h4.05c1.39,0,2.52,1.04,2.52,2.32h0Z" />
                    <path class="cls-58"
                        d="M1039.94,543.4c0,2.22-1.96,4.03-4.38,4.03h-9.51c-2.42,0-4.38-1.8-4.38-4.03h0c0-2.22,1.96-4.02,4.38-4.02h9.51c2.42,0,4.38,1.8,4.38,4.02h0Z" />
                    <path class="cls-134"
                        d="M1036.39,538.9c0,1.94-1.71,3.51-3.82,3.51h-6.14c-2.11,0-3.82-1.57-3.82-3.51h0c0-1.94,1.71-3.51,3.82-3.51h6.14c2.11,0,3.82,1.57,3.82,3.51h0Z" />
                    <path class="cls-137"
                        d="M1031.33,536.02c0,1.19-1.05,2.16-2.35,2.16h-3.77c-1.3,0-2.35-.97-2.35-2.16h0c0-1.19,1.05-2.16,2.35-2.16h3.77c1.3,0,2.35,.97,2.35,2.16h0Z" />
                    <path class="cls-58"
                        d="M1003.57,536c0,2.39-2.11,4.33-4.71,4.33h-10.22c-2.6,0-4.71-1.94-4.71-4.33h0c0-2.39,2.11-4.33,4.71-4.33h10.22c2.6,0,4.71,1.94,4.71,4.33h0Z" />
                    <path class="cls-134"
                        d="M999.76,531.16c0,2.08-1.84,3.77-4.1,3.77h-6.6c-2.27,0-4.1-1.69-4.1-3.77h0c0-2.08,1.84-3.77,4.1-3.77h6.6c2.27,0,4.1,1.69,4.1,3.77h0Z" />
                    <path class="cls-137"
                        d="M994.32,528.07c0,1.28-1.13,2.32-2.52,2.32h-4.05c-1.39,0-2.52-1.04-2.52-2.32h0c0-1.28,1.13-2.32,2.52-2.32h4.05c1.39,0,2.52,1.04,2.52,2.32h0Z" />
                    <path class="cls-58"
                        d="M939.7,549.47c0,2.27-2.01,4.12-4.48,4.12h-9.72c-2.47,0-4.48-1.84-4.48-4.12h0c0-2.27,2.01-4.12,4.48-4.12h9.72c2.47,0,4.48,1.84,4.48,4.12h0Z" />
                    <path class="cls-134"
                        d="M936.07,544.87c0,1.98-1.75,3.59-3.91,3.59h-6.28c-2.16,0-3.91-1.61-3.91-3.59h0c0-1.98,1.75-3.59,3.91-3.59h6.28c2.16,0,3.91,1.61,3.91,3.59h0Z" />
                    <path class="cls-58"
                        d="M955.57,571.17c0,1.69-1.49,3.06-3.33,3.06h-7.22c-1.84,0-3.33-1.37-3.33-3.06h0c0-1.69,1.49-3.06,3.33-3.06h7.22c1.84,0,3.33,1.37,3.33,3.06h0Z" />
                    <path class="cls-134"
                        d="M952.87,567.75c0,1.47-1.3,2.67-2.9,2.67h-4.66c-1.6,0-2.9-1.19-2.9-2.67h0c0-1.47,1.3-2.67,2.9-2.67h4.66c1.6,0,2.9,1.19,2.9,2.67h0Z" />
                    <path class="cls-137"
                        d="M949.03,565.56c0,.91-.8,1.64-1.78,1.64h-2.87c-.99,0-1.78-.73-1.78-1.64h0c0-.91,.8-1.64,1.78-1.64h2.87c.98,0,1.78,.73,1.78,1.64h0Z" />
                    <path class="cls-58"
                        d="M969.15,555.73c0,1.35-1.19,2.44-2.66,2.44h-5.76c-1.47,0-2.65-1.09-2.65-2.44h0c0-1.35,1.19-2.44,2.65-2.44h5.76c1.47,0,2.66,1.09,2.66,2.44h0Z" />
                    <path class="cls-134"
                        d="M967,553.01c0,1.18-1.04,2.13-2.32,2.13h-3.72c-1.28,0-2.31-.95-2.31-2.13h0c0-1.17,1.04-2.13,2.31-2.13h3.72c1.28,0,2.32,.95,2.32,2.13h0Z" />
                    <path class="cls-137"
                        d="M963.93,551.26c0,.72-.64,1.31-1.42,1.31h-2.29c-.79,0-1.42-.59-1.42-1.31h0c0-.72,.64-1.31,1.42-1.31h2.29c.79,0,1.42,.59,1.42,1.31h0Z" />
                    <path class="cls-46"
                        d="M954.2,588.71h-2.66v-44.9c0-.67,.59-1.21,1.31-1.21h.03c.73,0,1.32,.54,1.32,1.21v44.9Z" />
                    <path class="cls-46"
                        d="M952.87,579.38c-6.64,0-12.03-4.96-12.03-11.06v-4.49c0-.81,.72-1.47,1.6-1.47s1.6,.66,1.6,1.47v4.49c0,4.47,3.96,8.11,8.83,8.11s8.83-3.64,8.83-8.11v-10.84c0-.81,.72-1.47,1.6-1.47s1.6,.66,1.6,1.47v10.84c0,6.1-5.4,11.06-12.03,11.06Z" />
                    <path class="cls-46"
                        d="M963.11,566.19c-3.99,0-7.25-2.99-7.25-6.66v-2.64c0-.57,.5-1.03,1.12-1.03s1.12,.46,1.12,1.03v2.64c0,2.53,2.24,4.59,5,4.59s5-2.06,5-4.59v-6.36c0-.57,.5-1.03,1.12-1.03s1.12,.46,1.12,1.03v6.36c0,3.67-3.25,6.66-7.24,6.66Z" />
                    <path class="cls-46"
                        d="M942.44,574.31c-4,0-7.25-2.99-7.25-6.66v-14.94c0-.57,.5-1.03,1.12-1.03s1.12,.46,1.12,1.03v14.94c0,2.53,2.24,4.59,5,4.59s5-2.06,5-4.59v-6.36c0-.57,.5-1.03,1.12-1.03s1.12,.46,1.12,1.03v6.36c0,3.67-3.25,6.66-7.24,6.66Z" />
                    <path class="cls-46"
                        d="M935.83,562.91c-3.99,0-7.24-2.99-7.24-6.66v-5.33c0-.57,.5-1.03,1.12-1.03s1.12,.46,1.12,1.03v5.33c0,2.53,2.24,4.59,5,4.59s5-2.06,5-4.59v-6.36c0-.57,.5-1.03,1.12-1.03s1.12,.46,1.12,1.03v6.36c0,3.67-3.25,6.66-7.24,6.66Z" />
                    <path class="cls-46"
                        d="M941.95,551.93c-2.72,0-4.93-2.03-4.93-4.53v-2.85c0-.33,.29-.59,.64-.59s.64,.26,.64,.59v2.85c0,1.85,1.64,3.35,3.65,3.35s3.65-1.5,3.65-3.35v-1.55c0-.33,.29-.59,.64-.59s.64,.26,.64,.59v1.55c0,2.5-2.21,4.53-4.93,4.53Z" />
                    <path class="cls-46"
                        d="M929.71,556.16c-2.12,0-3.84-1.58-3.84-3.53v-2.78c0-.33,.29-.59,.64-.59s.64,.26,.64,.59v2.78c0,1.29,1.15,2.35,2.55,2.35,.92,0,1.77-.46,2.23-1.2,.17-.28,.56-.38,.87-.22,.31,.16,.42,.52,.24,.8-.68,1.11-1.96,1.8-3.35,1.8Z" />
                    <path class="cls-46"
                        d="M965.24,552.81c-2.72,0-4.93-2.03-4.93-4.53v-3.73c0-.33,.29-.59,.64-.59s.64,.26,.64,.59v3.73c0,1.85,1.64,3.35,3.65,3.35s3.65-1.5,3.65-3.35v-1.55c0-.33,.29-.59,.64-.59s.64,.26,.64,.59v1.55c0,2.5-2.21,4.53-4.93,4.53Z" />
                    <path class="cls-58"
                        d="M966.42,573.84c0,1.61-1.42,2.91-3.16,2.91h-6.87c-1.75,0-3.16-1.3-3.16-2.91h0c0-1.61,1.42-2.91,3.16-2.91h6.87c1.75,0,3.16,1.3,3.16,2.91h0Z" />
                    <path class="cls-134"
                        d="M963.86,570.59c0,1.4-1.24,2.54-2.76,2.54h-4.43c-1.52,0-2.76-1.14-2.76-2.54h0c0-1.4,1.24-2.54,2.76-2.54h4.43c1.52,0,2.76,1.13,2.76,2.54h0Z" />
                    <path class="cls-137"
                        d="M960.2,568.51c0,.86-.76,1.56-1.7,1.56h-2.73c-.94,0-1.7-.7-1.7-1.56h0c0-.86,.76-1.56,1.7-1.56h2.73c.94,0,1.7,.7,1.7,1.56h0Z" />
                    <path class="cls-46"
                        d="M969.24,556.95c-2.65,0-4.8-1.98-4.8-4.41v-1.72c0-.41,.36-.74,.8-.74s.8,.33,.8,.74v1.72c0,1.62,1.43,2.94,3.2,2.94s3.2-1.32,3.2-2.94v-4.16c0-.41,.36-.74,.8-.74s.8,.33,.8,.74v4.16c0,2.43-2.15,4.41-4.8,4.41Z" />
                    <path class="cls-58"
                        d="M961.51,558.99c0,2.23-1.97,4.04-4.39,4.04h-9.54c-2.43,0-4.39-1.81-4.39-4.04h0c0-2.23,1.97-4.04,4.39-4.04h9.54c2.43,0,4.39,1.81,4.39,4.04h0Z" />
                    <path class="cls-134"
                        d="M957.95,554.48c0,1.95-1.71,3.52-3.83,3.52h-6.16c-2.12,0-3.83-1.58-3.83-3.52h0c0-1.94,1.72-3.52,3.83-3.52h6.16c2.12,0,3.83,1.58,3.83,3.52h0Z" />
                    <path class="cls-137"
                        d="M952.88,551.59c0,1.2-1.05,2.16-2.35,2.16h-3.78c-1.3,0-2.35-.97-2.35-2.16h0c0-1.2,1.05-2.16,2.35-2.16h3.78c1.3,0,2.35,.97,2.35,2.16h0Z" />
                    <path class="cls-58"
                        d="M971.46,568.43c0,1.42-1.25,2.56-2.79,2.56h-6.05c-1.54,0-2.79-1.15-2.79-2.56h0c0-1.42,1.25-2.56,2.79-2.56h6.05c1.54,0,2.79,1.15,2.79,2.56h0Z" />
                    <path class="cls-134"
                        d="M969.2,565.57c0,1.23-1.09,2.24-2.43,2.24h-3.91c-1.34,0-2.43-1-2.43-2.24h0c0-1.23,1.09-2.24,2.43-2.24h3.91c1.34,0,2.43,1,2.43,2.24h0Z" />
                    <path class="cls-137"
                        d="M965.98,563.73c0,.76-.67,1.37-1.49,1.37h-2.4c-.83,0-1.5-.62-1.5-1.37h0c0-.76,.67-1.37,1.5-1.37h2.4c.83,0,1.49,.62,1.49,1.37h0Z" />
                    <path class="cls-58"
                        d="M932.5,558.69c0,.98-.86,1.77-1.93,1.77h-4.18c-1.06,0-1.93-.79-1.93-1.77h0c0-.98,.86-1.77,1.93-1.77h4.18c1.06,0,1.93,.79,1.93,1.77h0Z" />
                    <path class="cls-134"
                        d="M930.94,556.71c0,.85-.75,1.54-1.68,1.54h-2.7c-.93,0-1.68-.69-1.68-1.54h0c0-.85,.75-1.54,1.68-1.54h2.7c.93,0,1.68,.69,1.68,1.54h0Z" />
                    <path class="cls-137"
                        d="M928.71,555.45c0,.52-.46,.95-1.03,.95h-1.66c-.57,0-1.03-.42-1.03-.95h0c0-.52,.46-.95,1.03-.95h1.66c.57,0,1.03,.42,1.03,.95h0Z" />
                    <path class="cls-58"
                        d="M953.11,575.96c0,1.17-1.04,2.12-2.31,2.12h-5.02c-1.28,0-2.31-.95-2.31-2.12h0c0-1.17,1.03-2.12,2.31-2.12h5.02c1.28,0,2.31,.95,2.31,2.12h0Z" />
                    <path class="cls-134"
                        d="M951.24,573.59c0,1.02-.9,1.85-2.02,1.85h-3.24c-1.11,0-2.02-.83-2.02-1.85h0c0-1.02,.9-1.85,2.02-1.85h3.24c1.11,0,2.02,.83,2.02,1.85h0Z" />
                    <path class="cls-137"
                        d="M948.57,572.07c0,.63-.55,1.14-1.24,1.14h-1.99c-.68,0-1.24-.51-1.24-1.14h0c0-.63,.55-1.14,1.24-1.14h1.99c.68,0,1.24,.51,1.24,1.14h0Z" />
                    <path class="cls-58"
                        d="M945.25,566.55c0,2.02-1.78,3.66-3.98,3.66h-8.65c-2.2,0-3.98-1.64-3.98-3.66h0c0-2.02,1.78-3.66,3.98-3.66h8.65c2.2,0,3.98,1.64,3.98,3.66h0Z" />
                    <path class="cls-134"
                        d="M942.02,562.45c0,1.76-1.56,3.19-3.47,3.19h-5.58c-1.92,0-3.47-1.43-3.47-3.19h0c0-1.76,1.56-3.19,3.47-3.19h5.58c1.92,0,3.47,1.43,3.47,3.19h0Z" />
                    <path class="cls-137"
                        d="M937.42,559.83c0,1.08-.96,1.96-2.14,1.96h-3.43c-1.18,0-2.14-.88-2.14-1.96h0c0-1.08,.96-1.96,2.14-1.96h3.43c1.18,0,2.14,.88,2.14,1.96h0Z" />
                    <path class="cls-58"
                        d="M943.83,554.77c0,.98-.86,1.77-1.93,1.77h-4.18c-1.06,0-1.93-.79-1.93-1.77h0c0-.98,.86-1.77,1.93-1.77h4.18c1.06,0,1.93,.79,1.93,1.77h0Z" />
                    <path class="cls-134"
                        d="M942.27,552.79c0,.85-.75,1.54-1.68,1.54h-2.7c-.93,0-1.68-.69-1.68-1.54h0c0-.85,.75-1.54,1.68-1.54h2.7c.93,0,1.68,.69,1.68,1.54h0Z" />
                    <path class="cls-137"
                        d="M940.05,551.52c0,.52-.46,.95-1.03,.95h-1.66c-.57,0-1.03-.42-1.03-.95h0c0-.52,.46-.95,1.03-.95h1.66c.57,0,1.03,.42,1.03,.95h0Z" />
                    <path class="cls-58"
                        d="M968.64,546.08c0,2.02-1.78,3.66-3.99,3.66h-8.65c-2.2,0-3.98-1.64-3.98-3.66h0c0-2.02,1.78-3.66,3.98-3.66h8.65c2.2,0,3.99,1.64,3.99,3.66h0Z" />
                    <path class="cls-134"
                        d="M965.41,541.99c0,1.76-1.56,3.19-3.48,3.19h-5.58c-1.92,0-3.48-1.43-3.48-3.19h0c0-1.76,1.56-3.19,3.48-3.19h5.58c1.92,0,3.48,1.43,3.48,3.19h0Z" />
                    <path class="cls-137"
                        d="M960.81,539.37c0,1.08-.96,1.96-2.14,1.96h-3.43c-1.18,0-2.14-.88-2.14-1.96h0c0-1.08,.96-1.96,2.14-1.96h3.43c1.18,0,2.14,.88,2.14,1.96h0Z" />
                    <path class="cls-58"
                        d="M981.5,550.35c0,1.88-1.66,3.41-3.71,3.41h-8.05c-2.05,0-3.71-1.53-3.71-3.41h0c0-1.88,1.66-3.41,3.71-3.41h8.05c2.05,0,3.71,1.53,3.71,3.41h0Z" />
                    <path class="cls-134"
                        d="M978.5,546.54c0,1.64-1.45,2.97-3.23,2.97h-5.2c-1.79,0-3.23-1.33-3.23-2.97h0c0-1.64,1.45-2.97,3.23-2.97h5.2c1.79,0,3.23,1.33,3.23,2.97h0Z" />
                    <path class="cls-137"
                        d="M974.22,544.1c0,1.01-.89,1.83-1.99,1.83h-3.19c-1.1,0-1.99-.82-1.99-1.83h0c0-1.01,.89-1.83,1.99-1.83h3.19c1.1,0,1.99,.82,1.99,1.83h0Z" />
                    <path class="cls-58"
                        d="M950.72,544.09c0,2.02-1.78,3.66-3.98,3.66h-8.65c-2.2,0-3.98-1.64-3.98-3.66h0c0-2.02,1.78-3.66,3.98-3.66h8.65c2.2,0,3.98,1.64,3.98,3.66h0Z" />
                    <path class="cls-58"
                        d="M845.43,552.18c0,1.8-1.59,3.26-3.54,3.26h-7.69c-1.96,0-3.54-1.46-3.54-3.26h0c0-1.8,1.59-3.26,3.54-3.26h7.69c1.96,0,3.54,1.46,3.54,3.26h0Z" />
                    <path class="cls-134"
                        d="M842.56,548.54c0,1.57-1.38,2.84-3.09,2.84h-4.97c-1.71,0-3.09-1.27-3.09-2.84h0c0-1.57,1.38-2.84,3.09-2.84h4.97c1.71,0,3.09,1.27,3.09,2.84h0Z" />
                    <path class="cls-137"
                        d="M838.47,546.21c0,.96-.85,1.75-1.9,1.75h-3.05c-1.05,0-1.9-.78-1.9-1.75h0c0-.96,.85-1.75,1.9-1.75h3.05c1.05,0,1.9,.78,1.9,1.75h0Z" />
                    <path class="cls-58"
                        d="M827.48,557.66c0,1.8-1.59,3.26-3.54,3.26h-7.69c-1.96,0-3.54-1.46-3.54-3.26h0c0-1.8,1.59-3.26,3.54-3.26h7.69c1.96,0,3.54,1.46,3.54,3.26h0Z" />
                    <path class="cls-134"
                        d="M824.61,554.03c0,1.57-1.38,2.84-3.09,2.84h-4.97c-1.71,0-3.09-1.27-3.09-2.84h0c0-1.57,1.38-2.84,3.09-2.84h4.97c1.71,0,3.09,1.27,3.09,2.84h0Z" />
                    <path class="cls-137"
                        d="M820.52,551.69c0,.96-.85,1.75-1.9,1.75h-3.05c-1.05,0-1.9-.78-1.9-1.75h0c0-.96,.85-1.75,1.9-1.75h3.05c1.05,0,1.9,.78,1.9,1.75h0Z" />
                    <path class="cls-58"
                        d="M840.04,574.83c0,1.34-1.18,2.42-2.63,2.42h-5.72c-1.45,0-2.63-1.08-2.63-2.42h0c0-1.34,1.18-2.42,2.63-2.42h5.72c1.45,0,2.63,1.08,2.63,2.42h0Z" />
                    <path class="cls-134"
                        d="M837.91,572.13c0,1.17-1.03,2.11-2.3,2.11h-3.69c-1.27,0-2.3-.94-2.3-2.11h0c0-1.17,1.03-2.11,2.3-2.11h3.69c1.27,0,2.3,.94,2.3,2.11h0Z" />
                    <path class="cls-137"
                        d="M834.87,570.4c0,.72-.63,1.3-1.41,1.3h-2.27c-.78,0-1.41-.58-1.41-1.3h0c0-.72,.63-1.3,1.41-1.3h2.27c.78,0,1.41,.58,1.41,1.3h0Z" />
                    <path class="cls-58"
                        d="M850.78,562.62c0,1.07-.94,1.93-2.1,1.93h-4.56c-1.16,0-2.1-.86-2.1-1.93h0c0-1.07,.94-1.93,2.1-1.93h4.56c1.16,0,2.1,.86,2.1,1.93h0Z" />
                    <path class="cls-134"
                        d="M849.08,560.46c0,.93-.82,1.68-1.83,1.68h-2.94c-1.01,0-1.83-.75-1.83-1.68h0c0-.93,.82-1.68,1.83-1.68h2.94c1.01,0,1.83,.75,1.83,1.68h0Z" />
                    <path class="cls-137"
                        d="M846.66,559.08c0,.57-.5,1.03-1.13,1.03h-1.81c-.62,0-1.13-.46-1.13-1.03h0c0-.57,.5-1.03,1.13-1.03h1.81c.62,0,1.13,.46,1.13,1.03h0Z" />
                    <path class="cls-46"
                        d="M838.96,588.71h-2.1v-35.52c0-.53,.47-.96,1.04-.96h.02c.57,0,1.04,.43,1.04,.96v35.52Z" />
                    <path class="cls-46"
                        d="M837.91,581.33c-5.25,0-9.52-3.93-9.52-8.75v-3.56c0-.64,.57-1.17,1.27-1.17s1.27,.52,1.27,1.17v3.56c0,3.54,3.13,6.42,6.98,6.42s6.98-2.88,6.98-6.42v-8.58c0-.64,.57-1.17,1.27-1.17s1.27,.52,1.27,1.17v8.58c0,4.82-4.27,8.75-9.52,8.75Z" />
                    <path class="cls-46"
                        d="M846.01,570.9c-3.16,0-5.73-2.36-5.73-5.27v-2.09c0-.45,.4-.82,.89-.82s.89,.37,.89,.82v2.09c0,2,1.77,3.63,3.95,3.63s3.96-1.63,3.96-3.63v-5.03c0-.45,.4-.82,.89-.82s.89,.37,.89,.82v5.03c0,2.9-2.57,5.27-5.73,5.27Z" />
                    <path class="cls-46"
                        d="M829.65,577.31c-3.16,0-5.73-2.36-5.73-5.27v-11.82c0-.45,.4-.82,.89-.82s.89,.37,.89,.82v11.82c0,2,1.77,3.63,3.96,3.63s3.96-1.63,3.96-3.63v-5.03c0-.45,.4-.82,.89-.82s.89,.37,.89,.82v5.03c0,2.91-2.57,5.27-5.73,5.27Z" />
                    <path class="cls-46"
                        d="M824.42,568.3c-3.16,0-5.73-2.36-5.73-5.27v-4.21c0-.45,.4-.82,.89-.82s.89,.37,.89,.82v4.21c0,2,1.77,3.64,3.96,3.64s3.96-1.63,3.96-3.64v-5.03c0-.45,.4-.82,.89-.82s.89,.37,.89,.82v5.03c0,2.91-2.57,5.27-5.73,5.27Z" />
                    <path class="cls-46"
                        d="M829.27,559.61c-2.15,0-3.9-1.61-3.9-3.59v-2.26c0-.26,.23-.47,.51-.47s.51,.21,.51,.47v2.26c0,1.46,1.29,2.65,2.89,2.65s2.89-1.19,2.89-2.65v-1.23c0-.26,.23-.47,.51-.47s.51,.21,.51,.47v1.23c0,1.98-1.75,3.59-3.9,3.59Z" />
                    <path class="cls-46"
                        d="M819.58,562.96c-1.67,0-3.04-1.25-3.04-2.79v-2.2c0-.26,.23-.47,.51-.47s.51,.21,.51,.47v2.2c0,1.02,.91,1.86,2.02,1.86,.73,0,1.4-.36,1.76-.95,.14-.22,.45-.3,.69-.18,.24,.13,.33,.41,.19,.64-.54,.88-1.55,1.42-2.65,1.42Z" />
                    <path class="cls-46"
                        d="M847.69,560.31c-2.15,0-3.9-1.61-3.9-3.59v-2.95c0-.26,.23-.47,.51-.47s.51,.21,.51,.47v2.95c0,1.46,1.29,2.65,2.89,2.65s2.89-1.19,2.89-2.65v-1.23c0-.26,.23-.47,.51-.47s.51,.21,.51,.47v1.23c0,1.98-1.75,3.59-3.9,3.59Z" />
                    <path class="cls-58"
                        d="M848.62,576.94c0,1.27-1.12,2.3-2.5,2.3h-5.43c-1.38,0-2.5-1.03-2.5-2.3h0c0-1.27,1.12-2.3,2.5-2.3h5.43c1.38,0,2.5,1.03,2.5,2.3h0Z" />
                    <path class="cls-134"
                        d="M846.6,574.37c0,1.11-.98,2.01-2.18,2.01h-3.51c-1.21,0-2.18-.9-2.18-2.01h0c0-1.11,.98-2.01,2.18-2.01h3.51c1.21,0,2.18,.9,2.18,2.01h0Z" />
                    <path class="cls-137"
                        d="M843.71,572.73c0,.68-.6,1.23-1.34,1.23h-2.16c-.74,0-1.34-.55-1.34-1.23h0c0-.68,.6-1.23,1.34-1.23h2.16c.74,0,1.34,.55,1.34,1.23h0Z" />
                    <path class="cls-46"
                        d="M850.85,563.58c-2.09,0-3.8-1.57-3.8-3.49v-1.36c0-.32,.28-.58,.63-.58s.63,.26,.63,.58v1.36c0,1.28,1.13,2.32,2.53,2.32s2.53-1.04,2.53-2.32v-3.29c0-.32,.28-.58,.63-.58s.63,.26,.63,.58v3.29c0,1.92-1.7,3.49-3.8,3.49Z" />
                    <path class="cls-58"
                        d="M844.74,565.2c0,1.76-1.56,3.2-3.48,3.2h-7.55c-1.92,0-3.48-1.43-3.48-3.2h0c0-1.76,1.56-3.19,3.48-3.19h7.55c1.92,0,3.48,1.43,3.48,3.19h0Z" />
                    <path class="cls-134"
                        d="M841.92,561.63c0,1.54-1.36,2.79-3.03,2.79h-4.87c-1.67,0-3.03-1.25-3.03-2.79h0c0-1.54,1.36-2.79,3.03-2.79h4.87c1.67,0,3.03,1.25,3.03,2.79h0Z" />
                    <path class="cls-137"
                        d="M837.91,559.34c0,.95-.83,1.71-1.86,1.71h-2.99c-1.03,0-1.86-.77-1.86-1.71h0c0-.95,.83-1.71,1.86-1.71h2.99c1.03,0,1.86,.77,1.86,1.71h0Z" />
                    <path class="cls-58"
                        d="M852.61,572.67c0,1.12-.99,2.03-2.21,2.03h-4.79c-1.22,0-2.21-.91-2.21-2.03h0c0-1.12,.99-2.03,2.21-2.03h4.79c1.22,0,2.21,.91,2.21,2.03h0Z" />
                    <path class="cls-134"
                        d="M850.82,570.4c0,.98-.86,1.77-1.92,1.77h-3.09c-1.06,0-1.92-.79-1.92-1.77h0c0-.98,.86-1.77,1.92-1.77h3.09c1.06,0,1.92,.79,1.92,1.77h0Z" />
                    <path class="cls-137"
                        d="M848.27,568.95c0,.6-.53,1.09-1.18,1.09h-1.9c-.65,0-1.18-.49-1.18-1.09h0c0-.6,.53-1.09,1.18-1.09h1.9c.65,0,1.18,.49,1.18,1.09h0Z" />
                    <path class="cls-58"
                        d="M821.78,564.96c0,.77-.68,1.4-1.52,1.4h-3.31c-.84,0-1.52-.63-1.52-1.4h0c0-.77,.68-1.4,1.52-1.4h3.31c.84,0,1.52,.63,1.52,1.4h0Z" />
                    <path class="cls-134"
                        d="M820.55,563.4c0,.67-.59,1.22-1.33,1.22h-2.13c-.73,0-1.33-.55-1.33-1.22h0c0-.67,.59-1.22,1.33-1.22h2.13c.73,0,1.33,.55,1.33,1.22h0Z" />
                    <path class="cls-137"
                        d="M818.79,562.39c0,.41-.37,.75-.82,.75h-1.31c-.45,0-.82-.34-.82-.75h0c0-.41,.37-.75,.82-.75h1.31c.45,0,.82,.34,.82,.75h0Z" />
                    <path class="cls-58"
                        d="M838.09,578.63c0,.93-.82,1.68-1.83,1.68h-3.97c-1.01,0-1.83-.75-1.83-1.68h0c0-.93,.82-1.68,1.83-1.68h3.97c1.01,0,1.83,.75,1.83,1.68h0Z" />
                    <path class="cls-134"
                        d="M836.61,576.75c0,.81-.71,1.47-1.59,1.47h-2.56c-.88,0-1.59-.66-1.59-1.47h0c0-.81,.71-1.47,1.59-1.47h2.56c.88,0,1.59,.66,1.59,1.47h0Z" />
                    <path class="cls-137"
                        d="M834.5,575.54c0,.5-.44,.9-.98,.9h-1.58c-.54,0-.98-.4-.98-.9h0c0-.5,.44-.9,.98-.9h1.58c.54,0,.98,.4,.98,.9h0Z" />
                    <path class="cls-58"
                        d="M831.87,571.18c0,1.6-1.41,2.9-3.15,2.9h-6.84c-1.74,0-3.15-1.3-3.15-2.9h0c0-1.6,1.41-2.9,3.15-2.9h6.84c1.74,0,3.15,1.3,3.15,2.9h0Z" />
                    <path class="cls-134"
                        d="M829.32,567.94c0,1.4-1.23,2.53-2.75,2.53h-4.42c-1.52,0-2.75-1.13-2.75-2.53h0c0-1.4,1.23-2.53,2.75-2.53h4.42c1.52,0,2.75,1.13,2.75,2.53h0Z" />
                    <path class="cls-137"
                        d="M825.68,565.86c0,.86-.76,1.55-1.69,1.55h-2.72c-.93,0-1.69-.7-1.69-1.55h0c0-.86,.76-1.55,1.69-1.55h2.72c.93,0,1.69,.7,1.69,1.55h0Z" />
                    <path class="cls-58"
                        d="M830.75,561.86c0,.77-.68,1.4-1.52,1.4h-3.31c-.84,0-1.52-.63-1.52-1.4h0c0-.77,.68-1.4,1.52-1.4h3.31c.84,0,1.52,.63,1.52,1.4h0Z" />
                    <path class="cls-134"
                        d="M829.52,560.29c0,.67-.59,1.22-1.33,1.22h-2.14c-.73,0-1.33-.55-1.33-1.22h0c0-.67,.59-1.22,1.33-1.22h2.14c.73,0,1.33,.55,1.33,1.22h0Z" />
                    <path class="cls-137"
                        d="M827.76,559.29c0,.41-.37,.75-.82,.75h-1.31c-.45,0-.82-.34-.82-.75h0c0-.41,.37-.75,.82-.75h1.31c.45,0,.82,.34,.82,.75h0Z" />
                    <path class="cls-58"
                        d="M850.38,554.98c0,1.6-1.41,2.9-3.15,2.9h-6.84c-1.74,0-3.15-1.3-3.15-2.9h0c0-1.6,1.41-2.9,3.15-2.9h6.84c1.74,0,3.15,1.3,3.15,2.9h0Z" />
                    <path class="cls-134"
                        d="M847.83,551.75c0,1.4-1.23,2.53-2.75,2.53h-4.42c-1.52,0-2.75-1.13-2.75-2.53h0c0-1.4,1.23-2.53,2.75-2.53h4.42c1.52,0,2.75,1.13,2.75,2.53h0Z" />
                    <path class="cls-137"
                        d="M844.19,549.67c0,.86-.76,1.55-1.69,1.55h-2.72c-.93,0-1.69-.7-1.69-1.55h0c0-.86,.76-1.55,1.69-1.55h2.72c.93,0,1.69,.7,1.69,1.55h0Z" />
                    <path class="cls-58"
                        d="M860.56,558.36c0,1.49-1.31,2.7-2.93,2.7h-6.37c-1.62,0-2.93-1.21-2.93-2.7h0c0-1.49,1.31-2.7,2.93-2.7h6.37c1.62,0,2.93,1.21,2.93,2.7h0Z" />
                    <path class="cls-134"
                        d="M858.18,555.35c0,1.3-1.15,2.35-2.56,2.35h-4.11c-1.41,0-2.56-1.05-2.56-2.35h0c0-1.3,1.15-2.35,2.56-2.35h4.11c1.41,0,2.56,1.05,2.56,2.35h0Z" />
                    <path class="cls-137"
                        d="M854.8,553.42c0,.8-.7,1.44-1.57,1.44h-2.53c-.87,0-1.57-.65-1.57-1.44h0c0-.8,.7-1.45,1.57-1.45h2.53c.87,0,1.57,.65,1.57,1.45h0Z" />
                    <path class="cls-58"
                        d="M836.2,553.41c0,1.6-1.41,2.9-3.15,2.9h-6.84c-1.74,0-3.15-1.3-3.15-2.9h0c0-1.6,1.41-2.9,3.15-2.9h6.84c1.74,0,3.15,1.3,3.15,2.9h0Z" />
                    <path class="cls-134"
                        d="M833.65,550.17c0,1.4-1.23,2.53-2.75,2.53h-4.42c-1.52,0-2.75-1.13-2.75-2.53h0c0-1.4,1.23-2.53,2.75-2.53h4.42c1.52,0,2.75,1.13,2.75,2.53h0Z" />
                    <path class="cls-137"
                        d="M830.01,548.09c0,.86-.76,1.55-1.69,1.55h-2.72c-.93,0-1.69-.7-1.69-1.55h0c0-.86,.76-1.55,1.69-1.55h2.72c.93,0,1.69,.7,1.69,1.55h0Z" />
                    <path class="cls-58"
                        d="M895.59,544.71c0,2.17-1.91,3.92-4.27,3.92h-9.27c-2.36,0-4.27-1.76-4.27-3.92h0c0-2.17,1.91-3.92,4.27-3.92h9.27c2.36,0,4.27,1.76,4.27,3.92h0Z" />
                    <path class="cls-58"
                        d="M873.97,551.32c0,2.17-1.91,3.92-4.27,3.92h-9.27c-2.36,0-4.27-1.76-4.27-3.92h0c0-2.17,1.91-3.92,4.27-3.92h9.27c2.36,0,4.27,1.76,4.27,3.92h0Z" />
                    <path class="cls-134"
                        d="M870.51,546.94c0,1.89-1.67,3.42-3.72,3.42h-5.98c-2.06,0-3.72-1.53-3.72-3.42h0c0-1.89,1.67-3.42,3.72-3.42h5.98c2.06,0,3.72,1.53,3.72,3.42h0Z" />
                    <path class="cls-137"
                        d="M865.58,544.13c0,1.16-1.02,2.1-2.29,2.1h-3.68c-1.26,0-2.29-.94-2.29-2.1h0c0-1.16,1.02-2.1,2.29-2.1h3.68c1.26,0,2.29,.94,2.29,2.1h0Z" />
                    <path class="cls-58"
                        d="M889.09,572c0,1.61-1.42,2.91-3.17,2.91h-6.88c-1.75,0-3.17-1.3-3.17-2.91h0c0-1.61,1.42-2.91,3.17-2.91h6.88c1.75,0,3.17,1.3,3.17,2.91h0Z" />
                    <path class="cls-134"
                        d="M886.52,568.74c0,1.4-1.24,2.54-2.76,2.54h-4.44c-1.53,0-2.77-1.14-2.77-2.54h0c0-1.4,1.24-2.54,2.77-2.54h4.44c1.53,0,2.76,1.14,2.76,2.54h0Z" />
                    <path class="cls-137"
                        d="M882.86,566.65c0,.86-.76,1.56-1.7,1.56h-2.73c-.94,0-1.7-.7-1.7-1.56h0c0-.86,.76-1.56,1.7-1.56h2.73c.94,0,1.7,.7,1.7,1.56h0Z" />
                    <path class="cls-58"
                        d="M902.03,557.29c0,1.28-1.13,2.33-2.53,2.33h-5.49c-1.4,0-2.53-1.04-2.53-2.33h0c0-1.28,1.13-2.32,2.53-2.32h5.49c1.4,0,2.53,1.04,2.53,2.32h0Z" />
                    <path class="cls-134"
                        d="M899.98,554.69c0,1.12-.99,2.03-2.21,2.03h-3.54c-1.22,0-2.21-.91-2.21-2.03h0c0-1.12,.99-2.03,2.21-2.03h3.54c1.22,0,2.21,.91,2.21,2.03h0Z" />
                    <path class="cls-137"
                        d="M897.06,553.03c0,.69-.61,1.25-1.36,1.25h-2.18c-.75,0-1.36-.56-1.36-1.25h0c0-.69,.61-1.25,1.36-1.25h2.18c.75,0,1.36,.56,1.36,1.25h0Z" />
                    <path class="cls-46"
                        d="M887.79,588.71h-2.53v-42.78c0-.64,.56-1.15,1.25-1.15h.03c.69,0,1.25,.52,1.25,1.15v42.78Z" />
                    <path class="cls-46"
                        d="M886.52,579.82c-6.32,0-11.47-4.73-11.47-10.54v-4.28c0-.78,.69-1.4,1.53-1.4s1.53,.63,1.53,1.4v4.28c0,4.26,3.77,7.73,8.41,7.73s8.41-3.47,8.41-7.73v-10.33c0-.78,.68-1.4,1.53-1.4s1.53,.63,1.53,1.4v10.33c0,5.81-5.14,10.54-11.47,10.54Z" />
                    <path class="cls-46"
                        d="M896.28,567.25c-3.81,0-6.9-2.85-6.9-6.34v-2.51c0-.54,.48-.98,1.07-.98s1.07,.44,1.07,.98v2.51c0,2.41,2.14,4.38,4.76,4.38s4.76-1.96,4.76-4.38v-6.06c0-.54,.48-.98,1.07-.98s1.07,.44,1.07,.98v6.06c0,3.5-3.1,6.34-6.9,6.34Z" />
                    <path class="cls-46"
                        d="M876.58,574.98c-3.81,0-6.9-2.85-6.9-6.34v-14.24c0-.54,.48-.98,1.07-.98s1.07,.44,1.07,.98v14.24c0,2.41,2.14,4.38,4.76,4.38s4.76-1.96,4.76-4.38v-6.06c0-.54,.48-.98,1.07-.98s1.07,.44,1.07,.98v6.06c0,3.5-3.1,6.34-6.9,6.34Z" />
                    <path class="cls-46"
                        d="M870.29,564.13c-3.81,0-6.9-2.85-6.9-6.34v-5.07c0-.54,.48-.98,1.07-.98s1.07,.44,1.07,.98v5.07c0,2.41,2.14,4.38,4.76,4.38s4.76-1.96,4.76-4.38v-6.06c0-.54,.48-.98,1.07-.98s1.07,.44,1.07,.98v6.06c0,3.5-3.1,6.34-6.9,6.34Z" />
                    <path class="cls-46"
                        d="M876.12,553.67c-2.59,0-4.7-1.94-4.7-4.32v-2.72c0-.31,.27-.56,.61-.56s.61,.25,.61,.56v2.72c0,1.76,1.56,3.19,3.48,3.19s3.48-1.43,3.48-3.19v-1.48c0-.31,.27-.56,.61-.56s.61,.25,.61,.56v1.48c0,2.38-2.11,4.32-4.7,4.32Z" />
                    <path class="cls-46"
                        d="M864.45,557.69c-2.02,0-3.66-1.51-3.66-3.36v-2.65c0-.31,.27-.56,.61-.56s.61,.25,.61,.56v2.65c0,1.23,1.09,2.24,2.43,2.24,.88,0,1.69-.44,2.12-1.14,.17-.27,.54-.37,.83-.21,.29,.15,.4,.49,.23,.77-.65,1.06-1.87,1.71-3.19,1.71Z" />
                    <path class="cls-46"
                        d="M898.31,554.5c-2.59,0-4.7-1.94-4.7-4.32v-3.56c0-.31,.27-.56,.61-.56s.61,.25,.61,.56v3.56c0,1.76,1.56,3.2,3.48,3.2s3.48-1.43,3.48-3.2v-1.48c0-.31,.27-.56,.61-.56s.61,.25,.61,.56v1.48c0,2.38-2.11,4.32-4.7,4.32Z" />
                    <path class="cls-58"
                        d="M899.43,574.54c0,1.53-1.35,2.77-3.01,2.77h-6.54c-1.66,0-3.01-1.24-3.01-2.77h0c0-1.53,1.35-2.77,3.01-2.77h6.54c1.66,0,3.01,1.24,3.01,2.77h0Z" />
                    <path class="cls-134"
                        d="M896.99,571.44c0,1.33-1.18,2.42-2.63,2.42h-4.22c-1.45,0-2.63-1.08-2.63-2.42h0c0-1.33,1.18-2.42,2.63-2.42h4.22c1.45,0,2.63,1.08,2.63,2.42h0Z" />
                    <path class="cls-137"
                        d="M893.51,569.46c0,.82-.72,1.48-1.62,1.48h-2.6c-.89,0-1.62-.66-1.62-1.48h0c0-.82,.72-1.49,1.62-1.49h2.6c.89,0,1.62,.67,1.62,1.49h0Z" />
                    <path class="cls-46"
                        d="M902.11,558.45c-2.52,0-4.57-1.89-4.57-4.2v-1.64c0-.39,.34-.7,.76-.7s.76,.31,.76,.7v1.64c0,1.54,1.37,2.8,3.05,2.8s3.05-1.26,3.05-2.8v-3.96c0-.39,.34-.7,.76-.7s.76,.31,.76,.7v3.96c0,2.32-2.05,4.2-4.57,4.2Z" />
                    <path class="cls-58"
                        d="M894.75,560.39c0,2.12-1.87,3.85-4.19,3.85h-9.09c-2.31,0-4.19-1.72-4.19-3.85h0c0-2.12,1.87-3.85,4.19-3.85h9.09c2.31,0,4.19,1.72,4.19,3.85h0Z" />
                    <path class="cls-134"
                        d="M891.36,556.09c0,1.85-1.63,3.36-3.65,3.36h-5.87c-2.02,0-3.65-1.5-3.65-3.36h0c0-1.85,1.63-3.35,3.65-3.35h5.87c2.02,0,3.65,1.5,3.65,3.35h0Z" />
                    <path class="cls-137"
                        d="M886.53,553.34c0,1.14-1,2.06-2.24,2.06h-3.61c-1.24,0-2.24-.92-2.24-2.06h0c0-1.14,1-2.06,2.24-2.06h3.61c1.24,0,2.24,.92,2.24,2.06h0Z" />
                    <path class="cls-58"
                        d="M904.23,569.39c0,1.35-1.19,2.44-2.66,2.44h-5.77c-1.47,0-2.66-1.09-2.66-2.44h0c0-1.35,1.19-2.44,2.66-2.44h5.77c1.47,0,2.66,1.09,2.66,2.44h0Z" />
                    <path class="cls-134"
                        d="M902.08,566.66c0,1.18-1.04,2.13-2.32,2.13h-3.72c-1.28,0-2.32-.95-2.32-2.13h0c0-1.18,1.04-2.13,2.32-2.13h3.72c1.28,0,2.32,.95,2.32,2.13h0Z" />
                    <path class="cls-137"
                        d="M899.01,564.91c0,.72-.64,1.31-1.43,1.31h-2.29c-.79,0-1.42-.59-1.42-1.31h0c0-.72,.64-1.31,1.42-1.31h2.29c.79,0,1.43,.59,1.43,1.31h0Z" />
                    <path class="cls-58"
                        d="M867.11,560.11c0,.93-.82,1.69-1.84,1.69h-3.98c-1.01,0-1.83-.75-1.83-1.69h0c0-.93,.82-1.69,1.83-1.69h3.98c1.01,0,1.84,.75,1.84,1.69h0Z" />
                    <path class="cls-134"
                        d="M865.62,558.22c0,.81-.72,1.47-1.6,1.47h-2.57c-.88,0-1.6-.66-1.6-1.47h0c0-.81,.72-1.47,1.6-1.47h2.57c.88,0,1.6,.66,1.6,1.47h0Z" />
                    <path class="cls-137"
                        d="M863.5,557.02c0,.5-.44,.9-.98,.9h-1.58c-.54,0-.98-.41-.98-.9h0c0-.5,.44-.9,.98-.9h1.58c.54,0,.98,.41,.98,.9h0Z" />
                    <path class="cls-58"
                        d="M886.75,576.56c0,1.12-.99,2.02-2.2,2.02h-4.78c-1.22,0-2.2-.91-2.2-2.02h0c0-1.12,.99-2.02,2.2-2.02h4.78c1.22,0,2.2,.91,2.2,2.02h0Z" />
                    <path class="cls-134"
                        d="M884.96,574.3c0,.98-.86,1.77-1.92,1.77h-3.09c-1.06,0-1.92-.79-1.92-1.77h0c0-.97,.86-1.76,1.92-1.76h3.09c1.06,0,1.92,.79,1.92,1.76h0Z" />
                    <path class="cls-137"
                        d="M882.42,572.85c0,.6-.53,1.08-1.18,1.08h-1.9c-.65,0-1.18-.49-1.18-1.08h0c0-.6,.53-1.08,1.18-1.08h1.9c.65,0,1.18,.49,1.18,1.08h0Z" />
                    <path class="cls-58"
                        d="M879.26,567.59c0,1.93-1.7,3.49-3.8,3.49h-8.24c-2.1,0-3.8-1.56-3.8-3.49h0c0-1.93,1.7-3.49,3.8-3.49h8.24c2.1,0,3.8,1.56,3.8,3.49h0Z" />
                    <path class="cls-134"
                        d="M876.18,563.69c0,1.68-1.48,3.04-3.31,3.04h-5.32c-1.83,0-3.31-1.36-3.31-3.04h0c0-1.68,1.48-3.04,3.31-3.04h5.32c1.83,0,3.31,1.36,3.31,3.04h0Z" />
                    <path class="cls-137"
                        d="M871.8,561.19c0,1.03-.91,1.87-2.03,1.87h-3.27c-1.12,0-2.03-.84-2.03-1.87h0c0-1.03,.91-1.87,2.03-1.87h3.27c1.12,0,2.03,.84,2.03,1.87h0Z" />
                    <path class="cls-58"
                        d="M877.91,556.37c0,.93-.82,1.69-1.83,1.69h-3.98c-1.01,0-1.83-.75-1.83-1.69h0c0-.93,.82-1.69,1.83-1.69h3.98c1.01,0,1.83,.75,1.83,1.69h0Z" />
                    <path class="cls-134"
                        d="M876.42,554.49c0,.81-.72,1.47-1.6,1.47h-2.57c-.88,0-1.6-.66-1.6-1.47h0c0-.81,.72-1.47,1.6-1.47h2.57c.88,0,1.6,.66,1.6,1.47h0Z" />
                    <path class="cls-137"
                        d="M874.3,553.28c0,.5-.44,.9-.98,.9h-1.58c-.54,0-.98-.4-.98-.9h0c0-.5,.44-.9,.98-.9h1.58c.54,0,.98,.4,.98,.9h0Z" />
                    <path class="cls-58"
                        d="M901.55,548.09c0,1.93-1.7,3.49-3.8,3.49h-8.24c-2.1,0-3.8-1.56-3.8-3.49h0c0-1.93,1.7-3.49,3.8-3.49h8.24c2.1,0,3.8,1.56,3.8,3.49h0Z" />
                    <path class="cls-134"
                        d="M898.47,544.19c0,1.68-1.48,3.04-3.31,3.04h-5.32c-1.83,0-3.31-1.36-3.31-3.04h0c0-1.68,1.48-3.04,3.31-3.04h5.32c1.83,0,3.31,1.36,3.31,3.04h0Z" />
                    <path class="cls-58"
                        d="M913.81,552.16c0,1.79-1.58,3.25-3.53,3.25h-7.67c-1.95,0-3.53-1.45-3.53-3.25h0c0-1.79,1.58-3.25,3.53-3.25h7.67c1.95,0,3.53,1.45,3.53,3.25h0Z" />
                    <path class="cls-134"
                        d="M910.94,548.53c0,1.56-1.38,2.83-3.08,2.83h-4.95c-1.7,0-3.08-1.27-3.08-2.83h0c0-1.56,1.38-2.83,3.08-2.83h4.95c1.7,0,3.08,1.27,3.08,2.83h0Z" />
                    <path class="cls-137"
                        d="M906.86,546.21c0,.96-.85,1.74-1.89,1.74h-3.04c-1.05,0-1.89-.78-1.89-1.74h0c0-.96,.85-1.74,1.89-1.74h3.04c1.05,0,1.89,.78,1.89,1.74h0Z" />
                    <path class="cls-58"
                        d="M884.47,546.19c0,1.93-1.7,3.49-3.8,3.49h-8.24c-2.1,0-3.8-1.56-3.8-3.49h0c0-1.93,1.7-3.49,3.8-3.49h8.24c2.1,0,3.8,1.56,3.8,3.49h0Z" />
                </g>
                <g id="bos_2">
                    <g>
                        <rect class="cls-46" x="106.72" y="527.66" width="129.45" height="36.63" />
                        <rect class="cls-59" x="223.91" y="527.66" width="2.15" height="36.63" />
                        <rect class="cls-59" x="204.13" y="527.66" width="2.15" height="36.63" />
                        <polygon class="cls-59"
                            points="221.76 564.3 223.91 564.3 207.35 527.66 205.21 527.66 221.76 564.3" />
                        <polygon class="cls-59"
                            points="188.65 564.3 186.51 564.3 203.06 527.66 205.21 527.66 188.65 564.3" />
                        <g>
                            <rect class="cls-47" x="153.74" y="527.66" width="2.41" height="36.63" />
                            <rect class="cls-59" x="156.16" y="527.66" width="30.58" height="36.63" />
                        </g>
                        <g>
                            <rect class="cls-47" x="106.72" y="527.66" width="2.41" height="36.63" />
                            <rect class="cls-59" x="109.14" y="527.66" width="30.58" height="36.63" />
                        </g>
                        <rect class="cls-47" x="106.72" y="527.66" width="129.45" height="2.08" />
                        <g>
                            <rect class="cls-46" x="106.72" y="518.6" width="129.45" height="9.07" />
                            <rect class="cls-7" x="106.72" y="518.6" width="129.45" height="9.07" />
                            <path class="cls-7"
                                d="M114.45,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M123.27,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M132.08,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M140.9,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M149.71,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M158.52,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M167.34,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M176.15,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M184.96,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M193.78,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M202.59,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M211.41,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M220.22,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                            <path class="cls-7"
                                d="M229.03,518.55v6.01c0,.66-.57,1.2-1.26,1.2s-1.26-.54-1.26-1.2v-6.01h2.53Z" />
                        </g>
                    </g>
                    <rect class="cls-48" x="104.08" y="564.3" width="134.73" height="6.75" />
                    <g>
                        <polygon class="cls-58"
                            points="82.21 562.37 72.46 524.23 70.98 524.57 79.36 557.37 46.39 523.91 45.28 524.89 82.21 562.37" />
                        <polygon class="cls-134" points="0 525.76 0 559.87 62.13 559.87 75.5 525.76 0 525.76" />
                        <path class="cls-58"
                            d="M69.47,521.13H0v6.53H69.47c1.9,0,3.45-1.46,3.45-3.26s-1.54-3.26-3.45-3.26Z" />
                        <polygon class="cls-58" points="0 535.97 10.91 524.89 9.8 523.91 0 533.85 0 535.97" />
                    </g>
                    <path class="cls-91"
                        d="M277,588.84c-1.02-3.36-1.58-6.95-1.58-10.69,0-11.05,4.81-20.85,12.22-26.91-6.63-4.28-11.09-12.29-11.09-21.46,0-13.69,9.92-24.78,22.16-24.78s22.16,11.1,22.16,24.78c0,.43-.01,.86-.03,1.29,3.64-3.92,8.58-6.33,14.03-6.33,7.55,0,14.14,4.63,17.61,11.49,3.56-10.01,12.29-17.08,22.51-17.08,11.6,0,21.29,9.12,23.66,21.3,3.53-1.65,7.4-2.56,11.47-2.56,8.28,0,15.76,3.77,21.16,9.86,2.32-2.98,5.74-4.86,9.55-4.86,7,0,12.67,6.34,12.67,14.17,0,.43-.02,.86-.05,1.28,.65-.06,1.3-.1,1.96-.1,2.67,0,5.23,.53,7.61,1.48,2.31-3.38,5.96-5.56,10.06-5.56,2.12,0,4.12,.59,5.88,1.62,4.55-5.44,11.01-8.84,18.2-8.84,2.59,0,5.09,.44,7.44,1.26,0-.28-.02-.55-.02-.83,0-14.96,10.84-27.08,24.22-27.08s23.3,11.13,24.15,25.21c3.09-1.47,6.49-2.28,10.05-2.28,14.23,0,25.77,12.9,25.77,28.82,0,6.28-1.8,12.08-4.84,16.81H277Z" />
                    <path class="cls-58"
                        d="M505.11,490.7c0,4.82-3.5,8.73-7.81,8.73h-16.95c-4.31,0-7.81-3.91-7.81-8.73h0c0-4.82,3.5-8.73,7.81-8.73h16.95c4.31,0,7.81,3.91,7.81,8.73h0Z" />
                    <path class="cls-134"
                        d="M498.78,480.94c0,4.21-3.05,7.62-6.81,7.62h-10.94c-3.76,0-6.81-3.41-6.81-7.62h0c0-4.21,3.05-7.62,6.81-7.62h10.94c3.76,0,6.81,3.41,6.81,7.62h0Z" />
                    <path class="cls-137"
                        d="M489.76,474.69c0,2.59-1.87,4.68-4.19,4.68h-6.73c-2.31,0-4.19-2.1-4.19-4.68h0c0-2.59,1.88-4.68,4.19-4.68h6.73c2.31,0,4.19,2.1,4.19,4.68h0Z" />
                    <path class="cls-58"
                        d="M465.54,505.41c0,4.82-3.5,8.73-7.81,8.73h-16.95c-4.31,0-7.81-3.91-7.81-8.73h0c0-4.82,3.5-8.73,7.81-8.73h16.95c4.31,0,7.81,3.91,7.81,8.73h0Z" />
                    <path class="cls-134"
                        d="M459.22,495.66c0,4.21-3.05,7.62-6.81,7.62h-10.94c-3.76,0-6.81-3.41-6.81-7.62h0c0-4.21,3.05-7.62,6.81-7.62h10.94c3.76,0,6.81,3.41,6.81,7.62h0Z" />
                    <path class="cls-137"
                        d="M450.2,489.4c0,2.59-1.87,4.68-4.19,4.68h-6.73c-2.31,0-4.19-2.1-4.19-4.68h0c0-2.59,1.87-4.68,4.19-4.68h6.73c2.31,0,4.19,2.1,4.19,4.68h0Z" />
                    <path class="cls-58"
                        d="M493.22,551.45c0,3.58-2.6,6.49-5.8,6.49h-12.59c-3.2,0-5.8-2.91-5.8-6.49h0c0-3.58,2.6-6.49,5.8-6.49h12.59c3.2,0,5.8,2.9,5.8,6.49h0Z" />
                    <path class="cls-134"
                        d="M488.52,544.2c0,3.12-2.27,5.66-5.06,5.66h-8.13c-2.79,0-5.06-2.53-5.06-5.66h0c0-3.12,2.26-5.66,5.06-5.66h8.13c2.79,0,5.06,2.53,5.06,5.66h0Z" />
                    <path class="cls-137"
                        d="M481.82,539.55c0,1.92-1.39,3.48-3.11,3.48h-5c-1.72,0-3.11-1.56-3.11-3.48h0c0-1.92,1.39-3.48,3.11-3.48h5c1.72,0,3.11,1.56,3.11,3.48h0Z" />
                    <path class="cls-58"
                        d="M516.9,518.7c0,2.86-2.07,5.18-4.63,5.18h-10.05c-2.56,0-4.63-2.32-4.63-5.18h0c0-2.86,2.07-5.18,4.63-5.18h10.05c2.56,0,4.63,2.32,4.63,5.18h0Z" />
                    <path class="cls-134"
                        d="M513.15,512.92c0,2.49-1.81,4.51-4.04,4.51h-6.49c-2.23,0-4.04-2.02-4.04-4.51h0c0-2.49,1.81-4.51,4.04-4.51h6.49c2.23,0,4.04,2.02,4.04,4.51h0Z" />
                    <path class="cls-137"
                        d="M507.8,509.21c0,1.53-1.11,2.77-2.48,2.77h-3.99c-1.37,0-2.48-1.24-2.48-2.77h0c0-1.53,1.11-2.77,2.48-2.77h3.99c1.37,0,2.48,1.24,2.48,2.77h0Z" />
                    <path class="cls-46"
                        d="M490.83,588.67h-4.63v-95.26c0-1.42,1.03-2.56,2.29-2.56h.05c1.27,0,2.29,1.15,2.29,2.56v95.26Z" />
                    <path class="cls-46"
                        d="M488.52,568.87c-11.57,0-20.98-10.53-20.98-23.47v-9.53c0-1.73,1.25-3.13,2.8-3.13s2.8,1.4,2.8,3.13v9.53c0,9.49,6.9,17.21,15.39,17.21s15.39-7.72,15.39-17.21v-23c0-1.73,1.25-3.13,2.8-3.13s2.8,1.4,2.8,3.13v23c0,12.94-9.41,23.47-20.98,23.47Z" />
                    <path class="cls-46"
                        d="M506.37,540.89c-6.97,0-12.63-6.34-12.63-14.13v-5.6c0-1.21,.88-2.19,1.96-2.19s1.96,.98,1.96,2.19v5.6c0,5.37,3.91,9.75,8.72,9.75s8.72-4.37,8.72-9.75v-13.5c0-1.21,.88-2.19,1.96-2.19s1.96,.98,1.96,2.19v13.5c0,7.79-5.67,14.13-12.63,14.13Z" />
                    <path class="cls-46"
                        d="M470.33,558.11c-6.97,0-12.63-6.34-12.63-14.13v-31.7c0-1.21,.88-2.19,1.96-2.19s1.96,.98,1.96,2.19v31.7c0,5.37,3.91,9.75,8.72,9.75s8.72-4.37,8.72-9.75v-13.5c0-1.21,.88-2.19,1.96-2.19s1.96,.98,1.96,2.19v13.5c0,7.79-5.67,14.13-12.63,14.13Z" />
                    <path class="cls-46"
                        d="M458.81,533.93c-6.97,0-12.63-6.34-12.63-14.13v-11.3c0-1.21,.88-2.19,1.96-2.19s1.96,.98,1.96,2.19v11.3c0,5.37,3.91,9.75,8.72,9.75s8.72-4.37,8.72-9.75v-13.5c0-1.21,.88-2.19,1.96-2.19s1.96,.98,1.96,2.19v13.5c0,7.79-5.67,14.13-12.63,14.13Z" />
                    <path class="cls-46"
                        d="M469.48,510.64c-4.74,0-8.6-4.31-8.6-9.62v-6.06c0-.69,.5-1.25,1.12-1.25s1.12,.56,1.12,1.25v6.06c0,3.92,2.85,7.11,6.36,7.11s6.36-3.19,6.36-7.11v-3.29c0-.69,.5-1.25,1.12-1.25s1.12,.56,1.12,1.25v3.29c0,5.3-3.86,9.62-8.6,9.62Z" />
                    <path class="cls-46"
                        d="M448.13,519.6c-3.69,0-6.69-3.36-6.69-7.48v-5.9c0-.69,.5-1.25,1.12-1.25s1.12,.56,1.12,1.25v5.9c0,2.75,2,4.98,4.46,4.98,1.61,0,3.09-.97,3.88-2.54,.3-.6,.99-.81,1.52-.48,.54,.34,.73,1.1,.43,1.7-1.19,2.35-3.42,3.81-5.83,3.81Z" />
                    <path class="cls-46"
                        d="M510.08,512.5c-4.74,0-8.6-4.31-8.6-9.62v-7.92c0-.69,.5-1.25,1.12-1.25s1.12,.56,1.12,1.25v7.92c0,3.92,2.85,7.11,6.36,7.11s6.36-3.19,6.36-7.11v-3.29c0-.69,.5-1.25,1.12-1.25s1.12,.56,1.12,1.25v3.29c0,5.3-3.86,9.62-8.6,9.62Z" />
                    <path class="cls-58"
                        d="M512.14,557.11c0,3.41-2.47,6.17-5.52,6.17h-11.97c-3.05,0-5.52-2.76-5.52-6.17h0c0-3.41,2.47-6.17,5.52-6.17h11.97c3.05,0,5.52,2.76,5.52,6.17h0Z" />
                    <path class="cls-134"
                        d="M507.67,550.22c0,2.97-2.15,5.38-4.81,5.38h-7.73c-2.66,0-4.81-2.41-4.81-5.38h0c0-2.97,2.15-5.38,4.81-5.38h7.73c2.66,0,4.81,2.41,4.81,5.38h0Z" />
                    <path class="cls-137"
                        d="M501.3,545.8c0,1.83-1.32,3.31-2.96,3.31h-4.75c-1.63,0-2.96-1.48-2.96-3.31h0c0-1.83,1.32-3.31,2.96-3.31h4.75c1.63,0,2.96,1.48,2.96,3.31h0Z" />
                    <path class="cls-46"
                        d="M517.05,521.28c-4.61,0-8.37-4.2-8.37-9.36v-3.66c0-.86,.63-1.56,1.4-1.56s1.4,.7,1.4,1.56v3.66c0,3.44,2.5,6.23,5.57,6.23s5.57-2.8,5.57-6.23v-8.82c0-.86,.63-1.56,1.4-1.56s1.4,.7,1.4,1.56v8.82c0,5.16-3.75,9.36-8.37,9.36Z" />
                    <path class="cls-58"
                        d="M503.58,525.62c0,4.73-3.43,8.57-7.66,8.57h-16.63c-4.23,0-7.66-3.84-7.66-8.57h0c0-4.73,3.43-8.57,7.66-8.57h16.63c4.23,0,7.66,3.84,7.66,8.57h0Z" />
                    <path class="cls-134"
                        d="M497.37,516.05c0,4.13-2.99,7.47-6.68,7.47h-10.73c-3.69,0-6.68-3.34-6.68-7.47h0c0-4.13,2.99-7.47,6.68-7.47h10.73c3.69,0,6.68,3.34,6.68,7.47h0Z" />
                    <path class="cls-137"
                        d="M488.53,509.91c0,2.54-1.84,4.59-4.11,4.59h-6.6c-2.27,0-4.11-2.06-4.11-4.59h0c0-2.54,1.84-4.59,4.11-4.59h6.6c2.27,0,4.11,2.06,4.11,4.59h0Z" />
                    <path class="cls-58"
                        d="M520.92,545.64c0,3-2.18,5.44-4.86,5.44h-10.56c-2.69,0-4.86-2.43-4.86-5.44h0c0-3,2.18-5.44,4.86-5.44h10.56c2.69,0,4.86,2.43,4.86,5.44h0Z" />
                    <path class="cls-134"
                        d="M516.98,539.57c0,2.62-1.9,4.74-4.24,4.74h-6.82c-2.34,0-4.24-2.12-4.24-4.74h0c0-2.62,1.9-4.74,4.24-4.74h6.82c2.34,0,4.24,2.12,4.24,4.74h0Z" />
                    <path class="cls-137"
                        d="M511.37,535.67c0,1.61-1.17,2.92-2.61,2.92h-4.19c-1.44,0-2.61-1.3-2.61-2.92h0c0-1.61,1.17-2.92,2.61-2.92h4.19c1.44,0,2.61,1.31,2.61,2.92h0Z" />
                    <path class="cls-58"
                        d="M452.99,524.98c0,2.07-1.5,3.75-3.36,3.75h-7.29c-1.85,0-3.36-1.68-3.36-3.75h0c0-2.07,1.5-3.75,3.36-3.75h7.29c1.85,0,3.36,1.68,3.36,3.75h0Z" />
                    <path class="cls-134"
                        d="M450.27,520.78c0,1.81-1.31,3.27-2.93,3.27h-4.7c-1.62,0-2.93-1.47-2.93-3.27h0c0-1.81,1.31-3.27,2.93-3.27h4.7c1.62,0,2.93,1.47,2.93,3.27h0Z" />
                    <path class="cls-137"
                        d="M446.39,518.1c0,1.11-.81,2.01-1.8,2.01h-2.89c-.99,0-1.8-.9-1.8-2.01h0c0-1.11,.81-2.01,1.8-2.01h2.89c.99,0,1.8,.9,1.8,2.01h0Z" />
                    <path class="cls-58"
                        d="M488.93,561.62c0,2.49-1.8,4.51-4.03,4.51h-8.75c-2.23,0-4.03-2.02-4.03-4.51h0c0-2.49,1.8-4.51,4.03-4.51h8.75c2.23,0,4.03,2.02,4.03,4.51h0Z" />
                    <path class="cls-134"
                        d="M485.67,556.59c0,2.17-1.57,3.93-3.51,3.93h-5.65c-1.94,0-3.51-1.76-3.51-3.93h0c0-2.17,1.57-3.93,3.51-3.93h5.65c1.94,0,3.51,1.76,3.51,3.93h0Z" />
                    <path class="cls-137"
                        d="M481.01,553.36c0,1.33-.97,2.42-2.16,2.42h-3.47c-1.19,0-2.16-1.08-2.16-2.42h0c0-1.33,.97-2.41,2.16-2.41h3.47c1.19,0,2.16,1.08,2.16,2.41h0Z" />
                    <path class="cls-58"
                        d="M475.22,541.64c0,4.29-3.11,7.77-6.95,7.77h-15.08c-3.84,0-6.95-3.48-6.95-7.77h0c0-4.29,3.11-7.77,6.95-7.77h15.08c3.84,0,6.95,3.48,6.95,7.77h0Z" />
                    <path class="cls-134"
                        d="M469.59,532.96c0,3.74-2.71,6.78-6.06,6.78h-9.74c-3.35,0-6.06-3.03-6.06-6.78h0c0-3.74,2.71-6.77,6.06-6.77h9.74c3.35,0,6.06,3.03,6.06,6.77h0Z" />
                    <path class="cls-137"
                        d="M461.57,527.4c0,2.3-1.67,4.16-3.72,4.16h-5.98c-2.06,0-3.72-1.86-3.72-4.16h0c0-2.3,1.67-4.16,3.72-4.16h5.98c2.06,0,3.72,1.86,3.72,4.16h0Z" />
                    <path class="cls-58"
                        d="M472.75,516.66c0,2.07-1.5,3.75-3.36,3.75h-7.29c-1.85,0-3.36-1.68-3.36-3.75h0c0-2.07,1.5-3.75,3.36-3.75h7.29c1.85,0,3.36,1.68,3.36,3.75h0Z" />
                    <path class="cls-134"
                        d="M470.03,512.46c0,1.81-1.31,3.27-2.93,3.27h-4.7c-1.62,0-2.93-1.47-2.93-3.27h0c0-1.81,1.31-3.27,2.93-3.27h4.7c1.62,0,2.93,1.47,2.93,3.27h0Z" />
                    <path class="cls-137"
                        d="M466.16,509.78c0,1.11-.81,2.01-1.8,2.01h-2.89c-.99,0-1.8-.9-1.8-2.01h0c0-1.11,.81-2.01,1.8-2.01h2.89c.99,0,1.8,.9,1.8,2.01h0Z" />
                    <path class="cls-58"
                        d="M516.02,498.23c0,4.29-3.11,7.77-6.95,7.77h-15.08c-3.84,0-6.95-3.48-6.95-7.77h0c0-4.29,3.11-7.77,6.95-7.77h15.08c3.84,0,6.95,3.48,6.95,7.77h0Z" />
                    <path class="cls-134"
                        d="M510.39,489.54c0,3.74-2.71,6.77-6.06,6.77h-9.74c-3.35,0-6.06-3.03-6.06-6.77h0c0-3.74,2.71-6.77,6.06-6.77h9.74c3.35,0,6.06,3.03,6.06,6.77h0Z" />
                    <path class="cls-137"
                        d="M502.37,483.98c0,2.3-1.67,4.16-3.72,4.16h-5.98c-2.06,0-3.72-1.86-3.72-4.16h0c0-2.3,1.67-4.16,3.72-4.16h5.98c2.06,0,3.72,1.86,3.72,4.16h0Z" />
                    <path class="cls-58"
                        d="M484.76,493.99c0,4.29-3.11,7.77-6.95,7.77h-15.08c-3.84,0-6.95-3.48-6.95-7.77h0c0-4.29,3.11-7.77,6.95-7.77h15.08c3.84,0,6.95,3.48,6.95,7.77h0Z" />
                    <path class="cls-134"
                        d="M479.13,485.31c0,3.74-2.71,6.77-6.06,6.77h-9.74c-3.35,0-6.06-3.03-6.06-6.77h0c0-3.74,2.71-6.77,6.06-6.77h9.74c3.35,0,6.06,3.03,6.06,6.77h0Z" />
                    <path class="cls-137"
                        d="M471.11,479.75c0,2.3-1.67,4.16-3.72,4.16h-5.98c-2.06,0-3.72-1.86-3.72-4.16h0c0-2.3,1.67-4.16,3.72-4.16h5.98c2.06,0,3.72,1.86,3.72,4.16h0Z" />
                    <path class="cls-58"
                        d="M411.49,516.8c0,3.54-2.57,6.41-5.73,6.41h-12.44c-3.16,0-5.73-2.87-5.73-6.41h0c0-3.54,2.57-6.41,5.73-6.41h12.44c3.16,0,5.73,2.87,5.73,6.41h0Z" />
                    <path class="cls-134"
                        d="M406.85,509.64c0,3.09-2.24,5.59-5,5.59h-8.03c-2.76,0-5-2.5-5-5.59h0c0-3.09,2.24-5.59,5-5.59h8.03c2.76,0,5,2.5,5,5.59h0Z" />
                    <path class="cls-137"
                        d="M400.23,505.05c0,1.9-1.38,3.43-3.07,3.43h-4.94c-1.7,0-3.07-1.54-3.07-3.43h0c0-1.9,1.38-3.43,3.07-3.43h4.94c1.7,0,3.07,1.54,3.07,3.43h0Z" />
                    <path class="cls-58"
                        d="M382.47,527.59c0,3.54-2.57,6.41-5.73,6.41h-12.44c-3.16,0-5.73-2.87-5.73-6.41h0c0-3.54,2.57-6.41,5.73-6.41h12.44c3.16,0,5.73,2.87,5.73,6.41h0Z" />
                    <path class="cls-134"
                        d="M377.82,520.43c0,3.09-2.24,5.59-5,5.59h-8.03c-2.76,0-5-2.5-5-5.59h0c0-3.09,2.24-5.59,5-5.59h8.03c2.76,0,5,2.5,5,5.59h0Z" />
                    <path class="cls-137"
                        d="M371.21,515.84c0,1.9-1.37,3.43-3.07,3.43h-4.94c-1.7,0-3.07-1.54-3.07-3.43h0c0-1.9,1.37-3.43,3.07-3.43h4.94c1.7,0,3.07,1.54,3.07,3.43h0Z" />
                    <path class="cls-58"
                        d="M402.77,561.36c0,2.63-1.91,4.76-4.26,4.76h-9.24c-2.35,0-4.26-2.13-4.26-4.76h0c0-2.63,1.91-4.76,4.26-4.76h9.24c2.35,0,4.26,2.13,4.26,4.76h0Z" />
                    <path class="cls-134"
                        d="M399.32,556.05c0,2.29-1.66,4.15-3.71,4.15h-5.97c-2.05,0-3.71-1.86-3.71-4.15h0c0-2.29,1.66-4.15,3.71-4.15h5.97c2.05,0,3.71,1.86,3.71,4.15h0Z" />
                    <path class="cls-137"
                        d="M394.41,552.64c0,1.41-1.02,2.55-2.28,2.55h-3.67c-1.26,0-2.28-1.14-2.28-2.55h0c0-1.41,1.02-2.55,2.28-2.55h3.67c1.26,0,2.28,1.14,2.28,2.55h0Z" />
                    <path class="cls-58"
                        d="M420.14,537.34c0,2.1-1.52,3.8-3.4,3.8h-7.37c-1.88,0-3.4-1.7-3.4-3.8h0c0-2.1,1.52-3.8,3.4-3.8h7.37c1.88,0,3.4,1.7,3.4,3.8h0Z" />
                    <path class="cls-134"
                        d="M417.39,533.1c0,1.83-1.33,3.31-2.96,3.31h-4.76c-1.64,0-2.96-1.48-2.96-3.31h0c0-1.83,1.33-3.31,2.96-3.31h4.76c1.64,0,2.96,1.48,2.96,3.31h0Z" />
                    <path class="cls-137"
                        d="M413.47,530.38c0,1.12-.82,2.03-1.82,2.03h-2.92c-1.01,0-1.82-.91-1.82-2.03h0c0-1.12,.82-2.04,1.82-2.04h2.92c1,0,1.82,.91,1.82,2.04h0Z" />
                    <path class="cls-46"
                        d="M401.02,588.67h-3.4v-69.89c0-1.04,.75-1.88,1.68-1.88h.03c.93,0,1.68,.84,1.68,1.88v69.89Z" />
                    <path class="cls-46"
                        d="M399.32,574.15c-8.49,0-15.39-7.72-15.39-17.21v-6.99c0-1.27,.92-2.3,2.05-2.3s2.05,1.03,2.05,2.3v6.99c0,6.96,5.06,12.63,11.29,12.63s11.29-5.66,11.29-12.63v-16.87c0-1.27,.92-2.3,2.05-2.3s2.05,1.03,2.05,2.3v16.87c0,9.49-6.91,17.21-15.39,17.21Z" />
                    <path class="cls-46"
                        d="M412.42,553.62c-5.11,0-9.27-4.65-9.27-10.36v-4.11c0-.89,.64-1.61,1.44-1.61s1.44,.72,1.44,1.61v4.11c0,3.94,2.87,7.15,6.39,7.15s6.39-3.21,6.39-7.15v-9.9c0-.89,.64-1.61,1.44-1.61s1.44,.72,1.44,1.61v9.9c0,5.71-4.16,10.36-9.27,10.36Z" />
                    <path class="cls-46"
                        d="M385.98,566.25c-5.11,0-9.27-4.65-9.27-10.36v-23.25c0-.89,.64-1.61,1.44-1.61s1.44,.72,1.44,1.61v23.25c0,3.94,2.87,7.15,6.39,7.15s6.39-3.21,6.39-7.15v-9.9c0-.89,.64-1.61,1.44-1.61s1.44,.72,1.44,1.61v9.9c0,5.71-4.16,10.36-9.27,10.36Z" />
                    <path class="cls-46"
                        d="M377.52,548.51c-5.11,0-9.27-4.65-9.27-10.36v-8.29c0-.89,.64-1.61,1.44-1.61s1.44,.72,1.44,1.61v8.29c0,3.94,2.87,7.15,6.39,7.15s6.39-3.21,6.39-7.15v-9.9c0-.89,.64-1.61,1.44-1.61s1.44,.72,1.44,1.61v9.9c0,5.72-4.16,10.36-9.27,10.36Z" />
                    <path class="cls-46"
                        d="M385.35,531.42c-3.48,0-6.31-3.16-6.31-7.06v-4.44c0-.51,.37-.92,.82-.92s.82,.41,.82,.92v4.44c0,2.88,2.09,5.22,4.67,5.22s4.67-2.34,4.67-5.22v-2.41c0-.51,.37-.92,.82-.92s.82,.41,.82,.92v2.41c0,3.89-2.83,7.06-6.31,7.06Z" />
                    <path class="cls-46"
                        d="M369.69,538c-2.71,0-4.91-2.46-4.91-5.49v-4.33c0-.51,.37-.92,.82-.92s.82,.41,.82,.92v4.33c0,2.01,1.47,3.65,3.27,3.65,1.18,0,2.27-.71,2.85-1.86,.22-.44,.72-.6,1.12-.35,.39,.25,.53,.81,.31,1.25-.87,1.73-2.51,2.8-4.28,2.8Z" />
                    <path class="cls-46"
                        d="M415.14,532.79c-3.48,0-6.31-3.16-6.31-7.05v-5.81c0-.51,.37-.92,.82-.92s.82,.41,.82,.92v5.81c0,2.88,2.09,5.22,4.67,5.22s4.67-2.34,4.67-5.22v-2.41c0-.51,.37-.92,.82-.92s.82,.41,.82,.92v2.41c0,3.89-2.83,7.05-6.31,7.05Z" />
                    <path class="cls-58"
                        d="M416.65,565.52c0,2.5-1.81,4.53-4.05,4.53h-8.78c-2.24,0-4.05-2.03-4.05-4.53h0c0-2.5,1.81-4.53,4.05-4.53h8.78c2.24,0,4.05,2.03,4.05,4.53h0Z" />
                    <path class="cls-134"
                        d="M413.37,560.46c0,2.18-1.58,3.95-3.53,3.95h-5.67c-1.95,0-3.53-1.77-3.53-3.95h0c0-2.18,1.58-3.95,3.53-3.95h5.67c1.95,0,3.53,1.77,3.53,3.95h0Z" />
                    <path class="cls-137"
                        d="M408.7,557.22c0,1.34-.97,2.43-2.17,2.43h-3.49c-1.2,0-2.17-1.09-2.17-2.43h0c0-1.34,.97-2.43,2.17-2.43h3.49c1.2,0,2.17,1.09,2.17,2.43h0Z" />
                    <path class="cls-46"
                        d="M420.25,539.24c-3.39,0-6.14-3.08-6.14-6.87v-2.68c0-.63,.46-1.15,1.03-1.15s1.03,.51,1.03,1.15v2.68c0,2.52,1.83,4.57,4.09,4.57s4.09-2.05,4.09-4.57v-6.47c0-.63,.46-1.15,1.03-1.15s1.03,.51,1.03,1.15v6.47c0,3.79-2.75,6.87-6.14,6.87Z" />
                    <path class="cls-58"
                        d="M410.37,542.41c0,3.47-2.52,6.29-5.62,6.29h-12.2c-3.1,0-5.62-2.81-5.62-6.29h0c0-3.47,2.52-6.28,5.62-6.28h12.2c3.1,0,5.62,2.81,5.62,6.28h0Z" />
                    <path class="cls-134"
                        d="M405.82,535.39c0,3.03-2.19,5.48-4.9,5.48h-7.88c-2.71,0-4.9-2.45-4.9-5.48h0c0-3.03,2.19-5.48,4.9-5.48h7.88c2.71,0,4.9,2.45,4.9,5.48h0Z" />
                    <path class="cls-137"
                        d="M399.33,530.89c0,1.86-1.35,3.37-3.01,3.37h-4.84c-1.66,0-3.01-1.51-3.01-3.37h0c0-1.86,1.35-3.37,3.01-3.37h4.84c1.66,0,3.01,1.51,3.01,3.37h0Z" />
                    <path class="cls-58"
                        d="M423.09,557.11c0,2.2-1.6,3.99-3.57,3.99h-7.74c-1.97,0-3.57-1.79-3.57-3.99h0c0-2.2,1.6-3.99,3.57-3.99h7.74c1.97,0,3.57,1.79,3.57,3.99h0Z" />
                    <path class="cls-134"
                        d="M420.2,552.65c0,1.92-1.39,3.48-3.11,3.48h-5c-1.72,0-3.11-1.56-3.11-3.48h0c0-1.92,1.39-3.48,3.11-3.48h5c1.72,0,3.11,1.56,3.11,3.48h0Z" />
                    <path class="cls-137"
                        d="M416.08,549.79c0,1.18-.86,2.14-1.91,2.14h-3.07c-1.06,0-1.91-.96-1.91-2.14h0c0-1.18,.86-2.14,1.91-2.14h3.07c1.06,0,1.91,.96,1.91,2.14h0Z" />
                    <path class="cls-58"
                        d="M373.26,541.94c0,1.52-1.1,2.75-2.46,2.75h-5.35c-1.36,0-2.46-1.23-2.46-2.75h0c0-1.52,1.1-2.75,2.46-2.75h5.35c1.36,0,2.46,1.23,2.46,2.75h0Z" />
                    <path class="cls-134"
                        d="M371.26,538.87c0,1.33-.96,2.4-2.15,2.4h-3.45c-1.19,0-2.15-1.07-2.15-2.4h0c0-1.33,.96-2.4,2.15-2.4h3.45c1.19,0,2.15,1.08,2.15,2.4h0Z" />
                    <path class="cls-137"
                        d="M368.42,536.9c0,.82-.59,1.48-1.32,1.48h-2.12c-.73,0-1.32-.66-1.32-1.48h0c0-.82,.59-1.48,1.32-1.48h2.12c.73,0,1.32,.66,1.32,1.48h0Z" />
                    <path class="cls-134"
                        d="M397.23,565.13c0,1.59-1.15,2.88-2.58,2.88h-4.14c-1.42,0-2.58-1.29-2.58-2.88h0c0-1.59,1.15-2.88,2.58-2.88h4.14c1.42,0,2.58,1.29,2.58,2.88h0Z" />
                    <path class="cls-137"
                        d="M393.82,562.77c0,.98-.71,1.77-1.59,1.77h-2.55c-.87,0-1.58-.79-1.58-1.77h0c0-.98,.71-1.77,1.58-1.77h2.55c.88,0,1.59,.79,1.59,1.77h0Z" />
                    <path class="cls-58"
                        d="M389.56,554.17c0,3.15-2.28,5.7-5.1,5.7h-11.06c-2.81,0-5.1-2.55-5.1-5.7h0c0-3.15,2.28-5.7,5.1-5.7h11.06c2.82,0,5.1,2.55,5.1,5.7h0Z" />
                    <path class="cls-134"
                        d="M385.44,547.8c0,2.74-1.99,4.97-4.45,4.97h-7.14c-2.45,0-4.44-2.23-4.44-4.97h0c0-2.75,1.99-4.97,4.44-4.97h7.14c2.46,0,4.45,2.23,4.45,4.97h0Z" />
                    <path class="cls-137"
                        d="M379.55,543.72c0,1.69-1.22,3.06-2.73,3.06h-4.39c-1.51,0-2.73-1.37-2.73-3.06h0c0-1.69,1.22-3.06,2.73-3.06h4.39c1.51,0,2.73,1.37,2.73,3.06h0Z" />
                    <path class="cls-58"
                        d="M387.75,535.84c0,1.52-1.1,2.75-2.46,2.75h-5.35c-1.36,0-2.46-1.23-2.46-2.75h0c0-1.52,1.1-2.75,2.46-2.75h5.35c1.36,0,2.46,1.23,2.46,2.75h0Z" />
                    <path class="cls-134"
                        d="M385.76,532.76c0,1.33-.96,2.4-2.15,2.4h-3.45c-1.19,0-2.15-1.08-2.15-2.4h0c0-1.33,.96-2.4,2.15-2.4h3.45c1.19,0,2.15,1.08,2.15,2.4h0Z" />
                    <path class="cls-137"
                        d="M382.92,530.79c0,.82-.59,1.48-1.32,1.48h-2.12c-.73,0-1.32-.66-1.32-1.48h0c0-.82,.59-1.48,1.32-1.48h2.12c.73,0,1.32,.66,1.32,1.48h0Z" />
                    <path class="cls-58"
                        d="M419.49,522.32c0,3.15-2.28,5.7-5.1,5.7h-11.06c-2.81,0-5.1-2.55-5.1-5.7h0c0-3.15,2.28-5.7,5.1-5.7h11.06c2.82,0,5.1,2.55,5.1,5.7h0Z" />
                    <path class="cls-134"
                        d="M415.36,515.95c0,2.75-1.99,4.97-4.44,4.97h-7.14c-2.46,0-4.44-2.23-4.44-4.97h0c0-2.74,1.99-4.97,4.44-4.97h7.14c2.46,0,4.44,2.23,4.44,4.97h0Z" />
                    <path class="cls-137"
                        d="M409.48,511.87c0,1.69-1.22,3.06-2.73,3.06h-4.39c-1.51,0-2.73-1.37-2.73-3.06h0c0-1.69,1.22-3.05,2.73-3.05h4.39c1.51,0,2.73,1.37,2.73,3.05h0Z" />
                    <path class="cls-58"
                        d="M435.94,528.97c0,2.93-2.12,5.3-4.74,5.3h-10.29c-2.62,0-4.74-2.37-4.74-5.3h0c0-2.93,2.12-5.3,4.74-5.3h10.29c2.62,0,4.74,2.37,4.74,5.3h0Z" />
                    <path class="cls-134"
                        d="M432.1,523.04c0,2.55-1.85,4.62-4.14,4.62h-6.65c-2.28,0-4.14-2.07-4.14-4.62h0c0-2.55,1.85-4.62,4.14-4.62h6.65c2.28,0,4.14,2.07,4.14,4.62h0Z" />
                    <path class="cls-137"
                        d="M426.63,519.24c0,1.57-1.14,2.84-2.54,2.84h-4.08c-1.4,0-2.54-1.27-2.54-2.84h0c0-1.57,1.14-2.84,2.54-2.84h4.08c1.4,0,2.54,1.27,2.54,2.84h0Z" />
                    <path class="cls-58"
                        d="M396.56,519.21c0,3.15-2.28,5.7-5.1,5.7h-11.06c-2.81,0-5.1-2.55-5.1-5.7h0c0-3.15,2.28-5.7,5.1-5.7h11.06c2.81,0,5.1,2.55,5.1,5.7h0Z" />
                    <path class="cls-134"
                        d="M392.43,512.85c0,2.75-1.99,4.97-4.44,4.97h-7.14c-2.45,0-4.44-2.22-4.44-4.97h0c0-2.74,1.99-4.97,4.44-4.97h7.14c2.45,0,4.44,2.23,4.44,4.97h0Z" />
                    <path class="cls-137"
                        d="M386.55,508.76c0,1.69-1.22,3.06-2.73,3.06h-4.39c-1.51,0-2.73-1.37-2.73-3.06h0c0-1.69,1.22-3.06,2.73-3.06h4.39c1.51,0,2.73,1.37,2.73,3.06h0Z" />
                    <path class="cls-58"
                        d="M351.97,527.83c0,3-2.17,5.42-4.85,5.42h-10.53c-2.68,0-4.85-2.43-4.85-5.42h0c0-3,2.17-5.42,4.85-5.42h10.53c2.68,0,4.85,2.43,4.85,5.42h0Z" />
                    <path class="cls-134"
                        d="M348.04,521.76c0,2.61-1.89,4.73-4.23,4.73h-6.8c-2.34,0-4.23-2.12-4.23-4.73h0c0-2.61,1.89-4.73,4.23-4.73h6.8c2.34,0,4.23,2.12,4.23,4.73h0Z" />
                    <path class="cls-137"
                        d="M342.44,517.88c0,1.61-1.16,2.91-2.6,2.91h-4.18c-1.44,0-2.6-1.3-2.6-2.91h0c0-1.61,1.16-2.91,2.6-2.91h4.18c1.44,0,2.6,1.3,2.6,2.91h0Z" />
                    <path class="cls-58"
                        d="M327.4,536.96c0,3-2.17,5.42-4.85,5.42h-10.53c-2.68,0-4.85-2.43-4.85-5.42h0c0-3,2.17-5.42,4.85-5.42h10.53c2.68,0,4.85,2.43,4.85,5.42h0Z" />
                    <path class="cls-134"
                        d="M323.47,530.9c0,2.61-1.89,4.73-4.23,4.73h-6.8c-2.34,0-4.23-2.12-4.23-4.73h0c0-2.61,1.89-4.73,4.23-4.73h6.8c2.34,0,4.23,2.12,4.23,4.73h0Z" />
                    <path class="cls-137"
                        d="M317.87,527.02c0,1.61-1.16,2.91-2.6,2.91h-4.18c-1.44,0-2.6-1.3-2.6-2.91h0c0-1.61,1.16-2.91,2.6-2.91h4.18c1.44,0,2.6,1.3,2.6,2.91h0Z" />
                    <path class="cls-58"
                        d="M344.59,565.55c0,2.23-1.61,4.03-3.6,4.03h-7.82c-1.99,0-3.6-1.8-3.6-4.03h0c0-2.23,1.61-4.03,3.6-4.03h7.82c1.99,0,3.6,1.8,3.6,4.03h0Z" />
                    <path class="cls-134"
                        d="M341.67,561.05c0,1.94-1.41,3.51-3.14,3.51h-5.05c-1.73,0-3.14-1.57-3.14-3.51h0c0-1.94,1.41-3.51,3.14-3.51h5.05c1.74,0,3.14,1.57,3.14,3.51h0Z" />
                    <path class="cls-137"
                        d="M337.51,558.17c0,1.19-.86,2.16-1.93,2.16h-3.1c-1.07,0-1.93-.97-1.93-2.16h0c0-1.19,.86-2.16,1.93-2.16h3.1c1.07,0,1.93,.97,1.93,2.16h0Z" />
                    <path class="cls-58"
                        d="M359.29,545.21c0,1.78-1.29,3.22-2.87,3.22h-6.24c-1.59,0-2.88-1.44-2.88-3.22h0c0-1.77,1.29-3.21,2.88-3.21h6.24c1.59,0,2.87,1.44,2.87,3.21h0Z" />
                    <path class="cls-134"
                        d="M356.96,541.62c0,1.55-1.12,2.8-2.51,2.8h-4.03c-1.38,0-2.51-1.26-2.51-2.8h0c0-1.55,1.12-2.8,2.51-2.8h4.03c1.38,0,2.51,1.26,2.51,2.8h0Z" />
                    <path class="cls-137"
                        d="M353.64,539.32c0,.95-.69,1.72-1.54,1.72h-2.48c-.85,0-1.54-.77-1.54-1.72h0c0-.95,.69-1.72,1.54-1.72h2.48c.85,0,1.54,.77,1.54,1.72h0Z" />
                    <path class="cls-46"
                        d="M343.1,588.67h-2.88v-59.16c0-.88,.64-1.59,1.42-1.59h.03c.79,0,1.42,.71,1.42,1.59v59.16Z" />
                    <path class="cls-46"
                        d="M341.67,576.38c-7.19,0-13.03-6.54-13.03-14.57v-5.92c0-1.07,.78-1.94,1.74-1.94s1.74,.87,1.74,1.94v5.92c0,5.89,4.29,10.69,9.56,10.69s9.56-4.8,9.56-10.69v-14.28c0-1.07,.78-1.94,1.74-1.94s1.74,.87,1.74,1.94v14.28c0,8.03-5.85,14.57-13.03,14.57Z" />
                    <path class="cls-46"
                        d="M352.76,559c-4.33,0-7.85-3.94-7.85-8.77v-3.48c0-.75,.54-1.36,1.22-1.36s1.22,.61,1.22,1.36v3.48c0,3.34,2.43,6.05,5.41,6.05s5.41-2.72,5.41-6.05v-8.38c0-.75,.54-1.36,1.22-1.36s1.22,.61,1.22,1.36v8.38c0,4.84-3.52,8.77-7.85,8.77Z" />
                    <path class="cls-46"
                        d="M330.37,569.69c-4.33,0-7.85-3.94-7.85-8.77v-19.68c0-.75,.54-1.36,1.22-1.36s1.22,.61,1.22,1.36v19.68c0,3.34,2.43,6.05,5.41,6.05s5.41-2.72,5.41-6.05v-8.38c0-.75,.54-1.36,1.22-1.36s1.22,.61,1.22,1.36v8.38c0,4.84-3.52,8.77-7.85,8.77Z" />
                    <path class="cls-46"
                        d="M323.21,554.67c-4.33,0-7.85-3.94-7.85-8.77v-7.02c0-.75,.54-1.36,1.22-1.36s1.22,.61,1.22,1.36v7.02c0,3.34,2.43,6.05,5.41,6.05s5.41-2.71,5.41-6.05v-8.38c0-.75,.54-1.36,1.22-1.36s1.22,.61,1.22,1.36v8.38c0,4.84-3.52,8.77-7.84,8.77Z" />
                    <path class="cls-46"
                        d="M329.84,540.21c-2.95,0-5.34-2.68-5.34-5.97v-3.76c0-.43,.31-.78,.69-.78s.69,.35,.69,.78v3.76c0,2.44,1.77,4.42,3.95,4.42s3.95-1.98,3.95-4.42v-2.04c0-.43,.31-.78,.69-.78s.7,.35,.7,.78v2.04c0,3.29-2.4,5.97-5.34,5.97Z" />
                    <path class="cls-46"
                        d="M316.58,545.78c-2.29,0-4.16-2.09-4.16-4.65v-3.66c0-.43,.31-.78,.7-.78s.69,.35,.69,.78v3.66c0,1.71,1.24,3.09,2.77,3.09,1,0,1.92-.6,2.41-1.58,.19-.37,.61-.51,.95-.3,.33,.21,.45,.68,.26,1.06-.74,1.46-2.12,2.37-3.62,2.37Z" />
                    <path class="cls-46"
                        d="M355.06,541.36c-2.94,0-5.34-2.68-5.34-5.97v-4.92c0-.43,.31-.78,.7-.78s.69,.35,.69,.78v4.92c0,2.44,1.77,4.42,3.95,4.42s3.95-1.98,3.95-4.42v-2.04c0-.43,.31-.78,.7-.78s.69,.35,.69,.78v2.04c0,3.29-2.4,5.97-5.34,5.97Z" />
                    <path class="cls-134"
                        d="M353.56,564.79c0,1.85-1.34,3.34-2.99,3.34h-4.8c-1.65,0-2.99-1.5-2.99-3.34h0c0-1.84,1.34-3.34,2.99-3.34h4.8c1.65,0,2.99,1.5,2.99,3.34h0Z" />
                    <path class="cls-137"
                        d="M349.61,562.05c0,1.13-.82,2.05-1.84,2.05h-2.95c-1.01,0-1.84-.92-1.84-2.05h0c0-1.13,.82-2.05,1.84-2.05h2.95c1.01,0,1.84,.92,1.84,2.05h0Z" />
                    <path class="cls-46"
                        d="M359.38,546.82c-2.87,0-5.2-2.61-5.2-5.81v-2.27c0-.54,.39-.97,.87-.97s.87,.43,.87,.97v2.27c0,2.13,1.55,3.87,3.46,3.87s3.46-1.74,3.46-3.87v-5.48c0-.54,.39-.97,.87-.97s.87,.44,.87,.97v5.48c0,3.21-2.33,5.81-5.2,5.81Z" />
                    <path class="cls-58"
                        d="M351.02,549.51c0,2.94-2.13,5.32-4.76,5.32h-10.33c-2.63,0-4.76-2.38-4.76-5.32h0c0-2.94,2.13-5.32,4.76-5.32h10.33c2.63,0,4.76,2.38,4.76,5.32h0Z" />
                    <path class="cls-134"
                        d="M347.16,543.57c0,2.56-1.86,4.64-4.15,4.64h-6.67c-2.29,0-4.15-2.08-4.15-4.64h0c0-2.56,1.86-4.64,4.15-4.64h6.67c2.29,0,4.15,2.08,4.15,4.64h0Z" />
                    <path class="cls-137"
                        d="M341.67,539.76c0,1.58-1.14,2.85-2.55,2.85h-4.1c-1.41,0-2.55-1.28-2.55-2.85h0c0-1.57,1.14-2.85,2.55-2.85h4.1c1.41,0,2.55,1.28,2.55,2.85h0Z" />
                    <path class="cls-58"
                        d="M361.79,561.95c0,1.86-1.35,3.38-3.02,3.38h-6.56c-1.67,0-3.02-1.51-3.02-3.38h0c0-1.87,1.35-3.38,3.02-3.38h6.56c1.67,0,3.02,1.51,3.02,3.38h0Z" />
                    <path class="cls-134"
                        d="M359.34,558.18c0,1.63-1.18,2.95-2.63,2.95h-4.23c-1.45,0-2.63-1.32-2.63-2.95h0c0-1.63,1.18-2.95,2.63-2.95h4.23c1.45,0,2.63,1.32,2.63,2.95h0Z" />
                    <path class="cls-137"
                        d="M355.86,555.76c0,1-.72,1.81-1.62,1.81h-2.6c-.89,0-1.62-.81-1.62-1.81h0c0-1,.72-1.81,1.62-1.81h2.6c.89,0,1.62,.81,1.62,1.81h0Z" />
                    <path class="cls-58"
                        d="M319.6,549.11c0,1.29-.93,2.33-2.08,2.33h-4.53c-1.15,0-2.09-1.04-2.09-2.33h0c0-1.29,.93-2.33,2.09-2.33h4.53c1.15,0,2.08,1.04,2.08,2.33h0Z" />
                    <path class="cls-134"
                        d="M317.91,546.51c0,1.12-.81,2.03-1.82,2.03h-2.92c-1,0-1.82-.91-1.82-2.03h0c0-1.12,.81-2.03,1.82-2.03h2.92c1,0,1.82,.91,1.82,2.03h0Z" />
                    <path class="cls-137"
                        d="M315.5,544.84c0,.69-.5,1.25-1.12,1.25h-1.8c-.62,0-1.12-.56-1.12-1.25h0c0-.69,.5-1.25,1.12-1.25h1.8c.62,0,1.12,.56,1.12,1.25h0Z" />
                    <path class="cls-58"
                        d="M333.41,559.46c0,2.67-1.93,4.83-4.32,4.83h-9.37c-2.38,0-4.32-2.16-4.32-4.83h0c0-2.66,1.93-4.83,4.32-4.83h9.37c2.38,0,4.32,2.16,4.32,4.83h0Z" />
                    <path class="cls-134"
                        d="M329.91,554.07c0,2.32-1.68,4.21-3.76,4.21h-6.05c-2.08,0-3.76-1.88-3.76-4.21h0c0-2.32,1.68-4.21,3.76-4.21h6.05c2.08,0,3.76,1.88,3.76,4.21h0Z" />
                    <path class="cls-137"
                        d="M324.93,550.62c0,1.43-1.04,2.59-2.31,2.59h-3.72c-1.28,0-2.31-1.16-2.31-2.59h0c0-1.43,1.04-2.59,2.31-2.59h3.72c1.28,0,2.31,1.16,2.31,2.59h0Z" />
                    <path class="cls-58"
                        d="M331.87,543.95c0,1.29-.93,2.33-2.08,2.33h-4.53c-1.15,0-2.09-1.04-2.09-2.33h0c0-1.29,.93-2.33,2.09-2.33h4.53c1.15,0,2.08,1.04,2.08,2.33h0Z" />
                    <path class="cls-134"
                        d="M330.19,541.34c0,1.12-.81,2.03-1.82,2.03h-2.92c-1,0-1.82-.91-1.82-2.03h0c0-1.12,.81-2.03,1.82-2.03h2.92c1,0,1.82,.91,1.82,2.03h0Z" />
                    <path class="cls-137"
                        d="M327.78,539.67c0,.69-.5,1.25-1.12,1.25h-1.8c-.62,0-1.12-.56-1.12-1.25h0c0-.69,.5-1.25,1.12-1.25h1.8c.62,0,1.12,.56,1.12,1.25h0Z" />
                    <path class="cls-58"
                        d="M358.74,532.5c0,2.66-1.93,4.83-4.31,4.83h-9.37c-2.38,0-4.32-2.16-4.32-4.83h0c0-2.67,1.93-4.83,4.32-4.83h9.37c2.38,0,4.31,2.16,4.31,4.83h0Z" />
                    <path class="cls-134"
                        d="M355.25,527.11c0,2.32-1.69,4.21-3.76,4.21h-6.05c-2.08,0-3.76-1.88-3.76-4.21h0c0-2.32,1.68-4.21,3.76-4.21h6.05c2.08,0,3.76,1.88,3.76,4.21h0Z" />
                    <path class="cls-137"
                        d="M350.27,523.65c0,1.43-1.04,2.59-2.31,2.59h-3.72c-1.28,0-2.31-1.16-2.31-2.59h0c0-1.43,1.04-2.59,2.31-2.59h3.72c1.28,0,2.31,1.16,2.31,2.59h0Z" />
                    <path class="cls-58"
                        d="M372.67,538.13c0,2.48-1.8,4.49-4.01,4.49h-8.71c-2.22,0-4.01-2.01-4.01-4.49h0c0-2.48,1.8-4.49,4.01-4.49h8.71c2.22,0,4.01,2.01,4.01,4.49h0Z" />
                    <path class="cls-134"
                        d="M369.42,533.11c0,2.16-1.57,3.92-3.5,3.92h-5.63c-1.93,0-3.5-1.75-3.5-3.92h0c0-2.16,1.57-3.92,3.5-3.92h5.63c1.93,0,3.5,1.75,3.5,3.92h0Z" />
                    <path class="cls-137"
                        d="M364.78,529.9c0,1.33-.96,2.41-2.15,2.41h-3.46c-1.19,0-2.15-1.08-2.15-2.41h0c0-1.33,.96-2.41,2.15-2.41h3.46c1.19,0,2.15,1.08,2.15,2.41h0Z" />
                    <path class="cls-58"
                        d="M339.33,529.87c0,2.66-1.93,4.83-4.31,4.83h-9.37c-2.38,0-4.32-2.16-4.32-4.83h0c0-2.67,1.93-4.83,4.32-4.83h9.37c2.38,0,4.31,2.16,4.31,4.83h0Z" />
                    <path class="cls-134"
                        d="M335.84,524.48c0,2.32-1.68,4.21-3.76,4.21h-6.05c-2.08,0-3.76-1.88-3.76-4.21h0c0-2.32,1.68-4.21,3.76-4.21h6.05c2.08,0,3.76,1.88,3.76,4.21h0Z" />
                    <path class="cls-137"
                        d="M330.85,521.02c0,1.43-1.04,2.59-2.31,2.59h-3.72c-1.28,0-2.31-1.16-2.31-2.59h0c0-1.43,1.04-2.59,2.31-2.59h3.72c1.28,0,2.31,1.16,2.31,2.59h0Z" />
                    <path class="cls-58"
                        d="M200.8,540.53c0,2.37-1.72,4.29-3.84,4.29h-8.33c-2.12,0-3.84-1.92-3.84-4.29h0c0-2.37,1.72-4.29,3.84-4.29h8.33c2.12,0,3.84,1.92,3.84,4.29h0Z" />
                    <path class="cls-134"
                        d="M197.69,535.74c0,2.07-1.5,3.74-3.35,3.74h-5.38c-1.85,0-3.35-1.68-3.35-3.74h0c0-2.07,1.5-3.74,3.35-3.74h5.38c1.85,0,3.35,1.68,3.35,3.74h0Z" />
                    <path class="cls-137"
                        d="M193.26,532.66c0,1.27-.92,2.3-2.06,2.3h-3.31c-1.14,0-2.06-1.03-2.06-2.3h0c0-1.27,.92-2.3,2.06-2.3h3.31c1.14,0,2.06,1.03,2.06,2.3h0Z" />
                    <path class="cls-58"
                        d="M181.36,547.76c0,2.37-1.72,4.29-3.84,4.29h-8.33c-2.12,0-3.84-1.92-3.84-4.29h0c0-2.37,1.72-4.29,3.84-4.29h8.33c2.12,0,3.84,1.92,3.84,4.29h0Z" />
                    <path class="cls-134"
                        d="M178.25,542.97c0,2.07-1.5,3.74-3.35,3.74h-5.38c-1.85,0-3.35-1.68-3.35-3.74h0c0-2.07,1.5-3.74,3.35-3.74h5.38c1.85,0,3.35,1.68,3.35,3.74h0Z" />
                    <path class="cls-137"
                        d="M173.82,539.89c0,1.27-.92,2.3-2.06,2.3h-3.31c-1.14,0-2.06-1.03-2.06-2.3h0c0-1.27,.92-2.3,2.06-2.3h3.31c1.14,0,2.06,1.03,2.06,2.3h0Z" />
                    <path class="cls-134"
                        d="M192.65,566.82c0,1.53-1.11,2.78-2.49,2.78h-4c-1.37,0-2.49-1.25-2.49-2.78h0c0-1.54,1.11-2.78,2.49-2.78h4c1.37,0,2.49,1.24,2.49,2.78h0Z" />
                    <path class="cls-137"
                        d="M189.36,564.54c0,.94-.68,1.71-1.53,1.71h-2.46c-.84,0-1.53-.77-1.53-1.71h0c0-.94,.68-1.71,1.53-1.71h2.46c.84,0,1.53,.77,1.53,1.71h0Z" />
                    <path class="cls-58"
                        d="M206.59,554.29c0,1.4-1.02,2.54-2.27,2.54h-4.94c-1.26,0-2.27-1.14-2.27-2.54h0c0-1.4,1.02-2.54,2.27-2.54h4.94c1.26,0,2.27,1.14,2.27,2.54h0Z" />
                    <path class="cls-134"
                        d="M204.75,551.45c0,1.22-.89,2.22-1.98,2.22h-3.19c-1.1,0-1.98-.99-1.98-2.22h0c0-1.22,.89-2.22,1.98-2.22h3.19c1.1,0,1.98,.99,1.98,2.22h0Z" />
                    <path class="cls-137"
                        d="M202.12,549.63c0,.75-.55,1.36-1.22,1.36h-1.96c-.67,0-1.22-.61-1.22-1.36h0c0-.75,.55-1.36,1.22-1.36h1.96c.67,0,1.22,.61,1.22,1.36h0Z" />
                    <path class="cls-46"
                        d="M193.79,588.67h-2.28v-46.81c0-.7,.5-1.26,1.13-1.26h.02c.62,0,1.13,.56,1.13,1.26v46.81Z" />
                    <path class="cls-46"
                        d="M192.65,578.94c-5.69,0-10.31-5.17-10.31-11.53v-4.69c0-.85,.62-1.54,1.37-1.54s1.37,.69,1.37,1.54v4.69c0,4.66,3.39,8.46,7.56,8.46s7.56-3.79,7.56-8.46v-11.3c0-.85,.62-1.54,1.37-1.54s1.37,.69,1.37,1.54v11.3c0,6.36-4.63,11.53-10.31,11.53Z" />
                    <path class="cls-46"
                        d="M201.42,565.2c-3.42,0-6.21-3.11-6.21-6.94v-2.75c0-.59,.43-1.08,.96-1.08s.96,.48,.96,1.08v2.75c0,2.64,1.92,4.79,4.28,4.79s4.28-2.15,4.28-4.79v-6.63c0-.59,.43-1.08,.96-1.08s.96,.48,.96,1.08v6.63c0,3.83-2.78,6.94-6.21,6.94Z" />
                    <path class="cls-46"
                        d="M183.71,573.65c-3.42,0-6.21-3.11-6.21-6.94v-15.57c0-.59,.43-1.08,.96-1.08s.96,.48,.96,1.08v15.57c0,2.64,1.92,4.79,4.28,4.79s4.28-2.15,4.28-4.79v-6.63c0-.59,.43-1.08,.96-1.08s.96,.48,.96,1.08v6.63c0,3.83-2.78,6.94-6.21,6.94Z" />
                    <path class="cls-46"
                        d="M178.05,561.77c-3.42,0-6.21-3.11-6.21-6.94v-5.55c0-.59,.43-1.08,.96-1.08s.96,.48,.96,1.08v5.55c0,2.64,1.92,4.79,4.28,4.79s4.28-2.15,4.28-4.79v-6.63c0-.59,.43-1.08,.96-1.08s.96,.48,.96,1.08v6.63c0,3.83-2.78,6.94-6.21,6.94Z" />
                    <path class="cls-46"
                        d="M183.29,550.33c-2.33,0-4.23-2.12-4.23-4.73v-2.98c0-.34,.25-.62,.55-.62s.55,.27,.55,.62v2.98c0,1.93,1.4,3.5,3.13,3.5s3.13-1.57,3.13-3.5v-1.61c0-.34,.25-.62,.55-.62s.55,.28,.55,.62v1.61c0,2.61-1.9,4.73-4.23,4.73Z" />
                    <path class="cls-46"
                        d="M172.8,554.73c-1.81,0-3.29-1.65-3.29-3.68v-2.9c0-.34,.25-.62,.55-.62s.55,.28,.55,.62v2.9c0,1.35,.98,2.45,2.19,2.45,.79,0,1.52-.48,1.91-1.25,.15-.3,.48-.4,.75-.23,.26,.17,.36,.54,.21,.84-.58,1.16-1.68,1.87-2.87,1.87Z" />
                    <path class="cls-46"
                        d="M203.24,551.24c-2.33,0-4.23-2.12-4.23-4.73v-3.89c0-.34,.25-.62,.55-.62s.55,.27,.55,.62v3.89c0,1.93,1.4,3.5,3.13,3.5s3.13-1.57,3.13-3.5v-1.61c0-.34,.25-.61,.55-.61s.55,.28,.55,.61v1.61c0,2.61-1.9,4.73-4.22,4.73Z" />
                    <path class="cls-46"
                        d="M206.67,555.56c-2.27,0-4.11-2.06-4.11-4.6v-1.8c0-.42,.31-.77,.69-.77s.69,.34,.69,.77v1.8c0,1.69,1.23,3.06,2.74,3.06s2.74-1.37,2.74-3.06v-4.33c0-.42,.31-.77,.69-.77s.69,.34,.69,.77v4.33c0,2.54-1.84,4.6-4.11,4.6Z" />
                    <path class="cls-58"
                        d="M200.05,557.69c0,2.33-1.69,4.21-3.76,4.21h-8.17c-2.08,0-3.76-1.89-3.76-4.21h0c0-2.32,1.69-4.21,3.76-4.21h8.17c2.08,0,3.76,1.89,3.76,4.21h0Z" />
                    <path class="cls-134"
                        d="M197,552.99c0,2.03-1.47,3.67-3.28,3.67h-5.27c-1.81,0-3.28-1.64-3.28-3.67h0c0-2.03,1.47-3.67,3.28-3.67h5.27c1.81,0,3.28,1.64,3.28,3.67h0Z" />
                    <path class="cls-137"
                        d="M192.65,549.97c0,1.25-.9,2.26-2.02,2.26h-3.24c-1.11,0-2.02-1.01-2.02-2.26h0c0-1.25,.9-2.26,2.02-2.26h3.24c1.11,0,2.02,1.01,2.02,2.26h0Z" />
                    <path class="cls-134"
                        d="M206.63,564.54c0,1.29-.93,2.33-2.08,2.33h-3.35c-1.15,0-2.08-1.04-2.08-2.33h0c0-1.29,.93-2.33,2.08-2.33h3.35c1.15,0,2.08,1.04,2.08,2.33h0Z" />
                    <path class="cls-137"
                        d="M203.88,562.63c0,.79-.57,1.43-1.28,1.43h-2.06c-.71,0-1.28-.64-1.28-1.43h0c0-.79,.57-1.43,1.28-1.43h2.06c.71,0,1.28,.64,1.28,1.43h0Z" />
                    <path class="cls-58"
                        d="M175.19,557.37c0,1.02-.74,1.85-1.65,1.85h-3.58c-.91,0-1.65-.83-1.65-1.85h0c0-1.02,.74-1.84,1.65-1.84h3.58c.91,0,1.65,.83,1.65,1.84h0Z" />
                    <path class="cls-134"
                        d="M173.86,555.31c0,.89-.64,1.61-1.44,1.61h-2.31c-.79,0-1.44-.72-1.44-1.61h0c0-.89,.64-1.61,1.44-1.61h2.31c.79,0,1.44,.72,1.44,1.61h0Z" />
                    <path class="cls-137"
                        d="M171.95,553.99c0,.55-.4,.99-.88,.99h-1.42c-.49,0-.88-.44-.88-.99h0c0-.55,.4-.99,.88-.99h1.42c.49,0,.88,.44,.88,.99h0Z" />
                    <path class="cls-58"
                        d="M186.11,565.56c0,2.11-1.53,3.82-3.41,3.82h-7.41c-1.88,0-3.41-1.71-3.41-3.82h0c0-2.11,1.53-3.82,3.41-3.82h7.41c1.89,0,3.41,1.71,3.41,3.82h0Z" />
                    <path class="cls-134"
                        d="M183.35,561.3c0,1.84-1.33,3.33-2.98,3.33h-4.78c-1.64,0-2.98-1.49-2.98-3.33h0c0-1.84,1.33-3.33,2.98-3.33h4.78c1.64,0,2.98,1.49,2.98,3.33h0Z" />
                    <path class="cls-137"
                        d="M179.41,558.57c0,1.13-.82,2.05-1.83,2.05h-2.94c-1.01,0-1.83-.92-1.83-2.05h0c0-1.13,.82-2.05,1.83-2.05h2.94c1.01,0,1.83,.92,1.83,2.05h0Z" />
                    <path class="cls-58"
                        d="M184.9,553.29c0,1.02-.74,1.84-1.65,1.84h-3.58c-.91,0-1.65-.83-1.65-1.84h0c0-1.02,.74-1.84,1.65-1.84h3.58c.91,0,1.65,.83,1.65,1.84h0Z" />
                    <path class="cls-134"
                        d="M183.57,551.23c0,.89-.64,1.61-1.44,1.61h-2.31c-.79,0-1.44-.72-1.44-1.61h0c0-.89,.64-1.61,1.44-1.61h2.31c.79,0,1.44,.72,1.44,1.61h0Z" />
                    <path class="cls-137"
                        d="M181.66,549.91c0,.55-.4,.99-.88,.99h-1.42c-.49,0-.88-.44-.88-.99h0c0-.55,.4-.99,.88-.99h1.42c.49,0,.88,.44,.88,.99h0Z" />
                    <path class="cls-58"
                        d="M206.16,544.23c0,2.11-1.53,3.82-3.41,3.82h-7.41c-1.89,0-3.41-1.71-3.41-3.82h0c0-2.11,1.53-3.82,3.41-3.82h7.41c1.89,0,3.41,1.71,3.41,3.82h0Z" />
                    <path class="cls-134"
                        d="M203.39,539.97c0,1.84-1.33,3.33-2.98,3.33h-4.78c-1.64,0-2.98-1.49-2.98-3.33h0c0-1.84,1.33-3.33,2.98-3.33h4.78c1.64,0,2.98,1.49,2.98,3.33h0Z" />
                    <path class="cls-137"
                        d="M199.45,537.23c0,1.13-.82,2.05-1.83,2.05h-2.94c-1.01,0-1.83-.92-1.83-2.05h0c0-1.13,.82-2.05,1.83-2.05h2.94c1.01,0,1.83,.92,1.83,2.05h0Z" />
                    <path class="cls-58"
                        d="M217.18,548.68c0,1.96-1.42,3.55-3.18,3.55h-6.89c-1.75,0-3.18-1.59-3.18-3.55h0c0-1.96,1.42-3.55,3.18-3.55h6.89c1.75,0,3.18,1.59,3.18,3.55h0Z" />
                    <path class="cls-134"
                        d="M214.6,544.71c0,1.71-1.24,3.1-2.77,3.1h-4.45c-1.53,0-2.77-1.39-2.77-3.1h0c0-1.71,1.24-3.1,2.77-3.1h4.45c1.53,0,2.77,1.39,2.77,3.1h0Z" />
                    <path class="cls-137"
                        d="M210.94,542.17c0,1.05-.76,1.9-1.7,1.9h-2.74c-.94,0-1.7-.85-1.7-1.9h0c0-1.05,.76-1.9,1.7-1.9h2.74c.94,0,1.7,.85,1.7,1.9h0Z" />
                    <path class="cls-58"
                        d="M190.8,542.15c0,2.11-1.53,3.82-3.41,3.82h-7.41c-1.89,0-3.41-1.71-3.41-3.82h0c0-2.11,1.53-3.82,3.41-3.82h7.41c1.89,0,3.41,1.71,3.41,3.82h0Z" />
                    <path class="cls-134"
                        d="M188.04,537.89c0,1.84-1.33,3.33-2.98,3.33h-4.78c-1.64,0-2.98-1.49-2.98-3.33h0c0-1.84,1.33-3.33,2.98-3.33h4.78c1.64,0,2.98,1.49,2.98,3.33h0Z" />
                    <path class="cls-137"
                        d="M184.09,535.15c0,1.13-.82,2.05-1.83,2.05h-2.94c-1.01,0-1.83-.92-1.83-2.05h0c0-1.13,.82-2.05,1.83-2.05h2.94c1.01,0,1.83,.92,1.83,2.05h0Z" />
                    <path class="cls-58"
                        d="M279.64,530.7c0,2.85-2.07,5.17-4.62,5.17h-10.03c-2.55,0-4.62-2.31-4.62-5.17h0c0-2.85,2.07-5.17,4.62-5.17h10.03c2.55,0,4.62,2.31,4.62,5.17h0Z" />
                    <path class="cls-134"
                        d="M275.89,524.92c0,2.49-1.8,4.51-4.03,4.51h-6.48c-2.23,0-4.03-2.02-4.03-4.51h0c0-2.49,1.8-4.51,4.03-4.51h6.48c2.23,0,4.03,2.02,4.03,4.51h0Z" />
                    <path class="cls-137"
                        d="M270.56,521.22c0,1.53-1.11,2.77-2.48,2.77h-3.98c-1.37,0-2.48-1.24-2.48-2.77h0c0-1.53,1.11-2.77,2.48-2.77h3.98c1.37,0,2.48,1.24,2.48,2.77h0Z" />
                    <path class="cls-58"
                        d="M256.23,539.4c0,2.85-2.07,5.17-4.62,5.17h-10.03c-2.55,0-4.62-2.31-4.62-5.17h0c0-2.85,2.07-5.17,4.62-5.17h10.03c2.55,0,4.62,2.31,4.62,5.17h0Z" />
                    <path class="cls-134"
                        d="M252.48,533.63c0,2.49-1.8,4.51-4.03,4.51h-6.48c-2.23,0-4.03-2.02-4.03-4.51h0c0-2.49,1.8-4.51,4.03-4.51h6.48c2.23,0,4.03,2.02,4.03,4.51h0Z" />
                    <path class="cls-137"
                        d="M247.14,529.93c0,1.53-1.11,2.77-2.48,2.77h-3.98c-1.37,0-2.48-1.24-2.48-2.77h0c0-1.53,1.11-2.77,2.48-2.77h3.98c1.37,0,2.48,1.24,2.48,2.77h0Z" />
                    <path class="cls-58"
                        d="M272.6,566.64c0,2.12-1.54,3.84-3.43,3.84h-7.45c-1.9,0-3.43-1.72-3.43-3.84h0c0-2.12,1.54-3.84,3.43-3.84h7.45c1.9,0,3.43,1.72,3.43,3.84h0Z" />
                    <path class="cls-134"
                        d="M269.82,562.36c0,1.85-1.34,3.35-2.99,3.35h-4.81c-1.65,0-2.99-1.5-2.99-3.35h0c0-1.85,1.34-3.35,2.99-3.35h4.81c1.65,0,2.99,1.5,2.99,3.35h0Z" />
                    <path class="cls-137"
                        d="M265.86,559.61c0,1.14-.82,2.06-1.84,2.06h-2.96c-1.02,0-1.84-.92-1.84-2.06h0c0-1.14,.82-2.06,1.84-2.06h2.96c1.02,0,1.84,.92,1.84,2.06h0Z" />
                    <path class="cls-58"
                        d="M286.61,547.27c0,1.69-1.23,3.06-2.74,3.06h-5.95c-1.51,0-2.74-1.37-2.74-3.06h0c0-1.69,1.23-3.06,2.74-3.06h5.95c1.51,0,2.74,1.37,2.74,3.06h0Z" />
                    <path class="cls-134"
                        d="M284.39,543.84c0,1.47-1.07,2.67-2.39,2.67h-3.84c-1.32,0-2.39-1.2-2.39-2.67h0c0-1.48,1.07-2.67,2.39-2.67h3.84c1.32,0,2.39,1.2,2.39,2.67h0Z" />
                    <path class="cls-137"
                        d="M281.23,541.65c0,.91-.66,1.64-1.47,1.64h-2.36c-.81,0-1.47-.73-1.47-1.64h0c0-.91,.66-1.64,1.47-1.64h2.36c.81,0,1.47,.73,1.47,1.64h0Z" />
                    <path class="cls-46"
                        d="M271.19,588.67h-2.74v-56.37c0-.84,.61-1.52,1.36-1.52h.03c.75,0,1.36,.68,1.36,1.52v56.37Z" />
                    <path class="cls-46"
                        d="M269.82,576.96c-6.85,0-12.42-6.23-12.42-13.89v-5.64c0-1.02,.74-1.85,1.66-1.85s1.66,.83,1.66,1.85v5.64c0,5.62,4.09,10.18,9.11,10.18s9.11-4.57,9.11-10.18v-13.61c0-1.02,.74-1.85,1.65-1.85s1.66,.83,1.66,1.85v13.61c0,7.66-5.57,13.89-12.42,13.89Z" />
                    <path class="cls-46"
                        d="M280.39,560.4c-4.12,0-7.48-3.75-7.48-8.36v-3.31c0-.72,.52-1.3,1.16-1.3s1.16,.58,1.16,1.3v3.31c0,3.18,2.31,5.77,5.16,5.77s5.16-2.59,5.16-5.77v-7.99c0-.72,.52-1.3,1.16-1.3s1.16,.58,1.16,1.3v7.99c0,4.61-3.35,8.36-7.48,8.36Z" />
                    <path class="cls-46"
                        d="M259.06,570.58c-4.12,0-7.47-3.75-7.47-8.36v-18.76c0-.72,.52-1.3,1.16-1.3s1.16,.58,1.16,1.3v18.76c0,3.18,2.31,5.77,5.16,5.77s5.16-2.59,5.16-5.77v-7.99c0-.71,.52-1.29,1.16-1.29s1.16,.58,1.16,1.29v7.99c0,4.61-3.35,8.36-7.48,8.36Z" />
                    <path class="cls-46"
                        d="M252.24,556.28c-4.12,0-7.48-3.75-7.48-8.36v-6.69c0-.72,.52-1.3,1.16-1.3s1.16,.58,1.16,1.3v6.69c0,3.18,2.31,5.77,5.16,5.77s5.16-2.59,5.16-5.77v-7.99c0-.72,.52-1.3,1.16-1.3s1.16,.58,1.16,1.3v7.99c0,4.61-3.35,8.36-7.48,8.36Z" />
                    <path class="cls-46"
                        d="M258.55,542.49c-2.81,0-5.09-2.55-5.09-5.69v-3.58c0-.41,.3-.74,.66-.74s.66,.33,.66,.74v3.58c0,2.32,1.69,4.21,3.76,4.21s3.76-1.89,3.76-4.21v-1.95c0-.41,.3-.74,.66-.74s.66,.33,.66,.74v1.95c0,3.14-2.28,5.69-5.09,5.69Z" />
                    <path class="cls-46"
                        d="M245.92,547.8c-2.18,0-3.96-1.99-3.96-4.43v-3.49c0-.41,.3-.74,.66-.74s.66,.33,.66,.74v3.49c0,1.63,1.18,2.95,2.64,2.95,.95,0,1.83-.58,2.3-1.5,.18-.36,.58-.48,.9-.28,.32,.2,.43,.65,.25,1.01-.7,1.39-2.02,2.26-3.45,2.26Z" />
                    <path class="cls-46"
                        d="M282.58,543.6c-2.81,0-5.09-2.55-5.09-5.69v-4.69c0-.41,.3-.74,.66-.74s.66,.33,.66,.74v4.69c0,2.32,1.69,4.21,3.77,4.21s3.76-1.89,3.76-4.21v-1.95c0-.41,.3-.74,.66-.74s.66,.33,.66,.74v1.95c0,3.14-2.28,5.69-5.09,5.69Z" />
                    <path class="cls-134"
                        d="M281.15,565.92c0,1.76-1.27,3.18-2.85,3.18h-4.57c-1.57,0-2.85-1.43-2.85-3.18h0c0-1.76,1.27-3.18,2.85-3.18h4.57c1.57,0,2.85,1.43,2.85,3.18h0Z" />
                    <path class="cls-137"
                        d="M277.39,563.3c0,1.08-.78,1.96-1.75,1.96h-2.81c-.97,0-1.75-.88-1.75-1.96h0c0-1.08,.78-1.96,1.75-1.96h2.81c.97,0,1.75,.88,1.75,1.96h0Z" />
                    <path class="cls-46"
                        d="M286.7,548.79c-2.73,0-4.95-2.48-4.95-5.54v-2.16c0-.51,.37-.93,.83-.93s.83,.41,.83,.93v2.16c0,2.03,1.48,3.69,3.3,3.69s3.3-1.66,3.3-3.69v-5.22c0-.51,.37-.93,.83-.93s.83,.41,.83,.93v5.22c0,3.05-2.22,5.54-4.95,5.54Z" />
                    <path class="cls-58"
                        d="M278.73,551.36c0,2.8-2.03,5.07-4.53,5.07h-9.84c-2.5,0-4.53-2.27-4.53-5.07h0c0-2.8,2.03-5.07,4.53-5.07h9.84c2.5,0,4.53,2.27,4.53,5.07h0Z" />
                    <path class="cls-134"
                        d="M275.06,545.69c0,2.44-1.77,4.42-3.95,4.42h-6.35c-2.18,0-3.95-1.98-3.95-4.42h0c0-2.44,1.77-4.42,3.95-4.42h6.35c2.18,0,3.95,1.98,3.95,4.42h0Z" />
                    <path class="cls-137"
                        d="M269.83,542.06c0,1.5-1.09,2.72-2.43,2.72h-3.9c-1.34,0-2.43-1.22-2.43-2.72h0c0-1.5,1.09-2.72,2.43-2.72h3.9c1.34,0,2.43,1.22,2.43,2.72h0Z" />
                    <path class="cls-58"
                        d="M288.99,563.21c0,1.78-1.29,3.22-2.88,3.22h-6.25c-1.59,0-2.88-1.44-2.88-3.22h0c0-1.78,1.29-3.22,2.88-3.22h6.25c1.59,0,2.88,1.44,2.88,3.22h0Z" />
                    <path class="cls-134"
                        d="M286.66,559.61c0,1.55-1.12,2.81-2.51,2.81h-4.03c-1.39,0-2.51-1.26-2.51-2.81h0c0-1.55,1.12-2.81,2.51-2.81h4.03c1.39,0,2.51,1.26,2.51,2.81h0Z" />
                    <path class="cls-137"
                        d="M283.34,557.31c0,.95-.69,1.73-1.54,1.73h-2.48c-.85,0-1.54-.77-1.54-1.73h0c0-.95,.69-1.72,1.54-1.72h2.48c.85,0,1.54,.77,1.54,1.72h0Z" />
                    <path class="cls-58"
                        d="M248.8,550.98c0,1.23-.89,2.22-1.99,2.22h-4.31c-1.1,0-1.99-1-1.99-2.22h0c0-1.23,.89-2.22,1.99-2.22h4.31c1.1,0,1.99,.99,1.99,2.22h0Z" />
                    <path class="cls-134"
                        d="M247.19,548.5c0,1.07-.78,1.94-1.73,1.94h-2.78c-.96,0-1.73-.87-1.73-1.94h0c0-1.07,.78-1.94,1.73-1.94h2.78c.96,0,1.73,.87,1.73,1.94h0Z" />
                    <path class="cls-137"
                        d="M244.89,546.91c0,.66-.48,1.19-1.06,1.19h-1.71c-.59,0-1.06-.53-1.06-1.19h0c0-.66,.48-1.19,1.06-1.19h1.71c.59,0,1.06,.53,1.06,1.19h0Z" />
                    <path class="cls-58"
                        d="M261.95,560.84c0,2.54-1.84,4.6-4.11,4.6h-8.92c-2.27,0-4.11-2.06-4.11-4.6h0c0-2.54,1.84-4.6,4.11-4.6h8.92c2.27,0,4.11,2.06,4.11,4.6h0Z" />
                    <path class="cls-134"
                        d="M258.62,555.71c0,2.21-1.6,4.01-3.58,4.01h-5.76c-1.98,0-3.59-1.8-3.59-4.01h0c0-2.21,1.61-4.01,3.59-4.01h5.76c1.98,0,3.58,1.8,3.58,4.01h0Z" />
                    <path class="cls-137"
                        d="M253.87,552.41c0,1.36-.99,2.46-2.2,2.46h-3.54c-1.22,0-2.2-1.1-2.2-2.46h0c0-1.36,.99-2.46,2.2-2.46h3.54c1.22,0,2.2,1.1,2.2,2.46h0Z" />
                    <path class="cls-58"
                        d="M260.49,546.06c0,1.23-.89,2.22-1.99,2.22h-4.31c-1.1,0-1.99-.99-1.99-2.22h0c0-1.23,.89-2.22,1.99-2.22h4.31c1.1,0,1.99,1,1.99,2.22h0Z" />
                    <path class="cls-134"
                        d="M258.88,543.57c0,1.07-.78,1.94-1.73,1.94h-2.78c-.96,0-1.73-.87-1.73-1.94h0c0-1.07,.78-1.94,1.73-1.94h2.78c.96,0,1.73,.87,1.73,1.94h0Z" />
                    <path class="cls-137"
                        d="M256.59,541.98c0,.66-.48,1.19-1.06,1.19h-1.71c-.59,0-1.06-.53-1.06-1.19h0c0-.66,.48-1.19,1.06-1.19h1.71c.59,0,1.06,.53,1.06,1.19h0Z" />
                    <path class="cls-58"
                        d="M286.09,535.15c0,2.54-1.84,4.6-4.11,4.6h-8.92c-2.27,0-4.11-2.06-4.11-4.6h0c0-2.54,1.84-4.6,4.11-4.6h8.92c2.27,0,4.11,2.06,4.11,4.6h0Z" />
                    <path class="cls-134"
                        d="M282.76,530.01c0,2.21-1.61,4.01-3.59,4.01h-5.76c-1.98,0-3.59-1.79-3.59-4.01h0c0-2.21,1.61-4.01,3.59-4.01h5.76c1.98,0,3.59,1.8,3.59,4.01h0Z" />
                    <path class="cls-137"
                        d="M278.01,526.72c0,1.36-.99,2.46-2.2,2.46h-3.54c-1.22,0-2.2-1.1-2.2-2.46h0c0-1.36,.99-2.46,2.2-2.46h3.54c1.22,0,2.2,1.1,2.2,2.46h0Z" />
                    <path class="cls-58"
                        d="M299.36,540.51c0,2.36-1.71,4.28-3.83,4.28h-8.3c-2.11,0-3.83-1.92-3.83-4.28h0c0-2.36,1.71-4.28,3.83-4.28h8.3c2.11,0,3.83,1.92,3.83,4.28h0Z" />
                    <path class="cls-134"
                        d="M296.26,535.73c0,2.06-1.49,3.73-3.34,3.73h-5.36c-1.84,0-3.34-1.67-3.34-3.73h0c0-2.06,1.49-3.73,3.34-3.73h5.36c1.84,0,3.34,1.67,3.34,3.73h0Z" />
                    <path class="cls-137"
                        d="M291.85,532.67c0,1.27-.92,2.29-2.05,2.29h-3.29c-1.13,0-2.05-1.03-2.05-2.29h0c0-1.27,.92-2.29,2.05-2.29h3.29c1.13,0,2.05,1.03,2.05,2.29h0Z" />
                    <path class="cls-58"
                        d="M267.6,532.64c0,2.54-1.84,4.6-4.11,4.6h-8.92c-2.27,0-4.11-2.06-4.11-4.6h0c0-2.54,1.84-4.6,4.11-4.6h8.92c2.27,0,4.11,2.06,4.11,4.6h0Z" />
                    <path class="cls-134"
                        d="M264.26,527.51c0,2.21-1.61,4.01-3.59,4.01h-5.76c-1.98,0-3.59-1.79-3.59-4.01h0c0-2.21,1.61-4.01,3.59-4.01h5.76c1.98,0,3.59,1.8,3.59,4.01h0Z" />
                    <path class="cls-137"
                        d="M259.52,524.22c0,1.36-.99,2.46-2.2,2.46h-3.54c-1.22,0-2.2-1.1-2.2-2.46h0c0-1.36,.99-2.46,2.2-2.46h3.54c1.22,0,2.2,1.1,2.2,2.46h0Z" />
                    <rect class="cls-140" x="206.98" y="515.68" width="127.11" height="43.07" />
                    <path class="cls-140"
                        d="M331.37,518.25h-1.33s.56,.53,.56,.53h.76s0-.53,0-.53Zm-54.64,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-18.78,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-61.02,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm51.63,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-56.33,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-6.02,0v.53s.76,0,.76,0l.56-.53h-1.33Zm52.96,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Z" />
                    <path class="cls-143"
                        d="M297.76,555.64l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-18.78,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm37.55,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h1.33s0-.53,0-.53h-.76Zm-23.46,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-84.49,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm46.94,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-59.91,0v.53s1.33,0,1.33,0l-.56-.53h-.76Zm27.05,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm-23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm28.16,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Z" />
                    <path class="cls-143"
                        d="M290.81,518.25l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm23.47,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm18.78,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-14.08,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-42.25,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-51.63,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm32.86,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-56.89,.53v36.86s.56,.53,.56,.53v-37.93s-.56,.53-.56,.53Zm5.26-.53l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm46.94,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Z" />
                    <path class="cls-140"
                        d="M293.07,518.79l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm28.16,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.85,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.68,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-89.18,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-19.34-.53v37.93s.56-.53,.56-.53v-36.86s-.56-.53-.56-.53Zm24.03,.53l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.86,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Z" />
                    <rect class="cls-133" x="307.73" y="556.87" width="5.44" height=".75" />
                    <rect class="cls-133" x="228.08" y="556.87" width="5.44" height=".75" />
                    <g>
                        <rect class="cls-143" x="332.32" y="515.41" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M333.18,516.78c0,.3,.26,.55,.57,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.57,.24-.57,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-143" x="205.87" y="515.41" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M206.73,516.78c0,.3,.26,.55,.58,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.58,.24-.58,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-143" x="332.32" y="556.29" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M333.18,557.65c0,.3,.26,.54,.57,.54s.58-.24,.58-.54c0-.3-.26-.55-.58-.55s-.57,.24-.57,.55Z" />
                    </g>
                    <g>
                        <rect class="cls-143" x="205.87" y="556.29" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M206.73,557.65c0,.3,.26,.54,.58,.54s.58-.24,.58-.54-.26-.55-.58-.55-.58,.24-.58,.55Z" />
                    </g>
                    <rect class="cls-58" x="207.03" y="472.08" width="127.11" height="43.07" />
                    <path class="cls-12"
                        d="M331.43,474.65h-1.33s.56,.53,.56,.53h.76s0-.53,0-.53Zm-54.64,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-18.78,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-61.02,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm51.63,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-56.33,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-6.02,0v.53s.76,0,.76,0l.56-.53h-1.33Zm52.96,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Z" />
                    <path class="cls-91"
                        d="M297.82,512.04l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm-4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-18.78,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm37.55,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h1.33s0-.53,0-.53h-.76Zm-23.46,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-84.49,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm46.94,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-59.91,0v.53s1.33,0,1.33,0l-.56-.53h-.76Zm27.05,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm28.16,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Z" />
                    <path class="cls-91"
                        d="M290.87,474.65l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm23.47,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm18.78,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-14.08,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-42.25,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-51.63,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm32.86,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-56.89,.53v36.86s.56,.53,.56,.53v-37.93s-.56,.53-.56,.53Zm5.26-.53l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm46.94,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Z" />
                    <path class="cls-12"
                        d="M293.12,475.19l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm28.16,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.85,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.68,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-89.18,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-19.34-.53v37.93s.56-.53,.56-.53v-36.86s-.56-.53-.56-.53Zm24.03,.53l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.86,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Z" />
                    <rect class="cls-133" x="307.79" y="513.27" width="5.44" height=".75" />
                    <rect class="cls-133" x="228.14" y="513.27" width="5.44" height=".75" />
                    <g>
                        <rect class="cls-91" x="332.38" y="471.81" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M333.24,473.18c0,.3,.26,.55,.58,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.58,.24-.58,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-91" x="205.92" y="471.81" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M206.79,473.18c0,.3,.26,.55,.58,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.58,.24-.58,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-91" x="332.38" y="512.69" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M333.24,514.05c0,.3,.26,.55,.58,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.58,.24-.58,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-91" x="205.92" y="512.69" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M206.79,514.05c0,.3,.26,.55,.58,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.58,.24-.58,.54Z" />
                    </g>
                    <rect class="cls-58" x="71.25" y="515.99" width="127.11" height="43.07" />
                    <path class="cls-12"
                        d="M195.65,518.56h-1.33s.56,.53,.56,.53h.76s0-.53,0-.53Zm-54.64,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-18.78,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-61.02,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm51.63,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-56.33,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-6.02,0v.53s.76,0,.76,0l.56-.53h-1.33Zm52.96,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Z" />
                    <path class="cls-91"
                        d="M162.04,555.95l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-18.78,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm37.55,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h1.33s0-.53,0-.53h-.76Zm-23.46,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-84.49,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm46.94,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-59.91,0v.53s1.33,0,1.33,0l-.56-.53h-.76Zm27.05,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm28.16,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Z" />
                    <path class="cls-91"
                        d="M155.09,518.56l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm23.47,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm18.78,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-14.08,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-42.25,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-51.63,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm32.86,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-56.89,.53v36.86s.56,.53,.56,.53v-37.93s-.56,.53-.56,.53Zm5.26-.53l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm46.94,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Z" />
                    <path class="cls-12"
                        d="M157.34,519.09l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm28.16,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.85,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.68,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-89.18,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-19.34-.53v37.93s.56-.53,.56-.53v-36.86s-.56-.53-.56-.53Zm24.03,.53l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.86,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Z" />
                    <rect class="cls-133" x="172.01" y="557.18" width="5.44" height=".75" />
                    <rect class="cls-133" x="92.35" y="557.18" width="5.44" height=".75" />
                    <g>
                        <rect class="cls-91" x="196.6" y="515.72" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M197.46,517.08c0,.3,.26,.54,.58,.54s.58-.24,.58-.54-.26-.55-.58-.55-.58,.24-.58,.55Z" />
                    </g>
                    <g>
                        <rect class="cls-91" x="70.14" y="515.72" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M71.01,517.08c0,.3,.26,.54,.58,.54s.58-.24,.58-.54c0-.3-.26-.55-.58-.55s-.58,.24-.58,.55Z" />
                    </g>
                    <g>
                        <rect class="cls-91" x="196.6" y="556.6" width="2.88" height="2.73" />
                        <ellipse class="cls-133" cx="198.03" cy="557.96" rx=".58" ry=".54" />
                    </g>
                    <g>
                        <rect class="cls-91" x="70.14" y="556.6" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M71.01,557.96c0,.3,.26,.54,.58,.54s.58-.24,.58-.54-.26-.54-.58-.54-.58,.24-.58,.54Z" />
                    </g>
                    <rect class="cls-59" x="71.25" y="428.66" width="127.11" height="43.07" />
                    <path class="cls-13"
                        d="M195.65,431.23h-1.33s.56,.53,.56,.53h.76s0-.53,0-.53Zm-54.64,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-18.78,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-61.02,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm51.63,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-56.33,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-6.02,0v.53s.76,0,.76,0l.56-.53h-1.33Zm52.96,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Z" />
                    <path class="cls-46"
                        d="M162.04,468.62l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-18.78,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm37.55,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h1.33s0-.53,0-.53h-.76Zm-23.46,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-84.49,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm46.94,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-59.91,0v.53s1.33,0,1.33,0l-.56-.53h-.76Zm27.05,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm28.16,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Z" />
                    <path class="cls-46"
                        d="M155.09,431.23l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm23.47,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm18.78,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-14.08,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-42.25,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-51.63,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm32.86,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-56.89,.53v36.86s.56,.53,.56,.53v-37.93s-.56,.53-.56,.53Zm5.26-.53l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm46.94,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Z" />
                    <path class="cls-13"
                        d="M157.34,431.77l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm28.16,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.85,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.68,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-89.18,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-19.34-.53v37.93s.56-.53,.56-.53v-36.86s-.56-.53-.56-.53Zm24.03,.53l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.86,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Z" />
                    <rect class="cls-133" x="172.01" y="469.85" width="5.44" height=".75" />
                    <rect class="cls-133" x="92.35" y="469.85" width="5.44" height=".75" />
                    <g>
                        <rect class="cls-46" x="196.6" y="428.4" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M197.46,429.76c0,.3,.26,.54,.58,.54s.58-.24,.58-.54c0-.3-.26-.55-.58-.55s-.58,.24-.58,.55Z" />
                    </g>
                    <g>
                        <rect class="cls-46" x="70.14" y="428.4" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M71.01,429.76c0,.3,.26,.54,.58,.54s.58-.24,.58-.54c0-.3-.26-.55-.58-.55s-.58,.24-.58,.55Z" />
                    </g>
                    <g>
                        <rect class="cls-46" x="196.6" y="469.27" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M197.46,470.63c0,.3,.26,.55,.58,.55s.58-.24,.58-.55-.26-.54-.58-.54-.58,.24-.58,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-46" x="70.14" y="469.27" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M71.01,470.63c0,.3,.26,.55,.58,.55s.58-.24,.58-.55-.26-.54-.58-.54-.58,.24-.58,.54Z" />
                    </g>
                    <rect class="cls-140" x="71.25" y="472.39" width="127.11" height="43.07" />
                    <path class="cls-140"
                        d="M195.65,474.96h-1.33s.56,.53,.56,.53h.76s0-.53,0-.53Zm-54.64,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-18.78,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-4.69,0h-3s.56,.53,.56,.53h1.87s.56-.53,.56-.53Zm-61.02,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm4.69,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm51.63,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-56.33,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-6.02,0v.53s.76,0,.76,0l.56-.53h-1.33Zm52.96,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-23.47,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm28.16,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm-14.08,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Zm9.39,0h-3s.56,.53,.56,.53h1.88s.56-.53,.56-.53Z" />
                    <path class="cls-143"
                        d="M162.04,512.35l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-18.78,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm37.55,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h1.33s0-.53,0-.53h-.76Zm-23.46,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.87Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-84.49,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm46.94,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-59.91,0v.53s1.33,0,1.33,0l-.56-.53h-.76Zm27.05,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-23.47,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm28.16,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-14.08,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm-9.39,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Zm4.69,0l-.56,.53h3s-.56-.53-.56-.53h-1.88Z" />
                    <path class="cls-143"
                        d="M155.09,474.96l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm23.47,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm18.78,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-14.08,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-42.25,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-51.63,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-37.55,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm32.86,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-56.89,.53v36.86s.56,.53,.56,.53v-37.93s-.56,.53-.56,.53Zm5.26-.53l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm46.94,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-9.39,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Zm-4.69,0l-.56,.53v36.86s.56,.53,.56,.53v-37.93Z" />
                    <path class="cls-140"
                        d="M157.34,475.49l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm28.16,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm14.08,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.85,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.68,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-89.18,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm9.39,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-19.34-.53v37.93s.56-.53,.56-.53v-36.86s-.56-.53-.56-.53Zm24.03,.53l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm32.86,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-18.78,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm4.69,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Zm-23.47,0l-.56-.53v37.93s.56-.53,.56-.53v-36.86Z" />
                    <rect class="cls-133" x="172.01" y="513.58" width="5.44" height=".75" />
                    <rect class="cls-133" x="92.35" y="513.58" width="5.44" height=".75" />
                    <g>
                        <rect class="cls-143" x="196.6" y="472.12" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M197.46,473.48c0,.3,.26,.55,.58,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.58,.24-.58,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-143" x="70.14" y="472.12" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M71.01,473.48c0,.3,.26,.55,.58,.55s.58-.24,.58-.55c0-.3-.26-.54-.58-.54s-.58,.24-.58,.54Z" />
                    </g>
                    <g>
                        <rect class="cls-143" x="196.6" y="513" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M197.46,514.36c0,.3,.26,.54,.58,.54s.58-.24,.58-.54c0-.3-.26-.55-.58-.55s-.58,.24-.58,.55Z" />
                    </g>
                    <g>
                        <rect class="cls-143" x="70.14" y="513" width="2.88" height="2.73" />
                        <path class="cls-133"
                            d="M71.01,514.36c0,.3,.26,.54,.58,.54s.58-.24,.58-.54c0-.3-.26-.55-.58-.55s-.58,.24-.58,.55Z" />
                    </g>
                    <rect class="cls-59" x="58.21" y="493.85" width="4.08" height="23.54" />
                    <rect class="cls-46" x="54.12" y="493.85" width="4.08" height="23.54" />
                    <rect class="cls-59" x="50.04" y="493.85" width="4.09" height="23.54" />
                    <rect class="cls-46" x="45.95" y="493.85" width="4.09" height="23.54" />
                    <rect class="cls-59" x="41.87" y="493.85" width="4.09" height="23.54" />
                    <rect class="cls-46" x="37.78" y="493.85" width="4.08" height="23.54" />
                    <polygon class="cls-90" points="38.61 518.59 64.2 493.95 62.1 491.95 36.51 516.6 38.61 518.59" />
                    <polygon class="cls-90" points="61.56 518.59 35.98 493.95 38.08 491.95 63.66 516.6 61.56 518.59" />
                    <g>
                        <rect class="cls-90" x="62.29" y="491.03" width="2.97" height="29.18" />
                        <rect class="cls-59" x="62.29" y="491.03" width=".38" height="29.18" />
                        <rect class="cls-98" x="64.88" y="491.03" width=".38" height="29.18" />
                    </g>
                    <g>
                        <rect class="cls-90" x="34.81" y="491.03" width="2.97" height="29.18" />
                        <rect class="cls-98" x="34.81" y="491.03" width=".38" height="29.18" />
                        <rect class="cls-59" x="37.4" y="491.03" width=".38" height="29.18" />
                    </g>
                    <g>
                        <rect class="cls-90" x="34.81" y="491.03" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="491.03" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="493.49" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="34.81" y="517.39" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="517.39" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="519.85" width="30.46" height=".36" />
                    </g>
                    <path class="cls-48"
                        d="M63.98,492.02c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31,26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-26.58-26.37c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3,.14,.3,.32,.3,.32-.13,.32-.3Zm27.89,27.22c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-27.22-.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31,.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm1.31-26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm25.91,0c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Z" />
                    <rect class="cls-48" x="34.43" y="520.21" width="31.22" height=".81" />
                    <rect class="cls-48" x="34.43" y="524.4" width="31.22" height=".81" />
                    <rect class="cls-27" x="61.88" y="521.02" width="3.77" height="1.13" />
                    <rect class="cls-27" x="34.43" y="521.02" width="3.77" height="1.13" />
                    <rect class="cls-27" x="48.15" y="521.02" width="3.77" height="1.13" />
                    <rect class="cls-48" x="61.88" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.43" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.43" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.43" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.43" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.43" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.43" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="521.02" width="3.77" height="3.37" />
                    <rect class="cls-27" x="61.88" y="521.02" width="3.77" height="1.13" />
                    <rect class="cls-27" x="34.43" y="521.02" width="3.77" height="1.13" />
                    <rect class="cls-27" x="48.15" y="521.02" width="3.77" height="1.13" />
                    <rect class="cls-46" x="38.06" y="527.32" width="23.97" height="24.27" />
                    <rect class="cls-90" x="62.03" y="525.21" width="3.24" height="29.18" />
                    <rect class="cls-90" x="57.49" y="525.21" width="3.24" height="29.18" />
                    <rect class="cls-90" x="52.95" y="525.21" width="3.24" height="29.18" />
                    <rect class="cls-90" x="48.42" y="525.21" width="3.24" height="29.18" />
                    <rect class="cls-90" x="43.88" y="525.21" width="3.24" height="29.18" />
                    <rect class="cls-90" x="39.35" y="525.21" width="3.24" height="29.18" />
                    <rect class="cls-90" x="34.81" y="525.21" width="3.24" height="29.18" />
                    <g>
                        <rect class="cls-90" x="34.81" y="525.21" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="525.21" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="527.67" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="34.81" y="538.39" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="538.39" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="540.85" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="34.81" y="551.57" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="551.57" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="554.03" width="30.46" height=".36" />
                    </g>
                    <path class="cls-48"
                        d="M62.67,540.22c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm1.31-14.02c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-1.31,26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-26.58-26.37c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3,.14,.3,.32,.3,.32-.13,.32-.3Zm27.89,13.18c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm0,14.04c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-27.22-.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-1.31-12.35c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm0,13.2c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm1.31-26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm0,12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm25.91-12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Z" />
                    <rect class="cls-48" x="34.44" y="554.39" width="31.22" height=".81" />
                    <rect class="cls-48" x="34.44" y="558.58" width="31.22" height=".81" />
                    <rect class="cls-27" x="61.88" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-27" x="34.42" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-27" x="48.15" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-48" x="61.88" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-27" x="61.88" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-27" x="34.42" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-27" x="48.15" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-46" x="6.07" y="493.09" width="23.97" height="24.27" />
                    <rect class="cls-90" x="30.04" y="490.99" width="3.24" height="29.18" />
                    <rect class="cls-90" x="25.5" y="490.99" width="3.24" height="29.18" />
                    <rect class="cls-90" x="20.97" y="490.99" width="3.24" height="29.18" />
                    <rect class="cls-90" x="16.43" y="490.99" width="3.24" height="29.18" />
                    <rect class="cls-90" x="11.9" y="490.99" width="3.24" height="29.18" />
                    <rect class="cls-90" x="7.36" y="490.99" width="3.24" height="29.18" />
                    <rect class="cls-90" x="2.82" y="490.99" width="3.24" height="29.18" />
                    <g>
                        <rect class="cls-90" x="2.82" y="490.99" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="490.99" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="493.44" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="504.17" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="504.17" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="506.62" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="517.35" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="517.35" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="519.8" width="30.46" height=".36" />
                    </g>
                    <path class="cls-48"
                        d="M30.69,506c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm1.31-14.02c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31,26.37c0,.17,.14,.3,.32,.3s.32-.14,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-26.58-26.37c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3c0,.17,.14,.3,.32,.3s.32-.13,.32-.3Zm27.89,13.18c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm0,14.04c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-27.22-.84c0,.17,.14,.3,.32,.3s.32-.14,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-1.31-12.35c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm0,13.19c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm1.31-26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm0,12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm25.91-12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Z" />
                    <rect class="cls-48" x="2.45" y="520.16" width="31.22" height=".81" />
                    <rect class="cls-48" x="2.45" y="524.35" width="31.22" height=".81" />
                    <rect class="cls-27" x="29.9" y="520.98" width="3.77" height="1.55" />
                    <rect class="cls-27" x="2.43" y="520.98" width="3.77" height="1.55" />
                    <rect class="cls-27" x="16.17" y="520.98" width="3.77" height="1.55" />
                    <rect class="cls-48" x="29.9" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="520.98" width="3.77" height="3.37" />
                    <rect class="cls-27" x="29.9" y="520.98" width="3.77" height="1.55" />
                    <rect class="cls-27" x="2.43" y="520.98" width="3.77" height="1.55" />
                    <rect class="cls-27" x="16.17" y="520.98" width="3.77" height="1.55" />
                    <rect class="cls-59" x="27.24" y="528.03" width="3.06" height="23.54" />
                    <rect class="cls-46" x="24.18" y="528.03" width="3.06" height="23.54" />
                    <rect class="cls-59" x="21.12" y="528.03" width="3.06" height="23.54" />
                    <rect class="cls-46" x="18.05" y="528.03" width="3.06" height="23.54" />
                    <rect class="cls-59" x="14.99" y="528.03" width="3.06" height="23.54" />
                    <rect class="cls-46" x="11.92" y="528.03" width="3.06" height="23.54" />
                    <rect class="cls-59" x="8.86" y="528.03" width="3.06" height="23.54" />
                    <rect class="cls-46" x="5.8" y="528.03" width="3.06" height="23.54" />
                    <polygon class="cls-90" points="6.63 552.77 32.21 528.13 30.11 526.13 4.52 550.78 6.63 552.77" />
                    <g>
                        <rect class="cls-90" x="30.31" y="525.21" width="2.97" height="29.18" />
                        <rect class="cls-59" x="30.31" y="525.21" width=".38" height="29.18" />
                        <rect class="cls-98" x="32.9" y="525.21" width=".38" height="29.18" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="525.21" width="2.97" height="29.18" />
                        <rect class="cls-98" x="2.82" y="525.21" width=".38" height="29.18" />
                        <rect class="cls-59" x="5.41" y="525.21" width=".38" height="29.18" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="525.21" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="525.21" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="527.67" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="551.57" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="551.57" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="554.03" width="30.46" height=".36" />
                    </g>
                    <path class="cls-48"
                        d="M32,526.2c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-1.31,26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-26.58-26.37c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3,.14,.3,.32,.3,.32-.13,.32-.3Zm27.89,27.22c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-27.22-.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31,.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm1.31-26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm25.91,0c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Z" />
                    <rect class="cls-48" x="2.45" y="554.39" width="31.22" height=".81" />
                    <rect class="cls-48" x="2.45" y="558.58" width="31.22" height=".81" />
                    <rect class="cls-48" x="29.9" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="555.2" width="3.77" height="3.37" />
                    <rect class="cls-27" x="29.9" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-27" x="2.43" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-27" x="16.17" y="555.2" width="3.77" height="1.55" />
                    <rect class="cls-46" x="38.06" y="458.91" width="23.97" height="24.27" />
                    <rect class="cls-90" x="62.03" y="456.81" width="3.24" height="29.18" />
                    <rect class="cls-90" x="57.49" y="456.81" width="3.24" height="29.18" />
                    <rect class="cls-90" x="52.95" y="456.81" width="3.24" height="29.18" />
                    <rect class="cls-90" x="48.42" y="456.81" width="3.24" height="29.18" />
                    <rect class="cls-90" x="43.88" y="456.81" width="3.24" height="29.18" />
                    <rect class="cls-90" x="39.35" y="456.81" width="3.24" height="29.18" />
                    <rect class="cls-90" x="34.81" y="456.81" width="3.24" height="29.18" />
                    <g>
                        <rect class="cls-90" x="34.81" y="456.81" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="456.81" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="459.26" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="34.81" y="469.99" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="469.99" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="472.44" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="34.81" y="483.17" width="30.46" height="2.82" />
                        <rect class="cls-98" x="34.81" y="483.17" width="30.46" height=".36" />
                        <rect class="cls-59" x="34.81" y="485.62" width="30.46" height=".36" />
                    </g>
                    <path class="cls-48"
                        d="M62.67,471.82c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.14-.32,.3Zm1.31-14.02c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-1.31,26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-26.58-26.37c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3c0,.17,.14,.3,.32,.3s.32-.13,.32-.3Zm27.89,13.18c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm0,14.04c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-27.22-.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31-12.35c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.14-.32,.3Zm0,13.19c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm1.31-26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm0,12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm25.91-12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Z" />
                    <rect class="cls-48" x="34.44" y="485.99" width="31.22" height=".81" />
                    <rect class="cls-48" x="34.44" y="490.17" width="31.22" height=".81" />
                    <rect class="cls-27" x="61.88" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-27" x="34.42" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-27" x="48.15" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-48" x="61.88" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="61.88" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="34.42" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="48.15" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-27" x="61.88" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-27" x="34.42" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-27" x="48.15" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-46" x="6.07" y="424.69" width="23.97" height="24.27" />
                    <rect class="cls-90" x="30.04" y="422.58" width="3.24" height="29.18" />
                    <rect class="cls-90" x="25.5" y="422.58" width="3.24" height="29.18" />
                    <rect class="cls-90" x="20.97" y="422.58" width="3.24" height="29.18" />
                    <rect class="cls-90" x="16.43" y="422.58" width="3.24" height="29.18" />
                    <rect class="cls-90" x="11.9" y="422.58" width="3.24" height="29.18" />
                    <rect class="cls-90" x="7.36" y="422.58" width="3.24" height="29.18" />
                    <rect class="cls-90" x="2.82" y="422.58" width="3.24" height="29.18" />
                    <g>
                        <rect class="cls-90" x="2.82" y="422.58" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="422.58" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="425.04" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="435.76" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="435.76" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="438.22" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="448.94" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="448.94" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="451.4" width="30.46" height=".36" />
                    </g>
                    <path class="cls-48"
                        d="M30.69,437.59c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm1.31-14.02c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31,26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-26.58-26.37c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3c0,.17,.14,.3,.32,.3s.32-.13,.32-.3Zm27.89,13.18c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm0,14.04c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-27.22-.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-1.31-12.35c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm0,13.19c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm1.31-26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm0,12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm25.91-12.34c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Z" />
                    <rect class="cls-48" x="2.45" y="451.76" width="31.22" height=".81" />
                    <rect class="cls-48" x="2.45" y="455.95" width="31.22" height=".81" />
                    <rect class="cls-27" x="29.9" y="452.57" width="3.77" height="1.55" />
                    <rect class="cls-27" x="2.43" y="452.57" width="3.77" height="1.55" />
                    <rect class="cls-27" x="16.17" y="452.57" width="3.77" height="1.55" />
                    <rect class="cls-48" x="29.9" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="452.57" width="3.77" height="3.37" />
                    <rect class="cls-27" x="29.9" y="452.57" width="3.77" height="1.55" />
                    <rect class="cls-27" x="2.43" y="452.57" width="3.77" height="1.55" />
                    <rect class="cls-27" x="16.17" y="452.57" width="3.77" height="1.55" />
                    <rect class="cls-59" x="27.24" y="459.63" width="3.06" height="23.54" />
                    <rect class="cls-46" x="24.18" y="459.63" width="3.06" height="23.54" />
                    <rect class="cls-59" x="21.12" y="459.63" width="3.06" height="23.54" />
                    <rect class="cls-46" x="18.05" y="459.63" width="3.06" height="23.54" />
                    <rect class="cls-59" x="14.99" y="459.63" width="3.06" height="23.54" />
                    <rect class="cls-46" x="11.92" y="459.63" width="3.06" height="23.54" />
                    <rect class="cls-59" x="8.86" y="459.63" width="3.06" height="23.54" />
                    <rect class="cls-46" x="5.8" y="459.63" width="3.06" height="23.54" />
                    <polygon class="cls-90" points="6.63 484.37 32.21 459.72 30.11 457.73 4.52 482.37 6.63 484.37" />
                    <g>
                        <rect class="cls-90" x="30.31" y="456.81" width="2.97" height="29.18" />
                        <rect class="cls-59" x="30.31" y="456.81" width=".38" height="29.18" />
                        <rect class="cls-98" x="32.9" y="456.81" width=".38" height="29.18" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="456.81" width="2.97" height="29.18" />
                        <rect class="cls-98" x="2.82" y="456.81" width=".38" height="29.18" />
                        <rect class="cls-59" x="5.41" y="456.81" width=".38" height="29.18" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="456.81" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="456.81" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="459.26" width="30.46" height=".36" />
                    </g>
                    <g>
                        <rect class="cls-90" x="2.82" y="483.17" width="30.46" height="2.82" />
                        <rect class="cls-98" x="2.82" y="483.17" width="30.46" height=".36" />
                        <rect class="cls-59" x="2.82" y="485.62" width="30.46" height=".36" />
                    </g>
                    <path class="cls-48"
                        d="M32,457.8c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31,26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-26.58-26.37c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3c0,.17,.14,.3,.32,.3s.32-.13,.32-.3Zm27.89,27.22c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm-27.22-.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Zm-1.31,.84c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm1.31-26.37c0,.17,.14,.3,.32,.3s.32-.13,.32-.3c0-.17-.14-.3-.32-.3s-.32,.13-.32,.3Zm25.91,0c0,.17,.14,.3,.32,.3s.32-.13,.32-.3-.14-.3-.32-.3-.32,.13-.32,.3Z" />
                    <rect class="cls-48" x="2.45" y="485.99" width="31.22" height=".81" />
                    <rect class="cls-48" x="2.45" y="490.17" width="31.22" height=".81" />
                    <rect class="cls-48" x="29.9" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="29.9" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="2.43" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-48" x="16.17" y="486.8" width="3.77" height="3.37" />
                    <rect class="cls-27" x="29.9" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-27" x="2.43" y="486.8" width="3.77" height="1.55" />
                    <rect class="cls-27" x="16.17" y="486.8" width="3.77" height="1.55" />
                    <polygon class="cls-48"
                        points="27.98 499.19 0 514.34 0 521.68 69.5 521.68 69.51 521.67 27.98 499.19" />
                    <path class="cls-58"
                        d="M538.44,507.29c0,3.99-2.89,7.23-6.46,7.23h-14.03c-3.57,0-6.46-3.24-6.46-7.23h0c0-3.99,2.89-7.23,6.46-7.23h14.03c3.57,0,6.46,3.24,6.46,7.23h0Z" />
                    <path class="cls-134"
                        d="M533.2,499.21c0,3.48-2.52,6.3-5.64,6.3h-9.06c-3.11,0-5.64-2.82-5.64-6.3h0c0-3.48,2.52-6.3,5.64-6.3h9.06c3.11,0,5.64,2.82,5.64,6.3h0Z" />
                    <path class="cls-137"
                        d="M525.74,494.03c0,2.14-1.55,3.87-3.47,3.87h-5.57c-1.91,0-3.46-1.74-3.46-3.87h0c0-2.14,1.55-3.88,3.46-3.88h5.57c1.91,0,3.47,1.74,3.47,3.88h0Z" />
                    <path class="cls-58"
                        d="M399.62,568.83c0,1.83-1.32,3.31-2.96,3.31h-6.42c-1.63,0-2.96-1.48-2.96-3.31h0c0-1.83,1.32-3.31,2.96-3.31h6.42c1.63,0,2.96,1.48,2.96,3.31h0Z" />
                    <path class="cls-58"
                        d="M356.34,569.07c0,2.12-1.53,3.83-3.43,3.83h-7.44c-1.89,0-3.43-1.72-3.43-3.83h0c0-2.12,1.53-3.83,3.43-3.83h7.44c1.89,0,3.43,1.72,3.43,3.83h0Z" />
                    <rect class="cls-146" x="0" y="558.98" width="346.22" height="21.48" />
                    <polygon class="cls-48" points="0 580.47 33.83 580.47 28.41 563.92 1.19 563.92 0 567.55 0 580.47" />
                    <polygon class="cls-48"
                        points="297.41 563.92 324.62 563.92 330.04 580.47 291.98 580.47 297.41 563.92" />
                    <g>
                        <rect class="cls-105" x="301.96" y="563.92" width="5.64" height="16.65" />
                        <rect class="cls-105" x="314.43" y="563.92" width="5.64" height="16.65" />
                    </g>
                    <g class="cls-154">
                        <rect class="cls-48" x="301.96" y="563.92" width="5.64" height="1.76" />
                        <rect class="cls-48" x="314.43" y="563.92" width="5.64" height="1.76" />
                    </g>
                    <polygon class="cls-48"
                        points="240.85 563.92 268.06 563.92 273.49 580.47 235.43 580.47 240.85 563.92" />
                    <g>
                        <rect class="cls-105" x="245.4" y="563.92" width="5.64" height="16.65" />
                        <rect class="cls-105" x="257.87" y="563.92" width="5.64" height="16.65" />
                    </g>
                    <g class="cls-154">
                        <rect class="cls-48" x="245.4" y="563.92" width="5.64" height="1.76" />
                        <rect class="cls-48" x="257.87" y="563.92" width="5.64" height="1.76" />
                    </g>
                    <polygon class="cls-48"
                        points="184.29 563.92 211.51 563.92 216.93 580.47 178.87 580.47 184.29 563.92" />
                    <g>
                        <rect class="cls-105" x="188.84" y="563.92" width="5.64" height="16.65" />
                        <rect class="cls-105" x="201.31" y="563.92" width="5.64" height="16.65" />
                    </g>
                    <g class="cls-154">
                        <rect class="cls-48" x="188.84" y="563.92" width="5.64" height="1.76" />
                        <rect class="cls-48" x="201.31" y="563.92" width="5.64" height="1.76" />
                    </g>
                    <polygon class="cls-48"
                        points="127.73 563.92 154.95 563.92 160.37 580.47 122.31 580.47 127.73 563.92" />
                    <g>
                        <rect class="cls-105" x="132.28" y="563.92" width="5.64" height="16.65" />
                        <rect class="cls-105" x="144.75" y="563.92" width="5.64" height="16.65" />
                    </g>
                    <g class="cls-154">
                        <rect class="cls-48" x="132.28" y="563.92" width="5.64" height="1.76" />
                        <rect class="cls-48" x="144.75" y="563.92" width="5.64" height="1.76" />
                    </g>
                    <polygon class="cls-48" points="57.75 563.92 84.97 563.92 90.39 580.47 52.33 580.47 57.75 563.92" />
                    <g>
                        <rect class="cls-105" x="62.3" y="563.92" width="5.64" height="16.65" />
                        <rect class="cls-105" x="74.77" y="563.92" width="5.64" height="16.65" />
                    </g>
                    <g class="cls-154">
                        <rect class="cls-48" x="62.3" y="563.92" width="5.64" height="1.76" />
                        <rect class="cls-48" x="74.77" y="563.92" width="5.64" height="1.76" />
                    </g>
                    <g>
                        <rect class="cls-105" x="5.74" y="563.92" width="5.64" height="16.65" />
                        <rect class="cls-105" x="18.21" y="563.92" width="5.64" height="16.65" />
                    </g>
                    <g class="cls-154">
                        <rect class="cls-48" x="5.74" y="563.92" width="5.64" height="1.76" />
                        <rect class="cls-48" x="18.21" y="563.92" width="5.64" height="1.76" />
                    </g>
                </g>
                <g id="dijk">
                    <rect class="cls-106" x="605" y="588.45" width="956.39" height="57.22" />
                    <rect class="cls-50" x="605" y="588.45" width="182.33" height="57.22" />
                    <g>
                        <rect class="cls-116" x="1445.99" y="588.45" width="29.23" height="43.64" />
                        <rect class="cls-105" x="1416.76" y="588.45" width="29.23" height="49.67" />
                        <rect class="cls-116" x="908.9" y="602.03" width="29.23" height="43.64" />
                        <rect class="cls-116" x="1082.1" y="622.26" width="29.23" height="23.42" />
                        <rect class="cls-116" x="1310.87" y="588.45" width="29.23" height="23.41" />
                        <rect class="cls-116" x="1157.88" y="610.27" width="29.23" height="35.4" />
                        <rect class="cls-105" x="938.13" y="596" width="29.23" height="49.67" />
                        <rect class="cls-105" x="1187.11" y="598.84" width="29.23" height="46.83" />
                    </g>
                    <g>
                        <rect class="cls-116" x="702.43" y="588.45" width="29.23" height="43.64" />
                        <rect class="cls-105" x="673.2" y="588.45" width="29.23" height="49.67" />
                    </g>
                </g>
                <g id="kraan2">
                    <path class="cls-59"
                        d="M547.69,409.03c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,430.74c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,452.44c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,474.14c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,495.85c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,517.55c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,539.25c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,560.96c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <path class="cls-59"
                        d="M547.69,582.66c2.49-1.6,6.62-4.11,13.59-8.19l-13.59-6.84v-4.56l22.16,11.15s-20.2,11.95-22.16,13.4v-4.96Z" />
                    <rect class="cls-59" x="569.15" y="389.81" width="3.84" height="199.05" />
                    <rect class="cls-59" x="543.85" y="389.81" width="3.84" height="199.05" />
                    <path class="cls-59"
                        d="M617.27,386.78c-1.77-2.2-4.56-5.83-9.08-11.98l-7.58,11.98h-5.05l12.36-19.53s13.24,17.81,14.85,19.53h-5.49Z" />
                    <path class="cls-59"
                        d="M591.95,386.78c-1.77-2.2-4.56-5.83-9.08-11.98l-7.58,11.98h-5.05l12.36-19.53s13.24,17.81,14.85,19.53h-5.49Z" />
                    <path class="cls-59"
                        d="M566.28,386.78c-1.77-2.2-4.56-5.83-9.08-11.98l-7.58,11.98h-5.05l12.36-19.53s13.24,17.81,14.85,19.53h-5.49Z" />
                    <path class="cls-59"
                        d="M540.61,386.78c-1.77-2.2-4.56-5.83-9.08-11.98l-7.58,11.98h-5.05l12.36-19.53s13.24,17.81,14.85,19.53h-5.49Z" />
                    <path class="cls-59"
                        d="M514.95,386.78c-1.77-2.2-4.56-5.83-9.08-11.98l-7.58,11.98h-5.05l12.36-19.53s13.24,17.81,14.85,19.53h-5.49Z" />
                    <rect class="cls-59" x="493.4" y="365.04" width="129.69" height="3.75" />
                    <rect class="cls-59" x="493.4" y="386.78" width="129.69" height="3.03" />
                    <rect class="cls-59" x="493.4" y="368.8" width="2.57" height="19.69" />
                    <rect class="cls-59" x="620.52" y="368.8" width="2.57" height="19.69" />
                    <rect class="cls-59" x="604.87" y="358.72" width="7.15" height="9.61" />
                    <rect class="cls-59" x="579.24" y="358.72" width="7.15" height="9.61" />
                    <rect class="cls-59" x="553.62" y="358.72" width="7.15" height="9.61" />
                    <rect class="cls-59" x="528" y="358.72" width="7.15" height="9.61" />
                    <rect class="cls-59" x="502.38" y="358.72" width="7.15" height="9.61" />
                </g>
                <g id="zon">
                    <circle class="cls-60" cx="1515.14" cy="284.07" r="164.47" />
                    <circle class="cls-60" cx="1515.14" cy="284.07" r="140.44" />
                    <circle class="cls-60" cx="1515.14" cy="284.07" r="112.71" />
                    <circle class="cls-60" cx="1515.14" cy="284.07" r="88.71" />
                    <circle class="cls-59" cx="1515.14" cy="284.07" r="56.93"
                        transform="translate(242.91 1154.57) rotate(-45)" />
                </g>
                <g id="huis">
                    <rect class="cls-93" x="1097.42" y="492.32" width="114.36" height="96.18" />
                    <rect class="cls-128" x="1097.42" y="492.32" width="114.36" height="6.59" />
                    <rect class="cls-103" x="1087.66" y="483.22" width="133.88" height="9.1" />
                    <g>
                        <g>
                            <rect class="cls-100" x="1152.5" y="522.87" width="24.16" height="65.63" />
                            <rect class="cls-48" x="1154.73" y="525.08" width="19.71" height="61.21" />
                        </g>
                        <g>
                            <rect class="cls-100" x="1178.01" y="522.87" width="24.16" height="65.63" />
                            <rect class="cls-48" x="1180.23" y="525.08" width="19.71" height="61.21" />
                        </g>
                        <rect class="cls-96" x="1176.67" y="522.87" width="1.34" height="65.63" />
                        <path class="cls-97"
                            d="M1195.63,574.8c0,.66,.56,1.19,1.26,1.19h0c.7,0,1.26-.53,1.26-1.19v-3.23c0-.66-.56-1.19-1.26-1.19h0c-.7,0-1.26,.53-1.26,1.19v3.23Z" />
                        <path class="cls-97"
                            d="M1195.63,565c0,.66,.56,1.19,1.26,1.19h0c.7,0,1.26-.53,1.26-1.19v-5.33c0-.66-.56-1.19-1.26-1.19h0c-.7,0-1.26,.53-1.26,1.19v5.33Z" />
                    </g>
                    <g>
                        <rect class="cls-100" x="1104.45" y="508.93" width="40.87" height="48.48" />
                        <rect class="cls-48" x="1106.26" y="510.64" width="37.24" height="45.06" />
                        <polygon class="cls-8" points="1143.51 510.64 1106.26 555.7 1143.51 555.7 1143.51 510.64" />
                        <rect class="cls-100" x="1104.89" y="520.62" width="40" height="1.71" />
                        <g>
                            <path class="cls-97"
                                d="M1139.71,536.07c0,.53,.46,.97,1.03,.97h0c.57,0,1.03-.43,1.03-.97v-8.05c0-.53-.46-.97-1.03-.97h0c-.57,0-1.03,.43-1.03,.97v8.05Z" />
                            <path class="cls-97"
                                d="M1139.71,543.39c0,.53,.46,.97,1.03,.97h0c.57,0,1.03-.43,1.03-.97v-2.63c0-.53-.46-.97-1.03-.97h0c-.57,0-1.03,.43-1.03,.97v2.63Z" />
                        </g>
                    </g>
                    <rect class="cls-123" x="1097.42" y="446.09" width="72.12" height="37.13" />
                    <rect class="cls-57" x="1097.42" y="446.09" width="72.12" height="3.85" />
                    <rect class="cls-103" x="1093.02" y="436.98" width="84.99" height="9.1" />
                    <g>
                        <rect class="cls-100" x="1103.36" y="453.57" width="18.44" height="21.87" />
                        <rect class="cls-48" x="1104.18" y="454.34" width="16.8" height="20.32" />
                        <polygon class="cls-8" points="1120.98 454.34 1104.18 474.67 1120.98 474.67 1120.98 454.34" />
                        <rect class="cls-100" x="1103.56" y="458.85" width="18.04" height=".77" />
                        <g>
                            <path class="cls-97"
                                d="M1119.27,465.81c0,.24,.21,.44,.46,.44h0c.26,0,.46-.2,.46-.44v-3.63c0-.24-.21-.44-.46-.44h0c-.26,0-.46,.2-.46,.44v3.63Z" />
                            <path class="cls-97"
                                d="M1119.27,469.11c0,.24,.21,.44,.46,.44h0c.26,0,.46-.2,.46-.44v-1.19c0-.24-.21-.44-.46-.44h0c-.26,0-.46,.2-.46,.44v1.19Z" />
                        </g>
                    </g>
                    <g>
                        <rect class="cls-100" x="1126.76" y="453.57" width="18.44" height="21.87" />
                        <rect class="cls-48" x="1127.58" y="454.34" width="16.8" height="20.32" />
                        <polygon class="cls-8" points="1144.38 454.34 1127.58 474.67 1144.38 474.67 1144.38 454.34" />
                        <rect class="cls-100" x="1126.96" y="458.85" width="18.04" height=".77" />
                        <g>
                            <path class="cls-97"
                                d="M1142.67,465.81c0,.24,.21,.44,.46,.44h0c.26,0,.46-.2,.46-.44v-3.63c0-.24-.21-.44-.46-.44h0c-.26,0-.46,.2-.46,.44v3.63Z" />
                            <path class="cls-97"
                                d="M1142.67,469.11c0,.24,.21,.44,.46,.44h0c.26,0,.46-.2,.46-.44v-1.19c0-.24-.21-.44-.46-.44h0c-.26,0-.46,.2-.46,.44v1.19Z" />
                        </g>
                    </g>
                    <g>
                        <polygon class="cls-48" points="1113.25 417.69 1119.23 417.69 1116.24 396.74 1113.25 417.69" />
                        <g>
                            <polygon class="cls-48"
                                points="1111.29 419.49 1121.18 419.49 1120.87 417.69 1111.6 417.69 1111.29 419.49" />
                            <g>
                                <path class="cls-48"
                                    d="M1110.67,419.49h11.13l1.09,17.49h-13.3l1.09-17.49Zm10.47,.66h-9.81l-1.01,16.17h11.82l-1.01-16.17Z" />
                                <rect class="cls-48" x="1116.06" y="419.82" width=".35" height="16.83" />
                                <polygon class="cls-48"
                                    points="1122.37 436.58 1122.48 436.27 1110.73 432.61 1122.47 428.47 1111.15 423.73 1121.53 419.98 1121.41 419.67 1110.24 423.71 1121.55 428.45 1109.68 432.62 1122.37 436.58" />
                                <polygon class="cls-48"
                                    points="1109.88 436.65 1122.8 432.49 1110.9 428.6 1122.27 423.63 1111.25 419.8 1111.13 420.11 1121.36 423.66 1109.97 428.65 1121.72 432.49 1109.77 436.34 1109.88 436.65" />
                            </g>
                        </g>
                        <g>
                            <path class="cls-97"
                                d="M1103.44,416.91c0,3.29,2.83,5.96,6.33,5.96s6.32-2.67,6.32-5.96-2.83-5.96-6.32-5.96-6.33,2.67-6.33,5.96Z" />
                            <path class="cls-153"
                                d="M1109.77,419.02c1.23,0,2.24-.95,2.24-2.11s-1-2.11-2.24-2.11-2.24,.95-2.24,2.11,1,2.11,2.24,2.11Z" />
                            <path class="cls-48"
                                d="M1109.77,417.46c.32,0,.57-.24,.57-.54s-.26-.54-.57-.54-.58,.24-.58,.54,.26,.54,.58,.54Z" />
                            <path class="cls-153"
                                d="M1115.14,417.13c0,1.87-.99,3.53-2.51,4.53,1.85-.93,3.11-2.77,3.11-4.89,0-3.05-2.63-5.53-5.88-5.53-1.26,0-2.44,.38-3.4,1.02,.83-.43,1.78-.66,2.8-.66,3.24,0,5.88,2.48,5.88,5.54Z" />
                        </g>
                        <g>
                            <path class="cls-97"
                                d="M1118.9,421.01c0,1.72,1.48,3.12,3.31,3.12s3.31-1.4,3.31-3.12-1.48-3.12-3.31-3.12-3.31,1.4-3.31,3.12Z" />
                            <path class="cls-153"
                                d="M1122.21,422.11c.65,0,1.17-.49,1.17-1.1s-.53-1.1-1.17-1.1-1.17,.5-1.17,1.1,.53,1.1,1.17,1.1Z" />
                            <path class="cls-48"
                                d="M1122.21,421.29c.17,0,.3-.13,.3-.28s-.13-.28-.3-.28-.3,.13-.3,.28,.14,.28,.3,.28Z" />
                            <path class="cls-153"
                                d="M1125.02,421.12c0,.98-.52,1.85-1.31,2.37,.97-.49,1.63-1.45,1.63-2.56,0-1.6-1.38-2.9-3.08-2.9-.66,0-1.28,.2-1.78,.53,.44-.22,.93-.35,1.46-.35,1.7,0,3.08,1.3,3.08,2.9Z" />
                        </g>
                        <g>
                            <path class="cls-97"
                                d="M1116.21,411.24c0,2.48,2.13,4.49,4.76,4.49s4.76-2.01,4.76-4.49-2.13-4.49-4.76-4.49-4.76,2.01-4.76,4.49Z" />
                            <path class="cls-153"
                                d="M1120.98,412.83c.93,0,1.68-.71,1.68-1.59s-.76-1.59-1.68-1.59-1.68,.71-1.68,1.59,.76,1.59,1.68,1.59Z" />
                            <path class="cls-48"
                                d="M1120.98,411.65c.24,0,.43-.18,.43-.41s-.19-.41-.43-.41-.43,.18-.43,.41,.19,.41,.43,.41Z" />
                            <path class="cls-153"
                                d="M1125.03,411.4c0,1.41-.74,2.66-1.89,3.41,1.39-.7,2.34-2.09,2.34-3.68,0-2.3-1.98-4.17-4.43-4.17-.95,0-1.84,.29-2.56,.77,.63-.32,1.34-.5,2.11-.5,2.44,0,4.43,1.87,4.43,4.17Z" />
                        </g>
                    </g>
                </g>
                <g id="Bos2">
                    <path class="cls-91"
                        d="M1211.89,588.54c-.54-1.47-.84-3.05-.84-4.69,0-4.85,2.57-9.15,6.53-11.81-3.54-1.88-5.92-5.39-5.92-9.42,0-6.01,5.3-10.88,11.83-10.88s11.83,4.87,11.83,10.88c0,.19,0,.38-.02,.56,1.94-1.72,4.58-2.78,7.49-2.78,4.03,0,7.55,2.03,9.41,5.04,1.9-4.39,6.56-7.5,12.02-7.5,6.19,0,11.37,4,12.63,9.35,1.89-.72,3.95-1.12,6.12-1.12,4.42,0,8.42,1.66,11.3,4.33,1.24-1.31,3.06-2.13,5.1-2.13,3.74,0,6.77,2.78,6.77,6.22,0,.19-.01,.38-.03,.56,.35-.03,.7-.04,1.05-.04,1.43,0,2.79,.23,4.06,.65,1.24-1.48,3.18-2.44,5.37-2.44,1.13,0,2.2,.26,3.14,.71,2.43-2.39,5.88-3.88,9.72-3.88,1.38,0,2.72,.19,3.97,.55,0-.12-.01-.24-.01-.37,0-6.56,5.79-11.89,12.93-11.89s12.44,4.88,12.9,11.07c1.65-.64,3.46-1,5.37-1,7.6,0,13.76,5.66,13.76,12.65,0,2.75-.96,5.3-2.59,7.38h-163.91Z" />
                    <g>
                        <path class="cls-58"
                            d="M1239.82,545.47c0,2.12,1.87,3.83,4.17,3.83h9.05c2.3,0,4.17-1.72,4.17-3.83h0c0-2.12-1.87-3.83-4.17-3.83h-9.05c-2.3,0-4.17,1.72-4.17,3.83h0Z" />
                        <path class="cls-134"
                            d="M1243.2,541.18c0,1.85,1.63,3.34,3.64,3.34h5.84c2.01,0,3.64-1.5,3.64-3.34h0c0-1.85-1.63-3.34-3.64-3.34h-5.84c-2.01,0-3.64,1.5-3.64,3.34h0Z" />
                        <path class="cls-137"
                            d="M1248.01,538.44c0,1.13,1,2.05,2.24,2.05h3.59c1.23,0,2.24-.92,2.24-2.05h0c0-1.13-1-2.05-2.24-2.05h-3.59c-1.23,0-2.24,.92-2.24,2.05h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1260.95,551.92c0,2.12,1.87,3.83,4.17,3.83h9.05c2.3,0,4.17-1.72,4.17-3.83h0c0-2.12-1.87-3.83-4.17-3.83h-9.05c-2.3,0-4.17,1.72-4.17,3.83h0Z" />
                        <path class="cls-134"
                            d="M1264.33,547.64c0,1.85,1.63,3.34,3.64,3.34h5.84c2.01,0,3.64-1.5,3.64-3.34h0c0-1.85-1.63-3.34-3.64-3.34h-5.84c-2.01,0-3.64,1.5-3.64,3.34h0Z" />
                        <path class="cls-137"
                            d="M1269.14,544.9c0,1.13,1,2.05,2.24,2.05h3.59c1.23,0,2.24-.92,2.24-2.05h0c0-1.13-1-2.05-2.24-2.05h-3.59c-1.23,0-2.24,.92-2.24,2.05h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1246.17,572.13c0,1.57,1.39,2.85,3.1,2.85h6.73c1.71,0,3.1-1.28,3.1-2.85h0c0-1.57-1.39-2.85-3.1-2.85h-6.73c-1.71,0-3.1,1.28-3.1,2.85h0Z" />
                        <path class="cls-134"
                            d="M1248.68,568.95c0,1.37,1.21,2.48,2.7,2.48h4.34c1.49,0,2.7-1.11,2.7-2.48h0c0-1.37-1.21-2.48-2.7-2.48h-4.34c-1.49,0-2.7,1.11-2.7,2.48h0Z" />
                        <path class="cls-137"
                            d="M1252.25,566.91c0,.84,.74,1.53,1.66,1.53h2.67c.92,0,1.66-.68,1.66-1.53h0c0-.84-.74-1.53-1.66-1.53h-2.67c-.92,0-1.66,.68-1.66,1.53h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1233.53,557.75c0,1.25,1.11,2.27,2.47,2.27h5.37c1.36,0,2.47-1.02,2.47-2.27h0c0-1.25-1.11-2.27-2.47-2.27h-5.37c-1.36,0-2.47,1.02-2.47,2.27h0Z" />
                        <path class="cls-134"
                            d="M1235.53,555.22c0,1.09,.97,1.98,2.16,1.98h3.46c1.19,0,2.16-.89,2.16-1.98h0c0-1.09-.97-1.98-2.16-1.98h-3.46c-1.19,0-2.16,.89-2.16,1.98h0Z" />
                        <path class="cls-137"
                            d="M1238.38,553.59c0,.67,.59,1.22,1.32,1.22h2.13c.73,0,1.32-.54,1.32-1.22h0c0-.67-.59-1.22-1.32-1.22h-2.13c-.73,0-1.32,.54-1.32,1.22h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1247.44,588.46h2.47v-41.81c0-.62-.55-1.12-1.22-1.12h-.02c-.68,0-1.22,.5-1.22,1.12v41.81Z" />
                    <path class="cls-46"
                        d="M1248.68,579.77c6.18,0,11.2-4.62,11.2-10.3v-4.18c0-.76-.67-1.37-1.49-1.37s-1.49,.61-1.49,1.37v4.18c0,4.16-3.69,7.55-8.22,7.55s-8.22-3.39-8.22-7.55v-10.09c0-.76-.67-1.37-1.49-1.37s-1.49,.61-1.49,1.37v10.09c0,5.68,5.03,10.3,11.21,10.3Z" />
                    <path class="cls-46"
                        d="M1239.14,567.49c3.72,0,6.75-2.78,6.75-6.2v-2.46c0-.53-.47-.96-1.05-.96s-1.05,.43-1.05,.96v2.46c0,2.36-2.09,4.28-4.65,4.28s-4.65-1.92-4.65-4.28v-5.92c0-.53-.47-.96-1.05-.96s-1.05,.43-1.05,.96v5.92c0,3.42,3.03,6.2,6.75,6.2Z" />
                    <path class="cls-46"
                        d="M1258.39,575.05c3.72,0,6.75-2.78,6.75-6.2v-13.91c0-.53-.47-.96-1.05-.96s-1.05,.43-1.05,.96v13.91c0,2.36-2.09,4.28-4.65,4.28s-4.65-1.92-4.65-4.28v-5.92c0-.53-.47-.96-1.05-.96s-1.05,.43-1.05,.96v5.92c0,3.42,3.03,6.2,6.75,6.2Z" />
                    <path class="cls-46"
                        d="M1264.55,564.44c3.72,0,6.75-2.78,6.75-6.2v-4.96c0-.53-.47-.96-1.05-.96s-1.05,.43-1.05,.96v4.96c0,2.36-2.09,4.28-4.65,4.28s-4.65-1.92-4.65-4.28v-5.92c0-.53-.47-.96-1.05-.96s-1.05,.43-1.05,.96v5.92c0,3.42,3.03,6.2,6.75,6.2Z" />
                    <path class="cls-46"
                        d="M1258.85,554.22c2.53,0,4.59-1.89,4.59-4.22v-2.66c0-.3-.27-.55-.6-.55s-.6,.25-.6,.55v2.66c0,1.72-1.52,3.12-3.4,3.12s-3.4-1.4-3.4-3.12v-1.44c0-.3-.27-.55-.6-.55s-.6,.25-.6,.55v1.44c0,2.33,2.06,4.22,4.59,4.22Z" />
                    <path class="cls-46"
                        d="M1270.25,558.15c1.97,0,3.57-1.47,3.57-3.28v-2.59c0-.3-.27-.55-.6-.55s-.6,.25-.6,.55v2.59c0,1.21-1.07,2.19-2.38,2.19-.86,0-1.65-.43-2.07-1.11-.16-.26-.53-.36-.81-.21-.29,.15-.39,.48-.23,.75,.63,1.03,1.83,1.67,3.11,1.67Z" />
                    <path class="cls-46"
                        d="M1237.17,555.03c2.53,0,4.59-1.89,4.59-4.22v-3.47c0-.3-.27-.55-.6-.55s-.6,.25-.6,.55v3.47c0,1.72-1.52,3.12-3.4,3.12s-3.4-1.4-3.4-3.12v-1.44c0-.3-.27-.55-.6-.55s-.6,.25-.6,.55v1.44c0,2.33,2.06,4.22,4.59,4.22Z" />
                    <g>
                        <path class="cls-58"
                            d="M1236.07,574.61c0,1.49,1.32,2.71,2.95,2.71h6.39c1.63,0,2.95-1.21,2.95-2.71h0c0-1.5-1.32-2.71-2.95-2.71h-6.39c-1.63,0-2.95,1.21-2.95,2.71h0Z" />
                        <path class="cls-134"
                            d="M1238.45,571.59c0,1.3,1.15,2.36,2.57,2.36h4.13c1.42,0,2.57-1.06,2.57-2.36h0c0-1.3-1.15-2.36-2.57-2.36h-4.13c-1.42,0-2.57,1.06-2.57,2.36h0Z" />
                        <path class="cls-137"
                            d="M1241.85,569.65c0,.8,.71,1.45,1.58,1.45h2.54c.87,0,1.58-.65,1.58-1.45h0c0-.8-.71-1.45-1.58-1.45h-2.54c-.87,0-1.58,.65-1.58,1.45h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1233.44,558.89c2.46,0,4.47-1.84,4.47-4.11v-1.6c0-.38-.33-.69-.75-.69s-.75,.31-.75,.69v1.6c0,1.51-1.33,2.74-2.98,2.74s-2.98-1.23-2.98-2.74v-3.87c0-.38-.33-.69-.75-.69s-.75,.31-.75,.69v3.87c0,2.27,2.01,4.11,4.47,4.11Z" />
                    <g>
                        <path class="cls-58"
                            d="M1240.64,560.79c0,2.08,1.83,3.76,4.09,3.76h8.88c2.26,0,4.09-1.68,4.09-3.76h0c0-2.08-1.83-3.76-4.09-3.76h-8.88c-2.26,0-4.09,1.68-4.09,3.76h0Z" />
                        <path class="cls-134"
                            d="M1243.95,556.59c0,1.81,1.6,3.28,3.57,3.28h5.73c1.97,0,3.57-1.47,3.57-3.28h0c0-1.81-1.6-3.28-3.57-3.28h-5.73c-1.97,0-3.57,1.47-3.57,3.28h0Z" />
                        <path class="cls-137"
                            d="M1248.67,553.9c0,1.11,.98,2.02,2.19,2.02h3.52c1.21,0,2.19-.9,2.19-2.02h0c0-1.11-.98-2.02-2.19-2.02h-3.52c-1.21,0-2.19,.9-2.19,2.02h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1231.38,569.58c0,1.32,1.16,2.39,2.6,2.39h5.64c1.43,0,2.6-1.07,2.6-2.39h0c0-1.32-1.16-2.39-2.6-2.39h-5.64c-1.43,0-2.6,1.07-2.6,2.39h0Z" />
                        <path class="cls-134"
                            d="M1233.48,566.91c0,1.15,1.01,2.08,2.26,2.08h3.64c1.25,0,2.26-.93,2.26-2.08h0c0-1.15-1.01-2.08-2.26-2.08h-3.64c-1.25,0-2.26,.93-2.26,2.08h0Z" />
                        <path class="cls-137"
                            d="M1236.48,565.2c0,.71,.62,1.28,1.39,1.28h2.24c.77,0,1.39-.57,1.39-1.28h0c0-.71-.62-1.28-1.39-1.28h-2.24c-.77,0-1.39,.57-1.39,1.28h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1267.65,560.51c0,.91,.8,1.65,1.79,1.65h3.89c.99,0,1.79-.74,1.79-1.65h0c0-.91-.8-1.65-1.79-1.65h-3.89c-.99,0-1.79,.74-1.79,1.65h0Z" />
                        <path class="cls-134"
                            d="M1269.1,558.67c0,.79,.7,1.44,1.56,1.44h2.51c.86,0,1.56-.64,1.56-1.44h0c0-.79-.7-1.44-1.56-1.44h-2.51c-.86,0-1.56,.64-1.56,1.44h0Z" />
                        <path class="cls-137"
                            d="M1271.17,557.49c0,.49,.43,.88,.96,.88h1.54c.53,0,.96-.4,.96-.88h0c0-.49-.43-.88-.96-.88h-1.54c-.53,0-.96,.4-.96,.88h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1248.46,576.59c0,1.09,.96,1.98,2.15,1.98h4.67c1.19,0,2.15-.89,2.15-1.98h0c0-1.09-.96-1.98-2.15-1.98h-4.67c-1.19,0-2.15,.89-2.15,1.98h0Z" />
                        <path class="cls-134"
                            d="M1250.2,574.38c0,.95,.84,1.72,1.88,1.72h3.02c1.04,0,1.88-.77,1.88-1.72h0c0-.95-.84-1.72-1.88-1.72h-3.02c-1.04,0-1.88,.77-1.88,1.72h0Z" />
                        <path class="cls-137"
                            d="M1252.69,572.97c0,.59,.52,1.06,1.15,1.06h1.85c.64,0,1.15-.47,1.15-1.06h0c0-.59-.52-1.06-1.15-1.06h-1.85c-.64,0-1.15,.47-1.15,1.06h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1255.78,567.82c0,1.88,1.66,3.41,3.71,3.41h8.05c2.05,0,3.71-1.53,3.71-3.41h0c0-1.88-1.66-3.41-3.71-3.41h-8.05c-2.05,0-3.71,1.53-3.71,3.41h0Z" />
                        <path class="cls-134"
                            d="M1258.79,564.01c0,1.64,1.45,2.97,3.24,2.97h5.2c1.79,0,3.24-1.33,3.24-2.97h0c0-1.64-1.45-2.97-3.24-2.97h-5.2c-1.79,0-3.24,1.33-3.24,2.97h0Z" />
                        <path class="cls-137"
                            d="M1263.07,561.57c0,1.01,.89,1.83,1.99,1.83h3.2c1.1,0,1.99-.82,1.99-1.83h0c0-1.01-.89-1.83-1.99-1.83h-3.2c-1.1,0-1.99,.82-1.99,1.83h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1257.1,556.86c0,.91,.8,1.65,1.79,1.65h3.89c.99,0,1.79-.74,1.79-1.65h0c0-.91-.8-1.65-1.79-1.65h-3.89c-.99,0-1.79,.74-1.79,1.65h0Z" />
                        <path class="cls-134"
                            d="M1258.55,555.02c0,.79,.7,1.44,1.56,1.44h2.51c.86,0,1.56-.64,1.56-1.44h0c0-.79-.7-1.44-1.56-1.44h-2.51c-.86,0-1.56,.64-1.56,1.44h0Z" />
                        <path class="cls-137"
                            d="M1260.62,553.84c0,.49,.43,.88,.96,.88h1.54c.53,0,.96-.4,.96-.88h0c0-.49-.43-.88-.96-.88h-1.54c-.53,0-.96,.4-.96,.88h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1234,548.77c0,1.88,1.66,3.41,3.71,3.41h8.05c2.05,0,3.71-1.53,3.71-3.41h0c0-1.88-1.66-3.41-3.71-3.41h-8.05c-2.05,0-3.71,1.53-3.71,3.41h0Z" />
                        <path class="cls-134"
                            d="M1237,544.96c0,1.64,1.45,2.97,3.24,2.97h5.2c1.79,0,3.24-1.33,3.24-2.97h0c0-1.64-1.45-2.97-3.24-2.97h-5.2c-1.79,0-3.24,1.33-3.24,2.97h0Z" />
                        <path class="cls-137"
                            d="M1241.28,542.52c0,1.01,.89,1.83,1.99,1.83h3.2c1.1,0,1.99-.82,1.99-1.83h0c0-1.01-.89-1.83-1.99-1.83h-3.2c-1.1,0-1.99,.82-1.99,1.83h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1222.02,552.75c0,1.75,1.55,3.17,3.45,3.17h7.49c1.91,0,3.45-1.42,3.45-3.17h0c0-1.75-1.55-3.17-3.45-3.17h-7.49c-1.91,0-3.45,1.42-3.45,3.17h0Z" />
                        <path class="cls-134"
                            d="M1224.82,549.2c0,1.53,1.35,2.77,3.01,2.77h4.84c1.66,0,3.01-1.24,3.01-2.77h0c0-1.53-1.35-2.77-3.01-2.77h-4.84c-1.66,0-3.01,1.24-3.01,2.77h0Z" />
                        <path class="cls-137"
                            d="M1228.8,546.93c0,.94,.83,1.7,1.85,1.7h2.97c1.02,0,1.85-.76,1.85-1.7h0c0-.94-.83-1.7-1.85-1.7h-2.97c-1.02,0-1.85,.76-1.85,1.7h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1250.69,546.91c0,1.88,1.66,3.41,3.71,3.41h8.05c2.05,0,3.71-1.53,3.71-3.41h0c0-1.88-1.66-3.41-3.71-3.41h-8.05c-2.05,0-3.71,1.53-3.71,3.41h0Z" />
                        <path class="cls-134"
                            d="M1253.69,543.1c0,1.64,1.45,2.97,3.24,2.97h5.2c1.79,0,3.24-1.33,3.24-2.97h0c0-1.64-1.45-2.97-3.24-2.97h-5.2c-1.79,0-3.24,1.33-3.24,2.97h0Z" />
                        <path class="cls-137"
                            d="M1257.98,540.66c0,1.01,.89,1.83,1.99,1.83h3.2c1.1,0,1.99-.82,1.99-1.83h0c0-1.01-.89-1.83-1.99-1.83h-3.2c-1.1,0-1.99,.82-1.99,1.83h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1289.81,556.92c0,1.55,1.37,2.81,3.06,2.81h6.64c1.69,0,3.06-1.26,3.06-2.81h0c0-1.55-1.37-2.81-3.06-2.81h-6.64c-1.69,0-3.06,1.26-3.06,2.81h0Z" />
                        <path class="cls-134"
                            d="M1292.29,553.78c0,1.35,1.19,2.45,2.67,2.45h4.29c1.47,0,2.67-1.1,2.67-2.45h0c0-1.35-1.19-2.45-2.67-2.45h-4.29c-1.47,0-2.67,1.1-2.67,2.45h0Z" />
                        <path class="cls-137"
                            d="M1295.82,551.76c0,.83,.73,1.51,1.64,1.51h2.64c.91,0,1.64-.67,1.64-1.51h0c0-.83-.73-1.51-1.64-1.51h-2.64c-.91,0-1.64,.67-1.64,1.51h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1305.31,561.66c0,1.55,1.37,2.81,3.06,2.81h6.64c1.69,0,3.06-1.26,3.06-2.81h0c0-1.55-1.37-2.81-3.06-2.81h-6.64c-1.69,0-3.06,1.26-3.06,2.81h0Z" />
                        <path class="cls-134"
                            d="M1307.79,558.52c0,1.35,1.19,2.45,2.67,2.45h4.29c1.47,0,2.67-1.1,2.67-2.45h0c0-1.35-1.19-2.45-2.67-2.45h-4.29c-1.47,0-2.67,1.1-2.67,2.45h0Z" />
                        <path class="cls-137"
                            d="M1311.32,556.5c0,.83,.73,1.51,1.64,1.51h2.64c.91,0,1.64-.67,1.64-1.51h0c0-.83-.73-1.51-1.64-1.51h-2.64c-.91,0-1.64,.67-1.64,1.51h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1294.47,576.48c0,1.15,1.02,2.09,2.27,2.09h4.93c1.26,0,2.27-.94,2.27-2.09h0c0-1.15-1.02-2.09-2.27-2.09h-4.93c-1.26,0-2.27,.94-2.27,2.09h0Z" />
                        <path class="cls-134"
                            d="M1296.31,574.14c0,1.01,.89,1.82,1.98,1.82h3.19c1.09,0,1.98-.82,1.98-1.82h0c0-1.01-.89-1.82-1.98-1.82h-3.19c-1.09,0-1.98,.82-1.98,1.82h0Z" />
                        <path class="cls-137"
                            d="M1298.93,572.65c0,.62,.55,1.12,1.22,1.12h1.96c.67,0,1.22-.5,1.22-1.12h0c0-.62-.55-1.12-1.22-1.12h-1.96c-.67,0-1.22,.5-1.22,1.12h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1285.19,565.93c0,.92,.81,1.67,1.81,1.67h3.94c1,0,1.81-.75,1.81-1.67h0c0-.92-.81-1.67-1.81-1.67h-3.94c-1,0-1.81,.75-1.81,1.67h0Z" />
                        <path class="cls-134"
                            d="M1286.66,564.07c0,.8,.71,1.45,1.58,1.45h2.54c.87,0,1.58-.65,1.58-1.45h0c0-.8-.71-1.45-1.58-1.45h-2.54c-.87,0-1.58,.65-1.58,1.45h0Z" />
                        <path class="cls-137"
                            d="M1288.76,562.88c0,.49,.44,.89,.97,.89h1.56c.54,0,.97-.4,.97-.89h0c0-.49-.44-.89-.97-.89h-1.56c-.54,0-.97,.4-.97,.89h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1295.4,588.46h1.81v-30.67c0-.46-.4-.83-.9-.83h-.02c-.5,0-.9,.37-.9,.83v30.67Z" />
                    <path class="cls-46"
                        d="M1296.31,582.09c4.53,0,8.22-3.39,8.22-7.55v-3.07c0-.56-.49-1.01-1.1-1.01s-1.1,.45-1.1,1.01v3.07c0,3.05-2.7,5.54-6.03,5.54s-6.03-2.49-6.03-5.54v-7.4c0-.56-.49-1.01-1.1-1.01s-1.1,.45-1.1,1.01v7.4c0,4.17,3.69,7.55,8.22,7.55Z" />
                    <path class="cls-46"
                        d="M1289.32,573.08c2.73,0,4.95-2.04,4.95-4.55v-1.8c0-.39-.34-.71-.77-.71s-.77,.32-.77,.71v1.8c0,1.73-1.53,3.14-3.41,3.14s-3.41-1.41-3.41-3.14v-4.35c0-.39-.34-.71-.77-.71s-.77,.32-.77,.71v4.35c0,2.51,2.22,4.55,4.95,4.55Z" />
                    <path class="cls-46"
                        d="M1303.44,578.62c2.73,0,4.95-2.04,4.95-4.55v-10.2c0-.39-.34-.7-.77-.7s-.77,.32-.77,.7v10.2c0,1.73-1.53,3.14-3.41,3.14s-3.41-1.41-3.41-3.14v-4.35c0-.39-.34-.71-.77-.71s-.77,.32-.77,.71v4.35c0,2.51,2.22,4.55,4.95,4.55Z" />
                    <path class="cls-46"
                        d="M1307.95,570.84c2.73,0,4.95-2.04,4.95-4.55v-3.64c0-.39-.34-.7-.77-.7s-.77,.32-.77,.7v3.64c0,1.73-1.53,3.14-3.41,3.14s-3.41-1.41-3.41-3.14v-4.35c0-.39-.34-.7-.77-.7s-.77,.32-.77,.7v4.35c0,2.51,2.22,4.55,4.95,4.55Z" />
                    <path class="cls-46"
                        d="M1303.77,563.34c1.86,0,3.37-1.39,3.37-3.1v-1.95c0-.22-.2-.4-.44-.4s-.44,.18-.44,.4v1.95c0,1.26-1.12,2.29-2.49,2.29s-2.49-1.03-2.49-2.29v-1.06c0-.22-.2-.4-.44-.4s-.44,.18-.44,.4v1.06c0,1.71,1.51,3.1,3.37,3.1Z" />
                    <path class="cls-46"
                        d="M1312.13,566.23c1.45,0,2.62-1.08,2.62-2.41v-1.9c0-.22-.2-.4-.44-.4s-.44,.18-.44,.4v1.9c0,.88-.78,1.6-1.75,1.6-.63,0-1.21-.31-1.52-.82-.12-.19-.39-.26-.6-.15-.21,.11-.29,.36-.17,.55,.46,.76,1.34,1.23,2.29,1.23Z" />
                    <path class="cls-46"
                        d="M1287.87,563.94c1.86,0,3.37-1.39,3.37-3.1v-2.55c0-.22-.2-.4-.44-.4s-.44,.18-.44,.4v2.55c0,1.26-1.12,2.29-2.49,2.29s-2.49-1.03-2.49-2.29v-1.06c0-.22-.2-.4-.44-.4s-.44,.18-.44,.4v1.06c0,1.71,1.51,3.1,3.37,3.1Z" />
                    <g>
                        <path class="cls-58"
                            d="M1287.06,578.3c0,1.1,.97,1.99,2.16,1.99h4.69c1.19,0,2.16-.89,2.16-1.99h0c0-1.1-.97-1.99-2.16-1.99h-4.69c-1.19,0-2.16,.89-2.16,1.99h0Z" />
                        <path class="cls-134"
                            d="M1288.81,576.08c0,.96,.84,1.73,1.88,1.73h3.03c1.04,0,1.88-.78,1.88-1.73h0c0-.96-.84-1.73-1.88-1.73h-3.03c-1.04,0-1.88,.78-1.88,1.73h0Z" />
                        <path class="cls-137"
                            d="M1291.3,574.66c0,.59,.52,1.06,1.16,1.06h1.86c.64,0,1.16-.48,1.16-1.06h0c0-.59-.52-1.06-1.16-1.06h-1.86c-.64,0-1.16,.48-1.16,1.06h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1285.13,566.77c1.81,0,3.28-1.35,3.28-3.01v-1.18c0-.28-.25-.5-.55-.5s-.55,.23-.55,.5v1.18c0,1.11-.98,2.01-2.18,2.01s-2.18-.9-2.18-2.01v-2.84c0-.28-.25-.5-.55-.5s-.55,.23-.55,.5v2.84c0,1.66,1.47,3.01,3.28,3.01Z" />
                    <g>
                        <path class="cls-58"
                            d="M1290.41,568.16c0,1.52,1.34,2.76,3,2.76h6.51c1.66,0,3-1.24,3-2.76h0c0-1.52-1.34-2.76-3-2.76h-6.51c-1.66,0-3,1.24-3,2.76h0Z" />
                        <path class="cls-134"
                            d="M1292.84,565.08c0,1.33,1.17,2.41,2.62,2.41h4.21c1.45,0,2.62-1.08,2.62-2.41h0c0-1.33-1.17-2.41-2.62-2.41h-4.21c-1.45,0-2.62,1.08-2.62,2.41h0Z" />
                        <path class="cls-137"
                            d="M1296.31,563.1c0,.82,.72,1.48,1.61,1.48h2.58c.89,0,1.61-.66,1.61-1.48h0c0-.82-.72-1.48-1.61-1.48h-2.58c-.89,0-1.61,.66-1.61,1.48h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1283.62,574.61c0,.97,.85,1.75,1.91,1.75h4.14c1.05,0,1.91-.78,1.91-1.75h0c0-.97-.85-1.75-1.91-1.75h-4.14c-1.05,0-1.91,.78-1.91,1.75h0Z" />
                        <path class="cls-134"
                            d="M1285.16,572.65c0,.84,.74,1.53,1.66,1.53h2.67c.92,0,1.66-.68,1.66-1.53h0c0-.84-.74-1.53-1.66-1.53h-2.67c-.92,0-1.66,.68-1.66,1.53h0Z" />
                        <path class="cls-137"
                            d="M1287.36,571.4c0,.52,.46,.94,1.02,.94h1.64c.56,0,1.02-.42,1.02-.94h0c0-.52-.46-.94-1.02-.94h-1.64c-.56,0-1.02,.42-1.02,.94h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1310.23,567.96c0,.67,.59,1.21,1.32,1.21h2.85c.73,0,1.32-.54,1.32-1.21h0c0-.67-.59-1.21-1.32-1.21h-2.85c-.73,0-1.32,.54-1.32,1.21h0Z" />
                        <path class="cls-134"
                            d="M1311.3,566.61c0,.58,.51,1.05,1.15,1.05h1.84c.63,0,1.15-.47,1.15-1.05h0c0-.58-.51-1.05-1.15-1.05h-1.84c-.63,0-1.15,.47-1.15,1.05h0Z" />
                        <path class="cls-137"
                            d="M1312.81,565.74c0,.36,.32,.65,.7,.65h1.13c.39,0,.71-.29,.71-.65h0c0-.36-.32-.65-.71-.65h-1.13c-.39,0-.7,.29-.7,.65h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1296.15,579.75c0,.8,.71,1.45,1.58,1.45h3.43c.87,0,1.58-.65,1.58-1.45h0c0-.8-.71-1.45-1.58-1.45h-3.43c-.87,0-1.58,.65-1.58,1.45h0Z" />
                        <path class="cls-134"
                            d="M1297.43,578.13c0,.7,.62,1.26,1.38,1.26h2.21c.76,0,1.38-.57,1.38-1.26h0c0-.7-.62-1.27-1.38-1.27h-2.21c-.76,0-1.38,.57-1.38,1.27h0Z" />
                        <path class="cls-137"
                            d="M1299.25,577.09c0,.43,.38,.78,.85,.78h1.36c.47,0,.85-.35,.85-.78h0c0-.43-.38-.78-.85-.78h-1.36c-.47,0-.85,.35-.85,.78h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1301.52,573.32c0,1.38,1.22,2.5,2.72,2.5h5.91c1.5,0,2.72-1.12,2.72-2.5h0c0-1.38-1.22-2.5-2.72-2.5h-5.91c-1.5,0-2.72,1.12-2.72,2.5h0Z" />
                        <path class="cls-134"
                            d="M1303.73,570.53c0,1.2,1.06,2.18,2.37,2.18h3.81c1.31,0,2.37-.98,2.37-2.18h0c0-1.2-1.06-2.18-2.37-2.18h-3.81c-1.31,0-2.37,.98-2.37,2.18h0Z" />
                        <path class="cls-137"
                            d="M1306.87,568.74c0,.74,.65,1.34,1.46,1.34h2.34c.81,0,1.46-.6,1.46-1.34h0c0-.74-.65-1.34-1.46-1.34h-2.34c-.81,0-1.46,.6-1.46,1.34h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1302.49,565.28c0,.67,.59,1.21,1.32,1.21h2.85c.73,0,1.32-.54,1.32-1.21h0c0-.67-.59-1.21-1.32-1.21h-2.85c-.73,0-1.32,.54-1.32,1.21h0Z" />
                        <path class="cls-134"
                            d="M1303.55,563.93c0,.58,.51,1.05,1.15,1.05h1.84c.63,0,1.15-.47,1.15-1.05h0c0-.58-.51-1.05-1.15-1.05h-1.84c-.63,0-1.15,.47-1.15,1.05h0Z" />
                        <path class="cls-137"
                            d="M1305.07,563.06c0,.36,.32,.65,.7,.65h1.13c.39,0,.71-.29,.71-.65h0c0-.36-.32-.65-.71-.65h-1.13c-.39,0-.7,.29-.7,.65h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1285.54,559.34c0,1.38,1.22,2.5,2.72,2.5h5.91c1.5,0,2.72-1.12,2.72-2.5h0c0-1.38-1.22-2.5-2.72-2.5h-5.91c-1.5,0-2.72,1.12-2.72,2.5h0Z" />
                        <path class="cls-134"
                            d="M1287.74,556.55c0,1.2,1.06,2.18,2.37,2.18h3.81c1.31,0,2.37-.98,2.37-2.18h0c0-1.2-1.06-2.18-2.37-2.18h-3.81c-1.31,0-2.37,.98-2.37,2.18h0Z" />
                        <path class="cls-137"
                            d="M1290.89,554.76c0,.74,.65,1.34,1.46,1.34h2.34c.81,0,1.46-.6,1.46-1.34h0c0-.74-.65-1.34-1.46-1.34h-2.34c-.81,0-1.46,.6-1.46,1.34h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1276.75,562.26c0,1.29,1.13,2.33,2.53,2.33h5.5c1.4,0,2.53-1.04,2.53-2.33h0c0-1.29-1.13-2.33-2.53-2.33h-5.5c-1.4,0-2.53,1.04-2.53,2.33h0Z" />
                        <path class="cls-134"
                            d="M1278.81,559.66c0,1.12,.99,2.03,2.21,2.03h3.55c1.22,0,2.21-.91,2.21-2.03h0c0-1.12-.99-2.03-2.21-2.03h-3.55c-1.22,0-2.21,.91-2.21,2.03h0Z" />
                        <path class="cls-137"
                            d="M1281.73,557.99c0,.69,.61,1.25,1.36,1.25h2.18c.75,0,1.36-.56,1.36-1.25h0c0-.69-.61-1.25-1.36-1.25h-2.18c-.75,0-1.36,.56-1.36,1.25h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1297.78,557.98c0,1.38,1.22,2.5,2.72,2.5h5.91c1.5,0,2.72-1.12,2.72-2.5h0c0-1.38-1.22-2.5-2.72-2.5h-5.91c-1.5,0-2.72,1.12-2.72,2.5h0Z" />
                        <path class="cls-134"
                            d="M1299.99,555.19c0,1.2,1.06,2.18,2.37,2.18h3.81c1.31,0,2.37-.98,2.37-2.18h0c0-1.2-1.06-2.18-2.37-2.18h-3.81c-1.31,0-2.37,.98-2.37,2.18h0Z" />
                        <path class="cls-137"
                            d="M1303.13,553.39c0,.74,.65,1.34,1.46,1.34h2.34c.81,0,1.46-.6,1.46-1.34h0c0-.74-.65-1.34-1.46-1.34h-2.34c-.81,0-1.46,.6-1.46,1.34h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1321.6,561.76c0,1.31,1.16,2.38,2.59,2.38h5.62c1.43,0,2.59-1.07,2.59-2.38h0c0-1.31-1.16-2.38-2.59-2.38h-5.62c-1.43,0-2.59,1.07-2.59,2.38h0Z" />
                        <path class="cls-134"
                            d="M1323.7,559.1c0,1.15,1.01,2.08,2.26,2.08h3.63c1.25,0,2.26-.93,2.26-2.08h0c0-1.15-1.01-2.08-2.26-2.08h-3.63c-1.25,0-2.26,.93-2.26,2.08h0Z" />
                        <path class="cls-137"
                            d="M1326.69,557.39c0,.71,.62,1.28,1.39,1.28h2.23c.77,0,1.39-.57,1.39-1.28h0c0-.7-.62-1.28-1.39-1.28h-2.23c-.77,0-1.39,.57-1.39,1.28h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1334.72,565.77c0,1.31,1.16,2.38,2.59,2.38h5.62c1.43,0,2.59-1.07,2.59-2.38h0c0-1.31-1.16-2.38-2.59-2.38h-5.62c-1.43,0-2.59,1.07-2.59,2.38h0Z" />
                        <path class="cls-134"
                            d="M1336.82,563.11c0,1.15,1.01,2.08,2.26,2.08h3.63c1.25,0,2.26-.93,2.26-2.08h0c0-1.15-1.01-2.08-2.26-2.08h-3.63c-1.25,0-2.26,.93-2.26,2.08h0Z" />
                        <path class="cls-137"
                            d="M1339.81,561.41c0,.7,.62,1.28,1.39,1.28h2.23c.77,0,1.39-.57,1.39-1.28h0c0-.7-.62-1.28-1.39-1.28h-2.23c-.77,0-1.39,.57-1.39,1.28h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1325.54,578.32c0,.98,.86,1.77,1.92,1.77h4.18c1.06,0,1.92-.79,1.92-1.77h0c0-.98-.86-1.77-1.92-1.77h-4.18c-1.06,0-1.92,.79-1.92,1.77h0Z" />
                        <path class="cls-134"
                            d="M1327.1,576.34c0,.85,.75,1.54,1.68,1.54h2.7c.93,0,1.68-.69,1.68-1.54h0c0-.85-.75-1.54-1.68-1.54h-2.7c-.93,0-1.68,.69-1.68,1.54h0Z" />
                        <path class="cls-137"
                            d="M1329.32,575.08c0,.52,.46,.95,1.03,.95h1.66c.57,0,1.03-.42,1.03-.95h0c0-.52-.46-.95-1.03-.95h-1.66c-.57,0-1.03,.42-1.03,.95h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1317.69,569.39c0,.78,.69,1.41,1.54,1.41h3.33c.85,0,1.53-.63,1.53-1.41h0c0-.78-.69-1.41-1.53-1.41h-3.33c-.85,0-1.54,.63-1.54,1.41h0Z" />
                        <path class="cls-134"
                            d="M1318.93,567.81c0,.68,.6,1.23,1.34,1.23h2.15c.74,0,1.34-.55,1.34-1.23h0c0-.68-.6-1.23-1.34-1.23h-2.15c-.74,0-1.34,.55-1.34,1.23h0Z" />
                        <path class="cls-137"
                            d="M1320.7,566.8c0,.42,.37,.76,.82,.76h1.32c.45,0,.82-.34,.82-.76h0c0-.42-.37-.76-.82-.76h-1.32c-.45,0-.82,.34-.82,.76h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1326.33,588.46h1.54v-25.96c0-.39-.34-.7-.76-.7h-.02c-.42,0-.76,.31-.76,.7v25.96Z" />
                    <path class="cls-46"
                        d="M1327.1,583.07c3.84,0,6.96-2.87,6.96-6.4v-2.6c0-.47-.42-.85-.93-.85s-.93,.38-.93,.85v2.6c0,2.59-2.29,4.69-5.1,4.69s-5.1-2.1-5.1-4.69v-6.27c0-.47-.42-.85-.93-.85s-.93,.38-.93,.85v6.27c0,3.53,3.12,6.4,6.96,6.4Z" />
                    <path class="cls-46"
                        d="M1321.18,575.44c2.31,0,4.19-1.73,4.19-3.85v-1.53c0-.33-.29-.6-.65-.6s-.65,.27-.65,.6v1.53c0,1.46-1.3,2.66-2.89,2.66s-2.89-1.19-2.89-2.66v-3.68c0-.33-.29-.6-.65-.6s-.65,.27-.65,.6v3.68c0,2.12,1.88,3.85,4.19,3.85Z" />
                    <path class="cls-46"
                        d="M1333.13,580.13c2.31,0,4.19-1.73,4.19-3.85v-8.64c0-.33-.29-.6-.65-.6s-.65,.27-.65,.6v8.64c0,1.46-1.3,2.66-2.89,2.66s-2.89-1.19-2.89-2.66v-3.68c0-.33-.29-.6-.65-.6s-.65,.27-.65,.6v3.68c0,2.12,1.88,3.85,4.19,3.85Z" />
                    <path class="cls-46"
                        d="M1336.95,573.54c2.31,0,4.19-1.73,4.19-3.85v-3.08c0-.33-.29-.6-.65-.6s-.65,.27-.65,.6v3.08c0,1.46-1.3,2.66-2.89,2.66s-2.89-1.19-2.89-2.66v-3.68c0-.33-.29-.6-.65-.6s-.65,.27-.65,.6v3.68c0,2.12,1.88,3.85,4.19,3.85Z" />
                    <path class="cls-46"
                        d="M1333.41,567.19c1.57,0,2.85-1.18,2.85-2.62v-1.65c0-.19-.17-.34-.37-.34s-.37,.15-.37,.34v1.65c0,1.07-.95,1.94-2.11,1.94s-2.11-.87-2.11-1.94v-.9c0-.19-.17-.34-.37-.34s-.37,.15-.37,.34v.9c0,1.45,1.28,2.62,2.85,2.62Z" />
                    <path class="cls-46"
                        d="M1340.49,569.64c1.22,0,2.22-.92,2.22-2.04v-1.61c0-.19-.17-.34-.37-.34s-.37,.15-.37,.34v1.61c0,.75-.66,1.36-1.48,1.36-.53,0-1.03-.27-1.29-.69-.1-.16-.33-.22-.51-.13-.18,.09-.24,.3-.14,.46,.39,.64,1.13,1.04,1.93,1.04Z" />
                    <path class="cls-46"
                        d="M1319.95,567.7c1.57,0,2.85-1.18,2.85-2.62v-2.16c0-.19-.17-.34-.37-.34s-.37,.15-.37,.34v2.16c0,1.07-.95,1.94-2.11,1.94s-2.11-.87-2.11-1.94v-.9c0-.19-.17-.34-.37-.34s-.37,.15-.37,.34v.9c0,1.45,1.28,2.62,2.85,2.62Z" />
                    <g>
                        <path class="cls-58"
                            d="M1319.27,579.86c0,.93,.82,1.68,1.83,1.68h3.97c1.01,0,1.83-.75,1.83-1.68h0c0-.93-.82-1.68-1.83-1.68h-3.97c-1.01,0-1.83,.75-1.83,1.68h0Z" />
                        <path class="cls-134"
                            d="M1320.75,577.98c0,.81,.71,1.47,1.6,1.47h2.56c.88,0,1.6-.66,1.6-1.47h0c0-.81-.71-1.47-1.6-1.47h-2.56c-.88,0-1.6,.66-1.6,1.47h0Z" />
                        <path class="cls-137"
                            d="M1322.86,576.78c0,.5,.44,.9,.98,.9h1.58c.54,0,.98-.4,.98-.9h0c0-.5-.44-.9-.98-.9h-1.58c-.54,0-.98,.4-.98,.9h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1317.64,570.1c1.53,0,2.78-1.14,2.78-2.55v-1c0-.24-.21-.43-.46-.43s-.46,.19-.46,.43v1c0,.94-.83,1.7-1.85,1.7s-1.85-.76-1.85-1.7v-2.4c0-.24-.21-.43-.46-.43s-.46,.19-.46,.43v2.4c0,1.41,1.25,2.55,2.78,2.55Z" />
                    <g>
                        <path class="cls-58"
                            d="M1322.1,571.28c0,1.29,1.14,2.33,2.54,2.33h5.51c1.4,0,2.54-1.05,2.54-2.33h0c0-1.29-1.14-2.34-2.54-2.34h-5.51c-1.4,0-2.54,1.05-2.54,2.34h0Z" />
                        <path class="cls-134"
                            d="M1324.16,568.67c0,1.12,.99,2.04,2.22,2.04h3.56c1.22,0,2.22-.91,2.22-2.04h0c0-1.12-.99-2.04-2.22-2.04h-3.56c-1.22,0-2.22,.91-2.22,2.04h0Z" />
                        <path class="cls-137"
                            d="M1327.1,567c0,.69,.61,1.25,1.36,1.25h2.19c.75,0,1.36-.56,1.36-1.25h0c0-.69-.61-1.25-1.36-1.25h-2.19c-.75,0-1.36,.56-1.36,1.25h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1316.35,576.73c0,.82,.72,1.48,1.61,1.48h3.5c.89,0,1.61-.66,1.61-1.48h0c0-.82-.72-1.48-1.61-1.48h-3.5c-.89,0-1.61,.66-1.61,1.48h0Z" />
                        <path class="cls-134"
                            d="M1317.66,575.08c0,.71,.63,1.29,1.41,1.29h2.26c.78,0,1.41-.58,1.41-1.29h0c0-.71-.63-1.29-1.41-1.29h-2.26c-.78,0-1.41,.58-1.41,1.29h0Z" />
                        <path class="cls-137"
                            d="M1319.52,574.02c0,.44,.39,.79,.86,.79h1.39c.48,0,.86-.36,.86-.79h0c0-.44-.39-.79-.86-.79h-1.39c-.48,0-.86,.36-.86,.79h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1338.88,571.1c0,.57,.5,1.02,1.11,1.02h2.42c.61,0,1.11-.46,1.11-1.02h0c0-.57-.5-1.02-1.11-1.02h-2.42c-.61,0-1.11,.46-1.11,1.02h0Z" />
                        <path class="cls-134"
                            d="M1339.79,569.96c0,.49,.43,.89,.97,.89h1.56c.54,0,.97-.4,.97-.89h0c0-.49-.43-.89-.97-.89h-1.56c-.54,0-.97,.4-.97,.89h0Z" />
                        <path class="cls-137"
                            d="M1341.07,569.23c0,.3,.27,.55,.6,.55h.96c.33,0,.6-.25,.6-.55h0c0-.3-.27-.55-.6-.55h-.96c-.33,0-.6,.25-.6,.55h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1326.96,581.09c0,.68,.6,1.23,1.34,1.23h2.9c.74,0,1.34-.55,1.34-1.23h0c0-.68-.6-1.23-1.34-1.23h-2.9c-.74,0-1.34,.55-1.34,1.23h0Z" />
                        <path class="cls-134"
                            d="M1328.05,579.72c0,.59,.52,1.07,1.17,1.07h1.87c.64,0,1.17-.48,1.17-1.07h0c0-.59-.52-1.07-1.17-1.07h-1.87c-.64,0-1.17,.48-1.17,1.07h0Z" />
                        <path class="cls-137"
                            d="M1329.59,578.84c0,.36,.32,.66,.72,.66h1.15c.4,0,.72-.29,.72-.66h0c0-.36-.32-.66-.72-.66h-1.15c-.4,0-.72,.29-.72,.66h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1331.51,575.64c0,1.17,1.03,2.12,2.3,2.12h5c1.27,0,2.3-.95,2.3-2.12h0c0-1.17-1.03-2.12-2.3-2.12h-5c-1.27,0-2.3,.95-2.3,2.12h0Z" />
                        <path class="cls-134"
                            d="M1333.38,573.28c0,1.02,.9,1.85,2.01,1.85h3.23c1.11,0,2.01-.83,2.01-1.85h0c0-1.02-.9-1.85-2.01-1.85h-3.23c-1.11,0-2.01,.83-2.01,1.85h0Z" />
                        <path class="cls-137"
                            d="M1336.04,571.76c0,.63,.55,1.14,1.24,1.14h1.98c.68,0,1.23-.51,1.23-1.14h0c0-.63-.55-1.14-1.23-1.14h-1.98c-.68,0-1.24,.51-1.24,1.14h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1332.33,568.83c0,.57,.5,1.02,1.11,1.02h2.42c.61,0,1.11-.46,1.11-1.02h0c0-.57-.5-1.02-1.11-1.02h-2.42c-.61,0-1.11,.46-1.11,1.02h0Z" />
                        <path class="cls-134"
                            d="M1333.23,567.69c0,.49,.43,.89,.97,.89h1.56c.54,0,.97-.4,.97-.89h0c0-.49-.43-.89-.97-.89h-1.56c-.54,0-.97,.4-.97,.89h0Z" />
                        <path class="cls-137"
                            d="M1334.52,566.96c0,.3,.27,.55,.6,.55h.96c.33,0,.6-.25,.6-.55h0c0-.3-.27-.55-.6-.55h-.96c-.33,0-.6,.25-.6,.55h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1317.98,563.81c0,1.17,1.03,2.12,2.3,2.12h5c1.27,0,2.3-.95,2.3-2.12h0c0-1.17-1.03-2.12-2.3-2.12h-5c-1.27,0-2.3,.95-2.3,2.12h0Z" />
                        <path class="cls-134"
                            d="M1319.85,561.44c0,1.02,.9,1.85,2.01,1.85h3.23c1.11,0,2.01-.83,2.01-1.85h0c0-1.02-.9-1.85-2.01-1.85h-3.23c-1.11,0-2.01,.83-2.01,1.85h0Z" />
                        <path class="cls-137"
                            d="M1322.51,559.93c0,.63,.55,1.14,1.24,1.14h1.98c.68,0,1.24-.51,1.24-1.14h0c0-.63-.55-1.14-1.24-1.14h-1.98c-.68,0-1.24,.51-1.24,1.14h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1310.54,566.28c0,1.09,.96,1.97,2.14,1.97h4.65c1.18,0,2.14-.88,2.14-1.97h0c0-1.09-.96-1.97-2.14-1.97h-4.65c-1.18,0-2.14,.88-2.14,1.97h0Z" />
                        <path class="cls-134"
                            d="M1312.28,564.08c0,.95,.84,1.72,1.87,1.72h3c1.03,0,1.87-.77,1.87-1.72h0c0-.95-.84-1.72-1.87-1.72h-3c-1.03,0-1.87,.77-1.87,1.72h0Z" />
                        <path class="cls-137"
                            d="M1314.76,562.67c0,.58,.51,1.06,1.15,1.06h1.85c.63,0,1.15-.47,1.15-1.06h0c0-.58-.51-1.06-1.15-1.06h-1.85c-.63,0-1.15,.47-1.15,1.06h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1328.35,562.66c0,1.17,1.03,2.12,2.3,2.12h5c1.27,0,2.3-.95,2.3-2.12h0c0-1.17-1.03-2.12-2.3-2.12h-5c-1.27,0-2.3,.95-2.3,2.12h0Z" />
                        <path class="cls-134"
                            d="M1330.21,560.29c0,1.02,.9,1.85,2.01,1.85h3.23c1.11,0,2.01-.83,2.01-1.85h0c0-1.02-.9-1.85-2.01-1.85h-3.23c-1.11,0-2.01,.83-2.01,1.85h0Z" />
                        <path class="cls-137"
                            d="M1332.87,558.77c0,.63,.55,1.14,1.24,1.14h1.98c.68,0,1.24-.51,1.24-1.14h0c0-.63-.55-1.14-1.24-1.14h-1.98c-.68,0-1.24,.51-1.24,1.14h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1389.23,567.34c0,1.04,.92,1.88,2.05,1.88h4.45c1.13,0,2.05-.84,2.05-1.88h0c0-1.04-.92-1.88-2.05-1.88h-4.45c-1.13,0-2.05,.84-2.05,1.88h0Z" />
                        <path class="cls-134"
                            d="M1390.89,565.23c0,.91,.8,1.64,1.79,1.64h2.87c.99,0,1.79-.74,1.79-1.64h0c0-.91-.8-1.64-1.79-1.64h-2.87c-.99,0-1.79,.74-1.79,1.64h0Z" />
                        <path class="cls-137"
                            d="M1393.25,563.88c0,.56,.49,1.01,1.1,1.01h1.76c.61,0,1.1-.45,1.1-1.01h0c0-.56-.49-1.01-1.1-1.01h-1.76c-.61,0-1.1,.45-1.1,1.01h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1399.61,570.51c0,1.04,.92,1.88,2.05,1.88h4.45c1.13,0,2.05-.84,2.05-1.88h0c0-1.04-.92-1.88-2.05-1.88h-4.45c-1.13,0-2.05,.84-2.05,1.88h0Z" />
                        <path class="cls-134"
                            d="M1401.27,568.4c0,.91,.8,1.64,1.79,1.64h2.87c.99,0,1.79-.74,1.79-1.64h0c0-.91-.8-1.64-1.79-1.64h-2.87c-.99,0-1.79,.74-1.79,1.64h0Z" />
                        <path class="cls-137"
                            d="M1403.63,567.06c0,.56,.49,1.01,1.1,1.01h1.77c.61,0,1.1-.45,1.1-1.01h0c0-.56-.49-1.01-1.1-1.01h-1.77c-.61,0-1.1,.45-1.1,1.01h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1392.35,580.44c0,.77,.68,1.4,1.52,1.4h3.3c.84,0,1.52-.63,1.52-1.4h0c0-.77-.68-1.4-1.52-1.4h-3.3c-.84,0-1.52,.63-1.52,1.4h0Z" />
                        <path class="cls-134"
                            d="M1393.58,578.87c0,.67,.59,1.22,1.33,1.22h2.13c.73,0,1.33-.55,1.33-1.22h0c0-.67-.59-1.22-1.33-1.22h-2.13c-.73,0-1.33,.55-1.33,1.22h0Z" />
                        <path class="cls-137"
                            d="M1395.34,577.87c0,.41,.37,.75,.82,.75h1.31c.45,0,.82-.34,.82-.75h0c0-.41-.37-.75-.82-.75h-1.31c-.45,0-.82,.34-.82,.75h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1386.13,573.37c0,.62,.54,1.12,1.21,1.12h2.64c.67,0,1.21-.5,1.21-1.12h0c0-.62-.54-1.12-1.21-1.12h-2.64c-.67,0-1.21,.5-1.21,1.12h0Z" />
                        <path class="cls-134"
                            d="M1387.12,572.13c0,.54,.47,.97,1.06,.97h1.7c.58,0,1.06-.44,1.06-.97h0c0-.54-.47-.97-1.06-.97h-1.7c-.58,0-1.06,.44-1.06,.97h0Z" />
                        <path class="cls-137"
                            d="M1388.52,571.33c0,.33,.29,.6,.65,.6h1.05c.36,0,.65-.27,.65-.6h0c0-.33-.29-.6-.65-.6h-1.05c-.36,0-.65,.27-.65,.6h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1392.97,588.46h1.21v-20.54c0-.31-.27-.55-.6-.55h-.01c-.33,0-.6,.25-.6,.55v20.54Z" />
                    <path class="cls-46"
                        d="M1393.58,584.19c3.04,0,5.51-2.27,5.51-5.06v-2.06c0-.37-.33-.67-.73-.67s-.73,.3-.73,.67v2.06c0,2.05-1.81,3.71-4.04,3.71s-4.04-1.66-4.04-3.71v-4.96c0-.37-.33-.67-.73-.67s-.73,.3-.73,.67v4.96c0,2.79,2.47,5.06,5.51,5.06Z" />
                    <path class="cls-46"
                        d="M1388.89,578.16c1.83,0,3.31-1.37,3.31-3.05v-1.21c0-.26-.23-.47-.51-.47s-.51,.21-.51,.47v1.21c0,1.16-1.03,2.1-2.29,2.1s-2.29-.94-2.29-2.1v-2.91c0-.26-.23-.47-.51-.47s-.51,.21-.51,.47v2.91c0,1.68,1.49,3.05,3.31,3.05Z" />
                    <path class="cls-46"
                        d="M1398.35,581.87c1.83,0,3.31-1.37,3.31-3.05v-6.83c0-.26-.23-.47-.51-.47s-.51,.21-.51,.47v6.83c0,1.16-1.03,2.1-2.29,2.1s-2.29-.94-2.29-2.1v-2.91c0-.26-.23-.47-.51-.47s-.51,.21-.51,.47v2.91c0,1.68,1.49,3.05,3.31,3.05Z" />
                    <path class="cls-46"
                        d="M1401.38,576.66c1.83,0,3.31-1.37,3.31-3.05v-2.44c0-.26-.23-.47-.51-.47s-.51,.21-.51,.47v2.44c0,1.16-1.03,2.1-2.29,2.1s-2.29-.94-2.29-2.1v-2.91c0-.26-.23-.47-.51-.47s-.51,.21-.51,.47v2.91c0,1.68,1.49,3.05,3.31,3.05Z" />
                    <path class="cls-46"
                        d="M1398.58,571.64c1.24,0,2.26-.93,2.26-2.07v-1.31c0-.15-.13-.27-.29-.27s-.29,.12-.29,.27v1.31c0,.85-.75,1.53-1.67,1.53s-1.67-.69-1.67-1.53v-.71c0-.15-.13-.27-.29-.27s-.29,.12-.29,.27v.71c0,1.14,1.01,2.07,2.26,2.07Z" />
                    <path class="cls-46"
                        d="M1404.18,573.57c.97,0,1.76-.72,1.76-1.61v-1.27c0-.15-.13-.27-.29-.27s-.29,.12-.29,.27v1.27c0,.59-.52,1.07-1.17,1.07-.42,0-.81-.21-1.02-.55-.08-.13-.26-.18-.4-.1-.14,.07-.19,.24-.11,.37,.31,.51,.9,.82,1.53,.82Z" />
                    <path class="cls-46"
                        d="M1387.92,572.04c1.24,0,2.26-.93,2.26-2.07v-1.71c0-.15-.13-.27-.29-.27s-.29,.12-.29,.27v1.71c0,.85-.75,1.53-1.67,1.53s-1.67-.69-1.67-1.53v-.71c0-.15-.13-.27-.29-.27s-.29,.12-.29,.27v.71c0,1.14,1.01,2.07,2.26,2.07Z" />
                    <g>
                        <path class="cls-58"
                            d="M1387.38,581.66c0,.73,.65,1.33,1.45,1.33h3.14c.8,0,1.45-.6,1.45-1.33h0c0-.73-.65-1.33-1.45-1.33h-3.14c-.8,0-1.45,.6-1.45,1.33h0Z" />
                        <path class="cls-134"
                            d="M1388.55,580.17c0,.64,.57,1.16,1.26,1.16h2.03c.7,0,1.26-.52,1.26-1.16h0c0-.64-.57-1.16-1.26-1.16h-2.03c-.7,0-1.26,.52-1.26,1.16h0Z" />
                        <path class="cls-137"
                            d="M1390.23,579.22c0,.39,.35,.71,.78,.71h1.25c.43,0,.78-.32,.78-.71h0c0-.39-.35-.71-.78-.71h-1.25c-.43,0-.78,.32-.78,.71h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1386.09,573.93c1.21,0,2.2-.91,2.2-2.02v-.79c0-.19-.16-.34-.37-.34s-.37,.15-.37,.34v.79c0,.74-.66,1.34-1.46,1.34s-1.46-.6-1.46-1.34v-1.9c0-.19-.16-.34-.37-.34s-.37,.15-.37,.34v1.9c0,1.11,.99,2.02,2.2,2.02Z" />
                    <g>
                        <path class="cls-58"
                            d="M1389.63,574.87c0,1.02,.9,1.85,2.01,1.85h4.36c1.11,0,2.01-.83,2.01-1.85h0c0-1.02-.9-1.85-2.01-1.85h-4.36c-1.11,0-2.01,.83-2.01,1.85h0Z" />
                        <path class="cls-134"
                            d="M1391.26,572.8c0,.89,.78,1.61,1.75,1.61h2.82c.97,0,1.75-.72,1.75-1.61h0c0-.89-.78-1.61-1.75-1.61h-2.82c-.97,0-1.75,.72-1.75,1.61h0Z" />
                        <path class="cls-137"
                            d="M1393.58,571.48c0,.55,.48,.99,1.08,.99h1.73c.6,0,1.08-.44,1.08-.99h0c0-.55-.48-.99-1.08-.99h-1.73c-.59,0-1.08,.44-1.08,.99h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1385.08,579.18c0,.65,.57,1.17,1.28,1.17h2.77c.7,0,1.28-.53,1.28-1.17h0c0-.65-.57-1.17-1.28-1.17h-2.77c-.7,0-1.28,.53-1.28,1.17h0Z" />
                        <path class="cls-134"
                            d="M1386.11,577.87c0,.56,.5,1.02,1.11,1.02h1.79c.61,0,1.11-.46,1.11-1.02h0c0-.56-.5-1.02-1.11-1.02h-1.79c-.61,0-1.11,.46-1.11,1.02h0Z" />
                        <path class="cls-137"
                            d="M1387.58,577.03c0,.35,.31,.63,.68,.63h1.1c.38,0,.68-.28,.68-.63h0c0-.35-.31-.63-.68-.63h-1.1c-.38,0-.68,.28-.68,.63h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1402.9,574.73c0,.45,.39,.81,.88,.81h1.91c.49,0,.88-.36,.88-.81h0c0-.45-.39-.81-.88-.81h-1.91c-.49,0-.88,.36-.88,.81h0Z" />
                        <path class="cls-134"
                            d="M1403.62,573.82c0,.39,.34,.71,.77,.71h1.23c.42,0,.77-.32,.77-.71h0c0-.39-.34-.71-.77-.71h-1.23c-.42,0-.77,.32-.77,.71h0Z" />
                        <path class="cls-137"
                            d="M1404.63,573.24c0,.24,.21,.43,.47,.43h.76c.26,0,.47-.19,.47-.43h0c0-.24-.21-.43-.47-.43h-.76c-.26,0-.47,.19-.47,.43h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1393.47,582.63c0,.54,.47,.97,1.06,.97h2.3c.58,0,1.06-.43,1.06-.97h0c0-.54-.47-.97-1.06-.97h-2.3c-.58,0-1.06,.44-1.06,.97h0Z" />
                        <path class="cls-134"
                            d="M1394.33,581.54c0,.47,.41,.85,.92,.85h1.48c.51,0,.92-.38,.92-.85h0c0-.47-.41-.85-.92-.85h-1.48c-.51,0-.92,.38-.92,.85h0Z" />
                        <path class="cls-137"
                            d="M1395.55,580.85c0,.29,.25,.52,.57,.52h.91c.31,0,.57-.23,.57-.52h0c0-.29-.25-.52-.57-.52h-.91c-.31,0-.57,.23-.57,.52h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1397.07,578.32c0,.93,.82,1.68,1.82,1.68h3.96c1.01,0,1.82-.75,1.82-1.68h0c0-.93-.82-1.68-1.82-1.68h-3.96c-1.01,0-1.82,.75-1.82,1.68h0Z" />
                        <path class="cls-134"
                            d="M1398.55,576.45c0,.81,.71,1.46,1.59,1.46h2.55c.88,0,1.59-.65,1.59-1.46h0c0-.81-.71-1.46-1.59-1.46h-2.55c-.88,0-1.59,.65-1.59,1.46h0Z" />
                        <path class="cls-137"
                            d="M1400.65,575.25c0,.5,.44,.9,.98,.9h1.57c.54,0,.98-.4,.98-.9h0c0-.5-.44-.9-.98-.9h-1.57c-.54,0-.98,.4-.98,.9h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1397.72,572.93c0,.45,.39,.81,.88,.81h1.91c.49,0,.88-.36,.88-.81h0c0-.45-.39-.81-.88-.81h-1.91c-.49,0-.88,.36-.88,.81h0Z" />
                        <path class="cls-134"
                            d="M1398.43,572.03c0,.39,.34,.71,.77,.71h1.23c.42,0,.77-.32,.77-.71h0c0-.39-.34-.71-.77-.71h-1.23c-.42,0-.77,.32-.77,.71h0Z" />
                        <path class="cls-137"
                            d="M1399.45,571.45c0,.24,.21,.43,.47,.43h.76c.26,0,.47-.19,.47-.43h0c0-.24-.21-.43-.47-.43h-.76c-.26,0-.47,.19-.47,.43h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1386.36,568.96c0,.93,.82,1.68,1.82,1.68h3.96c1.01,0,1.82-.75,1.82-1.68h0c0-.93-.82-1.68-1.82-1.68h-3.96c-1.01,0-1.82,.75-1.82,1.68h0Z" />
                        <path class="cls-134"
                            d="M1387.84,567.09c0,.81,.71,1.46,1.59,1.46h2.55c.88,0,1.59-.65,1.59-1.46h0c0-.81-.71-1.46-1.59-1.46h-2.55c-.88,0-1.59,.65-1.59,1.46h0Z" />
                        <path class="cls-137"
                            d="M1389.95,565.89c0,.5,.44,.9,.98,.9h1.57c.54,0,.98-.4,.98-.9h0c0-.5-.44-.9-.98-.9h-1.57c-.54,0-.98,.4-.98,.9h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1380.48,570.91c0,.86,.76,1.56,1.7,1.56h3.68c.94,0,1.7-.7,1.7-1.56h0c0-.86-.76-1.56-1.7-1.56h-3.68c-.94,0-1.7,.7-1.7,1.56h0Z" />
                        <path class="cls-134"
                            d="M1381.85,569.17c0,.75,.66,1.36,1.48,1.36h2.38c.82,0,1.48-.61,1.48-1.36h0c0-.75-.66-1.36-1.48-1.36h-2.38c-.82,0-1.48,.61-1.48,1.36h0Z" />
                        <path class="cls-137"
                            d="M1383.81,568.05c0,.46,.41,.84,.91,.84h1.46c.5,0,.91-.37,.91-.84h0c0-.46-.41-.84-.91-.84h-1.46c-.5,0-.91,.37-.91,.84h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1394.57,568.05c0,.93,.82,1.68,1.82,1.68h3.96c1.01,0,1.82-.75,1.82-1.68h0c0-.93-.82-1.68-1.82-1.68h-3.96c-1.01,0-1.82,.75-1.82,1.68h0Z" />
                        <path class="cls-134"
                            d="M1396.04,566.17c0,.81,.71,1.46,1.59,1.46h2.55c.88,0,1.59-.65,1.59-1.46h0c0-.81-.71-1.46-1.59-1.46h-2.55c-.88,0-1.59,.65-1.59,1.46h0Z" />
                        <path class="cls-137"
                            d="M1398.15,564.97c0,.5,.44,.9,.98,.9h1.57c.54,0,.98-.4,.98-.9h0c0-.5-.44-.9-.98-.9h-1.57c-.54,0-.98,.4-.98,.9h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1360.22,563.02c0,1.25,1.11,2.27,2.47,2.27h5.36c1.36,0,2.47-1.02,2.47-2.27h0c0-1.25-1.11-2.27-2.47-2.27h-5.36c-1.36,0-2.47,1.02-2.47,2.27h0Z" />
                        <path class="cls-134"
                            d="M1362.22,560.48c0,1.09,.96,1.98,2.15,1.98h3.46c1.19,0,2.15-.89,2.15-1.98h0c0-1.09-.96-1.98-2.15-1.98h-3.46c-1.19,0-2.15,.89-2.15,1.98h0Z" />
                        <path class="cls-137"
                            d="M1365.07,558.86c0,.67,.59,1.22,1.32,1.22h2.13c.73,0,1.32-.54,1.32-1.22h0c0-.67-.59-1.22-1.32-1.22h-2.13c-.73,0-1.32,.54-1.32,1.22h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1372.73,566.84c0,1.25,1.11,2.27,2.47,2.27h5.36c1.36,0,2.47-1.02,2.47-2.27h0c0-1.25-1.11-2.27-2.47-2.27h-5.36c-1.36,0-2.47,1.02-2.47,2.27h0Z" />
                        <path class="cls-134"
                            d="M1374.73,564.31c0,1.09,.96,1.98,2.15,1.98h3.46c1.19,0,2.15-.89,2.15-1.98h0c0-1.09-.96-1.98-2.15-1.98h-3.46c-1.19,0-2.15,.89-2.15,1.98h0Z" />
                        <path class="cls-137"
                            d="M1377.58,562.68c0,.67,.59,1.22,1.32,1.22h2.13c.73,0,1.32-.54,1.32-1.22h0c0-.67-.59-1.22-1.32-1.22h-2.13c-.73,0-1.32,.54-1.32,1.22h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1363.98,578.8c0,.93,.82,1.69,1.83,1.69h3.98c1.01,0,1.83-.75,1.83-1.69h0c0-.93-.82-1.69-1.83-1.69h-3.98c-1.01,0-1.83,.75-1.83,1.69h0Z" />
                        <path class="cls-134"
                            d="M1365.47,576.91c0,.81,.72,1.47,1.6,1.47h2.57c.88,0,1.6-.66,1.6-1.47h0c0-.81-.72-1.47-1.6-1.47h-2.57c-.88,0-1.6,.66-1.6,1.47h0Z" />
                        <path class="cls-137"
                            d="M1367.58,575.71c0,.5,.44,.9,.98,.9h1.58c.54,0,.98-.4,.98-.9h0c0-.5-.44-.9-.98-.9h-1.58c-.54,0-.98,.4-.98,.9h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1356.5,570.29c0,.74,.65,1.34,1.46,1.34h3.18c.81,0,1.46-.6,1.46-1.34h0c0-.74-.65-1.34-1.46-1.34h-3.18c-.81,0-1.46,.6-1.46,1.34h0Z" />
                        <path class="cls-134"
                            d="M1357.68,568.79c0,.65,.57,1.17,1.28,1.17h2.05c.7,0,1.28-.52,1.28-1.17h0c0-.65-.57-1.17-1.28-1.17h-2.05c-.7,0-1.28,.52-1.28,1.17h0Z" />
                        <path class="cls-137"
                            d="M1359.37,567.83c0,.4,.35,.72,.78,.72h1.26c.43,0,.78-.32,.78-.72h0c0-.4-.35-.72-.78-.72h-1.26c-.43,0-.78,.32-.78,.72h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1364.74,588.46h1.46v-24.74c0-.37-.32-.67-.72-.67h-.01c-.4,0-.72,.3-.72,.67v24.74Z" />
                    <path class="cls-46"
                        d="M1365.47,583.32c3.66,0,6.63-2.73,6.63-6.09v-2.48c0-.45-.4-.81-.88-.81s-.88,.36-.88,.81v2.48c0,2.46-2.18,4.47-4.86,4.47s-4.86-2-4.86-4.47v-5.97c0-.45-.4-.81-.88-.81s-.88,.36-.88,.81v5.97c0,3.36,2.97,6.09,6.63,6.09Z" />
                    <path class="cls-46"
                        d="M1359.82,576.05c2.2,0,3.99-1.65,3.99-3.67v-1.45c0-.31-.28-.57-.62-.57s-.62,.25-.62,.57v1.45c0,1.4-1.24,2.53-2.75,2.53s-2.75-1.14-2.75-2.53v-3.51c0-.31-.28-.57-.62-.57s-.62,.25-.62,.57v3.51c0,2.02,1.79,3.67,3.99,3.67Z" />
                    <path class="cls-46"
                        d="M1371.21,580.52c2.2,0,3.99-1.65,3.99-3.67v-8.23c0-.31-.28-.57-.62-.57s-.62,.25-.62,.57v8.23c0,1.4-1.24,2.53-2.75,2.53s-2.75-1.14-2.75-2.53v-3.51c0-.31-.28-.57-.62-.57s-.62,.25-.62,.57v3.51c0,2.02,1.79,3.67,3.99,3.67Z" />
                    <path class="cls-46"
                        d="M1374.86,574.25c2.2,0,3.99-1.65,3.99-3.67v-2.93c0-.31-.28-.57-.62-.57s-.62,.25-.62,.57v2.93c0,1.4-1.24,2.53-2.75,2.53s-2.75-1.14-2.75-2.53v-3.51c0-.31-.28-.57-.62-.57s-.62,.25-.62,.57v3.51c0,2.02,1.79,3.67,3.99,3.67Z" />
                    <path class="cls-46"
                        d="M1371.48,568.2c1.5,0,2.72-1.12,2.72-2.5v-1.57c0-.18-.16-.32-.35-.32s-.35,.15-.35,.32v1.57c0,1.02-.9,1.85-2.01,1.85s-2.01-.83-2.01-1.85v-.85c0-.18-.16-.33-.35-.33s-.35,.15-.35,.33v.85c0,1.38,1.22,2.5,2.72,2.5Z" />
                    <path class="cls-46"
                        d="M1378.23,570.53c1.17,0,2.12-.87,2.12-1.94v-1.53c0-.18-.16-.32-.35-.32s-.35,.15-.35,.32v1.53c0,.71-.63,1.29-1.41,1.29-.51,0-.98-.25-1.23-.66-.1-.16-.31-.21-.48-.12-.17,.09-.23,.29-.13,.44,.37,.61,1.08,.99,1.84,.99Z" />
                    <path class="cls-46"
                        d="M1358.65,568.68c1.5,0,2.72-1.12,2.72-2.5v-2.06c0-.18-.16-.32-.35-.32s-.35,.15-.35,.32v2.06c0,1.02-.9,1.85-2.01,1.85s-2.01-.83-2.01-1.85v-.85c0-.18-.16-.33-.35-.33s-.35,.15-.35,.33v.85c0,1.38,1.22,2.5,2.72,2.5Z" />
                    <g>
                        <path class="cls-58"
                            d="M1358,580.27c0,.88,.78,1.6,1.74,1.6h3.78c.96,0,1.74-.72,1.74-1.6h0c0-.88-.78-1.6-1.74-1.6h-3.78c-.96,0-1.74,.72-1.74,1.6h0Z" />
                        <path class="cls-134"
                            d="M1359.41,578.48c0,.77,.68,1.4,1.52,1.4h2.44c.84,0,1.52-.63,1.52-1.4h0c0-.77-.68-1.4-1.52-1.4h-2.44c-.84,0-1.52,.63-1.52,1.4h0Z" />
                        <path class="cls-137"
                            d="M1361.43,577.33c0,.47,.42,.86,.93,.86h1.5c.52,0,.93-.38,.93-.86h0c0-.47-.42-.86-.93-.86h-1.5c-.52,0-.93,.38-.93,.86h0Z" />
                    </g>
                    <path class="cls-46"
                        d="M1356.45,570.96c1.46,0,2.64-1.09,2.64-2.43v-.95c0-.22-.2-.41-.44-.41s-.44,.18-.44,.41v.95c0,.89-.79,1.62-1.76,1.62s-1.76-.73-1.76-1.62v-2.29c0-.22-.2-.41-.44-.41s-.44,.18-.44,.41v2.29c0,1.34,1.19,2.43,2.65,2.43Z" />
                    <g>
                        <path class="cls-58"
                            d="M1360.71,572.09c0,1.23,1.08,2.23,2.42,2.23h5.25c1.34,0,2.42-1,2.42-2.23h0c0-1.23-1.08-2.22-2.42-2.22h-5.25c-1.34,0-2.42,1-2.42,2.22h0Z" />
                        <path class="cls-134"
                            d="M1362.67,569.6c0,1.07,.95,1.94,2.11,1.94h3.39c1.17,0,2.11-.87,2.11-1.94h0c0-1.07-.95-1.94-2.11-1.94h-3.39c-1.17,0-2.11,.87-2.11,1.94h0Z" />
                        <path class="cls-137"
                            d="M1365.46,568.01c0,.66,.58,1.19,1.3,1.19h2.09c.72,0,1.3-.53,1.3-1.19h0c0-.66-.58-1.19-1.3-1.19h-2.09c-.72,0-1.3,.53-1.3,1.19h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1355.23,577.29c0,.78,.69,1.41,1.54,1.41h3.34c.85,0,1.54-.63,1.54-1.41h0c0-.78-.69-1.41-1.54-1.41h-3.34c-.85,0-1.54,.63-1.54,1.41h0Z" />
                        <path class="cls-134"
                            d="M1356.47,575.71c0,.68,.6,1.23,1.34,1.23h2.15c.74,0,1.34-.55,1.34-1.23h0c0-.68-.6-1.23-1.34-1.23h-2.15c-.74,0-1.34,.55-1.34,1.23h0Z" />
                        <path class="cls-137"
                            d="M1358.25,574.7c0,.42,.37,.76,.82,.76h1.32c.45,0,.82-.34,.82-.76h0c0-.42-.37-.76-.82-.76h-1.32c-.45,0-.82,.34-.82,.76h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1376.69,571.92c0,.54,.48,.98,1.06,.98h2.3c.59,0,1.06-.44,1.06-.98h0c0-.54-.48-.97-1.06-.97h-2.3c-.59,0-1.06,.44-1.06,.97h0Z" />
                        <path class="cls-134"
                            d="M1377.55,570.83c0,.47,.41,.85,.92,.85h1.49c.51,0,.92-.38,.92-.85h0c0-.47-.41-.85-.92-.85h-1.49c-.51,0-.92,.38-.92,.85h0Z" />
                        <path class="cls-137"
                            d="M1378.78,570.13c0,.29,.25,.52,.57,.52h.91c.31,0,.57-.23,.57-.52h0c0-.29-.25-.52-.57-.52h-.91c-.31,0-.57,.23-.57,.52h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1365.34,581.44c0,.65,.57,1.17,1.27,1.17h2.76c.7,0,1.27-.52,1.27-1.17h0c0-.65-.57-1.17-1.27-1.17h-2.76c-.7,0-1.27,.52-1.27,1.17h0Z" />
                        <path class="cls-134"
                            d="M1366.37,580.13c0,.56,.5,1.02,1.11,1.02h1.78c.61,0,1.11-.46,1.11-1.02h0c0-.56-.5-1.02-1.11-1.02h-1.78c-.61,0-1.11,.46-1.11,1.02h0Z" />
                        <path class="cls-137"
                            d="M1367.84,579.29c0,.35,.31,.63,.68,.63h1.1c.38,0,.68-.28,.68-.63h0c0-.35-.31-.63-.68-.63h-1.1c-.38,0-.68,.28-.68,.63h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1369.67,576.25c0,1.11,.98,2.02,2.2,2.02h4.77c1.21,0,2.2-.9,2.2-2.02h0c0-1.11-.98-2.02-2.2-2.02h-4.77c-1.21,0-2.2,.9-2.2,2.02h0Z" />
                        <path class="cls-134"
                            d="M1371.45,574c0,.97,.86,1.76,1.91,1.76h3.08c1.06,0,1.91-.79,1.91-1.76h0c0-.97-.86-1.76-1.91-1.76h-3.08c-1.06,0-1.91,.79-1.91,1.76h0Z" />
                        <path class="cls-137"
                            d="M1373.98,572.55c0,.6,.53,1.08,1.18,1.08h1.89c.65,0,1.18-.48,1.18-1.08h0c0-.6-.53-1.08-1.18-1.08h-1.89c-.65,0-1.18,.48-1.18,1.08h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1370.45,569.76c0,.54,.47,.97,1.06,.97h2.3c.59,0,1.06-.44,1.06-.97h0c0-.54-.48-.98-1.06-.98h-2.3c-.59,0-1.06,.44-1.06,.98h0Z" />
                        <path class="cls-134"
                            d="M1371.31,568.67c0,.47,.41,.85,.92,.85h1.49c.51,0,.92-.38,.92-.85h0c0-.47-.41-.85-.92-.85h-1.49c-.51,0-.92,.38-.92,.85h0Z" />
                        <path class="cls-137"
                            d="M1372.53,567.97c0,.29,.25,.52,.57,.52h.91c.31,0,.57-.23,.57-.52h0c0-.29-.25-.52-.57-.52h-.91c-.31,0-.57,.23-.57,.52h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1356.78,564.97c0,1.11,.98,2.02,2.2,2.02h4.77c1.21,0,2.2-.9,2.2-2.02h0c0-1.11-.98-2.02-2.2-2.02h-4.77c-1.21,0-2.2,.9-2.2,2.02h0Z" />
                        <path class="cls-134"
                            d="M1358.56,562.72c0,.97,.86,1.76,1.91,1.76h3.08c1.06,0,1.91-.79,1.91-1.76h0c0-.97-.86-1.76-1.91-1.76h-3.08c-1.06,0-1.91,.79-1.91,1.76h0Z" />
                        <path class="cls-137"
                            d="M1361.09,561.27c0,.6,.53,1.08,1.18,1.08h1.89c.65,0,1.18-.48,1.18-1.08h0c0-.6-.53-1.08-1.18-1.08h-1.89c-.65,0-1.18,.48-1.18,1.08h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1349.69,567.33c0,1.04,.91,1.88,2.04,1.88h4.43c1.13,0,2.04-.84,2.04-1.88h0c0-1.04-.91-1.88-2.04-1.88h-4.43c-1.13,0-2.04,.84-2.04,1.88h0Z" />
                        <path class="cls-134"
                            d="M1351.35,565.23c0,.9,.8,1.64,1.78,1.64h2.86c.98,0,1.78-.73,1.78-1.64h0c0-.9-.8-1.64-1.78-1.64h-2.86c-.98,0-1.78,.73-1.78,1.64h0Z" />
                        <path class="cls-137"
                            d="M1353.7,563.88c0,.56,.49,1.01,1.09,1.01h1.76c.6,0,1.09-.45,1.09-1.01h0c0-.56-.49-1.01-1.09-1.01h-1.76c-.6,0-1.09,.45-1.09,1.01h0Z" />
                    </g>
                    <g>
                        <path class="cls-58"
                            d="M1366.65,563.87c0,1.11,.98,2.02,2.2,2.02h4.77c1.21,0,2.2-.9,2.2-2.02h0c0-1.11-.98-2.02-2.2-2.02h-4.77c-1.21,0-2.2,.9-2.2,2.02h0Z" />
                        <path class="cls-134"
                            d="M1368.43,561.62c0,.97,.86,1.76,1.91,1.76h3.08c1.06,0,1.91-.79,1.91-1.76h0c0-.97-.86-1.76-1.91-1.76h-3.08c-1.06,0-1.91,.79-1.91,1.76h0Z" />
                        <path class="cls-137"
                            d="M1370.97,560.18c0,.6,.53,1.08,1.18,1.08h1.89c.65,0,1.18-.48,1.18-1.08h0c0-.6-.53-1.08-1.18-1.08h-1.89c-.65,0-1.18,.48-1.18,1.08h0Z" />
                    </g>
                </g>
                <g id="dijk2">
                    <g>
                        <polygon class="cls-46"
                            points="1416.73 584.9 1416.33 584.9 1416.33 586.2 1416.73 586.13 1416.73 584.9" />
                        <polygon class="cls-46"
                            points="1408.94 584.9 1409.34 584.9 1409.34 586.2 1408.94 586.13 1408.94 584.9" />
                        <path class="cls-59"
                            d="M1416.4,584.18v3.06c0,.23-.2,.42-.45,.42h-6.22c-.25,0-.45-.19-.45-.42v-3.06h7.13Z" />
                        <rect class="cls-98" x="1415.27" y="584.39" width=".55" height=".33" />
                        <g>
                            <rect class="cls-48" x="1415.44" y="584.48" width=".28" height=".04" />
                            <rect class="cls-48" x="1415.58" y="584.59" width=".14" height=".04" />
                        </g>
                        <rect class="cls-46" x="1409.27" y="584.18" width="7.13" height=".16" />
                        <rect class="cls-97" x="1411.91" y="585.48" width="1.85" height="1.22" />
                        <rect class="cls-98" x="1409.12" y="583.81" width="7.43" height=".37" rx=".18" ry=".18" />
                        <rect class="cls-105" x="1409.27" y="583.51" width="7.13" height=".3" />
                        <polygon class="cls-48"
                            points="1409.94 582.5 1415.73 582.5 1416.4 583.51 1409.27 583.51 1409.94 582.5" />
                        <path class="cls-98"
                            d="M1409.86,584.81h5.95c.09,0,.16,.07,.16,.16h0c0,.09-.07,.16-.16,.16h-5.95c-.09,0-.16-.07-.16-.16h0c0-.09,.07-.16,.16-.16Z" />
                        <rect class="cls-46" x="1409.86" y="585.13" width="5.95" height=".23" />
                        <path class="cls-50" d="M1415.47,582.5v.55c0,.11-.09,.2-.21,.2s-.21-.09-.21-.2v-.55h.42Z" />
                        <path class="cls-50" d="M1414.5,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1413.53,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1412.55,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1411.58,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1410.61,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <g>
                            <g>
                                <rect class="cls-105" x="1410.19" y="587.65" width=".42" height=".35" />
                                <rect class="cls-54" x="1410.19" y="587.65" width=".42" height=".12" />
                                <ellipse class="cls-48" cx="1410.4" cy="588.21" rx=".39" ry=".36" />
                                <path class="cls-105"
                                    d="M1410.24,588.21c0,.08,.07,.15,.16,.15s.16-.07,.16-.15-.07-.15-.16-.15-.16,.07-.16,.15Z" />
                            </g>
                            <g>
                                <rect class="cls-105" x="1415.05" y="587.65" width=".42" height=".35" />
                                <rect class="cls-54" x="1415.05" y="587.65" width=".42" height=".12" />
                                <path class="cls-48"
                                    d="M1414.88,588.21c0,.2,.17,.36,.39,.36s.39-.16,.39-.36-.17-.36-.39-.36-.39,.16-.39,.36Z" />
                                <ellipse class="cls-105" cx="1415.26" cy="588.21" rx=".16" ry=".15" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <polygon class="cls-46"
                            points="1428.4 584.9 1428 584.9 1428 586.2 1428.4 586.13 1428.4 584.9" />
                        <polygon class="cls-46"
                            points="1420.61 584.9 1421.01 584.9 1421.01 586.2 1420.61 586.13 1420.61 584.9" />
                        <path class="cls-59"
                            d="M1428.07,584.18v3.06c0,.23-.2,.42-.45,.42h-6.22c-.25,0-.45-.19-.45-.42v-3.06h7.13Z" />
                        <rect class="cls-98" x="1426.93" y="584.39" width=".55" height=".33" />
                        <g>
                            <rect class="cls-48" x="1427.11" y="584.48" width=".28" height=".04" />
                            <rect class="cls-48" x="1427.25" y="584.59" width=".14" height=".04" />
                        </g>
                        <rect class="cls-46" x="1420.94" y="584.18" width="7.13" height=".16" />
                        <rect class="cls-97" x="1423.58" y="585.48" width="1.85" height="1.22" />
                        <rect class="cls-98" x="1420.79" y="583.81" width="7.43" height=".37" rx=".18" ry=".18" />
                        <rect class="cls-105" x="1420.94" y="583.51" width="7.13" height=".3" />
                        <polygon class="cls-48"
                            points="1421.61 582.5 1427.39 582.5 1428.07 583.51 1420.94 583.51 1421.61 582.5" />
                        <path class="cls-98"
                            d="M1421.53,584.81h5.95c.09,0,.16,.07,.16,.16h0c0,.09-.07,.16-.16,.16h-5.95c-.09,0-.16-.07-.16-.16h0c0-.09,.07-.16,.16-.16Z" />
                        <rect class="cls-46" x="1421.53" y="585.13" width="5.95" height=".23" />
                        <path class="cls-50" d="M1427.14,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1426.17,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1425.2,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1424.22,582.5v.55c0,.11-.09,.2-.21,.2s-.21-.09-.21-.2v-.55h.42Z" />
                        <path class="cls-50" d="M1423.25,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <path class="cls-50" d="M1422.28,582.5v.55c0,.11-.1,.2-.21,.2s-.21-.09-.21-.2v-.55h.43Z" />
                        <g>
                            <g>
                                <rect class="cls-105" x="1421.86" y="587.65" width=".42" height=".35" />
                                <rect class="cls-54" x="1421.86" y="587.65" width=".42" height=".12" />
                                <path class="cls-48"
                                    d="M1421.68,588.21c0,.2,.17,.36,.39,.36s.39-.16,.39-.36-.17-.36-.39-.36-.39,.16-.39,.36Z" />
                                <path class="cls-105"
                                    d="M1421.91,588.21c0,.08,.07,.15,.16,.15s.16-.07,.16-.15-.07-.15-.16-.15-.16,.07-.16,.15Z" />
                            </g>
                            <g>
                                <rect class="cls-105" x="1426.72" y="587.65" width=".42" height=".35" />
                                <rect class="cls-54" x="1426.72" y="587.65" width=".42" height=".12" />
                                <ellipse class="cls-48" cx="1426.93" cy="588.21" rx=".39" ry=".36" />
                                <ellipse class="cls-105" cx="1426.93" cy="588.21" rx=".16" ry=".15" />
                            </g>
                        </g>
                    </g>
                    <path class="cls-48"
                        d="M1561.3,588.45h-170.36c-.94,0-1.7-.38-1.7-.85v-2.05c0-.47,.76-.85,1.7-.85h170.36c.94,0,1.7,.38,1.7,.85v2.05c0,.47-.76,.85-1.7,.85Z" />
                    <g>
                        <polygon class="cls-98"
                            points="1502.21 588.45 1507.73 584.7 1502.98 584.7 1497.46 588.45 1502.21 588.45" />
                        <polygon class="cls-98"
                            points="1489.03 588.45 1494.55 584.7 1489.8 584.7 1484.28 588.45 1489.03 588.45" />
                        <polygon class="cls-98"
                            points="1475.85 588.45 1481.37 584.7 1476.62 584.7 1471.1 588.45 1475.85 588.45" />
                        <polygon class="cls-98"
                            points="1462.67 588.45 1468.19 584.7 1463.44 584.7 1457.92 588.45 1462.67 588.45" />
                        <polygon class="cls-98"
                            points="1449.5 588.45 1455.01 584.7 1450.26 584.7 1444.75 588.45 1449.5 588.45" />
                        <polygon class="cls-98"
                            points="1554.92 588.45 1560.44 584.7 1555.69 584.7 1550.17 588.45 1554.92 588.45" />
                        <polygon class="cls-98"
                            points="1541.75 588.45 1547.26 584.7 1542.51 584.7 1537 588.45 1541.75 588.45" />
                        <polygon class="cls-98"
                            points="1528.57 588.45 1534.08 584.7 1529.33 584.7 1523.82 588.45 1528.57 588.45" />
                        <polygon class="cls-98"
                            points="1515.39 588.45 1520.9 584.7 1516.15 584.7 1510.64 588.45 1515.39 588.45" />
                        <polygon class="cls-98"
                            points="1436.32 588.45 1441.83 584.7 1437.08 584.7 1431.57 588.45 1436.32 588.45" />
                        <polygon class="cls-98"
                            points="1423.14 588.45 1428.65 584.7 1423.9 584.7 1418.39 588.45 1423.14 588.45" />
                        <polygon class="cls-98"
                            points="1410.4 588.45 1415.91 584.7 1411.16 584.7 1405.65 588.45 1410.4 588.45" />
                        <polygon class="cls-98"
                            points="1397.22 588.45 1402.73 584.7 1397.98 584.7 1392.47 588.45 1397.22 588.45" />
                    </g>
                </g>
                <g id="kraan1">
                    <polygon class="cls-105"
                        points="924.22 508.17 898.25 545.66 905.61 545.7 931.58 508.22 924.22 508.17" />
                    <polygon class="cls-105"
                        points="949.31 508.17 975.27 545.66 967.91 545.7 941.95 508.22 949.31 508.17" />
                    <rect class="cls-116" x="898.83" y="506.25" width="6.47" height="39.45" />
                    <rect class="cls-50" x="898.83" y="506.25" width="6.47" height="2.77" />
                    <rect class="cls-140" x="893" y="502.76" width="89.1" height="3.49" />
                    <path class="cls-98"
                        d="M919.96,493.57v35.74c0,6.71,7.35,12.14,16.41,12.14s16.41-5.44,16.41-12.14v-35.74h-32.81Z" />
                    <path class="cls-25"
                        d="M927.99,517.47c0,.77-.84,1.39-1.88,1.39h0c-1.04,0-1.88-.62-1.88-1.39v-12.85c0-.77,.84-1.39,1.88-1.39h0c1.04,0,1.88,.62,1.88,1.39v12.85Z" />
                    <path class="cls-25"
                        d="M927.99,528.71c0,.77-.84,1.39-1.88,1.39h0c-1.04,0-1.88-.62-1.88-1.39v-4.73c0-.77,.84-1.39,1.88-1.39h0c1.04,0,1.88,.62,1.88,1.39v4.73Z" />
                    <path class="cls-38" d="M936.56,493.57v47.87c8.97-.07,16.22-5.48,16.22-12.13v-35.74h-16.22Z" />
                    <rect class="cls-55" x="919.96" y="494.23" width="32.81" height="3.82" />
                    <rect class="cls-55" x="919.96" y="504.34" width="32.81" height="3.82" />
                    <rect class="cls-98" x="905.99" y="487.57" width="60.77" height="5.58" />
                    <rect class="cls-99" x="905.99" y="487.57" width="60.77" height="1.55" />
                    <rect class="cls-140" x="896.43" y="493.15" width="79.87" height="2.98" />
                    <rect class="cls-140" x="897.57" y="496.13" width="6.62" height="3.82" />
                    <rect class="cls-116" x="899.13" y="499.95" width="3.48" height="2.8" />
                    <rect class="cls-50" x="899.13" y="499.95" width="3.48" height="1.4" />
                    <rect class="cls-105" x="897.3" y="545.7" width="77.98" height="5.41" />
                    <polygon class="cls-105"
                        points="923.52 588.76 897.99 551.11 905.35 551.11 930.88 588.76 923.52 588.76" />
                    <polygon class="cls-105"
                        points="992.97 588.76 967.44 551.11 974.8 551.11 1000.33 588.76 992.97 588.76" />
                    <g>
                        <rect class="cls-105" x="980.45" y="506.25" width="1.64" height="56.64" />
                        <rect class="cls-105" x="975.04" y="551.3" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="548" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="544.71" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="541.41" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="538.11" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="534.82" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="531.52" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="528.22" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="524.93" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="521.63" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="518.33" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="515.03" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="511.74" width="6.23" height="1.16" />
                        <rect class="cls-105" x="975.04" y="508.44" width="6.23" height="1.16" />
                    </g>
                    <polygon class="cls-52"
                        points="907.38 553.93 905.35 551.11 897.99 551.11 899.79 553.93 907.38 553.93" />
                    <polygon class="cls-52"
                        points="976.6 553.93 974.8 551.11 967.44 551.11 969.24 553.93 976.6 553.93" />
                    <polygon class="cls-116"
                        points="949.74 588.76 975.27 551.11 967.91 551.11 942.38 588.76 949.74 588.76" />
                    <polygon class="cls-116"
                        points="879.77 588.76 905.3 551.11 897.94 551.11 872.41 588.76 879.77 588.76" />
                    <polygon class="cls-52"
                        points="973.36 553.93 975.27 551.11 967.91 551.11 966 553.93 973.36 553.93" />
                    <polygon class="cls-52"
                        points="903.39 553.93 905.3 551.11 897.94 551.11 896.03 553.93 903.39 553.93" />
                    <rect class="cls-116" x="968.8" y="506.25" width="6.47" height="39.45" />
                    <rect class="cls-50" x="968.8" y="506.25" width="6.47" height="2.77" />
                    <rect class="cls-140" x="967.55" y="496.13" width="6.62" height="3.82" />
                    <rect class="cls-116" x="969.11" y="499.95" width="3.48" height="2.8" />
                    <rect class="cls-116" x="969.11" y="499.95" width="3.48" height="2.8" />
                    <rect class="cls-50" x="969.11" y="499.95" width="3.48" height="1.4" />
                    <path class="cls-140"
                        d="M989.33,477.77c0,5.41-5.93,9.8-13.24,9.8h-70.47c-7.31,0-13.24-4.39-13.24-9.8v-24.84c0-5.41,5.93-9.8,13.24-9.8h70.47c7.31,0,13.24,4.39,13.24,9.8v24.84Z" />
                    <path class="cls-140"
                        d="M976.09,443.13h-35.2v44.44h35.2c7.31,0,13.24-4.39,13.24-9.8v-24.84c0-5.41-5.93-9.8-13.24-9.8Z" />
                    <path class="cls-48"
                        d="M919.9,470.05c0,.8-.88,1.45-1.96,1.45h0c-1.08,0-1.96-.65-1.96-1.45v-18.21c0-.8,.88-1.45,1.96-1.45h0c1.08,0,1.96,.65,1.96,1.45v18.21Z" />
                    <path class="cls-48"
                        d="M927.55,470.05c0,.8-.88,1.45-1.96,1.45h0c-1.08,0-1.96-.65-1.96-1.45v-18.21c0-.8,.88-1.45,1.96-1.45h0c1.08,0,1.96,.65,1.96,1.45v18.21Z" />
                    <path class="cls-48"
                        d="M935.21,470.05c0,.8-.88,1.45-1.96,1.45h0c-1.08,0-1.96-.65-1.96-1.45v-18.21c0-.8,.88-1.45,1.96-1.45h0c1.08,0,1.96,.65,1.96,1.45v18.21Z" />
                    <path class="cls-140"
                        d="M902.44,465.31c0,.9-.99,1.63-2.21,1.63h0c-1.22,0-2.21-.73-2.21-1.63v-12.85c0-.9,.99-1.63,2.21-1.63h0c1.22,0,2.21,.73,2.21,1.63v12.85Z" />
                    <path class="cls-140"
                        d="M902.44,476.3c0,.9-.99,1.63-2.21,1.63h0c-1.22,0-2.21-.73-2.21-1.63v-4.73c0-.9,.99-1.63,2.21-1.63h0c1.22,0,2.21,.73,2.21,1.63v4.73Z" />
                    <rect class="cls-48" x="961.8" y="477.17" width="15.19" height="1.01" />
                    <rect class="cls-48" x="961.8" y="478.9" width="15.19" height="1.01" />
                    <rect class="cls-48" x="961.8" y="480.64" width="15.19" height="1.01" />
                    <rect class="cls-48" x="961.8" y="482.37" width="15.19" height="1.01" />
                    <path class="cls-48"
                        d="M965.04,305.48c0,2.7-2.96,4.89-6.6,4.89h-35.16c-3.65,0-6.6-2.19-6.6-4.89v-17.8c0-2.7,2.96-4.89,6.6-4.89h35.16c3.65,0,6.6,2.19,6.6,4.89v17.8Z" />
                    <path class="cls-27"
                        d="M958.44,282.78h-17.56v27.58h17.56c3.65,0,6.6-2.19,6.6-4.89v-17.8c0-2.7-2.96-4.89-6.6-4.89Z" />
                    <path class="cls-49"
                        d="M930.4,301.62c0,.4-.44,.72-.98,.72h0c-.54,0-.98-.32-.98-.72v-14.5c0-.4,.44-.72,.98-.72h0c.54,0,.98,.32,.98,.72v14.5Z" />
                    <path class="cls-49"
                        d="M934.22,301.62c0,.4-.44,.72-.98,.72h0c-.54,0-.98-.32-.98-.72v-14.5c0-.4,.44-.72,.98-.72h0c.54,0,.98,.32,.98,.72v14.5Z" />
                    <path class="cls-49"
                        d="M938.04,301.62c0,.4-.44,.72-.98,.72h0c-.54,0-.98-.32-.98-.72v-14.5c0-.4,.44-.72,.98-.72h0c.54,0,.98,.32,.98,.72v14.5Z" />
                    <path class="cls-24"
                        d="M921.69,299.26c0,.45-.49,.82-1.1,.82h0c-.61,0-1.1-.37-1.1-.82v-11.82c0-.45,.49-.82,1.1-.82h0c.61,0,1.1,.37,1.1,.82v11.82Z" />
                    <path class="cls-24"
                        d="M921.69,304.74c0,.45-.49,.82-1.1,.82h0c-.61,0-1.1-.37-1.1-.82v-2.36c0-.45,.49-.82,1.1-.82h0c.61,0,1.1,.37,1.1,.82v2.36Z" />
                    <rect class="cls-48" x="709.78" y="294.7" width="206.89" height="5.84" />
                    <polygon class="cls-48"
                        points="709.78 286.8 936.09 278.46 936.09 275.11 709.78 283.45 709.78 286.8" />
                    <path class="cls-48"
                        d="M949.98,436.49c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.99c13.7,6.71,14.06,7.27,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M949.98,420.77c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.98c13.7,6.71,14.06,7.27,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M949.98,405.05c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.99c13.7,6.71,14.06,7.26,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M949.98,389.32c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.98c13.7,6.71,14.06,7.27,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M949.98,373.6c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.99c13.7,6.71,14.06,7.26,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M949.98,357.87c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.99c13.7,6.71,14.06,7.27,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M949.98,342.15c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.99c13.7,6.71,14.06,7.27,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M889.04,294.76c2.04-1.65,5.26-4.41,10.54-9.1l8.8,9.1h5.82l-14.3-14.8s-15.69,13.82-17.19,14.8h6.34Z" />
                    <path class="cls-48"
                        d="M858.77,294.76c2.04-1.53,5.26-4.09,10.54-8.45l8.8,8.45h5.82l-14.3-13.74s-15.69,12.83-17.19,13.74h6.34Z" />
                    <path class="cls-48"
                        d="M829.27,294.76c2.04-1.43,5.26-3.81,10.54-7.86l8.8,7.86h5.82l-14.3-12.78s-15.69,11.94-17.19,12.78h6.34Z" />
                    <path class="cls-48"
                        d="M801.1,294.76c2.04-1.29,5.26-3.45,10.54-7.11l8.8,7.11h5.82l-14.3-11.56s-15.69,10.8-17.19,11.56h6.34Z" />
                    <path class="cls-48"
                        d="M773.38,294.76c2.04-1.18,5.26-3.14,10.54-6.48l8.8,6.48h5.82l-14.3-10.53s-15.69,9.84-17.19,10.53h6.34Z" />
                    <path class="cls-48"
                        d="M744.37,294.76c2.04-1.06,5.26-2.82,10.54-5.83l8.8,5.83h5.82l-14.3-9.48s-15.69,8.85-17.19,9.48h6.34Z" />
                    <path class="cls-48"
                        d="M717.41,294.76c2.04-1.01,5.26-2.69,10.54-5.54l8.8,5.54h5.82l-14.3-9.01s-15.69,8.42-17.19,9.01h6.34Z" />
                    <path class="cls-48"
                        d="M949.98,326.42c-.84-.63-18.22-9.23-18.22-9.23l19.4-8.17,1.81,2.35-14.21,5.98c13.7,6.71,14.06,7.27,14.3,7.64l-3.07,1.43Z" />
                    <path class="cls-48"
                        d="M949.81,282.78c-.51-.63-10.98-9.23-10.98-9.23l11.68-8.17,1.09,2.35-8.56,5.99c8.25,6.71,8.47,7.27,8.61,7.64l-1.85,1.43Z" />
                    <path class="cls-48"
                        d="M950.1,266.76c-.43-.63-9.26-9.23-9.26-9.23l9.86-8.17,.92,2.35-7.23,5.98c6.97,6.71,7.15,7.27,7.27,7.64l-1.56,1.43Z" />
                    <path class="cls-48"
                        d="M950.44,250.74c-.34-.63-7.26-9.23-7.26-9.23l7.73-8.17,.72,2.35-5.66,5.99c5.46,6.71,5.6,7.27,5.7,7.64l-1.22,1.43Z" />
                    <path class="cls-48"
                        d="M962.63,439.71c0,1.89-2.07,3.42-4.63,3.42h-34.3c-2.56,0-4.63-1.53-4.63-3.42h0c0-1.89,2.07-3.42,4.63-3.42h34.3c2.55,0,4.63,1.53,4.63,3.42h0Z" />
                    <rect class="cls-48" x="949.77" y="310.42" width="4.22" height="125.87" />
                    <rect class="cls-48" x="949.77" y="228.36" width="4.22" height="54.43" />
                    <polygon class="cls-48"
                        points="932.56 285.49 936.79 285.49 947.75 224.3 943.53 224.3 932.56 285.49" />
                    <rect class="cls-48" x="929.07" y="310.42" width="4.22" height="125.87" />
                    <path class="cls-48"
                        d="M959.21,396.42c0,1.59-1.75,2.89-3.9,2.89h-28.9c-2.15,0-3.9-1.29-3.9-2.89h0c0-1.59,1.75-2.88,3.9-2.88h28.9c2.15,0,3.9,1.29,3.9,2.88h0Z" />
                    <rect class="cls-48" x="709.78" y="283.45" width="5.18" height="17.1" />
                    <polygon class="cls-48"
                        points="723.86 285.05 723.22 283.78 939.84 224.74 940.47 226.01 723.86 285.05" />
                    <polygon class="cls-48"
                        points="867.6 279.22 866.32 278.26 939.52 224.89 940.8 225.86 867.6 279.22" />
                    <path class="cls-48"
                        d="M728.97,333.07c-5.54,0-10.05-3.34-10.05-7.44,0-.75,.82-1.35,1.83-1.35s1.83,.6,1.83,1.35c0,2.61,2.87,4.73,6.4,4.73s6.4-2.12,6.4-4.73-2.87-4.73-6.4-4.73h-1.83v-8.45c0-.75,.82-1.35,1.83-1.35s1.83,.61,1.83,1.35v5.87c4.67,.64,8.22,3.68,8.22,7.32,0,4.1-4.51,7.44-10.05,7.44Z" />
                    <g>
                        <rect class="cls-48" x="719.53" y="300.21" width="18.88" height="7.2" />
                        <rect class="cls-51" x="719.53" y="300.21" width="18.88" height="1.66" />
                    </g>
                    <g>
                        <path class="cls-92"
                            d="M735.9,308.39c0,2.83-3.1,5.13-6.93,5.13s-6.93-2.3-6.93-5.13,3.1-5.13,6.93-5.13,6.93,2.3,6.93,5.13Z" />
                        <path class="cls-48"
                            d="M732.1,308.39c0,1.28-1.4,2.31-3.12,2.31s-3.12-1.03-3.12-2.31,1.4-2.31,3.12-2.31,3.12,1.03,3.12,2.31Z" />
                    </g>
                    <rect class="cls-48" x="935.35" y="221.91" width="25.22" height="9.55" />
                    <polygon class="cls-48"
                        points="768.96 362.49 728.97 332.9 688.99 362.49 687.91 361.7 728.97 331.3 770.04 361.7 768.96 362.49" />
                    <rect class="cls-140" x="672.69" y="360.53" width="116.22" height="30.76" />
                    <path class="cls-140"
                        d="M675.17,362.74h.7l.52-.38h-1.21v.38Zm50.48,0h1.71l.52-.38h-2.75l.52,.38Zm-12.88,0h1.71l.52-.38h-2.75l.52,.38Zm4.29,0h1.71l.52-.38h-2.75l.52,.38Zm-12.88,0h1.71l.52-.38h-2.75l.52,.38Zm17.17,0h1.71l.52-.38h-2.75l.52,.38Zm-12.88,0h1.71l.52-.38h-2.75l.52,.38Zm-25.75,0h1.71l.52-.38h-2.75l.52,.38Zm4.29,0h1.71l.52-.38h-2.75l.52,.38Zm12.88,0h1.71l.52-.38h-2.75l.52,.38Zm-21.46,0h1.71l.52-.38h-2.75l.52,.38Zm12.88,0h1.71l.52-.38h-2.75l.52,.38Zm4.29,0h1.71l.52-.38h-2.75l.52,.38Zm55.8,0h1.71l.52-.38h-2.75l.52,.38Zm21.46,0h1.71l.52-.38h-2.75l.52,.38Zm-4.29,0h1.71l.52-.38h-2.75l.52,.38Zm-4.29,0h1.71l.52-.38h-2.75l.52,.38Zm12.88,0h1.72l.52-.38h-2.75l.52,.38Zm-47.21,0h1.71l.52-.38h-2.75l.52,.38Zm51.5,0h1.72l.52-.38h-2.75l.52,.38Zm3.78-.38l.52,.38h.7v-.38h-1.21Zm-46.7,.38h1.71l.52-.38h-2.75l.52,.38Zm21.46,0h1.72l.52-.38h-2.75l.52,.38Zm-25.75,0h1.71l.52-.38h-2.75l.52,.38Zm8.58,0h1.72l.52-.38h-2.75l.52,.38Zm12.88,0h1.71l.52-.38h-2.75l.52,.38Zm-8.58,0h1.71l.52-.38h-2.75l.52,.38Z" />
                    <path class="cls-91"
                        d="M704.19,389.06l-.52,.38h2.75l-.52-.38h-1.71Zm4.29,0l-.52,.38h2.75l-.52-.38h-1.71Zm12.88,0l-.52,.38h2.75l-.52-.38h-1.71Zm-21.46,0l-.52,.38h2.75l-.52-.38h-1.71Zm17.17,0l-.52,.38h2.75l-.52-.38h-1.71Zm8.58,0l-.52,.38h2.75l-.52-.38h-1.71Zm-12.88,0l-.52,.38h2.75l-.52-.38h-1.71Zm-34.34,0l-.52,.38h2.75l-.52-.38h-1.71Zm-3.27,0v.38h1.21l-.52-.38h-.7Zm20.44,0l-.52,.38h2.75l-.52-.38h-1.71Zm-12.88,0l-.52,.38h2.75l-.52-.38h-1.71Zm8.58,0l-.52,.38h2.75l-.52-.38h-1.71Zm-4.29,0l-.52,.38h2.75l-.52-.38h-1.71Zm77.26,0l-.52,.38h2.75l-.52-.38h-1.71Zm12.88,0l-.52,.38h2.75l-.52-.38h-1.72Zm-4.29,0l-.52,.38h2.75l-.52-.38h-1.71Zm-42.92,0l-.52,.38h2.75l-.52-.38h-1.71Zm55.8,0l-.52,.38h1.21v-.38h-.7Zm-25.75,0l-.52,.38h2.75l-.52-.38h-1.72Zm21.46,0l-.52,.38h2.75l-.52-.38h-1.72Zm-12.88,0l-.52,.38h2.75l-.52-.38h-1.71Zm-25.75,0l-.52,.38h2.75l-.52-.38h-1.72Zm-4.29,0l-.52,.38h2.75l-.52-.38h-1.71Zm-4.29,0l-.52,.38h2.75l-.52-.38h-1.71Zm12.88,0l-.52,.38h2.75l-.52-.38h-1.71Zm8.58,0l-.52,.38h2.75l-.52-.38h-1.71Zm-4.29,0l-.52,.38h2.75l-.52-.38h-1.71Z" />
                    <path class="cls-91"
                        d="M712.26,389.45l.52-.38v-26.32l-.52-.38v27.08Zm-4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm8.58,0l.52-.38v-26.32l-.52-.38v27.08Zm4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm-21.46,0l.52-.38v-26.32l-.52-.38v27.08Zm-4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm-17.17,0l.52-.38v-26.32l-.52-.38v27.08Zm4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm-8.58,0l.52-.38v-26.32l-.52-.38v27.08Zm12.88,0l.52-.38v-26.32l-.52-.38v27.08Zm38.63,0l.52-.38v-26.32l-.52-.38v27.08Zm-34.34,0l.52-.38v-26.32l-.52-.38v27.08Zm47.21,0l.52-.38v-26.32l-.52-.38v27.08Zm34.34,0l.52-.38v-26.32l-.52-.38v27.08Zm-4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm-4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm-4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm-30.04,0l.52-.38v-26.32l-.52-.38v27.08Zm51.5-27.08v27.08l.52-.38v-26.32l-.52-.38Zm-4.29,27.08l.52-.38v-26.32l-.52-.38v27.08Zm-42.92,0l.52-.38v-26.32l-.52-.38v27.08Zm8.58,0l.52-.38v-26.32l-.52-.38v27.08Zm4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm4.29,0l.52-.38v-26.32l-.52-.38v27.08Zm4.29,0l.52-.38v-26.32l-.52-.38v27.08Z" />
                    <path class="cls-140"
                        d="M710.19,389.06l.52,.38v-27.08l-.52,.38v26.32Zm17.17,0l.52,.38v-27.08l-.52,.38v26.32Zm-25.75,0l.52,.38v-27.08l-.52,.38v26.32Zm12.88,0l.52,.38v-27.08l-.52,.38v26.32Zm8.58,0l.52,.38v-27.08l-.52,.38v26.32Zm-4.29,0l.52,.38v-27.08l-.52,.38v26.32Zm-12.88,0l.52,.38v-27.08l-.52,.38v26.32Zm-30.03,0l.52,.38v-27.08l-.52,.38v26.32Zm4.28,0l.52,.38v-27.08l-.52,.38v26.32Zm4.29,0l.52,.38v-27.08l-.52,.38v26.32Zm8.58,0l.52,.38v-27.08l-.52,.38v26.32Zm4.29,0l.52,.38v-27.08l-.52,.38v26.32Zm-8.58,0l.52,.38v-27.08l-.52,.38v26.32Zm81.55,0l.52,.38v-27.08l-.52,.38v26.32Zm4.29,0l.52,.38v-27.08l-.52,.38v26.32Zm-8.58,0l.52,.38v-27.08l-.52,.38v26.32Zm17.17-26.32v26.32l.52,.38v-27.08l-.52,.38Zm-21.46,26.32l.52,.38v-27.08l-.52,.38v26.32Zm17.17,0l.52,.38v-27.08l-.52,.38v26.32Zm-30.04,0l.52,.38v-27.08l-.52,.38v26.32Zm-4.29,0l.52,.38v-27.08l-.52,.38v26.32Zm-4.29,0l.52,.38v-27.08l-.52,.38v26.32Zm17.17,0l.52,.38v-27.08l-.52,.38v26.32Zm-21.46,0l.52,.38v-27.08l-.52,.38v26.32Zm-4.29,0l.52,.38v-27.08l-.52,.38v26.32Zm21.46,0l.52,.38v-27.08l-.52,.38v26.32Z" />
                    <rect class="cls-133" x="691.82" y="389.94" width="4.97" height=".53" />
                    <rect class="cls-133" x="764.65" y="389.94" width="4.97" height=".53" />
                    <g>
                        <g>
                            <rect class="cls-91" x="671.67" y="360.33" width="2.63" height="1.95" />
                            <path class="cls-133"
                                d="M673.51,361.31c0,.21-.24,.39-.53,.39s-.53-.17-.53-.39,.24-.39,.53-.39,.53,.17,.53,.39Z" />
                        </g>
                        <g>
                            <rect class="cls-91" x="787.3" y="360.33" width="2.63" height="1.95" />
                            <path class="cls-133"
                                d="M789.14,361.31c0,.21-.24,.39-.53,.39s-.53-.17-.53-.39,.24-.39,.53-.39,.53,.17,.53,.39Z" />
                        </g>
                        <g>
                            <rect class="cls-91" x="671.67" y="389.53" width="2.63" height="1.95" />
                            <path class="cls-133"
                                d="M673.51,390.5c0,.21-.24,.39-.53,.39s-.53-.17-.53-.39,.24-.39,.53-.39,.53,.17,.53,.39Z" />
                        </g>
                        <g>
                            <rect class="cls-91" x="787.3" y="389.53" width="2.63" height="1.95" />
                            <path class="cls-133"
                                d="M789.14,390.5c0,.21-.24,.39-.53,.39s-.53-.17-.53-.39,.24-.39,.53-.39,.53,.17,.53,.39Z" />
                        </g>
                    </g>
                    <rect class="cls-49" x="951.3" y="305.18" width="7.58" height=".51" />
                    <rect class="cls-49" x="951.3" y="306.04" width="7.58" height=".51" />
                    <rect class="cls-49" x="951.3" y="306.91" width="7.58" height=".51" />
                    <rect class="cls-49" x="951.3" y="307.77" width="7.58" height=".51" />
                    <polygon class="cls-48"
                        points="970.06 299.19 1033.04 299.19 1033.04 281.17 1000.26 281.17 1000.22 293.35 970.06 293.35 970.06 299.19" />
                    <rect class="cls-27" x="970.06" y="293.35" width="6.21" height="5.84" />
                    <rect class="cls-48" x="965.04" y="289.45" width="6.85" height="13.64" />
                    <polygon class="cls-48"
                        points="1007.21 281.96 957.48 224.58 959.02 223.85 1008.74 281.24 1007.21 281.96" />
                    <polygon class="cls-48"
                        points="1024.58 282.04 957.56 224.66 958.94 223.78 1025.96 281.16 1024.58 282.04" />
                    <path class="cls-105"
                        d="M967.38,219.52c0,1.32-1.44,2.39-3.22,2.39h-32.8c-1.78,0-3.23-1.07-3.23-2.39h0c0-1.32,1.44-2.39,3.23-2.39h32.8c1.78,0,3.22,1.07,3.22,2.39h0Z" />
                </g>
                <g id="boot">
                    <rect class="cls-116" x="405.33" y="521.79" width="122.89" height="15.42" />
                    <g>
                        <path class="cls-121"
                            d="M381.22,537.33c-.77,1.89-10.59,24.77-35.98,36.46,25.4-11.69,35.21-34.57,35.98-36.46Z" />
                        <path class="cls-121"
                            d="M694.39,544.91H386.43c-6.75,12.39-28.18,43.36-73.63,43.36H1v93.14H615.46v-51.39l86.09-92.82-7.16,7.71Z" />
                    </g>
                    <polygon class="cls-106"
                        points="673.58 537.21 660.89 537.21 660.89 422.22 665.97 422.22 673.58 537.21" />
                    <polygon class="cls-107"
                        points="660.89 513.15 660.89 537.21 673.58 537.21 671.99 513.15 660.89 513.15" />
                    <polygon class="cls-44"
                        points="381.27 681.4 615.46 681.41 615.46 630.02 671.11 570.02 381.27 681.4" />
                    <path class="cls-44"
                        d="M522.17,583.73c0,5.9-5.05,10.68-11.27,10.68h-12.88c-6.22,0-11.27-4.78-11.27-10.68h0c0-5.9,5.05-10.68,11.27-10.68h12.88c6.22,0,11.27,4.78,11.27,10.68h0Z" />
                    <path class="cls-48"
                        d="M515.82,584.58c0,1.69-1.44,3.05-3.22,3.05h-16.27c-1.78,0-3.22-1.37-3.22-3.05h0c0-1.69,1.44-3.05,3.22-3.05h16.27c1.78,0,3.22,1.37,3.22,3.05h0Z" />
                    <rect class="cls-48" x="497.26" y="586.58" width="5.93" height="3.21" />
                    <rect class="cls-48" x="498.75" y="589.35" width="2.97" height="1.61" />
                    <polygon class="cls-48"
                        points="498.68 575.93 501.77 575.93 502.31 584.9 498.15 584.9 498.68 575.93" />
                    <rect class="cls-48" x="506.5" y="586.58" width="5.93" height="3.21" />
                    <rect class="cls-48" x="507.98" y="589.35" width="2.97" height="1.61" />
                    <polygon class="cls-48"
                        points="507.92 575.93 511.01 575.93 511.54 584.9 507.39 584.9 507.92 575.93" />
                    <path class="cls-48"
                        d="M554.12,549.45c0,2.39-2.05,4.34-4.58,4.34h-5.42c-2.53,0-4.58-1.94-4.58-4.34h0c0-2.39,2.05-4.34,4.58-4.34h5.42c2.53,0,4.58,1.94,4.58,4.34h0Z" />
                    <path class="cls-48"
                        d="M571.53,549.45c0,2.39-2.05,4.34-4.58,4.34h-5.42c-2.53,0-4.58-1.94-4.58-4.34h0c0-2.39,2.05-4.34,4.58-4.34h5.42c2.53,0,4.58,1.94,4.58,4.34h0Z" />
                    <path class="cls-48"
                        d="M588.94,549.45c0,2.39-2.05,4.34-4.58,4.34h-5.42c-2.53,0-4.58-1.94-4.58-4.34h0c0-2.39,2.05-4.34,4.58-4.34h5.42c2.53,0,4.58,1.94,4.58,4.34h0Z" />
                    <rect class="cls-116" x="539.64" y="525.64" width="98.59" height="11.56" />
                    <polygon class="cls-48"
                        points="666.61 431.84 665.97 422.22 660.89 422.22 660.89 431.84 666.61 431.84" />
                    <rect class="cls-48" x="662.29" y="405.89" width="2.09" height="16.33" />
                    <rect class="cls-48" x="663.33" y="411.85" width="4.38" height="5.95" />
                    <path class="cls-48" d="M459.9,630.66s-11.92,30.52-48.17,30.52H1v20.21H615.47v-50.74h-155.57Z" />
                    <path class="cls-59"
                        d="M381.27,537.21s-16.94,43.36-68.47,43.36H0v7.71H312.8c45.45,0,66.88-30.97,73.63-43.36h307.96l7.16-7.71H381.27Z" />
                </g>
            </g>
            <g id="Toppics">
                <rect id="Patje_button" class="cls-45" x="745.05" y="565" width="680.25" height="460.2" rx="11.45"
                    ry="11.45" style:opacity={scrollIndex == 1 ? 0.5 : 0}/>
                <rect id="Brug_button" class="cls-45" x="1563.76" y="452.35" width="236.27" height="202.08" rx="11.45"
                    ry="11.45" style:opacity={scrollIndex == 3 ? 0.5 : 0}/>
                <g id="Brug1">
                    <g id="Brug">
                        <rect class="cls-95" x="1601.65" y="497.09" width="25.13" height="75.85" />
                        <g>
                            <rect class="cls-136" x="1601.78" y="547.68" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-95" x="1605.38" y="547.5" width=".74" height="9.33" />
                                <rect class="cls-95" x="1611.01" y="547.5" width=".74" height="9.33" />
                                <rect class="cls-95" x="1616.64" y="547.5" width=".74" height="9.33" />
                                <rect class="cls-95" x="1622.27" y="547.5" width=".74" height="9.33" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-136" x="1601.78" y="532.71" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-95" x="1605.38" y="532.52" width=".74" height="9.33" />
                                <rect class="cls-95" x="1611.01" y="532.52" width=".74" height="9.33" />
                                <rect class="cls-95" x="1616.64" y="532.52" width=".74" height="9.33" />
                                <rect class="cls-95" x="1622.27" y="532.52" width=".74" height="9.33" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-136" x="1601.66" y="517.73" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-95" x="1605.38" y="517.55" width=".74" height="9.33" />
                                <rect class="cls-95" x="1611.01" y="517.55" width=".74" height="9.33" />
                                <rect class="cls-95" x="1616.64" y="517.55" width=".74" height="9.33" />
                                <rect class="cls-95" x="1622.27" y="517.55" width=".74" height="9.33" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-136" x="1601.66" y="502.76" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-95" x="1605.38" y="502.58" width=".74" height="9.33" />
                                <rect class="cls-95" x="1611.01" y="502.58" width=".74" height="9.33" />
                                <rect class="cls-95" x="1616.64" y="502.58" width=".74" height="9.33" />
                                <rect class="cls-95" x="1622.27" y="502.58" width=".74" height="9.33" />
                            </g>
                        </g>
                        <rect class="cls-130" x="1729.47" y="497.09" width="25.13" height="76.51" />
                        <rect class="cls-119" x="1626.87" y="476.87" width="102.59" height="94.57" />
                        <rect class="cls-115" x="1678.44" y="476.87" width="51.02" height="94.57" />
                        <polygon class="cls-95"
                            points="1609.29 613.57 1678.18 613.57 1678.18 546.27 1591.23 562.02 1609.29 613.57" />
                        <polygon class="cls-136"
                            points="1605.56 602.91 1609.29 613.57 1678.18 613.57 1678.18 602.91 1605.56 602.91" />
                        <g>
                            <rect class="cls-135" x="1729.57" y="547.68" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-130" x="1733.18" y="547.5" width=".74" height="9.33" />
                                <rect class="cls-130" x="1738.81" y="547.5" width=".74" height="9.33" />
                                <rect class="cls-130" x="1744.44" y="547.5" width=".74" height="9.33" />
                                <rect class="cls-130" x="1750.07" y="547.5" width=".74" height="9.33" />
                            </g>
                        </g>
                        <polygon class="cls-132"
                            points="1747.03 613.57 1678.14 613.57 1678.14 546.27 1765.1 562.02 1747.03 613.57" />
                        <polygon class="cls-135"
                            points="1678.14 602.89 1678.14 613.57 1747.03 613.57 1750.78 602.89 1678.14 602.89" />
                        <g>
                            <rect class="cls-135" x="1729.57" y="532.71" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-130" x="1733.18" y="532.52" width=".74" height="9.33" />
                                <rect class="cls-130" x="1738.81" y="532.52" width=".74" height="9.33" />
                                <rect class="cls-130" x="1744.44" y="532.52" width=".74" height="9.33" />
                                <rect class="cls-130" x="1750.07" y="532.52" width=".74" height="9.33" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-135" x="1729.46" y="517.73" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-130" x="1733.18" y="517.55" width=".74" height="9.33" />
                                <rect class="cls-130" x="1738.81" y="517.55" width=".74" height="9.33" />
                                <rect class="cls-130" x="1744.44" y="517.55" width=".74" height="9.33" />
                                <rect class="cls-130" x="1750.07" y="517.55" width=".74" height="9.33" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-135" x="1729.46" y="502.76" width="25.02" height="8.97" />
                            <g>
                                <rect class="cls-130" x="1733.18" y="502.58" width=".74" height="9.33" />
                                <rect class="cls-130" x="1738.81" y="502.58" width=".74" height="9.33" />
                                <rect class="cls-130" x="1744.44" y="502.58" width=".74" height="9.33" />
                                <rect class="cls-130" x="1750.07" y="502.58" width=".74" height="9.33" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-136" x="1627.86" y="480.7" width="100.61" height="11.57" />
                            <rect class="cls-135" x="1678.44" y="480.7" width="50.03" height="11.57" />
                            <g>
                                <rect class="cls-119" x="1645.61" y="480.66" width=".94" height="11.65" />
                                <rect class="cls-119" x="1667.83" y="480.66" width=".94" height="11.65" />
                                <rect class="cls-115" x="1690.04" y="480.66" width=".94" height="11.65" />
                                <rect class="cls-115" x="1712.26" y="480.66" width=".94" height="11.65" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-136" x="1632.01" y="494.77" width="92.31" height="10.36" />
                            <rect class="cls-135" x="1678.44" y="494.77" width="45.88" height="10.36" />
                            <g>
                                <rect class="cls-119" x="1645.61" y="494.06" width=".94" height="11.65" />
                                <rect class="cls-119" x="1667.83" y="494.06" width=".94" height="11.65" />
                                <rect class="cls-115" x="1690.04" y="494.06" width=".94" height="11.65" />
                                <rect class="cls-115" x="1712.26" y="494.06" width=".94" height="11.65" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-136" x="1627.48" y="507.44" width="101.37" height="11.38" />
                            <rect class="cls-135" x="1678.44" y="507.44" width="50.41" height="11.38" />
                            <g>
                                <rect class="cls-119" x="1645.61" y="507.3" width=".94" height="11.65" />
                                <rect class="cls-119" x="1667.83" y="507.3" width=".94" height="11.65" />
                                <rect class="cls-115" x="1690.04" y="507.3" width=".94" height="11.65" />
                                <rect class="cls-115" x="1712.26" y="507.3" width=".94" height="11.65" />
                            </g>
                        </g>
                        <g>
                            <rect class="cls-136" x="1630.73" y="521.31" width="94.86" height="10.65" />
                            <rect class="cls-135" x="1678.44" y="521.31" width="47.16" height="10.65" />
                            <g>
                                <rect class="cls-119" x="1645.61" y="520.71" width=".94" height="11.65" />
                                <rect class="cls-119" x="1667.83" y="520.71" width=".94" height="11.65" />
                                <rect class="cls-115" x="1690.04" y="520.71" width=".94" height="11.65" />
                                <rect class="cls-115" x="1712.26" y="520.71" width=".94" height="11.65" />
                            </g>
                        </g>
                        <rect class="cls-95" x="1625.53" y="472.71" width="105.26" height="4.11" />
                        <g>
                            <rect class="cls-119" x="1655.27" y="483.98" width="45.78" height="2.11" />
                            <rect class="cls-115" x="1678.45" y="483.98" width="22.61" height="2.11" />
                        </g>
                        <g>
                            <rect class="cls-119" x="1661.9" y="512.89" width="32.53" height="3.46" />
                            <rect class="cls-115" x="1678.45" y="512.89" width="15.94" height="3.46" />
                        </g>
                        <g>
                            <path class="cls-95"
                                d="M1588.97,557c-.45,0-.84-.32-.92-.77-.09-.51,.25-1,.76-1.09l89.63-16.17,.17,.03,88.7,16.1c.51,.09,.85,.58,.76,1.09-.09,.51-.58,.85-1.09,.76l-88.54-16.07-89.3,16.11c-.06,0-.11,.01-.17,.01Z" />
                            <g>
                                <path class="cls-1" d="M1682.76,547.11l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1688,548.05l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1693.24,549.01l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1703.72,550.9l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1708.95,551.85l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1714.2,552.8l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1719.43,553.75l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1724.67,554.7l-.94-.17,.94,.17Z" />
                                <path class="cls-1" d="M1729.91,555.65l-.94-.18,.94,.18Z" />
                                <path class="cls-1" d="M1735.15,556.59l-.94-.17,.94,.17Z" />
                                <polygon class="cls-95"
                                    points="1761.34 555.13 1761.34 561.34 1760.4 561.26 1760.4 555.13 1761.34 555.13" />
                                <polygon class="cls-95"
                                    points="1756.11 554.14 1756.11 560.39 1755.17 560.3 1755.17 554.14 1756.11 554.14" />
                                <polygon class="cls-95"
                                    points="1750.87 552.63 1750.87 559.44 1749.93 559.34 1749.93 552.63 1750.87 552.63" />
                                <polygon class="cls-95"
                                    points="1745.63 552.06 1745.63 558.5 1744.69 558.39 1744.69 552.06 1745.63 552.06" />
                                <polygon class="cls-95"
                                    points="1740.39 550.42 1740.39 557.54 1739.45 557.42 1739.45 550.42 1740.39 550.42" />
                                <polygon class="cls-95"
                                    points="1735.15 556.59 1735.15 550.03 1734.21 550.03 1734.21 556.46 1735.15 556.59" />
                                <polygon class="cls-95"
                                    points="1729.91 555.65 1729.91 548.85 1728.97 548.85 1728.97 555.49 1729.91 555.65" />
                                <polygon class="cls-95"
                                    points="1724.67 554.7 1724.67 548.15 1723.74 548.15 1723.74 554.53 1724.67 554.7" />
                                <polygon class="cls-95"
                                    points="1719.43 553.75 1719.43 548.15 1718.49 548.15 1718.49 553.58 1719.43 553.75" />
                                <polygon class="cls-95"
                                    points="1714.2 552.8 1714.2 545.72 1713.26 545.72 1713.26 552.63 1714.2 552.8" />
                                <polygon class="cls-95"
                                    points="1708.95 551.85 1708.95 545.15 1708.02 545.15 1708.02 551.68 1708.95 551.85" />
                                <polygon class="cls-95"
                                    points="1703.72 550.9 1703.72 543.84 1702.78 543.84 1702.78 550.73 1703.72 550.9" />
                                <polygon class="cls-95"
                                    points="1698.48 543.09 1698.48 549.96 1697.54 549.78 1697.54 543.09 1698.48 543.09" />
                                <polygon class="cls-95"
                                    points="1693.24 549.01 1693.24 542.15 1692.3 542.15 1692.3 548.84 1693.24 549.01" />
                                <polygon class="cls-95"
                                    points="1688 548.05 1688 540.64 1687.06 540.64 1687.06 547.89 1688 548.05" />
                                <polygon class="cls-95"
                                    points="1682.76 547.11 1682.76 540.64 1681.83 540.64 1681.83 546.94 1682.76 547.11" />
                            </g>
                            <g>
                                <path class="cls-1" d="M1674.51,546.94l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1669.28,547.89l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1664.03,548.84l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1653.56,550.73l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1648.32,551.68l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1643.08,552.63l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1637.84,553.58l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1632.6,554.53l-.94,.17,.94-.17Z" />
                                <path class="cls-1" d="M1627.37,555.47l-.94,.18,.94-.18Z" />
                                <path class="cls-1" d="M1622.12,556.42l-.94,.17,.94-.17Z" />
                                <polygon class="cls-95"
                                    points="1595 555.13 1595 561.34 1595.93 561.26 1595.93 555.13 1595 555.13" />
                                <polygon class="cls-95"
                                    points="1600.23 554.14 1600.23 560.39 1601.17 560.3 1601.17 554.14 1600.23 554.14" />
                                <polygon class="cls-95"
                                    points="1605.47 552.63 1605.47 559.44 1606.41 559.34 1606.41 552.63 1605.47 552.63" />
                                <polygon class="cls-95"
                                    points="1610.71 552.06 1610.71 558.5 1611.65 558.39 1611.65 552.06 1610.71 552.06" />
                                <polygon class="cls-95"
                                    points="1615.95 550.42 1615.95 557.54 1616.89 557.42 1616.89 550.42 1615.95 550.42" />
                                <polygon class="cls-95"
                                    points="1622.12 556.46 1622.12 550.03 1621.19 550.03 1621.19 556.59 1622.12 556.46" />
                                <polygon class="cls-95"
                                    points="1627.37 555.49 1627.37 548.85 1626.43 548.85 1626.43 555.65 1627.37 555.49" />
                                <polygon class="cls-95"
                                    points="1632.6 554.53 1632.6 548.15 1631.66 548.15 1631.66 554.7 1632.6 554.53" />
                                <polygon class="cls-95"
                                    points="1637.84 553.58 1637.84 548.15 1636.91 548.15 1636.91 553.75 1637.84 553.58" />
                                <polygon class="cls-95"
                                    points="1643.08 552.63 1643.08 545.72 1642.14 545.72 1642.14 552.8 1643.08 552.63" />
                                <polygon class="cls-95"
                                    points="1648.32 551.68 1648.32 545.15 1647.38 545.15 1647.38 551.85 1648.32 551.68" />
                                <polygon class="cls-95"
                                    points="1653.56 550.73 1653.56 543.84 1652.62 543.84 1652.62 550.9 1653.56 550.73" />
                                <polygon class="cls-95"
                                    points="1657.86 543.09 1657.86 549.96 1658.8 549.78 1658.8 543.09 1657.86 543.09" />
                                <polygon class="cls-95"
                                    points="1664.03 548.84 1664.03 542.15 1663.1 542.15 1663.1 549.01 1664.03 548.84" />
                                <polygon class="cls-95"
                                    points="1669.28 547.89 1669.28 540.64 1668.34 540.64 1668.34 548.05 1669.28 547.89" />
                                <polygon class="cls-95"
                                    points="1674.51 546.94 1674.51 540.64 1673.57 540.64 1673.57 547.11 1674.51 546.94" />
                            </g>
                        </g>
                        <g>
                            <path class="cls-115"
                                d="M1761.97,568.1c0,1.14-.93,2.07-2.07,2.07h-3.01c-1.14,0-2.07-.93-2.07-2.07h0c0-1.14,.93-2.07,2.07-2.07h3.01c1.14,0,2.07,.93,2.07,2.07h0Z" />
                            <path class="cls-115"
                                d="M1706.68,568.1c0,1.14-.93,2.07-2.07,2.07h-3.01c-1.14,0-2.07-.93-2.07-2.07h0c0-1.14,.93-2.07,2.07-2.07h3.01c1.14,0,2.07,.93,2.07,2.07h0Z" />
                            <path class="cls-115"
                                d="M1717.74,568.1c0,1.14-.93,2.07-2.07,2.07h-3.01c-1.14,0-2.07-.93-2.07-2.07h0c0-1.14,.93-2.07,2.07-2.07h3.01c1.14,0,2.07,.93,2.07,2.07h0Z" />
                            <path class="cls-115"
                                d="M1728.8,568.1c0,1.14-.93,2.07-2.07,2.07h-3.01c-1.14,0-2.07-.93-2.07-2.07h0c0-1.14,.93-2.07,2.07-2.07h3.01c1.14,0,2.07,.93,2.07,2.07h0Z" />
                            <path class="cls-115"
                                d="M1739.86,568.1c0,1.14-.93,2.07-2.07,2.07h-3.01c-1.14,0-2.07-.93-2.07-2.07h0c0-1.14,.93-2.07,2.07-2.07h3.01c1.14,0,2.07,.93,2.07,2.07h0Z" />
                            <path class="cls-115"
                                d="M1750.92,568.1c0,1.14-.93,2.07-2.07,2.07h-3.01c-1.14,0-2.07-.93-2.07-2.07h0c0-1.14,.93-2.07,2.07-2.07h3.01c1.14,0,2.07,.93,2.07,2.07h0Z" />
                        </g>
                        <g>
                            <rect class="cls-136" x="1753.89" y="584.13" width="1.6" height="1.58" />
                            <rect class="cls-136" x="1750.14" y="584.13" width="1.6" height="1.58" />
                            <rect class="cls-136" x="1746.4" y="584.13" width="1.6" height="1.58" />
                        </g>
                        <rect class="cls-136" x="1687.87" y="593.44" width="10.11" height="3.75" />
                    </g>
                    <g class="brug" transform={`translate(${$bridgeX}, 0)`}>
                        <g class="cls-155">
                            <rect class="cls-140" x="1555.8" y="598.61" width="368.2" height="24.39" rx="3.25"
                                ry="3.25" />
                        </g>
                        <g>
                            <rect class="cls-106" x="1556.61" y="589.06" width="365.39" height="24.53" />
                            <g>
                                <rect class="cls-116" x="1912.23" y="594.88" width="9.77" height="18.7" />
                                <g>
                                    <rect class="cls-116" x="1593.54" y="594.88" width="12.53" height="18.7" />
                                    <rect class="cls-105" x="1606.07" y="592.3" width="12.53" height="21.29" />
                                    <rect class="cls-116" x="1823.73" y="589.06" width="12.53" height="18.7" />
                                    <rect class="cls-116" x="1749.5" y="589.06" width="12.53" height="10.04" />
                                    <rect class="cls-116" x="1651.45" y="603.55" width="12.53" height="10.04" />
                                    <rect class="cls-116" x="1717.02" y="589.06" width="12.53" height="15.17" />
                                    <rect class="cls-105" x="1811.21" y="589.06" width="12.53" height="21.29" />
                                    <rect class="cls-105" x="1704.5" y="589.06" width="12.53" height="20.07" />
                                </g>
                            </g>
                            <rect class="cls-50" x="1888.37" y="589.06" width="33.63" height="24.53" />
                        </g>
                    </g>
                </g>
                <rect id="Nose_button" class="cls-45" x="1450.04" y="414.48" width="95.99" height="182.56" rx="11.45"
                    ry="11.45" style:opacity={scrollIndex == 2 ? 0.5 : 0}/>
                <g id="Nose1">
                    <polyline class="cls-106" points="1493.42 588.35 1498.48 588.35 1498.48 473.36 1493.42 473.36" />
                    <rect class="cls-107" x="1493.42" y="564.29" width="5.07" height="24.06" />
                    <g>
                        <rect class="cls-48" x="1494.99" y="457.04" width="2.09" height="16.33" />
                        <rect class="cls-48" x="1491.66" y="462.99" width="4.38" height="5.95" />
                    </g>
                    <rect class="cls-48" x="1493.42" y="473.36" width="5.07" height="9.62" />
                    <g class="cls-157">
                        <polygon class="cls-106"
                            points="1518.3 429.27 1509.75 451.68 1473.89 465.12 1482.42 444.64 1518.3 429.27" />
                        <polygon class="cls-120"
                            points="1518.3 429.27 1519.79 430.76 1511.24 453.17 1509.75 451.68 1518.3 429.27" />
                        <polygon class="cls-106"
                            points="1509.75 451.68 1511.24 453.17 1475.38 466.61 1473.89 465.12 1509.75 451.68" />
                    </g>
                    <g>
                        <polygon class="cls-48"
                            points="1517.33 430.05 1509.15 451.48 1474.86 464.34 1483.02 444.75 1517.33 430.05" />
                        <g class="cls-66">
                            <g>
                                <g>
                                    <line class="cls-3" x1="1487.49" y1="440.88" x2="1477.79" y2="463.88" />
                                    <line class="cls-3" x1="1493.15" y1="439.17" x2="1483.44" y2="462.17" />
                                    <line class="cls-3" x1="1499.62" y1="436.6" x2="1489.91" y2="459.6" />
                                    <line class="cls-3" x1="1506.45" y1="434.02" x2="1496.75" y2="457.02" />
                                    <line class="cls-3" x1="1513.64" y1="430.63" x2="1503.94" y2="453.63" />
                                </g>
                                <line class="cls-3" x1="1512.2" y1="445.38" x2="1476.02" y2="458.66" />
                                <line class="cls-3" x1="1515.62" y1="436.84" x2="1479.43" y2="450.13" />
                            </g>
                        </g>
                    </g>
                    <g class="cls-151">
                        <polygon class="cls-106"
                            points="1509.75 451.68 1511.24 453.17 1475.38 466.61 1473.89 465.12 1509.75 451.68" />
                    </g>
                    <rect class="cls-148" x="1489" y="518.85" width="7" height="15.15" />
                </g>
                <g id="Patje_plastiek1">
                    <g id="afval">
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-36"
                                d="M1079.29,896.56s9.11-6.13,9.37-9.41c0,0,7.01,3.18,6.51,6.01-.5,2.83,3.01,2.84,5.29,4.38,2.28,1.53,3.2,3.46,3.2,3.46,0,0-1.22,8.58-8.56,10.95,0,0-5.16-2.76-5.66-6.36-.5-3.6-6.78-2.68-6.79-4.99,0-2.3-3.35-4.04-3.35-4.04Z" />
                            <path class="cls-108"
                                d="M1103.65,900.99s-1.37-.67-2.17,.17c-.81,.84-1.47,1.63-3.18,2.06-1.71,.44-3.6,5.39-3.21,8.72,7.34-2.37,8.56-10.95,8.56-10.95Z" />

                            <path class="cls-118"
                                d="M1102.73,899.62c-.52-.64-1.25-1.4-2.27-2.09-2.28-1.53-5.79-1.55-5.29-4.38,.04-.24,.01-.48-.04-.72-.82,1.05-2.11,1.69-3.33,2.3-1.38,.68-2.85,1.49-3.51,2.88-.36,.77-.46,1.67-.98,2.34-.35,.45-.86,.75-1.36,1.04-.63,.37-1.29,.74-1.97,1,1.88,.76,5.09,1.06,5.44,3.59,.26,1.83,1.72,3.44,3.09,4.59,.06-.15,.12-.31,.16-.46,.72-2.42,1.08-4.97,2.21-7.22,.29-.58,.69-1.19,1.33-1.35,.59-.15,1.19,.14,1.8,.13,.63-.01,1.25-.38,1.56-.93,.11-.2,.2-.43,.4-.55,.14-.08,.3-.09,.46-.09,.77-.02,1.53-.04,2.3-.06Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-36"
                                d="M1251.13,824.83s3.08,8.44,7.13,9.94c0,0-7.69,3.27-11.04,1.79-3.34-1.48-5.19,.8-8.32,1.65-3.13,.86-6.07,.67-6.07,.67,0,0-10.3-4.3-9.51-10.04,0,0,6.19-2.23,11.04-1.09,4.85,1.14,6.94-3.32,9.88-2.38,2.94,.94,6.89-.53,6.89-.53Z" />
                            <path class="cls-108"
                                d="M1232.84,838.89s1.56-.62,.91-1.48c-.65-.87-1.31-1.62-.98-2.91,.33-1.29-5-4.54-9.44-5.65-.79,5.75,9.51,10.04,9.51,10.04Z" />
                            <path class="cls-118"
                                d="M1235.07,838.85c1.08-.08,2.43-.24,3.84-.63,3.13-.86,4.98-3.13,8.32-1.65,.28,.12,.6,.21,.94,.27-.92-.96-1.06-2.07-1.2-3.1-.15-1.18-.42-2.46-1.85-3.46-.79-.55-1.88-.98-2.47-1.59-.4-.41-.52-.87-.63-1.31-.14-.56-.27-1.14-.26-1.69-1.94,.91-3.99,2.88-7.4,2.08-2.47-.58-5.28-.29-7.45,.14,.16,.1,.34,.2,.51,.29,2.7,1.46,5.77,2.73,8.06,4.39,.59,.43,1.16,.94,1.03,1.41-.12,.44-.8,.72-1.09,1.12-.31,.42-.16,.97,.38,1.4,.2,.15,.45,.31,.5,.48,.03,.12-.05,.23-.12,.34-.37,.51-.74,1.02-1.11,1.52Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <rect class="cls-144" x="1136.47" y="843.5" width="5.36" height="4.39" rx=".6" ry=".6"
                                transform="translate(-233.02 1206.26) rotate(-51.31)" />
                            <path class="cls-72"
                                d="M1124.55,839.92l1.78,1.43c.29,.24,.48,.56,.55,.9,.26,1.3,1.12,2.4,2.15,3.23l1.48,1.19c2.64,2.11,6.45,1.62,8.64-.96,2.24-2.65,1.97-6.3-.72-8.46l-1.7-1.36c-1.03-.83-2.3-1.43-3.62-1.39-.35,0-.7-.1-1-.34l-1.78-1.43c-.29-.24-.48-.56-.55-.9-.26-1.3-1.12-2.4-2.15-3.23l-12.32-9.86c-.78-.62-1.92-.5-2.54,.28l-5.43,6.78c-.62,.78-.5,1.92,.28,2.54l12.32,9.86c1.03,.83,2.3,1.43,3.62,1.39,.35,0,.7,.1,1,.34Z" />
                            <rect class="cls-113" x="1137.42" y="845.06" width="7.35" height="4.39" rx=".71" ry=".71"
                                transform="translate(-233.49 1208.42) rotate(-51.32)" />
                            <path class="cls-16"
                                d="M1138.94,846.43l.88-1.1,.92-.1,2.41,1.93,.1,.92-.88,1.1-.92,.1-2.41-1.93-.1-.92Z" />
                            <path class="cls-21"
                                d="M1125.96,838.16l1.78,1.43c.29,.24,.52,.51,.66,.76,.52,.97,1.47,1.95,2.51,2.78l1.48,1.19c2.64,2.11,5.61,2.67,6.76,1.38,1.18-1.33,.09-3.96-2.6-6.12l-1.7-1.36c-1.03-.83-2.2-1.55-3.26-1.84-.28-.08-.59-.24-.89-.47l-1.78-1.43c-.29-.24-.52-.51-.66-.76-.52-.97-1.48-1.95-2.51-2.78l-12.32-9.86c-.78-.62-1.67-.81-1.99-.41l-2.78,3.47c-.32,.4,.05,1.23,.83,1.85l12.32,9.86c1.03,.83,2.2,1.55,3.26,1.84,.28,.08,.59,.24,.89,.47Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <rect class="cls-144" x="1064.5" y="883.24" width="3.87" height="3.17" rx=".44" ry=".44"
                                transform="translate(98.32 1876.26) rotate(-85.36)" />
                            <path class="cls-72"
                                d="M1077.51,882.38l-1.64-.13c-.27-.02-.51-.14-.69-.32-.68-.67-1.64-.98-2.59-1.06l-1.37-.11c-2.43-.2-4.52,1.64-4.78,4.07-.27,2.49,1.37,4.57,3.85,4.77l1.57,.13c.95,.08,1.95-.07,2.73-.63,.21-.15,.46-.22,.73-.2l1.64,.13c.27,.02,.51,.14,.69,.32,.68,.67,1.64,.98,2.59,1.06l11.36,.92c.72,.06,1.35-.48,1.41-1.2l.51-6.25c.06-.72-.48-1.35-1.2-1.41l-11.36-.92c-.95-.08-1.95,.07-2.73,.63-.21,.15-.46,.22-.73,.2Z" />
                            <rect class="cls-113" x="1062" y="883.09" width="5.3" height="3.17" rx=".51" ry=".51"
                                transform="translate(96.89 1874.41) rotate(-85.37)" />
                            <path class="cls-6"
                                d="M1064.14,883.09h1.02c.26,0,.47,.21,.47,.47v2.22c0,.26-.21,.47-.47,.47h-1.02c-.26,0-.47-.21-.47-.47v-2.22c0-.26,.21-.47,.47-.47Z"
                                transform="translate(96.66 1874.19) rotate(-85.36)" />
                            <path class="cls-21"
                                d="M1077.38,884l-1.64-.13c-.27-.02-.52-.09-.7-.19-.7-.37-1.67-.57-2.62-.65l-1.37-.11c-2.43-.2-4.44,.67-4.6,1.91-.17,1.28,1.55,2.41,4.03,2.61l1.57,.13c.95,.08,1.94,.04,2.69-.22,.2-.07,.45-.1,.72-.08l1.64,.13c.27,.02,.52,.09,.7,.19,.7,.37,1.67,.57,2.62,.65l11.36,.92c.72,.06,1.32-.19,1.35-.56l.26-3.2c.03-.37-.53-.71-1.25-.77l-11.36-.92c-.95-.08-1.94-.04-2.69,.22-.2,.07-.45,.1-.72,.08Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <g>
                                <path class="cls-76"
                                    d="M1126.55,830.46l1.39,1.65c.23,.28,.66,.29,.99,.02,.33-.27,.47-.72,.31-1.06l-.93-2.02c-.18-.4-.69-.48-1.08-.16l-.46,.37c-.39,.31-.49,.88-.22,1.21Zm.51-.33c-.13-.15-.08-.4,.1-.55l.21-.17c.18-.14,.41-.11,.49,.07l.42,.91c.07,.15,.01,.36-.14,.48s-.34,.12-.45,0l-.63-.75Z" />
                                <g>
                                    <path class="cls-79"
                                        d="M1153.8,839.95l.37-7.72c.06-1.21-.82-2.07-1.97-1.92l-11.17,3.65-7.91-5.98c-1.02-.37-2.27,.26-2.81,1.42l-3.43,7.41c-.54,1.16-.15,2.4,.86,2.78l11.25,1.33,12.62,1.49c1.15-.15,2.13-1.25,2.18-2.46Z" />
                                    <path class="cls-81"
                                        d="M1133.13,827.98l8.35,5.2,10.73-2.87-.59,12.1s-1.96,.17-3.75,.3c-1.19,.08-3.88-.67-4.3-.69-.9-.06-2.36,2.12-3.57,1.93-1.29-.21-2.13-1.73-3.4-2.11-.56-.17-1.59,.84-2.13,.65-3.75-1.31-6.72-2.89-6.72-2.89l5.38-11.61Z" />
                                    <path class="cls-19"
                                        d="M1153.93,837.21l.11-2.25c.04-.78-.53-1.34-1.28-1.24l-11.31,2.17-10.53-4.79c-.66-.24-1.47,.17-1.82,.92l-1,2.16c-.35,.75-.1,1.56,.56,1.8,0,0,8.35,4.1,11.51,4.45,2.97,.33,12.35-1.63,12.35-1.63,.74-.1,1.38-.81,1.42-1.6Z" />
                                    <path class="cls-82"
                                        d="M1154.41,839.98l.38-7.86c0-.19-.13-.32-.31-.3h0c-.18,.02-.33,.19-.34,.38l-.38,7.86c0,.19,.13,.32,.31,.3h0c.18-.02,.33-.19,.34-.38Z" />
                                    <path class="cls-82"
                                        d="M1126.83,836.92l3.49-7.53c.08-.18,.02-.37-.13-.43h0c-.16-.06-.35,.04-.44,.22l-3.49,7.53c-.08,.18-.02,.37,.13,.43h0c.16,.06,.35-.04,.44-.22Z" />
                                </g>
                            </g>
                            <path class="cls-30"
                                d="M1141.48,833.18l-5.08-3.16s-2.01,2.87-1.11,4.09c.9,1.22,6.45,2.71,6.17,1.79-.29-.92,.02-2.71,.02-2.71Z" />
                            <path class="cls-30"
                                d="M1141.48,833.18l7.75-2.07s1.05,1.88,1.25,2.19c.2,.31-4.43,3.51-4.68,3.14-.14-.2-4.34-.54-4.34-.54l-1.84-1.47,1.86-1.25Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-34"
                            d="M918.36,813.23c-3.03-1.39-6.72-.41-9.72,.38-5.73,1.51-5.32,.74-4.86-.61,.28-.83,10.43,.42,7.4-2.08-1.37-1.13-5.9-2.73-10.75-.6-2.19,.96-4.62,.58-7.17,1.35-1.83,.56-4.54,.31-6.49,.27-4.3-.09-8.84,0-11.3,2.56-2.55,2.67,.57,4.87,2.04,6.78,1.27,1.66,4.06,3.41,7.01,3.15,.98-.08,1.9-.34,2.86-.44,1.88-.19,3.89-.21,5.8-.34,2.67-.18,4.76-.5,7.21-1.16,1.84-.5,2.65-1.14,3.8-2.13,.53-.45,.93-1.02,1.59-1.42,.94-.57,2.25-.52,3.47-.62,3.43-.28,6.98-.25,9.34-2.15,1.09-.88,1.35-2.23-.24-2.96Zm-17.51-.64c-.33-1.59,4.75-2.13,6.38-2.13,.85,0,3.19,.24,3.27,1.07,.07,.75-3.69,.51-4.61,.54-1.4,.03-1.79,.18-2.81,.6-.57,.24-1.25,.42-2.23-.07Zm16.73,2.58c-1.18,.29-2.39,.01-3.62,.38-1.29,.39-2.15,1.18-3.52,1.45-1.93,.37-3.92-.05-5.12-1.09-.99-.86,.78-.97,2.03-1.21,1.71-.34,3.89-1.22,5.64-1.46,1.67-.24,2.95-.11,4.38,.45,.99,.39,1.79,1.11,.22,1.49Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <g class="cls-56">
                            <path class="cls-101"
                                d="M1204.11,854.78c-2.87-1.14-4.08-4.85-2.71-8.28l5.71-14.29,.64-1.6,.24-.59c.09-.23,.41-.48,.64-.5,2.4-.17,7.03,1.65,9.62,3.84,.17,.15,.21,.57,.12,.8l-.23,.59-.64,1.6-5.7,14.28s0,0,0,0c-1.37,3.43-4.8,5.29-7.67,4.14Z" />
                            <path class="cls-88"
                                d="M1200.44,862.7c2.23,.89,5.69,1.13,6.29,1.09,.78-.05-1.52-1.6-3.65-2.75l1.07-2.68s0,0,0,0c.74,.2,1.32,.25,1.45-.08,0,0,.16-.41,.19-.48l1.09-2.72c.03-.09,.04-.18,.02-.24,.05-.51-.84-1.28-2.09-1.78-1.26-.5-2.43-.56-2.74-.15-.06,.03-.12,.1-.16,.19l-1.09,2.72c-.03,.08-.2,.5-.2,.51-.14,.34,.35,.71,1.07,1.08l-1.01,2.54c-1.76-.66-1.74-.33-1.97,.24-.25,.62-.49,1.63,1.74,2.52Z" />
                            <path class="cls-88"
                                d="M1213.14,843.11c1.49-3.74,1-7.45-1.11-8.29-2.1-.84-5.02,1.51-6.51,5.25-1.49,3.74-1,7.45,1.11,8.29,2.1,.84,5.02-1.51,6.51-5.25Z" />
                        </g>
                    </AnimatedG>
                    <AnimatedG moved={scrollIndex == 0}>
                        <g>
                            <path class="cls-78"
                                d="M906.15,824.55l3.57,.09c.08,0,.16-.06,.16-.15h0c0-.08-.06-.16-.15-.16l-3.57-.09c-.08,0-.16,.06-.16,.15h0c0,.08,.06,.16,.15,.16Z" />
                            <path class="cls-65"
                                d="M881.41,828.66h26.84c1.32,0,2.4,1.08,2.4,2.4v8.46c0,1.32-1.08,2.4-2.4,2.4h-26.84c-1.32,0-2.4-1.08-2.4-2.4v-8.46c0-1.32,1.08-2.4,2.4-2.4Z"
                                transform="translate(-320.31 983.16) rotate(-49.97)" />
                            <rect class="cls-109" x="881.41" y="828.66" width="26.84" height="13.26"
                                transform="translate(-320.3 983.18) rotate(-49.97)" />
                            <rect class="cls-15" x="879.01" y="832.5" width="31.64" height="5.58" rx="1.56" ry="1.56"
                                transform="translate(-320.3 983.23) rotate(-49.97)" />
                            <path class="cls-80"
                                d="M880.87,845.14l6.59,5.54c.16,.13,.39,.11,.52-.05h0c.13-.16,.11-.39-.05-.52l-6.59-5.54c-.16-.13-.39-.11-.52,.05h0c-.13,.16-.11,.39,.05,.52Z" />
                            <path class="cls-80"
                                d="M901.67,820.37l6.59,5.54c.16,.13,.39,.11,.52-.05h0c.13-.16,.11-.39-.05-.52l-6.59-5.54c-.16-.13-.39-.11-.52,.05h0c-.13,.16-.11,.39,.05,.52Z" />
                        </g>
                    </AnimatedG>
                    <AnimatedG moved={scrollIndex == 0}>
                        <g>
                            <g>
                                <path class="cls-22"
                                    d="M1145.65,819.29l10.7-6.37c2.38-1.42,5.45-.64,6.87,1.74l14.4,24.17c2.7,4.53,1.21,10.39-3.32,13.09l-2.9,1.73c-4.53,2.7-10.39,1.21-13.09-3.32l-14.4-24.17c-1.42-2.38-.64-5.45,1.74-6.87Z" />
                                <path class="cls-83"
                                    d="M1175.16,851.22l1.51,2.53c.31,.53,.19,1.18-.28,1.46l-3.07,1.83c-.47,.28-1.11,.08-1.42-.45l-1.51-2.53c-.58-.98,4.19-3.82,4.77-2.84Z" />
                                <path class="cls-10"
                                    d="M1156.79,843.81h22.53c.48,0,.87,.39,.87,.87v.09c0,.48-.39,.87-.87,.87h-22.53c-.48,0-.87-.39-.87-.87v-.1c0-.48,.39-.87,.87-.87Z"
                                    transform="translate(-267.76 716.8) rotate(-30.78)" />
                                <path class="cls-10"
                                    d="M1150.72,833.63h22.53c.48,0,.87,.39,.87,.87v.1c0,.48-.39,.87-.87,.87h-22.53c-.48,0-.87-.39-.87-.87v-.09c0-.48,.39-.87,.87-.87Z"
                                    transform="translate(-263.4 712.25) rotate(-30.78)" />
                                <path class="cls-10"
                                    d="M1144.7,823.53h22.53c.48,0,.87,.39,.87,.87v.1c0,.48-.39,.87-.87,.87h-22.53c-.48,0-.87-.39-.87-.87v-.09c0-.48,.39-.87,.87-.87Z"
                                    transform="translate(-259.08 707.75) rotate(-30.78)" />
                            </g>
                            <path class="cls-22"
                                d="M1144.75,820.82c-.58-.97,2.56-2.52,6.25-4.72,3.69-2.2,6.55-4.22,7.12-3.25,.58,.97-1.93,3.57-5.62,5.77-3.69,2.2-7.18,3.16-7.75,2.2Z" />
                        </g>
                    </AnimatedG>
                    <AnimatedG moved={scrollIndex == 0}>
                            <g>
                                <path class="cls-22"
                                    d="M1060.25,888.48l-13.65-14.73c-3.03-3.27-2.83-8.38,.44-11.41l33.29-30.83c6.24-5.78,15.98-5.41,21.76,.83l3.69,3.99c5.78,6.24,5.41,15.98-.83,21.76l-33.29,30.83c-3.27,3.03-8.38,2.83-11.41-.44Z" />
                                <path class="cls-83"
                                    d="M1100.71,831.23l3.49-3.23c.73-.67,1.8-.69,2.4-.04l3.91,4.22c.6,.65,.5,1.72-.23,2.39l-3.49,3.23c-1.35,1.25-7.43-5.32-6.08-6.57Z" />
                                <path class="cls-10"
                                    d="M1092.78,825.05h.16c.77,0,1.4,.63,1.4,1.4v36.32c0,.77-.63,1.4-1.4,1.4h-.15c-.77,0-1.4-.63-1.4-1.4v-36.33c0-.77,.63-1.4,1.4-1.4Z"
                                    transform="translate(-282.86 967.54) rotate(-42.8)" />
                                <path class="cls-22"
                                    d="M1090.08,871.93l-24.68-26.65c-.53-.57-.49-1.45,.08-1.98l.11-.1c.57-.53,1.45-.49,1.98,.08l24.68,26.65c.53,.57,.49,1.45-.08,1.98l-.11,.1c-.57,.53-1.45,.49-1.98-.08Z" />
                                <path class="cls-10"
                                    d="M1064.84,850.92h.16c.77,0,1.4,.63,1.4,1.4v36.32c0,.77-.63,1.4-1.4,1.4h-.15c-.77,0-1.4-.63-1.4-1.4v-36.32c0-.77,.63-1.4,1.4-1.4Z"
                                    transform="translate(-307.88 955.44) rotate(-42.8)" />
                            </g>
                            <path class="cls-22"
                                d="M1062.97,889.39c-1.33,1.23-4.83-3.18-9.54-8.27-4.71-5.08-8.85-8.91-7.51-10.14,1.33-1.23,6.28,1.84,10.99,6.93,4.71,5.08,7.4,10.25,6.07,11.49Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-76"
                                d="M851.85,805.84l-.34-1.97,.53-.75,1.38-.24,.75,.53,.34,1.97-.53,.75-1.38,.24-.75-.53Z" />
                            <path class="cls-33"
                                d="M848.35,809.27l1.15-.2c2.05-.36,3.37-2.31,3.06-4.37-.31-2.11-2.1-3.46-4.19-3.1l-1.32,.23c-.8,.14-6.25,1.09-7.05,1.23l-7.2,1.26c-.6,.11-1.01,.68-.9,1.28l.92,5.25c.11,.6,.68,1.01,1.28,.9l7.2-1.26c.8-.14,6.25-1.09,7.05-1.23Z" />
                            <path class="cls-73"
                                d="M847.05,801.83c-.8,.14-6.25,1.09-7.05,1.23l-7.2,1.26,1.3,7.44,7.2-1.26c.8-.14,6.25-1.09,7.05-1.23l-1.3-7.44Z" />
                            <path class="cls-33"
                                d="M848.03,807.45l1.15-.2c2.05-.36,3.51-1.5,3.38-2.56-.13-1.09-1.79-1.65-3.87-1.29l-1.32,.23c-.8,.14-14.24,2.49-14.24,2.49-.6,.11-1.05,.44-.99,.75l.47,2.69c.05,.31,.59,.47,1.19,.37,0,0,13.44-2.35,14.24-2.49Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <rect class="cls-127" x="1183.81" y="802.29" width="5.28" height="4.32" rx=".59" ry=".59"
                                transform="translate(-230.85 589.84) rotate(-25.53)" />
                            <path class="cls-33"
                                d="M1195.64,809.64l-.81-1.69c-1.44-3.01-5.03-4.2-8.07-2.85-3.12,1.39-4.45,4.75-2.98,7.81l.93,1.94c.56,1.18,4.39,9.18,4.95,10.36l5.05,10.58c.42,.89,1.49,1.26,2.37,.84l7.72-3.69c.89-.42,1.26-1.49,.84-2.37l-5.05-10.58c-.56-1.18-4.39-9.18-4.95-10.36Z" />
                            <path class="cls-73"
                                d="M1184.71,814.87c.56,1.18,4.39,9.18,4.95,10.36l5.05,10.58,10.93-5.22-5.05-10.58c-.56-1.18-4.39-9.18-4.95-10.36l-10.93,5.22Z" />
                            <path class="cls-33"
                                d="M1192.98,810.92l-.81-1.69c-1.44-3.01-3.83-4.78-5.41-4.12-1.62,.68-1.78,3.47-.32,6.54l.93,1.94c.56,1.18,10,20.94,10,20.94,.42,.89,1.14,1.43,1.59,1.21l3.96-1.89c.45-.22,.48-1.11,.06-2,0,0-9.44-19.76-10-20.94Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-76"
                                d="M1248.03,803.58l.36-1.78,.69-.46,1.25,.25,.46,.69-.36,1.78-.69,.46-1.25-.25-.46-.69Z" />
                            <path class="cls-33"
                                d="M1243.93,805.33l1.04,.21c1.85,.37,3.61-.84,4.03-2.68,.43-1.89-.63-3.62-2.52-4.01l-1.19-.24c-.72-.15-5.64-1.14-6.37-1.29l-6.5-1.32c-.55-.11-1.08,.24-1.19,.79l-.96,4.74c-.11,.55,.24,1.08,.79,1.19l6.5,1.32c.72,.15,5.64,1.14,6.37,1.29Z" />
                            <path class="cls-69"
                                d="M1245.29,798.61c-.72-.15-5.64-1.14-6.37-1.29l-6.5-1.32-1.36,6.72,6.5,1.32c.72,.15,5.64,1.14,6.37,1.29l1.36-6.72Z" />
                            <path class="cls-33"
                                d="M1244.26,803.69l1.04,.21c1.85,.37,3.46-.11,3.7-1.04,.25-.96-.96-1.99-2.85-2.37l-1.19-.24c-.72-.15-12.87-2.6-12.87-2.6-.55-.11-1.03,.03-1.09,.31l-.49,2.43c-.06,.28,.34,.6,.88,.71,0,0,12.14,2.46,12.87,2.6Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-76"
                                d="M942.14,804.77l-.85,1.6-.79,.24-1.12-.6-.24-.79,.85-1.6,.79-.24,1.12,.6,.24,.79Z" />
                            <path class="cls-33"
                                d="M946.57,804.27l-.93-.5c-1.66-.89-3.7-.22-4.63,1.42-.95,1.68-.43,3.65,1.27,4.56l1.07,.57c.65,.35,5.08,2.71,5.73,3.05l5.85,3.12c.49,.26,1.1,.08,1.36-.42l2.28-4.27c.26-.49,.08-1.1-.42-1.36l-5.85-3.12c-.65-.35-5.08-2.71-5.73-3.05Z" />
                            <path class="cls-69"
                                d="M943.34,810.32c.65,.35,5.08,2.71,5.73,3.05l5.85,3.12,3.22-6.05-5.85-3.12c-.65-.35-5.08-2.71-5.73-3.05l-3.22,6.05Z" />
                            <path class="cls-32"
                                d="M945.78,805.74l-.93-.5c-1.66-.89-3.35-.89-3.85-.06-.51,.85,.36,2.18,2.05,3.08l1.07,.57c.65,.35,11.58,6.17,11.58,6.17,.49,.26,1,.27,1.13,.02l1.17-2.19c.13-.25-.15-.67-.65-.93,0,0-10.93-5.83-11.58-6.17Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-127"
                                d="M856.71,845.26h3.82c.4,0,.72,.32,.72,.72v4.99c0,.4-.32,.72-.72,.72h-3.82c-.4,0-.72-.32-.72-.72v-4.99c0-.4,.32-.72,.72-.72Z"
                                transform="translate(-201.35 270.9) rotate(-16.07)" />
                            <path class="cls-33"
                                d="M866.71,838.45l-2.19,.63c-3.91,1.13-6.07,5.21-5.04,9.14,1.05,4.04,4.82,6.3,8.81,5.15l2.52-.73c1.53-.44,11.93-3.44,13.46-3.88l13.74-3.96c1.15-.33,1.82-1.54,1.49-2.69l-2.89-10.03c-.33-1.15-1.54-1.82-2.69-1.49l-13.74,3.96c-1.53,.44-11.93,3.44-13.46,3.88Z" />
                            <path class="cls-73"
                                d="M870.8,852.66c1.53-.44,11.93-3.44,13.46-3.88l13.74-3.96-4.09-14.2-13.74,3.96c-1.53,.44-11.93,3.44-13.46,3.88l4.09,14.2Z" />
                            <path class="cls-32"
                                d="M867.71,841.92l-2.19,.63c-3.91,1.13-6.52,3.65-6.04,5.68,.49,2.08,3.82,2.84,7.81,1.69l2.52-.73c1.53-.44,27.2-7.84,27.2-7.84,1.15-.33,1.95-1.08,1.78-1.67l-1.48-5.14c-.17-.59-1.24-.8-2.4-.47,0,0-25.67,7.4-27.2,7.84Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-68"
                                d="M1155.95,885.31c-1.78,7.01-10.06,10.96-18.51,8.81-8.45-2.14-13.85-9.56-12.08-16.57,1.78-7.01,10.06-10.96,18.51-8.81,8.45,2.14,13.85,9.56,12.08,16.57Z" />
                            <path class="cls-68"
                                d="M1148.31,883.37c-.93,3.69-5.12,5.81-9.35,4.73-4.23-1.07-6.9-4.93-5.96-8.62,.93-3.69,5.12-5.81,9.35-4.73,4.23,1.07,6.9,4.93,5.96,8.62Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-68"
                                d="M1109.33,869.74c-1.01,4-8.31,5.59-16.31,3.57-7.99-2.03-13.65-6.91-12.64-10.91,1.01-4,8.31-5.59,16.31-3.57,7.99,2.03,13.65,6.91,12.64,10.91Z" />
                            <path class="cls-68"
                                d="M1102.11,867.91c-.53,2.1-4.21,2.98-8.21,1.97-4-1.01-6.81-3.54-6.28-5.64,.53-2.1,4.21-2.98,8.21-1.97,4,1.01,6.81,3.54,6.28,5.64Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <g>
                                <path class="cls-79"
                                    d="M1326.36,859.11l-1.13-.87c-.19-.15-.47-.09-.64,.13-.18,.22-.2,.53-.05,.73l.88,1.17c.18,.23,.51,.21,.72-.05l.24-.3c.21-.26,.2-.64-.03-.81Zm-.29,.29c.1,.08,.11,.25,.01,.37l-.11,.14c-.09,.12-.25,.13-.33,.02l-.4-.53c-.07-.09-.06-.23,.02-.33,.08-.1,.21-.12,.29-.06l.51,.39Z" />
                                <g>
                                    <path class="cls-79"
                                        d="M1307.43,856.83l.85,5.04c.13,.79,.82,1.22,1.54,.96l6.69-3.93,5.95,2.74c.71,.1,1.43-.49,1.61-1.32l1.17-5.27c.18-.82-.24-1.57-.95-1.67l-7.45,.73-8.36,.82c-.72,.26-1.19,1.11-1.06,1.9Z" />
                                    <path class="cls-78"
                                        d="M1322.46,861.64l-6.12-2.18-6.52,3.37-1.33-7.9s1.24-.39,2.38-.72c.76-.22,2.6-.12,2.88-.16,.59-.09,1.22-1.7,2.03-1.75,.86-.05,1.62,.81,2.49,.88,.39,.03,.91-.76,1.28-.72,2.61,.32,4.75,.92,4.75,.92l-1.84,8.25Z" />
                                    <path class="cls-19"
                                        d="M1307.73,858.62l.25,1.47c.09,.51,.53,.79,1,.62l6.99-3,7.48,1.6c.46,.06,.93-.32,1.05-.85l.34-1.53c.12-.54-.16-1.02-.62-1.08,0,0-5.97-1.47-8.06-1.25-1.97,.21-7.74,2.79-7.74,2.79-.47,.17-.77,.72-.69,1.23Z" />
                                    <path class="cls-82"
                                        d="M1307.03,856.9l.86,5.13c.02,.12,.13,.19,.24,.15h0c.11-.04,.18-.17,.16-.29l-.86-5.13c-.02-.12-.13-.19-.24-.15h0c-.11,.04-.18,.17-.16,.29Z" />
                                    <path class="cls-82"
                                        d="M1325.27,854.98l-1.19,5.36c-.03,.13,.04,.24,.15,.26h0c.11,.02,.22-.08,.25-.2l1.19-5.36c.03-.13-.04-.24-.15-.26h0c-.11-.02-.22,.08-.25,.2Z" />
                                </g>
                            </g>
                            <path class="cls-30"
                                d="M1316.34,859.46l3.72,1.32s.89-2.14,.14-2.79c-.75-.66-4.55-.84-4.23-.28,.31,.55,.37,1.75,.37,1.75Z" />
                            <path class="cls-30"
                                d="M1316.34,859.46l-4.71,2.43s-.94-1.06-1.12-1.24c-.17-.17,2.36-2.89,2.58-2.69,.12,.11,2.88-.26,2.88-.26l1.4,.69-1.03,1.07Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-156"
                                d="M856.57,805.37s-4.31-.45-6.77,.44c-2.46,.88-3.45,.3-3.45,.3l-.17-.07-.16-.02v-.15s-1.07-.11-1.07-.11l-.39,4.11,1.09,.11v-.16s.18,.02,.18,.02l.18-.04s1.09-.39,3.33,.95c2.24,1.34,6.56,1.72,6.56,1.72,0,0,8.07,.88,8.66,.45,.45-.33,.99-5.93,.61-6.34-.5-.54-8.59-1.21-8.59-1.21Z" />
                            <path class="cls-150"
                                d="M856.57,805.37s-2.6,6.12-1.41,8.13l.78,.77c.17-1.02,.97-.7,.97-.7,0,0,.85-.9,2.86-.73h0c.43-2.29,.65-4.61,.68-6.93v-.16c-2-.22-3.89-.38-3.89-.38Z" />
                            <path class="cls-31"
                                d="M856.39,807.28s-4.31-.43-6.71-.14c-2.4,.29-3.42-.04-3.42-.04l-.17-.04-.16-.02v-.07s-1.08-.1-1.08-.1l-.18,1.9,1.09,.11v-.08s.17,.02,.17,.02h.17s1.06-.13,3.37,.61c2.3,.74,6.61,1.14,6.61,1.14,0,0,8.08,.83,8.64,.65,.43-.13,.68-2.72,.28-2.93-.52-.28-8.61-1.01-8.61-1.01Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-156"
                                d="M944.82,842.13s8.95-2.61,13.12-6.37c4.18-3.76,6.64-3.4,6.64-3.4h.39s.34-.1,.34-.1l.09,.3,2.25-.67-2.56-8.51-2.25,.67,.11,.34-.34,.1-.32,.22s-1.85,1.66-7.41,.81c-5.56-.84-14.46,1.92-14.46,1.92,0,0-16.79,4.81-17.61,6.15-.63,1.04,2.85,12.61,3.94,13.13,1.43,.67,18.09-4.57,18.09-4.57Z" />
                            <path class="cls-112"
                                d="M944.82,842.13s-8.33-13.59-12.47-12.28h0c1.01,4.9,2.45,9.71,4.28,14.36l.13,.32c4.18-1.19,8.06-2.41,8.06-2.41Z" />
                            <path class="cls-61"
                                d="M943.64,838.18s8.94-2.65,13.48-5.18c4.55-2.53,6.85-2.71,6.85-2.71l.37-.06,.34-.1,.04,.14,2.25-.67-1.19-3.94-2.25,.67,.05,.16-.34,.1-.34,.16s-2.02,1.11-7.2,1.51c-5.19,.4-14.1,3.11-14.1,3.11,0,0-16.75,4.92-17.74,5.73-.76,.62,.85,5.97,1.82,6.07,1.27,.13,17.96-5,17.96-5Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-152"
                                d="M1120.93,881.35s-8.25-2.73-13.44-2.05c-5.2,.68-6.89-.9-6.89-.9l-.3-.21-.31-.1,.09-.28-2.08-.67-2.54,7.89,2.08,.67,.1-.32,.31,.1h.36s2.3-.29,6.12,3.3c3.82,3.58,12.11,6.18,12.11,6.18,0,0,15.43,5.19,16.77,4.6,1.03-.46,4.49-11.19,3.92-12.16-.74-1.27-16.3-6.06-16.3-6.06Z" />
                            <path class="cls-62"
                                d="M1119.75,885.01s-8.26-2.69-13.09-3.16c-4.83-.47-6.69-1.54-6.69-1.54l-.31-.15-.31-.1,.04-.13-2.08-.67-1.17,3.65,2.08,.67,.04-.15,.31,.1,.34,.06s2.14,.21,6.33,2.65c4.19,2.44,12.47,5.07,12.47,5.07,0,0,15.46,5.08,16.65,4.99,.91-.07,2.51-5.03,1.81-5.62-.9-.77-16.43-5.67-16.43-5.67Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-152"
                                d="M1117.56,866.37s6.59-1.29,9.83-3.71c3.24-2.42,4.98-2.01,4.98-2.01l.28,.03,.25-.05,.05,.22,1.65-.34-1.28-6.27-1.66,.33,.05,.25-.25,.05-.25,.13s-1.44,1.07-5.37,.1c-3.93-.96-10.49,.43-10.49,.43,0,0-12.35,2.35-13.03,3.26-.52,.7,1.22,9.23,1.97,9.67,.98,.57,13.27-2.09,13.27-2.09Z" />
                            <path class="cls-62"
                                d="M1116.96,863.46s6.58-1.31,10.01-2.83c3.43-1.51,5.09-1.49,5.09-1.49l.27-.02,.25-.05,.02,.1,1.65-.34-.59-2.9-1.66,.34,.03,.11-.25,.05-.25,.09s-1.52,.67-5.26,.61c-3.75-.05-10.31,1.31-10.31,1.31,0,0-12.34,2.43-13.1,2.95-.58,.39,.22,4.34,.91,4.47,.9,.17,13.2-2.41,13.2-2.41Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-152"
                                d="M1201.54,888.61s8.67-3.35,12.5-7.45c3.83-4.09,6.31-3.95,6.31-3.95l.39-.03,.33-.13,.12,.29,2.18-.86-3.27-8.24-2.18,.86,.13,.33-.33,.13-.3,.24s-1.7,1.8-7.3,1.44c-5.59-.36-14.21,3.13-14.21,3.13,0,0-16.28,6.2-16.98,7.61-.54,1.08,3.9,12.29,5.03,12.71,1.48,.54,17.58-6.08,17.58-6.08Z" />
                            <path class="cls-62"
                                d="M1200.02,884.78s8.65-3.39,12.96-6.29c4.3-2.9,6.57-3.27,6.57-3.27l.36-.09,.33-.13,.05,.13,2.17-.86-1.51-3.81-2.18,.86,.06,.15-.33,.13-.32,.19s-1.91,1.28-7.03,2.11c-5.12,.84-13.75,4.29-13.75,4.29,0,0-16.23,6.32-17.14,7.2-.7,.68,1.35,5.86,2.33,5.88,1.27,.02,17.42-6.49,17.42-6.49Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-18"
                            d="M1203.56,874.79c-.08-.85-.27-1.72-.75-2.44-.61-.92-1.62-1.48-2.62-1.93-2.5-1.14-5.19-1.86-7.58-3.2-2.43-1.36-4.5-3.33-7.02-4.51-3.02-1.41-6.48-1.58-9.8-1.31-3.89,.31-7.86,1.26-10.94,3.64-4.79,3.69-6.59,10.1-6.99,16.13-.17,2.57-.13,5.2,.65,7.65,.46,1.46,1.18,2.82,1.64,4.28,1.08,3.39,6.1,8.58-4.34,8.93-1.95,.07-9.21,2.14-7.12,6.02,.89,1.66,4.2,2.56,5.34,2.59,3.17,.06,6.3-1.45,8.73-3.4,2.32-1.86,4.81-4.32,7.74-3.81,.9,.16,1.87,.82,1.78,1.73-.05,.56-.49,.99-.89,1.37-2.12,1.99-4.24,3.98-6.35,5.97-.66,.62-1.37,1.47-1.05,2.31,.33,.88,1.5,1.01,2.43,.97,3.51-.16,7.05-.75,10.2-2.29,3.15-1.54,5.89-4.13,7.09-7.44,.36-.99,.58-2.04,.96-3.02,.4-1.03,.98-1.97,1.62-2.87,3.06-4.31,7.41-7.51,11.06-11.33,3.65-3.82,6.75-8.78,6.24-14.04Zm-39.58,28.35c-2.23,2.16-4.58,4.4-7.55,5.29-1.1,.33-2.25,.46-3.39,.53-1.25,.07-2.78-.11-3.31-1.24-.34-.71-.12-1.6,.41-2.18,2.11-2.28,6.76-1.34,9.46-2.18,1.41-.43,2.76-1.3,4.23-1.14,.15,.02,.32,.05,.41,.18,.17,.23-.05,.54-.26,.74Zm12.8,7.07c-1.82,.56-3.81,.71-5.43,1.72-.97,.61-1.77,1.52-2.82,1.97-1.07,.46-2.28,.41-3.43,.28-.12-.01-.25-.03-.34-.11-.28-.22-.06-.67,.18-.93,2.38-2.64,5.59-4.37,8.3-6.67,.68-.58,.3-1.47,.85-2.11,1.16-1.35,3.76,.22,5.49,.62,.22,.05,.44,.1,.62,.24,.37,.26,.47,.77,.43,1.22-.13,1.9-2.02,3.2-3.84,3.76Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M1309.28,896.29c4.88-3.67,2.31-9.09,.23-13.49-3.96-8.39-1.57-7.57,2.68-6.47,2.6,.67-2.89,14.18,5.54,10.9,3.82-1.49,9.61-7.16,3.52-14.56-2.75-3.34-1.15-6.55-3.26-10.32-1.51-2.7-.32-6.34,.11-9.02,.94-5.87,1.35-12.13-6.5-16.41-8.15-4.44-15.63-.91-21.98,.44-5.5,1.17-11.49,4.39-11.12,8.53,.12,1.37,.8,2.72,.97,4.08,.32,2.65,.1,5.42,.23,8.09,.18,3.73,.89,6.71,2.63,10.31,1.31,2.7,3.25,4.04,6.24,5.97,1.37,.89,3.12,1.63,4.29,2.68,1.67,1.49,1.32,3.28,1.46,4.99,.39,4.81-.25,9.67,5.47,13.58,2.64,1.81,6.92,2.62,9.48,.7Zm4.67-23.83c5.12,.1,6.1,7.27,5.86,9.51-.13,1.17-1.26,4.3-3.89,4.12-2.42-.17-1.09-5.24-1.02-6.51,.11-1.93-.32-2.52-1.51-4.07-.67-.87-1.14-1.86,.56-3.04Zm-10.73,22.08c-.74-1.72,.32-3.29-.68-5.11-1.06-1.91-3.46-3.37-4.1-5.34-.9-2.77,.73-5.37,4.24-6.65,2.89-1.06,2.97,1.4,3.58,3.21,.83,2.47,3.3,5.76,3.84,8.25,.51,2.38-.08,4.1-2.09,5.86-1.4,1.23-3.8,2.07-4.78-.21Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M1270.9,814.65c-2.57-1.91-5.55-.37-7.98,.88-4.62,2.38-5.74,1.27-4,.29,1.26-.71,8.57-.75,5.93-4.27-1.19-1.6-4.12-.46-8.19,1.35-2.09,.92-4.47-.87-6.52,.34-1.47,.87-3.72,.61-5.33,.61-3.54,0-7.27,.28-9.15,4.09-1.95,3.95-3.14,7.38,3.32,6.01,1.91-.41,3.61,.79,2.58,6.11-.15,.8,1.91,3.16,3.67-.16,.74-1.39,3.31-.66,4.76-1.3,2.72-1.2,.74-3.62,2.72-4.66,1.49-.78,2.01,.08,3.7,.4,2.52,.47,3.93-.36,4.45-.96,.74-.85,8.55-1.61,10.39-4.44,.85-1.31,.98-3.28-.37-4.28Zm-14.9,.98c-.36-2.29,3.78-3.25,5.13-3.31,.7-.03,3.09-1.09,3.2,.1,.1,1.09-3.45,2.2-4.21,2.26-1.15,.09-1.46,.33-2.28,.97-.46,.36-1,.64-1.84-.03Zm14.36,1.85c-.96,.45-4.66,2.46-5.77,2.89-1.56,.6-3.23,.06-4.28-1.41-.86-1.21,.58-1.43,1.6-1.83,1.39-.55,3.13-1.89,4.55-2.31,1.36-.4,2.42-.26,3.63,.51,.84,.53,1.54,1.55,.27,2.15Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M1380.05,838.93c-1-3.19-4.46-3.72-7.26-4.15-5.36-.83-5.61-2.45-3.54-2.21,1.5,.17,7.68,4.62,7.62,.03-.03-2.08-3.19-2.91-7.73-3.89-2.32-.5-3.23-3.47-5.7-3.71-1.77-.17-3.51-1.77-4.86-2.76-2.99-2.17-6.3-4.23-10.22-2.16-4.07,2.14-7.17,4.3-.89,7.1,1.86,.83,2.56,2.89-1.57,6.74-.62,.58-.33,3.84,3.19,2.12,1.47-.72,3.2,1.48,4.81,1.83,3.03,.66,2.85-2.6,5.16-2.26,1.73,.25,1.65,1.3,2.88,2.61,1.83,1.94,3.54,2.11,4.34,1.92,1.15-.27,8.19,3.89,11.48,2.63,1.52-.58,2.83-2.16,2.31-3.83Zm-13.16-8.31c1.1-2.15,5.18-.42,6.35,.36,.61,.41,3.27,.98,2.63,2.05-.59,.98-4.26-.26-4.94-.67-1.02-.63-1.43-.62-2.51-.58-.61,.02-1.24-.07-1.53-1.16Zm10.98,10.37c-1.08-.2-5.44-.78-6.64-1.1-1.69-.45-2.76-1.93-2.74-3.81,.02-1.55,1.37-.85,2.47-.56,1.51,.39,3.8,.32,5.26,.85,1.39,.5,2.2,1.27,2.75,2.65,.38,.96,.35,2.25-1.09,1.98Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M1108.94,858.1c3.18,5.39,9.72,4.88,15.03,4.48,10.13-.77,11.29,2.07,7.41,2.52-2.81,.33-15.99-5.15-13.92,3.19,.94,3.78,7.06,3.94,15.76,3.79,4.45-.08,7.38,4.96,11.99,4.34,3.3-.45,7.15,1.73,10.05,2.95,6.38,2.68,13.3,5.02,19.57-.42,6.5-5.64,11.25-10.9-1.41-13.33-3.75-.72-5.9-4.17,0-12.96,.89-1.32-1.03-7.14-6.72-2.5-2.38,1.94-6.46-1.33-9.56-1.28-5.81,.09-4.09,5.96-8.44,6.33-3.27,.28-3.56-1.68-6.36-3.53-4.17-2.77-7.35-2.33-8.74-1.65-1.98,.98-16.61-3.6-22.07,.1-2.52,1.71-4.25,5.16-2.58,7.98Zm27.56,9.54c-1.09,4.4-9.28,2.98-11.74,2.06-1.29-.48-6.39-.39-5.68-2.61,.65-2.04,7.88-1.35,9.29-.88,2.14,.71,2.88,.52,4.83-.02,1.1-.3,2.29-.4,3.29,1.45Zm-24.45-14.23c2.06-.09,10.26-.9,12.59-.83,3.27,.1,5.86,2.34,6.63,5.78,.63,2.84-2.13,2.13-4.26,2.08-2.92-.07-7.07,1.03-9.95,.7-2.75-.31-4.56-1.37-6.15-3.66-1.1-1.6-1.59-3.95,1.14-4.07Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-9"
                                d="M1294.53,842.29c.03,.1,.72-.1,1.92-.58,2.2-1.18,9.96-3.72,11.35-3.2,1.26,.27,3.12,5.54,2.48,6.5-.76,.54-11.05,3.54-11.66,2.82-.12,.46,.11,1.23,.32,1.45,.08,.08,.42,.17,.59,.14,2.12-.44,4.3-.86,7.5-1.77,1.27-.42,5.39-1.85,7.37-2.08,2.15-.33,4.56-.6,6.71-.61,2.29-.48,13.07,.76,14.81,1.28,2.12,.09-.71-3.19-.74-4.14-.03-1.37-.55-3.02-.95-4.36-.52-1.72-1.74-4.55-2.61-6.32-.64-1.32-3.46-1.73-6.39-1.91-2.37-.27-7.67-.05-10.07,.18-1.66,.09-3.38,.48-5.15,1.12,7.1-1.12-2.69,5.45-4.52,6.32-9.12,3.13-10.05,3.09-10.99,3.71-.25,.27-.06,1.08,.04,1.43Z" />
                            <path class="cls-4"
                                d="M1325.23,829.52c-2.37-.27-7.67-.05-10.07,.18-2.44,.13-5,.91-7.67,2.19-2.22,1.08-3.97,2.35-6.1,3.83-1.77,1.16-5.41,3.43-6.89,5.13-.25,.27-.06,1.08,.04,1.43,.03,.1,.17,.09,.32-.01,1.18-.85,2.35-1.82,3.55-2.64,1.39-.96,2.86-1.76,4.38-2.34,1.19-.45,2.39-.75,3.33-.37,1.72,.71,2.86,2.4,3.05,3.93,.14,1.12-.61,1.74-2.25,2.55-1.46,.73-2.96,1.58-4.44,2.24-.79,.36-1.57,.75-2.33,1.17-.25,.13-1.44,.72-1.51,1.01-.12,.46,.11,1.23,.32,1.45,.08,.08,.25,.07,.42-.03,1.53-1.45,12.62-6.57,14.44-6.02,4.68,.2-.22,2.4-.21,2.43-.01-.08,5.36-.82,7.51-.7,2.29-.48,13.07,.76,14.81,1.28,2.12,.09-.71-3.19-.74-4.14-.03-1.37-.55-3.02-.95-4.36-.52-1.72-1.74-4.55-2.61-6.32-.64-1.32-3.46-1.73-6.39-1.91Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-77"
                                d="M1232.31,796.63l.03,.61c-.47,.71,.13,1.88,.83,2.18,.16-.51,.3-1.06,.57-1.52,.24-.4,.71-.4,.97-.74-.28-.16-.61-.29-.91-.43-.18-.08-.64-.4-.85-.39-.28,.02-.38,.4-.65,.29Z" />
                            <path class="cls-75"
                                d="M1219.92,795.68l.34,1.8-.47,.69-1.26,.24-.69-.47-.34-1.8c-.06-.32,.15-.63,.47-.69l1.26-.24,.69,.47Z" />
                            <path class="cls-67"
                                d="M1227.12,792.61l-1.27,.24c-.21,.04-.41,0-.59-.1-.65-.37-1.44-.4-2.17-.26l-1.05,.2c-1.87,.35-3.06,2.17-2.76,4.05,.31,1.93,1.97,3.16,3.88,2.8l1.21-.23c.73-.14,1.45-.46,1.92-1.04,.12-.15,.3-.26,.51-.3l1.27-.24c.21-.04,.41,0,.59,.1,.65,.37,1.44,.4,2.17,.26l8.74-1.65c.55-.1,.92-.64,.81-1.19l-.91-4.81c-.1-.55-.64-.92-1.19-.81l-8.74,1.65c-.73,.14-1.45,.46-1.92,1.04-.12,.15-.3,.26-.51,.3Z" />
                            <path class="cls-74"
                                d="M1228.95,791.96c.19,.2,.53,.36,.71,.5,.38,.3,.84,.41,1.12,.83,.22,.33,.12,.98,.49,1.16,.24,.11,.55-.14,.8-.08,.38,.08,.56,.46,.72,.77,.25,.5,.4,.99,.66,1.47,.04,.07,.07,.16,.09,.27,.09,.37,.47,.61,.85,.54l3.07-.58-1.3-6.81-6.73,1.27c-.2,.04-.57,.57-.48,.66Z" />
                            <path class="cls-37"
                                d="M1228.97,791.43s0,.41,.1,.51c.19,.2,.53,.36,.71,.5,.38,.3,.85,.41,1.13,.83,.22,.33,.13,.78,.5,.96,.24,.11,.67-.33,1.07-.11,.34,.19,.28,.69,.44,1,.25,.5,.4,.99,.66,1.47,.02,.03,.03,.06,.04,.09,.18,.48,.69,.75,1.2,.66l2.8-.53-1.3-6.81-6.77,1.28c-.2,.04-.39,.09-.58,.16Z" />
                            <path class="cls-67"
                                d="M1227.35,793.86l-1.27,.24c-.21,.04-.41,.04-.57,0-.61-.14-1.38-.09-2.11,.05l-1.05,.2c-1.87,.35-3.2,1.42-3.07,2.39,.14,.99,1.66,1.5,3.57,1.13l1.21-.23c.73-.14,1.47-.37,1.98-.72,.14-.09,.32-.17,.53-.21l1.27-.24c.21-.04,.41-.04,.57,0,.61,.14,1.38,.09,2.11-.05l8.74-1.65c.55-.1,.96-.42,.9-.7l-.46-2.47c-.05-.28-.54-.43-1.1-.32l-8.74,1.65c-.73,.14-1.47,.37-1.98,.72-.14,.09-.32,.17-.53,.21Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-131"
                                d="M880.92,830.6h4.7c.38,0,.68,.31,.68,.68v3.6c0,.38-.31,.68-.68,.68h-4.7c-.38,0-.68-.31-.68-.68v-3.6c0-.38,.31-.68,.68-.68Z"
                                transform="translate(24.52 1691.42) rotate(-88.31)" />
                            <path class="cls-67"
                                d="M865.29,837.78l2.59,.08c.43,.01,.81,.18,1.11,.44,1.12,1,2.64,1.41,4.14,1.45l2.15,.06c3.83,.11,6.94-2.93,7.15-6.75,.22-3.92-2.51-7.04-6.42-7.15l-2.47-.07c-1.5-.04-3.05,.27-4.22,1.21-.31,.25-.7,.39-1.13,.38l-2.59-.08c-.43-.01-.81-.18-1.11-.44-1.12-1-2.64-1.41-4.14-1.45l-17.86-.53c-1.13-.03-2.07,.86-2.11,1.99l-.29,9.83c-.03,1.13,.86,2.07,1.99,2.11l17.86,.53c1.5,.04,3.05-.27,4.22-1.21,.31-.25,.71-.39,1.13-.38Z" />
                            <path class="cls-63"
                                d="M865.37,835.23l2.59,.08c.43,.01,.81,.1,1.11,.24,1.13,.53,2.66,.76,4.16,.8l2.15,.06c3.83,.11,6.89-1.4,7.05-3.36,.16-2.01-2.61-3.65-6.52-3.76l-2.47-.07c-1.5-.04-3.04,.1-4.2,.56-.31,.12-.7,.19-1.13,.18l-2.59-.08c-.43-.01-.81-.1-1.11-.24-1.13-.53-2.66-.76-4.16-.8l-17.86-.53c-1.13-.03-2.06,.41-2.08,.99l-.15,5.04c-.02,.58,.88,1.08,2.01,1.11l17.86,.53c1.5,.04,3.04-.1,4.2-.56,.31-.12,.7-.19,1.13-.18Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-35"
                                d="M1348.36,850.09c1.39,2.11,.78,4.55-.33,6.07-1.71,2.34-4.1,1.48-4.1,1.48-1.96,1.59-2.84-1.1-4.39-.3-.46,.24-.92,.67-1.33,1.16-1.17,1.4-3.19,1.74-4.75,.79h0c-1.94-1.17-2.46-3.86-.94-5.55,.41-.45,.92-.88,1.58-1.27,3.38-1.97,5.01-3.85,6.63-2.85,.64,.39-.15-1.23,1.6-1.81,1.74-.57,4.47-.1,6.03,2.27Z" />
                            <g class="cls-43">
                                <path class="cls-53"
                                    d="M1344.12,853.92c.53-.35,.1-1.25-.12-1.58-.13-.2-.84-1.05-1.16-1-.5,.07-.06,.84-.25,1.14-.42-.07-.53-.09-.58-.51-.07-.59,.11-1.21,.02-1.83-.05-.35-.23-.6-.31-.85-.06-.2-.16-.54,.1-.77,.34-.3,1.28-.23,1.68-.21,.84,.03,2.21,.82,2.96,.25,.06-.05,.11-.1,.16-.15-1.43-.87-3.1-.97-4.3-.58-1.74,.57-.96,2.2-1.6,1.81-1.62-1-3.25,.88-6.63,2.85-.74,.43-1.29,.93-1.72,1.44,.86,.03,1.71-.43,2.59-.57,1.31-.2,1.87-.69,2.8-1.54,.33-.3,1.02-.83,1.41-.45,.12,.12,.09,.34,.16,.48,.14,.28,.37,.5,.48,.84,.42,1.33,.77,2.63,.73,4.05,.52-.64,.37-1.53,.27-2.25-.07-.51-.79-2.82-.04-3.07,1.12,.37,.55,3.23,.54,4.2,0,.58,0,1.28-.21,1.9,.27,.15,.53,.32,.82,.45-.12-.12-.2-.28-.22-.49-.04-.38,.09-.89,.09-1.28,0-.58,.19-1.14,.6-1.59,.27-.29,.73-.77,1.15-.77,.25,0,.36,.24,.57,.1Z" />
                                <path class="cls-53"
                                    d="M1345.7,853.2c-.37,.46-.56,1.16-.8,1.7-.3,.68-.63,1.36-.9,2.06-.25,.66-.49,1.02-1.2,1.18,.33-.03,.7-.16,1.11-.5,0,0,.56,.2,1.34,.14,.78-.43,1.3-1.07,1.59-2.04,.24-.79-.25-2.38-1.16-2.54Z" />
                            </g>
                            <path class="cls-35"
                                d="M1332.66,863.76c-2.58-.97-3.55-4.33-2.17-7.5,1.38-3.17,4.59-4.96,7.17-4,2.58,.97,3.55,4.33,2.17,7.5-1.38,3.17-4.59,4.96-7.17,4Z" />
                            <path class="cls-11"
                                d="M1338.37,853.51c1.2,1.51,1.45,3.92,.44,6.24-1.05,2.42-3.16,4.02-5.25,4.23,2.38,.32,5.04-1.4,6.27-4.22,1.02-2.34,.76-4.78-.48-6.29l-.98,.04Z" />
                            <path class="cls-41"
                                d="M1332.81,863.42c-1.26-.47-1.76-2.04-1.12-3.5,.64-1.46,2.17-2.27,3.43-1.8,1.26,.47,1.76,2.04,1.12,3.5-.64,1.46-2.17,2.27-3.43,1.8Z" />
                            <path class="cls-41"
                                d="M1333.4,857.72c1.17-2.69,3.65-4.37,5.94-4.24-.44-.54-1-.96-1.68-1.21-2.58-.97-5.79,.82-7.17,4-1.38,3.17-.41,6.53,2.17,7.5,.4,.15,.81,.23,1.23,.25-1.24-1.51-1.51-3.95-.49-6.29Z" />
                            <g>
                                <path class="cls-127"
                                    d="M1332.78,863.41c.87,.33,1.79,.22,2.45-.19l.34,.13,.56-1.29c.46-1.07-.22-2.33-1.53-2.82-1.31-.49-2.75-.03-3.21,1.04l-.56,1.29,.34,.13c.16,.72,.75,1.39,1.62,1.72Z" />
                                <path class="cls-150"
                                    d="M1332.36,864.38c1.31,.49,2.75,.03,3.21-1.04,.46-1.07-.22-2.33-1.53-2.82-1.31-.49-2.75-.03-3.21,1.04-.46,1.07,.22,2.33,1.53,2.82Z" />
                            </g>
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-35"
                                d="M813.59,825.57c.19-3.88,3.17-6.34,5.91-7.21,4.24-1.34,6.42,1.89,6.42,1.89,3.87-.18,2.51,3.94,5.17,4.36,.79,.13,1.75,0,2.7-.24,2.72-.68,5.52,.74,6.6,3.32h0c1.35,3.21-.45,7.01-3.87,7.72-.91,.19-1.94,.26-3.1,.14-5.97-.63-9.69,.22-10.8-2.49-.43-1.07-.94,1.66-3.62,.79-2.68-.87-5.63-3.93-5.42-8.28Z" />
                            <g class="cls-43">
                                <path class="cls-53"
                                    d="M822.31,824.67c-.97-.05-1.25,1.46-1.29,2.05-.02,.37,.09,2.07,.53,2.3,.69,.36,.84-.99,1.34-1.19,.45,.46,.57,.59,.25,1.16-.45,.79-1.24,1.4-1.69,2.25-.25,.48-.26,.96-.38,1.34-.1,.3-.3,.81-.82,.86-.69,.07-1.79-.88-2.28-1.26-1.01-.79-1.98-3.02-3.45-2.99-.12,0-.22,.02-.33,.04,.98,2.38,2.95,4.02,4.8,4.62,2.68,.87,3.18-1.86,3.62-.79,1.1,2.71,4.82,1.86,10.8,2.49,1.31,.14,2.45,.02,3.44-.23-1.03-.82-2.51-1.01-3.73-1.65-1.81-.94-2.94-.83-4.87-.64-.69,.07-2.02,.1-2.16-.73-.04-.25,.19-.5,.24-.74,.08-.48,0-.96,.17-1.47,.69-2.02,1.43-3.96,2.77-5.67-1.23,.33-1.84,1.56-2.38,2.54-.38,.69-1.57,4.21-2.73,3.84-1.05-1.48,2.25-4.5,3.13-5.69,.53-.71,1.18-1.58,1.98-2.16-.19-.43-.37-.88-.6-1.3,.04,.26-.01,.53-.17,.8-.29,.5-.92,1.02-1.27,1.5-.52,.72-1.27,1.23-2.19,1.43-.59,.12-1.6,.29-2.12-.09-.31-.22-.23-.62-.62-.64Z" />
                                <path class="cls-53"
                                    d="M819.7,824.12c.88-.23,1.75-.93,2.53-1.38,.99-.58,2.02-1.11,2.97-1.73,.91-.59,1.53-.83,2.55-.37-.44-.27-1.01-.43-1.83-.39,0,0-.52-.76-1.53-1.39-1.35-.18-2.58,.15-3.82,1.08-1.01,.76-1.85,3.18-.87,4.19Z" />
                            </g>
                            <path class="cls-35"
                                d="M845.42,822.87c2.31,3.54,.47,8.57-4.12,11.25-4.59,2.68-10.19,1.98-12.5-1.56-2.31-3.54-.47-8.57,4.12-11.25,4.59-2.68,10.19-1.98,12.5,1.56Z" />
                            <path class="cls-11"
                                d="M829.06,830.39c-.12-2.97,1.77-6.17,5.12-8.12,3.49-2.04,7.57-2.12,10.33-.48-2.66-2.55-7.52-2.83-11.58-.46-3.39,1.98-5.28,5.24-5.11,8.23l1.24,.84Z" />
                            <path class="cls-41"
                                d="M844.92,823.17c1.13,1.72,.33,4.12-1.79,5.36-2.12,1.23-4.75,.84-5.87-.89-1.13-1.72-.33-4.12,1.79-5.36,2.12-1.23,4.75-.84,5.87,.89Z" />
                            <path class="cls-41"
                                d="M839.03,829.68c-3.88,2.27-8.48,2.11-11.21-.13,.06,1.06,.37,2.09,.98,3.02,2.31,3.54,7.91,4.23,12.5,1.56,4.59-2.68,6.44-7.72,4.12-11.25-.36-.54-.8-1.01-1.29-1.42,.17,2.99-1.72,6.25-5.1,8.23Z" />
                            <g>
                                <path class="cls-147"
                                    d="M844.95,823.21c-.78-1.19-2.02-1.9-3.21-1.99l-.3-.46-1.86,1.09c-1.54,.9-1.84,3.09-.66,4.88,1.18,1.8,3.38,2.52,4.92,1.62l1.86-1.09-.3-.46c.45-1.05,.34-2.41-.45-3.6Z" />
                                <path class="cls-149"
                                    d="M846.36,822.38c-1.18-1.8-3.38-2.52-4.92-1.62-1.54,.9-1.84,3.09-.66,4.88,1.18,1.8,3.38,2.53,4.92,1.63,1.54-.9,1.84-3.09,.66-4.88Z" />
                            </g>
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <rect class="cls-126" x="1128.24" y="909.02" width="5.06" height="4.14" rx=".57" ry=".57"
                                transform="translate(-208.92 1471.42) rotate(-61.56)" />
                            <path class="cls-67"
                                d="M1116.24,908.18l1.9,1.03c.31,.17,.54,.44,.66,.74,.46,1.16,1.44,2.04,2.54,2.64l1.58,.85c2.81,1.52,6.27,.42,7.86-2.35,1.63-2.84,.77-6.19-2.09-7.74l-1.81-.98c-1.1-.59-2.37-.94-3.59-.69-.32,.07-.67,.02-.98-.14l-1.9-1.03c-.31-.17-.54-.44-.66-.74-.46-1.16-1.44-2.04-2.54-2.64l-13.1-7.09c-.83-.45-1.86-.14-2.31,.69l-3.9,7.21c-.45,.83-.14,1.86,.69,2.31l13.1,7.09c1.1,.59,2.37,.94,3.59,.69,.32-.07,.67-.02,.98,.14Z" />
                            <path class="cls-63"
                                d="M1117.25,906.31l1.9,1.03c.31,.17,.57,.38,.74,.6,.64,.82,1.7,1.57,2.8,2.16l1.58,.85c2.81,1.52,5.66,1.54,6.51,.14,.87-1.44-.58-3.7-3.44-5.25l-1.81-.98c-1.1-.6-2.3-1.07-3.34-1.16-.27-.02-.59-.12-.9-.29l-1.9-1.03c-.31-.17-.57-.38-.74-.6-.64-.82-1.7-1.57-2.8-2.16l-13.1-7.09c-.83-.45-1.69-.47-1.92-.04l-2,3.7c-.23,.42,.25,1.13,1.08,1.58l13.1,7.09c1.1,.6,2.3,1.07,3.34,1.16,.27,.02,.59,.12,.9,.29Z" />
                            <rect class="cls-88" x="1129.37" y="910.14" width="6.93" height="4.14" rx=".67" ry=".67"
                                transform="translate(-208.78 1473.93) rotate(-61.56)" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-28"
                            d="M1254.89,932.12c2.88,.03,3.69-2.97,3.64-3.92-.03-.5,1.07-2.27-.41-2.23-1.36,.04-2.43,2.64-2.43,3.17,0,.81-.27,1.06-1,1.69-.41,.36-.71,.77,.2,1.29Zm-3.59-9.88c-.48,.72-2.64,3.51-3.07,4.33-.61,1.16,.21,2.27,2.14,2.86,1.58,.48,1.72-.55,2.13-1.3,.56-1.03,2.07-2.38,2.46-3.42,.37-.99,.11-1.72-.95-2.49-.74-.54-2.06-.92-2.69,.02Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <g>
                                <path class="cls-22"
                                    d="M1068.19,793.89l3.24,17.94c.65,3.59-1.74,7.02-5.32,7.67l-33.62,6.07c-6.84,1.23-13.39-3.31-14.62-10.15l-1.11-6.16c-1.23-6.84,3.31-13.39,10.15-14.62l33.62-6.07c3.59-.65,7.02,1.74,7.67,5.32Z" />
                                <path class="cls-83"
                                    d="M1018.19,816.12l-3.62,.65c-.75,.14-1.47-.36-1.61-1.12l-.89-4.91c-.14-.75,.36-1.47,1.12-1.61l3.62-.65c1.4-.25,2.78,7.39,1.38,7.64Z" />
                                <path class="cls-22"
                                    d="M1027.15,794.47l5.62,31.14c.11,.62-.3,1.22-.92,1.33h0c-.62,.11-1.22-.3-1.33-.92l-5.62-31.14c-.11-.62,.3-1.22,.92-1.33h0c.62-.11,1.22,.3,1.33,.92Z" />
                                <path class="cls-22"
                                    d="M1041.73,791.84l5.62,31.14c.11,.62-.3,1.22-.92,1.33h0c-.62,.11-1.22-.3-1.33-.92l-5.62-31.14c-.11-.62,.3-1.22,.92-1.33h0c.62-.11,1.22,.3,1.33,.92Z" />
                                <path class="cls-22"
                                    d="M1056.18,789.23l5.62,31.14c.11,.62-.3,1.22-.92,1.33h0c-.62,.11-1.22-.3-1.33-.92l-5.62-31.14c-.11-.62,.3-1.22,.92-1.33h0c.62-.11,1.22,.3,1.33,.92Z" />
                            </g>
                            <path class="cls-22"
                                d="M1066.82,792.34c1.38-.25,1.92,4.61,2.99,10.52,1.07,5.92,2.27,10.65,.88,10.9-1.38,.25-3.42-4.33-4.49-10.25-1.07-5.92-.76-10.92,.63-11.17Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M924.53,829.13c2.2,1.64,3.83-.36,5.15-1.98,2.52-3.09,3.55-2.09,2.62-.83-.67,.91-5.58,1.93-2.94,5.23,1.2,1.5,2.88-.08,5.18-2.5,1.18-1.24,3.22,.31,4.3-1.23,.77-1.1,2.35-1.13,3.44-1.35,2.38-.48,2.11-6.13,5.15-5.47,7.38,1.6,6.13-17.31-2.75-14.49-4.18,1.33-6.92-3.3-10.23-1.66-6.78,3.34-5.12,13.19-5.32,13.88-.29,.99-5.36,2.81-5.89,6-.25,1.47,.15,3.53,1.3,4.39Zm9.78-3.02c.81,2.33-1.74,3.88-2.64,4.12-.47,.12-1.81,1.54-2.18,.32-.33-1.12,1.78-2.75,2.27-2.91,.75-.25,.9-.53,1.29-1.31,.22-.44,.51-.8,1.25-.22Zm-10.12,.01c.53-.6,2.53-3.18,3.17-3.78,.9-.84,2.16-.5,3.23,.89,.88,1.14-.04,1.56-.62,2.11-.8,.76-1.64,2.38-2.49,3.01-.82,.6-1.57,.6-2.57-.04-.7-.44-1.42-1.4-.71-2.19Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M817.78,817.42c.91-4.41,6.37-4.45,7.99-3.99,.85,.24,4.29-.73,3.72,1.53-.52,2.07-5.37,2.65-6.3,2.44-1.41-.31-1.92,0-3.26,.85-.75,.48-1.56,.78-2.16-.83Zm15.88,9.47c-1.39,.44-6.95,2.62-8.52,2.95-2.2,.47-3.85-1.24-4.23-4.42-.31-2.61,1.52-2.41,2.96-2.73,1.96-.44,4.8-2.2,6.73-2.38,1.84-.17,3.02,.53,3.99,2.47,.68,1.34,.91,3.52-.94,4.1Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <g>
                                <path class="cls-79"
                                    d="M1027.07,825.18l1.91,.76c.32,.13,.69,0,.84-.32,.16-.31,.06-.69-.22-.89l-1.7-1.19c-.34-.24-.8-.12-.98,.24l-.22,.43c-.19,.36-.02,.82,.36,.97Zm.28-.42c-.17-.07-.25-.27-.17-.44l.1-.19c.08-.17,.29-.22,.45-.11l.77,.54c.13,.09,.17,.26,.1,.41-.07,.14-.24,.2-.38,.14l-.87-.34Z" />
                                <g>
                                    <path class="cls-79"
                                        d="M1054.3,823.13l-3.19-5.86c-.5-.92-1.63-1.26-2.53-.77l-7.73,6.44-9.37-1.8c-1.02,.06-1.79,.95-1.72,2l.48,6.65c.07,1.04,.97,1.84,1.99,1.78l10.06-2.77,11.29-3.1c.9-.5,1.22-1.64,.72-2.56Z" />
                                    <path class="cls-71"
                                        d="M1031.48,821.14l9.38,1.07,7.72-5.71,5.01,9.18s-6.55,4.43-9.08,5.01c-5.37,1.25-12.28,.85-12.28,.85l-.75-10.42Z" />
                                    <path class="cls-20"
                                        d="M1053.17,821.05l-.93-1.71c-.32-.6-1.06-.82-1.64-.5l-8.52,5.39-11.03-.04c-.66,.04-1.16,.62-1.11,1.29l.14,1.94c.05,.68,.63,1.19,1.29,1.15,0,0,8.88,.26,11.7-.54,2.65-.75,9.65-5.33,9.65-5.33,.58-.32,.79-1.06,.46-1.66Z" />
                                    <path class="cls-82"
                                        d="M1054.82,822.95l-3.25-5.96c-.08-.14-.25-.2-.39-.12h0c-.14,.08-.19,.25-.11,.4l3.25,5.96c.08,.14,.25,.19,.39,.12h0c.14-.08,.19-.25,.11-.4Z" />
                                    <path class="cls-82"
                                        d="M1030.24,829.88l-.49-6.76c-.01-.16-.15-.28-.31-.27h0c-.16,0-.28,.15-.27,.31l.49,6.76c.01,.16,.15,.28,.31,.27h0c.16,0,.28-.15,.27-.31Z" />
                                </g>
                            </g>
                            <path class="cls-30"
                                d="M1040.86,822.21l-5.7-.65s-.39,2.8,.92,3.4c1.31,.6,6.65-.14,6-.73-.66-.59-1.22-2.02-1.22-2.02Z" />
                            <path class="cls-23"
                                d="M1040.86,822.21l5.57-4.12s1.74,1.04,2.05,1.21c.31,.16-2.13,4.08-2.51,3.89-.21-.1-3.89,1.04-3.89,1.04l-2.21-.48,1-1.55Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M994.21,814.74c-.49-.83-2.71-3.07-3.56-4.22-.93-1.26-2.07-2.59-3.02-3.82-.99-1.3-7.98-8.11-12.41-4.8-1.47,1.1-3.11,2.15-4.36,3.17-1.59,1.3-5.64,4.49-3.11,9.12,.84,1.54,3.91,4.09,4.84,5.55,.64,1.01,9.12,11.3,9.82,11.75,.23,.12,1.12-.53,1.53-.84,.11-.09,.08-.74-.2-1.71-.55-1.86-3.34-4.95-1.22-7.46,.74-.88,4.47-2.68,5.64-2.79,1.17-.1,4.01,3.08,5.98,3.49,.47-.14,1.34-.81,1.6-1.15,.1-.12,.24-.51,.22-.65-.22-1.8-.54-3.57-1.76-5.64Zm.37,6.2c-.22,.03-.44-.05-.66-.12-.43-.15-.87-.3-1.2-.61-.57-.53-.85-1.32-1.39-1.89-.62-.65-2.32-1.47-1.72-2.56,.58-1.04,2.15-1.72,3.17-.92,.25,.2,.43,.46,.61,.73,.65,.99,1.21,2.05,1.68,3.14,.17,.41,.34,.84,.3,1.29-.04,.44-.35,.89-.79,.95Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                        <path class="cls-4"
                            d="M1176.48,841.93c.97,.81,4.7,2.62,6.26,3.67,1.71,1.16,3.7,2.31,5.41,3.42,1.79,1.17,13.44,6.56,17.46,.52,1.34-2.01,2.92-4.03,4.01-5.83,1.4-2.31,5.02-8.03-.14-12.66-1.72-1.54-6.63-3.36-8.42-4.76-1.23-.97-16.24-10.01-17.3-10.27-.34-.05-1.16,1.15-1.52,1.71-.1,.16,.22,.96,1,2.03,1.49,2.07,6.3,4.68,4.76,8.71-.54,1.41-4.36,5.27-5.77,5.91-1.4,.64-6.31-2.07-8.94-1.72-.52,.38-1.3,1.59-1.48,2.13-.07,.2-.07,.74,.01,.91,1.07,2.13,2.23,4.18,4.65,6.22Zm-3.17-7.53c.26-.13,.57-.14,.87-.14,.6,0,1.21,0,1.76,.23,.94,.4,1.63,1.27,2.55,1.74,1.05,.54,3.52,.8,3.24,2.42-.26,1.54-1.91,3.07-3.53,2.52-.4-.13-.74-.38-1.07-.64-1.24-.95-2.4-2-3.45-3.15-.4-.43-.79-.9-.93-1.46-.14-.57,.05-1.26,.57-1.52Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <rect class="cls-127" x="1136.82" y="851.68" width="4.93" height="6.02" rx=".68" ry=".68"
                                transform="translate(-284.58 887.4) rotate(-38.19)" />
                            <path class="cls-67"
                                d="M1135.81,866.23l1.68-1.32c2.99-2.35,3.42-6.65,1.15-9.7-2.33-3.13-6.4-3.76-9.44-1.36l-1.93,1.52c-1.17,.92-9.12,7.18-10.29,8.1l-13.94,10.97c-.88,.69-1.03,1.97-.34,2.85l6.04,7.67c.69,.88,1.97,1.03,2.85,.34l13.94-10.97c1.17-.92,9.13-7.18,10.29-8.1Z" />
                            <path class="cls-63"
                                d="M1133.72,863.58l1.68-1.32c2.99-2.35,4.36-5.46,3.23-7.05-1.16-1.63-4.31-1.11-7.36,1.28l-1.93,1.52c-1.17,.92-24.23,19.06-24.23,19.06-.88,.69-1.31,1.62-.95,2.07l3.09,3.93c.36,.45,1.36,.26,2.24-.44,0,0,23.06-18.15,24.23-19.06Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-76"
                                d="M1093.87,821.57l-.11-2.1c-.02-.38,.27-.7,.64-.72l1.47-.08,.72,.64,.11,2.1c.02,.38-.27,.7-.64,.72l-1.47,.08-.72-.64Z" />
                            <path class="cls-67"
                                d="M1089.77,824.72l1.23-.06c2.18-.11,3.81-1.99,3.76-4.18-.06-2.24-1.76-3.89-3.99-3.77l-1.41,.07c-.85,.04-6.67,.35-7.52,.39l-10.19,.53c-.64,.03-1.14,.58-1.11,1.23l.29,5.61c.03,.64,.58,1.14,1.23,1.11l10.19-.53c.85-.04,6.67-.35,7.52-.39Z" />
                            <path class="cls-63"
                                d="M1089.67,822.78l1.23-.06c2.18-.11,3.86-1.12,3.86-2.24,0-1.15-1.66-1.95-3.89-1.84l-1.41,.07c-.85,.04-17.71,.93-17.71,.93-.64,.03-1.15,.33-1.14,.66l.15,2.87c.02,.33,.55,.57,1.2,.54,0,0,16.86-.88,17.71-.93Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-76"
                                d="M1293.82,822.67l.37,1.47-.36,.59-1.03,.26-.59-.36-.37-1.47,.36-.59,1.03-.26,.59,.36Z" />
                            <path class="cls-67"
                                d="M1296.27,819.88l-.86,.22c-1.53,.38-2.41,1.93-2.07,3.47,.35,1.58,1.78,2.5,3.34,2.11l.98-.25c.6-.15,4.66-1.17,5.26-1.32l7.12-1.79c.45-.11,.72-.57,.61-1.02l-.99-3.92c-.11-.45-.57-.72-1.02-.61l-7.12,1.79c-.6,.15-4.66,1.17-5.26,1.32Z" />
                            <path class="cls-67"
                                d="M1296.61,821.23l-.86,.22c-1.53,.38-2.57,1.33-2.41,2.12,.16,.81,1.44,1.15,3,.76l.98-.25c.6-.15,12.37-3.11,12.37-3.11,.45-.11,.77-.39,.71-.62l-.51-2.01c-.06-.23-.47-.33-.92-.21,0,0-11.78,2.96-12.37,3.11Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-76"
                                d="M1281.17,837.21l.98-1.69,.87-.23,1.19,.69,.23,.87-.98,1.69-.87,.23-1.19-.69-.23-.87Z" />
                            <path class="cls-67"
                                d="M1276.38,837.57l.99,.57c1.76,1.02,3.99,.39,5.06-1.34,1.1-1.78,.61-3.93-1.19-4.97l-1.14-.66c-.69-.4-5.38-3.13-6.07-3.53l-8.21-4.78c-.52-.3-1.19-.13-1.49,.39l-2.63,4.52c-.3,.52-.13,1.19,.39,1.49l8.21,4.77c.69,.4,5.38,3.13,6.07,3.53Z" />
                            <path class="cls-67"
                                d="M1277.29,836.01l.99,.57c1.76,1.02,3.58,1.09,4.15,.22,.58-.9-.3-2.37-2.09-3.41l-1.14-.66c-.69-.4-14.28-8.3-14.28-8.3-.52-.3-1.07-.33-1.22-.06l-1.35,2.32c-.15,.27,.14,.73,.66,1.03,0,0,13.59,7.9,14.28,8.3Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <rect class="cls-127" x="1250.58" y="840.95" width="3.29" height="4.02" rx=".45" ry=".45"
                                transform="translate(-233.79 577.6) rotate(-23.82)" />
                            <path class="cls-17"
                                d="M1256.39,836.08l-1.3,.58c-2.32,1.02-3.31,3.73-2.35,6.08,.99,2.41,3.51,3.49,5.88,2.45l1.5-.66c.91-.4,7.09-3.13,8-3.53l6.89-3.04c.69-.3,1-1.1,.69-1.79l-2.63-5.96c-.3-.68-1.1-1-1.79-.69l-6.89,3.04c-.91,.4-7.09,3.13-8,3.53Z" />
                            <path class="cls-17"
                                d="M1257.3,838.14l-1.3,.58c-2.32,1.02-3.72,2.81-3.26,4.02,.48,1.25,2.6,1.44,4.97,.39l1.5-.66c.91-.4,14.89-6.57,14.89-6.57,.69-.3,1.11-.83,.96-1.18l-1.35-3.06c-.16-.35-.84-.39-1.52-.09,0,0-13.98,6.17-14.89,6.57Z" />
                        </AnimatedG>
                        <AnimatedG moved={scrollIndex == 0}>
                            <path class="cls-76"
                                d="M1230.86,811.35l.78-2.08,.93-.42,1.46,.55,.42,.93-.78,2.08-.93,.42-1.45-.55-.42-.93Z" />
                            <path class="cls-67"
                                d="M1225.57,812.65l1.21,.46c2.16,.82,4.53-.31,5.4-2.45,.89-2.19-.05-4.5-2.25-5.34l-1.39-.53c-.84-.32-6.59-2.49-7.44-2.81l-10.07-3.8c-.64-.24-1.35,.08-1.59,.72l-2.09,5.54c-.24,.64,.08,1.35,.72,1.59l10.07,3.8c.84,.32,6.59,2.49,7.44,2.81Z" />
                            <path class="cls-67"
                                d="M1226.29,810.74l1.21,.46c2.16,.82,4.2,.55,4.68-.53,.49-1.11-.77-2.59-2.98-3.42l-1.39-.53c-.84-.32-17.51-6.61-17.51-6.61-.64-.24-1.25-.17-1.38,.16l-1.07,2.84c-.12,.33,.29,.79,.93,1.03,0,0,16.67,6.29,17.51,6.61Z" />
                        </AnimatedG>
                        <g class="cls-56">
                            <path class="cls-101"
                                d="M927.76,816.91c1.3,1.37,1.03,3.74-.6,5.3l-6.82,6.5-.76,.73-.28,.27c-.11,.11-.35,.16-.48,.12-1.38-.52-3.49-2.71-4.38-4.59-.06-.12,.03-.37,.14-.48l.28-.27,.76-.73,6.81-6.5h0c1.64-1.56,4.02-1.72,5.32-.35Z" />
                            <path class="cls-89"
                                d="M931.82,813.44c-1.01-1.06-2.88-2.08-3.22-2.21-.45-.17,.44,1.28,1.33,2.46l-1.28,1.22s0,0,0,0c-.36-.3-.67-.47-.83-.32,0,0-.19,.18-.23,.22l-1.3,1.24s-.07,.09-.07,.13c-.16,.27,.14,.92,.71,1.52,.57,.6,1.21,.93,1.49,.78,.04,0,.09-.03,.13-.07l1.3-1.24s.24-.23,.24-.23c.16-.15-.01-.48-.32-.87l1.21-1.16c.81,.81,.89,.63,1.16,.37,.29-.28,.69-.79-.33-1.85Z" />
                            <path class="cls-89"
                                d="M919.76,821.11c-1.78,1.7-2.45,3.89-1.5,4.9,.96,1,3.18,.44,4.96-1.26,1.78-1.7,2.45-3.89,1.5-4.9-.96-1-3.18-.44-4.96,1.26Z" />
                        </g>
                        <g class="cls-56">
                            <path class="cls-101"
                                d="M1282.6,806.39c1-1.01,2.79-.86,4,.33l5.05,4.97,.57,.56,.21,.2c.08,.08,.13,.26,.1,.36-.36,1.05-1.96,2.69-3.35,3.4-.09,.05-.28-.01-.36-.09l-.21-.2-.57-.56-5.04-4.97h0c-1.21-1.2-1.39-2.98-.39-4Z" />
                            <path class="cls-88"
                                d="M1279.89,803.42c-.78,.79-1.49,2.21-1.59,2.47-.12,.34,.95-.36,1.82-1.06l.95,.93h0c-.22,.28-.34,.52-.22,.63,0,0,.14,.14,.17,.17l.96,.95s.07,.05,.1,.05c.21,.11,.69-.13,1.13-.57,.44-.44,.67-.93,.55-1.14,0-.03-.02-.07-.05-.1l-.96-.95s-.18-.17-.18-.18c-.12-.12-.36,.02-.65,.26l-.9-.88c.59-.63,.45-.68,.25-.88-.22-.22-.61-.5-1.38,.29Z" />
                            <path class="cls-88"
                                d="M1285.94,812.31c1.32,1.3,2.98,1.75,3.72,1.01,.73-.74,.26-2.4-1.06-3.7-1.32-1.3-2.99-1.75-3.72-1.01-.73,.74-.26,2.4,1.07,3.7Z" />
                        </g>
                        <g>
                            <g>
                                <path class="cls-22"
                                    d="M1000.94,830.2l16.89,3.07c5.14,.93,8.55,5.86,7.62,11.01l-6.04,33.22c-1.78,9.81-11.18,16.31-20.99,14.53h0c-9.81-1.78-16.31-11.18-14.53-20.99l6.04-33.22c.93-5.14,5.86-8.56,11.01-7.62Z" />
                                <path class="cls-83"
                                    d="M1002.85,892.59l-.76,4.16c-.16,.87-.99,1.44-1.85,1.28l-5.64-1.03c-.87-.16-1.44-.99-1.28-1.85l.76-4.16c.29-1.61,9.07-.01,8.77,1.6Z" />
                                <path class="cls-22"
                                    d="M983.18,874.24l35.76,6.5c.71,.13,1.19,.81,1.06,1.53h0c-.13,.71-.81,1.19-1.53,1.06l-35.76-6.5c-.71-.13-1.19-.81-1.06-1.53h0c.13-.71,.81-1.19,1.53-1.06Z" />
                                <path class="cls-22"
                                    d="M986.22,857.49l35.76,6.5c.71,.13,1.19,.81,1.06,1.53h0c-.13,.71-.81,1.19-1.53,1.06l-35.76-6.5c-.71-.13-1.19-.81-1.06-1.53h0c.13-.71,.81-1.19,1.53-1.06Z" />
                                <path class="cls-22"
                                    d="M989.24,840.89l35.76,6.5c.71,.13,1.19,.81,1.06,1.53h0c-.13,.71-.81,1.19-1.53,1.06l-35.76-6.5c-.71-.13-1.19-.81-1.06-1.53h0c.13-.71,.81-1.19,1.53-1.06Z" />
                            </g>
                            <path class="cls-22"
                                d="M996.86,830.7c.29-1.59,5.73-.21,12.52,1.03,6.79,1.23,12.37,1.85,12.08,3.44-.29,1.59-6.04,1.94-12.83,.7-6.79-1.23-12.06-3.58-11.77-5.17Z" />
                        </g>
                        <path class="cls-84"
                            d="M1253.21,868.42l8.46,3.71c.47,.21,1.02-.02,1.2-.5,.19-.48-.06-1.01-.54-1.18l-8.75-3.03c0-.89-.71-1.82-1.83-2.26-1.36-.53-2.79-.14-3.19,.88-.4,1.02,.38,2.28,1.74,2.82,1.14,.45,2.32,.24,2.91-.45Z" />
                        <path class="cls-76"
                            d="M1333.78,892.06l2.84-6.37c.11-.22,0-.49-.23-.6-.24-.11-.52-.02-.61,.21,0,0-4.77,11.24-4.51,11.68,.39,.68,1.96-1.25,2.69-3.49,.18-.56,.16-1.12-.17-1.43Z" />
                        <path class="cls-76"
                            d="M1220.38,890.56l-8.43,3.76c-.47,.21-.76,.6-.63,.87,.12,.27,.6,.3,1.06,.07l8.27-4.16c.49,.29,1.54,.18,2.63-.34,.84-.4,1.45-.93,1.75-1.41l-1.88,.91-.13-.28s2.03-1.26,2.01-1.31c-.03-.06-2.29,.74-2.29,.74l-.14-.29,1.86-.9c-.55-.06-1.32,.08-2.12,.46-1.11,.53-1.88,1.29-1.95,1.86Z" />
                        <path class="cls-76"
                            d="M923.35,854.3l-9.19-.82c-.51-.05-.95,.16-.98,.45-.02,.29,.38,.55,.89,.58l9.24,.4c.28,.49,1.26,.9,2.46,.98,.92,.06,1.72-.1,2.21-.38l-2.08-.12,.02-.31s2.39-.11,2.39-.16c0-.06-2.36-.47-2.36-.47l.02-.32,2.06,.12c-.45-.32-1.19-.57-2.08-.63-1.22-.08-2.27,.21-2.61,.68Z" />
                        <path class="cls-84"
                            d="M1164.9,856.31l9.12,1.41c.51,.08,.96-.1,1.01-.39,.04-.29-.35-.58-.86-.63l-9.2-.99c-.25-.51-1.2-.98-2.39-1.14-.92-.12-1.72,0-2.23,.24l2.07,.25-.04,.3s-2.39-.04-2.4,0c0,.06,2.32,.62,2.32,.62l-.04,.32-2.05-.25c.43,.35,1.15,.65,2.03,.76,1.22,.16,2.28-.06,2.65-.51Z" />
                        <g>
                            <path class="cls-110"
                                d="M1270.39,897.66s-2.44,1.93-3.14,2.74c-.64,.73-2.23,3.42-2.23,3.42,0,0,.87,.24,1.13,.47,1.18,1.04,3.57,2.68,4.7,3.43,.37,.25,.56,.69,.48,1.13-.35,1.91-1.2,7.02-.66,8.49,.33,.9,2.09,.92,1.86,1.72-.29,1.05,1.52,.66,1.67,1.22,.3,1.1-1.44,2.32-1.25,3.29,.19,.97,5.37,1.11,6.92-2.29,.41-.91-.63-1.1-.87-1.27-.24-.17-1.38-1.5-.64-1.71,.74-.21,.41-3.22-1.61-2.28-2.02,.94,.12-1.66,.03-1.89-.41-1.01-2.02-1.55-2.11-2.34-.1-.79,1.84-3.36,1.44-4.2-.41-.84-3.5-4.05-3.75-4.47-.18-.29-.32-2.72-1.02-4.17-.19-.4-.95-1.32-.95-1.32Z" />
                            <path class="cls-139"
                                d="M1274.12,921.09c.92-.67,1.69-1.65,2.81-1.78,.52-.06,1.04,.08,1.56,.14-.32-.44-.58-1-.12-1.13,.74-.21,.41-3.22-1.61-2.28-2.02,.94,.12-1.66,.03-1.89-.41-1.01-2.02-1.55-2.11-2.34-.1-.79,1.84-3.36,1.44-4.2-.41-.84-3.5-4.05-3.75-4.47-.02-.03-.04-.1-.06-.19-.33,.77-.72,1.54-1.41,1.99-.41,.27-.89,.41-1.28,.69-.28,.2-.51,.46-.72,.74,.76,.53,1.48,1.02,1.97,1.35,.37,.25,.56,.69,.48,1.13-.35,1.91-1.2,7.02-.66,8.49,.33,.9,2.09,.92,1.86,1.72-.29,1.05,1.52,.66,1.67,1.22,.07,.26,.02,.53-.08,.8Z" />
                            <path class="cls-114"
                                d="M1273.17,922.7c-.17,.31-.27,.61-.22,.88,.19,.97,5.37,1.11,6.92-2.29,.41-.91-.63-1.1-.87-1.27-1.88,.1-4.08,1.14-5.84,2.68Z" />
                            <g class="cls-43">
                                <path class="cls-110"
                                    d="M1278.59,919.59c-.36-.46-.73-1.12-.23-1.27,.18-.05,.3-.27,.34-.57-.01,0-.02,0-.03,0-1.15,.13-1.85,1.31-2.89,1.82-.47,.23-1.03,.32-1.51,.1-.47-.22-.79-.8-.61-1.28,.09-.23,.26-.41,.44-.58,.3-.31,.61-.61,.98-.83,.33-.19,.7-.31,1.07-.43,.6-.19,1.21-.38,1.81-.57-.31-.16-.71-.18-1.22,.06-2.02,.94,.12-1.66,.03-1.89,0-.02-.02-.04-.03-.05-.35,.12-.66,.36-.89,.65-.37,.48-.57,1.07-.86,1.61-.36,.69-.86,1.3-1.46,1.79-.39,.32-1.29,.2-1.78,.07h0c.47,.22,.88,.43,.76,.84-.29,1.05,1.52,.66,1.67,1.22,.04,.16,.04,.33,0,.5,.08-.1,.15-.19,.23-.28,.66-.76,1.77-.92,2.78-.89,.45,.02,.94,.04,1.38-.03Z" />
                                <path class="cls-110"
                                    d="M1271.4,918.05c-.2-.19-.14-.49-.11-.95,.05-.63,.34-1.22,.49-1.84,.15-.6,.17-1.26,.53-1.77,.46-.66,1.37-.87,2.17-.78,.45,.05,.89,.19,1.32,.34-.53-.42-1.08-.79-1.13-1.24-.03-.24,.13-.64,.35-1.11-.18-.16-.33-.34-.39-.58-.04-.15-.05-.32-.13-.46-.3-.49-1.25-.14-1.53-.64-.17-.31,.05-.73-.14-1.03-.1-.16-.28-.23-.45-.3-2.05-.85-3.89-2.22-5.28-3.95,.62-.38,1.18-.85,1.65-1.39-.91,.7-1.91,1.18-2.88,1.78,.11,.05,.21,.11,.27,.16,1.18,1.04,3.57,2.68,4.7,3.43,.37,.25,.56,.69,.48,1.13-.35,1.91-1.2,7.02-.66,8.49,.12,.32,.42,.53,.74,.7Z" />
                            </g>
                            <g class="cls-26">
                                <path class="cls-110"
                                    d="M1272.31,903.03c-.16-.57-.33-2.72-.97-4.06-.19-.4-.95-1.32-.95-1.32,0,0-2.44,1.93-3.14,2.74-.03,.03-.06,.07-.09,.11,.91-.18,1.82-.35,2.73-.53,.25-.05,.63,.03,.57,.28-.62,.38-1.19,.85-1.68,1.38-.08,.08-.16,.18-.15,.3,.01,.16,.21,.26,.37,.24,.16-.03,.3-.13,.44-.22,.62-.42,1.41-.6,2.15-.48,.1,.01,.2,.04,.25,.13,.05,.09,.02,.2,0,.3-.08,.43,.15,.84,.48,1.14Z" />
                                <path class="cls-110"
                                    d="M1279.87,921.29c.41-.91-.63-1.1-.87-1.27-.05-.03-.13-.11-.23-.22-.83-.04-1.7,.35-2.16,1.06,.24,.37,.76,.37,1.2,.39,.44,.02,.99,.2,1.01,.64,.02,.28-.2,.63,0,.82,.42-.38,.78-.85,1.04-1.42Z" />
                                <path class="cls-110"
                                    d="M1275.63,907.84c.08,.02,.17,.04,.22,.11,.07,.1,.02,.23,.01,.35,0,.13,.05,.24,.13,.34,.15-.42,.22-.79,.11-1.02-.16-.34-.77-1.07-1.45-1.84-.31-.01-.64-.09-.95-.13-.51-.06-1.15,.08-1.29,.58-.09,.34,.11,.7,.4,.9,.29,.2,.64,.28,.98,.35,.61,.12,1.21,.25,1.82,.37Z" />
                                <path class="cls-110"
                                    d="M1272.01,917.36c.07,.14,.21,.22,.36,.27,.58,.18,1.21-.15,1.6-.61,.39-.46,.61-1.04,.9-1.57,.29-.53,.68-1.05,1.25-1.24,.22-.07,.44-.09,.66-.07-.13-.32-.39-.59-.67-.84-.8,.03-1.6,.28-2.27,.73-.97,.66-1.66,1.72-1.86,2.88-.03,.15-.04,.31,.03,.45Z" />
                                <path class="cls-110"
                                    d="M1275.66,917.85c.87-.15,1.74-.29,2.61-.44,.15-.03,.31-.05,.46-.04-.02-.36-.13-.76-.35-1.05,0,0-.02,.01-.03,.02-.42,.22-.9,.23-1.37,.32-.59,.11-1.16,.34-1.66,.66-.11,.07-.22,.16-.22,.29,0,.23,.33,.28,.57,.24Z" />
                            </g>
                            <path class="cls-39"
                                d="M1271.03,898.48c-.29-.4-.64-.82-.64-.82,0,0-2.44,1.93-3.14,2.74-.64,.73-2.23,3.42-2.23,3.42,0,0,.87,.24,1.13,.47,0,0,1.72-3,2.02-3.33s2.86-2.48,2.86-2.48Z" />
                            <g>
                                <path class="cls-125"
                                    d="M1278.19,922.88c-.1-.25-.69-.25-1.32,0-.63,.25-1.06,.66-.96,.91l1.29,2.29,1.65-.66-.65-2.55Z" />
                                <path class="cls-117"
                                    d="M1277.68,925.77l-1.4-2.57c-.28,.21-.43,.43-.37,.59l1.29,2.29,.91-.37-.43,.05Z" />
                                <path class="cls-122"
                                    d="M1278.15,926.08c-.46,.18-.88,.18-.96,0-.07-.18,.24-.47,.69-.66,.46-.18,.88-.18,.96,0,.07,.18-.24,.48-.69,.66Z" />
                            </g>
                        </g>
                        <g>
                            <path class="cls-104"
                                d="M946.49,849.51c-.11,.07-.19,.17-.21,.3-.1,.64-.32,1.31-.67,1.96-.13,.24-.28,.47-.44,.68-.05,.63-.23,1.3-.54,1.95-.04,.09-.05,.2-.02,.3,.23,.76,.2,1.69-.11,2.65-.04,.11-.02,.23,.03,.33,.3,.55,.42,1.27,.35,2.04-.01,.14,.04,.27,.14,.36,.47,.44,.73,1.1,.79,1.87,.23-.02,.45,0,.63,.11,.26,.14,.44,.39,.55,.71,.54-.28,1.05-.35,1.44-.13,.05,.03,.09,.06,.14,.1,.41-.34,.83-.58,1.21-.68,.09-.02,.18-.08,.24-.16,.41-.55,.85-1.01,1.29-1.34,.05-.07,.1-.13,.15-.2,.14-.39,.32-.79,.54-1.19,.15-.28,.32-.54,.49-.79,.02-.24,.03-.42,.05-.65l-4.57-8.74c-.13-.25-.46-.3-.67-.11-.26,.24-.53,.45-.81,.61Z" />
                            <rect class="cls-145" x="947.1" y="837.52" width="22.54" height="17.27" rx=".52" ry=".52"
                                transform="translate(-283.04 540.62) rotate(-27.61)" />
                            <rect class="cls-64" x="949.73" y="836.35" width="17.27" height="19.61"
                                transform="translate(652.81 2087.56) rotate(-117.61)" />
                            <path class="cls-14"
                                d="M967.13,838.58l2.45,4.69-.83,2.64-16.51,8.64c-1.03-.32-1.61-.5-2.64-.83l-2.46-4.69,.83-2.64,16.51-8.63,2.64,.83Z" />
                        </g>
                        <g>
                            <path class="cls-124"
                                d="M1230.27,873.93c-.01,.08,.04,.17,.16,.24,.56,.38,1.07,.83,1.47,1.32,.15,.18,.27,.36,.38,.54,.58,.35,1.12,.78,1.55,1.26,.06,.07,.16,.13,.28,.17,.9,.31,1.8,.82,2.53,1.45,.09,.07,.21,.13,.35,.16,.75,.17,1.53,.5,2.24,.94,.13,.08,.29,.12,.45,.13,.75,.04,1.58,.29,2.38,.67,.14-.1,.31-.18,.54-.2,.31-.03,.68,.03,1.08,.15,.09-.37,.37-.61,.85-.66,.06,0,.12,0,.19,0-.05-.35,0-.64,.15-.85,.04-.05,.04-.11,0-.18-.27-.46-.42-.89-.44-1.24-.03-.06-.06-.11-.09-.17-.29-.27-.56-.55-.8-.85-.17-.21-.32-.42-.44-.62-.22-.13-.39-.23-.61-.37l-11.68-2.78c-.33-.08-.61,.03-.56,.21,.06,.23,.08,.46,.05,.66Z" />
                            <path class="cls-145"
                                d="M1226.73,854.62h22.21c.3,0,.54,.24,.54,.54v16.76c0,.3-.24,.54-.54,.54h-22.21c-.3,0-.54-.24-.54-.54v-16.76c0-.3,.24-.54,.54-.54Z"
                                transform="translate(111.39 1868.06) rotate(-76.63)" />
                            <rect class="cls-36" x="1228.91" y="853.41" width="17.85" height="20.26"
                                transform="translate(2242.36 1989.98) rotate(-166.63)" />
                            <path class="cls-5"
                                d="M1237.87,851.58l5.32,1.27,1.5,2.43-4.45,18.73-2.43,1.5-5.33-1.27-1.5-2.43,4.45-18.73c.95-.59,1.48-.91,2.43-1.5Z" />
                        </g>
                        <path class="cls-70"
                            d="M1006.03,804.94c-.18-.42-.44-.29-.68-.18-.76,.35-1.5,.45-2.21,.23-.61-.19-1-1.07-1.7,.92,1.61,.91,3.21,1.82,4.82,2.73,.02-1.46,.03-3.07-.23-3.7Z" />
                        <path class="cls-67"
                            d="M872.49,857.03c.95,.32,1.83,.98,2.83,.95,.93-.02,1.72-.62,2.56-1.01,2.91-1.35,6.79,.14,8.05,3.09-1.51,.83-3.53,.6-4.81-.55-.64-.57-1.22-1.4-2.08-1.41-.88-.01-1.51,.86-2.34,1.18-1.1,.42-2.29-.21-3.39-.64-2.2-.88-4.41-.86-6.71-.49,1.34-1.29,4.16-1.7,5.88-1.12Z" />
                        <path class="cls-67"
                            d="M924.03,872.95l-.23,.15s-.08,.03-.12,.03c-.16-.02-.31,.04-.45,.13l-.19,.13c-.34,.22-.42,.68-.21,1.02,.22,.35,.65,.46,.99,.23l.22-.14c.13-.09,.25-.21,.29-.36,.01-.04,.04-.08,.07-.1l.23-.15s.08-.03,.12-.03c.16,.02,.31-.04,.45-.13l1.58-1.04c.1-.07,.13-.2,.06-.3l-.57-.87c-.07-.1-.2-.13-.3-.06l-1.58,1.04c-.13,.09-.25,.21-.29,.36-.01,.04-.04,.08-.07,.1Z" />
                        <path class="cls-67"
                            d="M959.06,789.73l.3-.2s.11-.05,.16-.04c.21,.02,.41-.06,.58-.17l.25-.17c.44-.3,.54-.9,.25-1.34-.3-.46-.86-.59-1.3-.28l-.28,.19c-.17,.12-.32,.28-.38,.48-.01,.05-.05,.1-.1,.13l-.3,.2s-.11,.05-.16,.04c-.21-.02-.41,.06-.58,.17l-2.05,1.4c-.13,.09-.16,.26-.07,.39l.77,1.13c.09,.13,.26,.16,.39,.07l2.05-1.4c.17-.12,.32-.28,.38-.48,.01-.05,.05-.1,.1-.13Z" />
                        <path class="cls-67"
                            d="M986.64,791.1l.03-.17s.04-.05,.09-.06c.2-.04,.29-.13,.31-.23l.03-.14c.04-.25-.53-.55-1.26-.69-.76-.14-1.38-.06-1.42,.19l-.03,.16c-.02,.1,.04,.21,.21,.32,.05,.03,.07,.06,.07,.09l-.03,.17s-.04,.05-.09,.06c-.2,.04-.29,.13-.3,.23l-.21,1.17c-.01,.07,.15,.16,.37,.2l1.9,.34c.22,.04,.41,.01,.42-.06l.21-1.17c.02-.1-.03-.21-.21-.32-.05-.03-.07-.06-.07-.09Z" />
                        <path class="cls-67"
                            d="M1341.22,803l.19-.12s.07-.02,.12,0c.18,.09,.32,.07,.43,0l.16-.1c.28-.18,.19-.79-.18-1.36-.38-.59-.88-.9-1.16-.71l-.18,.12c-.11,.07-.18,.2-.17,.4,0,.05,0,.09-.04,.11l-.19,.12s-.07,.02-.12,0c-.18-.09-.32-.07-.43,0l-1.29,.86c-.08,.05-.06,.23,.05,.4l.97,1.45c.11,.17,.27,.26,.35,.2l1.29-.86c.11-.07,.18-.2,.17-.4,0-.05,0-.09,.04-.11Z" />
                        <path class="cls-85"
                            d="M961.05,809.06c-.14,.43-.1,.9,.11,1.38l.41,.02c.67,.45,1.34,.91,2.02,1.36,.36-.54,.72-1.08,1.08-1.62-1.27-.02-2.74-.49-3.61-1.15Z" />
                        <path class="cls-86"
                            d="M905.5,789.25c-1.48,.95-2.96,1.91-4.44,2.86l-.91,1.21c-.42,.22-.66,.73-.57,1.19,.09,.46,.51,.84,.98,.88,.79,.07,1.37-.67,1.98-1.17,.66-.53,1.48-.83,2.23-1.22,.75-.4,1.48-.96,1.74-1.76,.26-.81-.18-1.87-1.02-1.99Z" />
                        <path class="cls-86"
                            d="M824.29,797.88c-.73-.98-2.17-1.32-3.29-.85-1.13,.47-1.89,1.65-1.96,2.87l1.71,.71c.59,1.07,2.22,1.33,3.17,.57,.96-.76,1.1-2.31,.37-3.3Z" />
                        <path class="cls-102"
                            d="M913.41,883.7c-1.26,.7-1.37,2.79-.2,3.62,1.17,.83,3.1,.03,3.35-1.38,.24-1.42-1.31-2.81-2.69-2.42l-.46,.19Z" />
                        <path class="cls-149"
                            d="M866.21,815.22c-.96,0-1.8,1.05-1.48,1.96l-1.06-.18c-.51-.43-1.36,.23-1.29,.9,.07,.67,.69,1.14,1.31,1.39,.89,.36,1.92,.43,2.79,.04,.87-.39,1.54-1.28,1.52-2.24-.02-.96-.84-1.87-1.79-1.87Z" />
                        <g>
                            <path class="cls-102"
                                d="M942.2,873.42l-1.18,.51c-1.62,.74-3.09,1.78-4.33,3.05,2.17-1.14,4.96-.97,6.98,.42,.8,.55,1.77,1.31,2.61,.82,.46-.27,.65-.83,.69-1.36,.08-1.17-.47-2.36-1.42-3.05-.95-.69-2.26-.84-3.34-.39Z" />
                            <path class="cls-102"
                                d="M939.4,878.32c-1.01,.07-1.92,.89-2.1,1.89h-.3c-.39-.02-.71,.46-.53,.82,1.82,.07,3.65-.32,5.27-1.13-.32-.96-1.34-1.64-2.35-1.57Z" />
                            <path class="cls-102"
                                d="M992.41,884.16c-1.44-.3-3.03,.57-3.56,1.94,1.3,.73,2.98,.73,4.27,0l1.78,1.26c.06-1.47-1.05-2.9-2.49-3.2Z" />
                        </g>
                        <path class="cls-29"
                            d="M1318,904.48c-.72,.34-1.53,.41-2.33,.47-.35-.58-.7-1.19-.75-1.87-.05-.67,.31-1.43,.97-1.6h.35c.5-.5,1.29-.7,1.96-.46,.67,.24,1.16,.92,1.15,1.63-.02,.8-.63,1.48-1.35,1.82Z" />
                        <path class="cls-29"
                            d="M1328.79,907.62c.68,.16,1.5,.21,1.98-.3,.29-.3,.38-.74,.51-1.14,.37-1.1,1.18-2.05,2.21-2.6-.87,.05-1.75,.11-2.58,.37-.83,.26-1.62,.76-2.05,1.52-.13,.22-.26,.5-.51,.56-.41,.1-.66-.41-.83-.79-.52-1.16-3.25-3.31-3.51-1.05-.25,2.11,3.34,3.1,4.78,3.43Z" />
                        <path class="cls-70"
                            d="M1332.24,904.08c.52,.31,1.06,.65,1.67,.66,.9,0,1.62-.7,2.23-1.36-4.17-1.35-8.34-2.7-12.51-4.05,.72,1.63,2.05,1.67,3.55,2.25,1.76,.67,3.45,1.52,5.06,2.5Z" />
                        <path class="cls-67"
                            d="M1316.09,926.48c.95-.32,1.83-.98,2.83-.95,.93,.02,1.72,.62,2.56,1.01,2.91,1.35,6.79-.14,8.05-3.09-1.51-.83-3.53-.6-4.81,.55-.64,.57-1.22,1.4-2.08,1.41-.88,0-1.51-.86-2.34-1.18-1.1-.42-2.29,.21-3.39,.64-2.2,.88-4.41,.86-6.71,.49,1.34,1.29,4.16,1.7,5.88,1.12Z" />
                        <path class="cls-86"
                            d="M1281.45,906.63c.11-.09,.27-.1,.41-.11,2.29-.12,4.59-.23,6.89-.35-.71,.38-.82,1.47-1.57,1.78-.33,.14-.7,.08-1.06,.03-1.16-.16-2.33-.21-3.5-.15-.39,.02-.91,.2-1.18-.1-.19-.21-.24-.91,0-1.1Z" />
                        <path class="cls-129"
                            d="M1290.18,901.45c.14-.53,.62-1.04,1.16-.95,.46,.08,.75,.54,.91,.99,.18,.53,.26,1.12,.06,1.64-.2,.52-.74,.94-1.29,.86-.23-.51-.2-1.13,.07-1.62,.11-.2,.26-.39,.29-.61,.03-.23-.13-.5-.36-.47-.53,.07-.28,.99-.64,1.14-.49,.2-.26-.77-.21-.97Z" />
                        <path class="cls-86"
                            d="M1248.31,921.08c1.33,1.74,3.9,2.39,5.89,1.48-.8,.29-1.69-.12-2.36-.65-.67-.53-1.25-1.19-2.03-1.55-.78-.35-1.86-.26-2.27,.48l.77,.23Z" />
                        <path class="cls-85"
                            d="M1278.07,925.08c-1.28,.05-2.57-.15-3.76-.6l.19-.57c-.83-1.08-1.67-2.16-2.5-3.23,1.73-.33,3.45-.65,5.18-.98-.75,1.77-.39,3.95,.89,5.38Z" />
                        <path class="cls-85"
                            d="M1251.7,916.56c.56-.49,1.15-1,1.86-1.23,.71-.23,1.59-.12,2.06,.47,.48,.6,.36,1.54-.16,2.11-.51,.57-1.33,.79-2.1,.73-.86-.07-1.69-.49-2.26-1.13l.59-.94Z" />
                        <path class="cls-64"
                            d="M1226.53,911.22c.89-.45,1.72-1.06,2.7-1.24,.98-.18,2.18,.27,2.39,1.24,.2,.93-.63,1.82-1.54,2.1-1.24,.38-2.59,.06-3.86,.23-.82,.11-2.22,.95-2.97,.8-1.13-.22-1.69-1.44-1.96-2.47-.16-.62-.57-2.02,.28-2.22,.6-.15,1.38,1.38,1.82,1.61,1.08,.59,2.04,.5,3.15-.06Z" />
                        <path class="cls-86"
                            d="M1234.74,904.87c-1.13,1.86-1.7,4.05-1.63,6.23,1.22,.21,2.47,.29,3.71,.23,.58-.03,1.26-.16,1.5-.68,.34-.74-.52-1.5-1.31-1.66-.8-.16-1.69-.06-2.35-.53,.99,.16,1.97-.86,1.78-1.84-.18-.98-1.47-1.57-2.33-1.06l.62-.68Z" />
                        <path class="cls-86"
                            d="M1228.66,916.28c.06-.98,1.32-1.67,2.18-1.19,.86,.48,.94,1.91,.14,2.48-.8,.57-2.13,.03-2.3-.94l-.02-.35Z" />
                        <path class="cls-86"
                            d="M1234.51,914.69c-.23-.79-.29-1.92,.5-2.14,.22-.06,.45-.02,.68,.02,1.3,.23,2.59,.46,3.89,.69-.82,1.97-3.32,3.06-5.32,2.3-.01-.14-.02-.28-.03-.42l.29-.45Z" />
                        <path class="cls-129"
                            d="M1231.22,917.6c-.28-1.29,.4-2.73,1.58-3.34,.88-.45,2.17-.32,2.58,.58,.36,.8-.2,1.78-.99,2.18-.78,.4-1.71,.37-2.59,.34l-.57,.25Z" />
                        <path class="cls-86"
                            d="M1165.6,907.87c.14,.73-.53,1.41-1.25,1.58-.72,.17-1.47-.01-2.2-.16-.73-.15-1.52-.25-2.18,.08-1.01,.49-3.52,1.78-4.07-.36-.25-.97,.34-.74,1.33-.8,1.36-.08,2.7,.08,4.02-.35,1-.32,3.94-2.17,4.35,0Z" />
                        <path class="cls-86"
                            d="M1163.34,913.51c.29,1.06,.84,2.05,1.57,2.87,.13,.15,.3,.3,.49,.28,.23-.02,.36-.25,.46-.46,.63-1.31,1.26-2.64,1.55-4.07-1.48,.79-3.5,.31-4.47-1.06-.66,.88-.39,2.3,.54,2.88l-.14-.45Z" />
                        <path class="cls-86"
                            d="M1173.35,907.5c.29,.1,.6,.23,.73,.5,.28,.55-.39,1.12-.98,1.28-1.06,.29-6.2,.04-5.91-1.6,.39-2.13,5.16-.54,6.15-.18Z" />
                        <path class="cls-129"
                            d="M1160.64,908.2c.69,.07,1.48,.05,1.95,.57,.13,.14,.22,.3,.36,.42,.43,.36,1.12,.1,1.44-.35,.32-.45,.39-1.03,.51-1.58,.12-.54,.35-1.12,.84-1.38,.49-.26,1.07-.13,1.62-.17,.55-.04,1.18-.46,1.05-.99-.1-.42-.61-.6-1.04-.65-1.88-.22-3.72,.51-5.56,.97-.92,.23-5.02-.14-5.18,.62-.28,1.32,3.12,2.45,4,2.54Z" />
                        <path class="cls-86"
                            d="M1181.32,901.27c.09-.55,.15-1.21-.26-1.58-.36-.32-.91-.28-1.38-.22-1.86,.24-6.68,1-3.88,3.75,2.45,2.4,5.02,1.07,5.53-1.95Z" />
                        <path class="cls-86"
                            d="M1182.28,925.55c1.09-.19,2.17-.48,3.27-.48,.29-.64,.58-1.3,.62-2,.04-.7-.21-1.46-.81-1.84-.73-.46-1.71-.19-2.43,.29-.72,.47-1.31,1.14-2.07,1.54-.76,.41-1.81,.48-2.39-.16-.8-.89-.47-2.53-2.07-2.47-1.06,.04-2.16,1.33-1.76,2.34,.36,.9,2.15,1.44,2.92,1.85,1.64,.87,2.84,1.26,4.72,.93Z" />
                        <g>
                            <path class="cls-129" d="M1181.84,910.17v.53c-.08-.18-.06-.36,0-.53Z" />
                            <path class="cls-129"
                                d="M1186.29,909.93c-.41,.27-.96,.21-1.42,.06-.64-.21-1.25-.59-1.93-.59-.48,0-.96,.35-1.1,.76v-.33c-.29-.66-.58-1.33-.67-2.04-.09-.71,.06-1.49,.55-2.01,.49-.53,1.36-.71,1.95-.3,.5,.36,.66,1.02,.84,1.61,.18,.59,.48,1.24,1.07,1.4,.28,.08,.59,.04,.83,.2,.41,.27,.29,.97-.12,1.24Z" />
                        </g>
                        <path class="cls-86"
                            d="M1195.53,897.42c.47,.41,1.11,.77,1.7,.56,.38-.14,.64-.49,.93-.77,.3-.28,.74-.5,1.1-.32,.23,.12,.36,.37,.41,.62,.16,.78-.35,1.58-1.03,1.98-.68,.41-1.51,.5-2.3,.52-1.46,.04-3.75-.5-3.51-2.47,.24-1.87,1.77-.93,2.7-.12Z" />
                        <path class="cls-149"
                            d="M1097.68,918.84c-.33-.06-.66,.02-.99,.05-2.08,.18-2.45-1.73-4-2.32-.34,.96,.5,2.53,1.22,3.2,.68,.63,1.45,.91,2.4,.84,.73-.06,3.21-1.44,1.36-1.77Z" />
                        <path class="cls-86"
                            d="M1107.53,914.61c-.13-.22-.38-.33-.62-.41-2.01-.69-4.1-.14-6.12,.21-1.3,.23-2.75,.04-1.64,1.39,1.57,1.91,9.34,.42,8.37-1.19Z" />
                        <path class="cls-149"
                            d="M1102.5,921.98c.63,.18,1.37,.06,1.87-.32,.21-.16,.38-.37,.47-.63,.14-.37,.09-.83-.2-1.08-.21-.18-.5-.23-.78-.27-1.88-.27-3.99,1.29-1.37,2.3Z" />
                        <path class="cls-129"
                            d="M1111.72,912.01c-.93,.24-1.99-.71-1.71-1.62l-1.16-.07c-.2,.31-.75,.19-.89-.15-.14-.34,.06-.75,.36-.96,.31-.21,.69-.26,1.06-.27,1.2-.04,2.42,.23,3.49,.78,.27,.92-.23,2.06-1.16,2.3Z" />
                        <path class="cls-102"
                            d="M1119.21,918.14c-.59,.24-1.24,.25-1.87,.24-2.28-.04-4.54-.43-6.7-1.16l-2.41-.33c-.56-.03-.87-.77-.63-1.28s.86-.74,1.42-.71c.56,.02,1.09,.24,1.62,.44,3.1,1.14,6.47,1.3,9.77,1.45-.1,.62-.62,1.12-1.21,1.35Z" />
                        <path class="cls-94"
                            d="M1137.82,910.01c1.16,.71,2.33,1.43,3.49,2.14,.14,.09,.3,.2,.33,.36,.03,.16-.07,.32-.19,.42-.47,.42-1.23,.35-1.78,.04-.56-.3-.98-.79-1.46-1.2-.48-.41-1.09-.76-1.71-.68-1.03,.12-1.53,1.26-2.38,1.58-.18-2.11,1.83-3.82,3.71-2.67Z" />
                        <path class="cls-86"
                            d="M1133.2,911.38c.81-.11,1.63-.22,2.44-.33,1.78-.24,3.65-.51,5.1-1.56,.32-.23,.63-.53,.7-.92,.1-.58-.39-1.12-.96-1.29s-1.16-.05-1.74,.06c-2.27,.46-5.95,.49-7.96,1.63-2.67,1.52,.9,2.59,2.41,2.41Z" />
                        <path class="cls-86"
                            d="M1145.18,908.68c.49-.27,1.02-.62,1.1-1.18,.06-.44-.21-.88-.58-1.13-.36-.26-.81-.38-1.24-.49-2.2-.57-6.04-1.22-6.38,2.13-.38,3.81,5.47,1.56,7.09,.66Z" />
                        <path class="cls-64"
                            d="M1135.28,908.08c.98-.16,1.9-.77,2.88-.63,.49,.07,.93,.32,1.4,.48,.47,.16,1.03,.2,1.42-.11,.5-.4,.44-1.24,0-1.7-.44-.46-1.14-.59-1.78-.53-.64,.06-1.24,.29-1.86,.44-1.05,.25-3.82-.44-4.55,.28-1.2,1.17,1.6,1.91,2.49,1.77Z" />
                        <path class="cls-64"
                            d="M1127.58,909.59c.64,0,1.25,.23,1.86,.42,.61,.19,1.26,.34,1.88,.19s1.18-.71,1.14-1.35c-.04-.51-.44-.93-.92-1.12-.47-.19-1-.19-1.51-.15-1.95,.15-6.31,1.1-6.61,3.56,1.41,.04,2.6-1.55,4.14-1.55Z" />
                        <path class="cls-129"
                            d="M1134.31,918.01c.44-.14,.91-.31,1.17-.69,.23-.35,.2-.8,0-1.17-.15-.28-.4-.52-.69-.65-.68-.28-1.49-.06-2.1,.34-2.26,1.48-.32,2.79,1.63,2.16Z" />
                        <path class="cls-149"
                            d="M1147.2,911.25c.09-.39-.2-.78-.56-.94-.28-.12-.58-.14-.89-.13-.09,0-.19,0-.28,.01-.73,.04-1.48,.1-2.14,.41-2.6,1.19,3.29,3.08,3.88,.65Z" />
                        <path class="cls-86"
                            d="M883.78,792.55c-.15,.86-.31,1.71-.46,2.57l.12,.39c0,.38,.02,.76,.02,1.14,.55,.02,1.13,.03,1.64-.18,.51-.21,.94-.72,.88-1.27-.13-1.19-2.2-1.45-2.21-2.65Z" />
                        <path class="cls-64"
                            d="M1373.49,803.98c-1.1,.71-2.19,1.41-3.29,2.12l-.67,.9c-.31,.16-.49,.54-.42,.88,.07,.34,.38,.62,.73,.65,.58,.05,1.01-.5,1.47-.87,.49-.4,1.09-.61,1.65-.91,.56-.29,1.1-.71,1.29-1.31,.19-.6-.13-1.38-.76-1.47Z" />
                        <path class="cls-85"
                            d="M1342.42,802.62c-.71,0-1.33,.78-1.09,1.45l-.79-.13c-.38-.32-1.01,.17-.95,.67,.05,.49,.51,.85,.97,1.03,.66,.27,1.42,.32,2.07,.03,.65-.29,1.14-.95,1.13-1.66-.02-.71-.62-1.39-1.33-1.39Z" />
                        <path class="cls-86"
                            d="M1333.2,801.1c-.11,.63-.23,1.27-.34,1.9l.09,.29c0,.28,.01,.56,.02,.84,.41,.01,.83,.03,1.21-.13,.38-.16,.7-.53,.65-.94-.1-.88-1.63-1.07-1.64-1.96Z" />
                        <path class="cls-86"
                            d="M957.9,806.48c.76-.42,1.52-.84,2.29-1.26l.22-.34c.29-.24,.58-.49,.87-.73-.33-.44-.68-.9-1.17-1.16-.49-.26-1.15-.28-1.54,.11-.85,.85,.25,2.62-.67,3.38Z" />
                        <path class="cls-86"
                            d="M1265.32,799.67c.76-.42,1.52-.84,2.29-1.26l.22-.34c.29-.24,.58-.49,.87-.73-.33-.44-.68-.9-1.17-1.16-.49-.26-1.15-.28-1.54,.11-.85,.85,.25,2.62-.67,3.38Z" />
                        <path class="cls-86"
                            d="M879.43,797.02c1.3-.39,2.69-.52,4.04-.37-.15,.79-.85,1.43-1.64,1.53-.79,.1-1.63-.35-1.97-1.07l-.43-.09Z" />
                        <path class="cls-102"
                            d="M1270.94,800.27c1.31-.39,2.69-.52,4.04-.37-.15,.79-.85,1.43-1.64,1.53-.79,.1-1.63-.35-1.97-1.07l-.43-.09Z" />
                        <path class="cls-85"
                            d="M923.34,807.32c-.29-.49-.91-.77-1.46-.66-.56,.11-1.02,.61-1.09,1.17l-.73,.9c-.55-.05-.78,.83-.39,1.23,.39,.4,1.03,.39,1.58,.28,.69-.14,1.37-.43,1.83-.97s.63-1.35,.27-1.95Z" />
                        <path class="cls-85"
                            d="M1008.22,799.19c1.01-.51,2.15-.99,3.25-.71,1.1,.28,1.91,1.72,1.19,2.6-.68,.82-2,.51-2.95,.03-.95-.48-2.05-1.08-3.01-.61,.44-.29,.89-.58,1.33-.87l.2-.44Z" />
                        <path class="cls-102"
                            d="M994.84,802.17c-.26-.64-.81-1.13-1.39-1.51-.79-.51-1.79-.88-2.66-.53-.87,.36-1.25,1.74-.47,2.25l2.43,1.21c.17,.67,1.15,.89,1.7,.47,.55-.43,.66-1.26,.4-1.9Z" />
                        <path class="cls-86"
                            d="M1107.48,799.02l-.54,.12c.29,.4,1.05,.45,1.6,.26,.54-.19,.95-.54,1.42-.83,.47-.28,1.09-.5,1.66-.35-1.59-1.74-3.67-3.22-4.14,.79Z" />
                        <path class="cls-85"
                            d="M1183.37,793.61c-.36-.3-.94-.42-1.47-.39-.6,.04-1.18,.26-1.59,.6l.42,.5c.4,.26,.81,.53,1.31,.66,.5,.12,1.12,.06,1.45-.25,.34-.32,.25-.82-.11-1.13Z" />
                        <path class="cls-64"
                            d="M1157.24,793.05c-.87-.2-1.82-.03-2.71-.12-.57-.06-1.56-.51-2.09-.43-.79,.12-1.19,.77-1.38,1.32-.11,.33-.4,1.08,.2,1.19,.42,.08,.97-.74,1.28-.86,.76-.31,1.43-.27,2.21,.03,.62,.24,1.21,.57,1.9,.66,.69,.1,1.53-.14,1.68-.66,.14-.5-.44-.97-1.08-1.12Z" />
                        <path class="cls-85"
                            d="M1285.2,798.76c.71,.54,1.65,.77,2.43,1.22,.5,.29,1.22,1.09,1.73,1.24,.77,.22,1.4-.22,1.79-.65,.24-.26,.8-.82,.3-1.17-.35-.24-1.19,.28-1.52,.27-.82-.02-1.42-.34-2.01-.93-.47-.47-.87-1.01-1.46-1.38-.59-.37-1.46-.49-1.8-.08-.33,.4,.01,1.07,.54,1.46Z" />
                        <path class="cls-64"
                            d="M1136.5,794.48c-.17-.28-.65-.35-1.05-.36-.87-.03-1.75,0-2.61,.12-.05,1.05,.32,2.09,.97,3.02,.58,.1,1.26-.17,1.37-.62,.13-.52-.56-1.07-1.25-.98,.46-.25,1.09-.2,1.65-.29,.56-.08,1.16-.49,.92-.89Z" />
                        <path class="cls-86"
                            d="M1156.25,792.04c-.56-.31-1.49-.02-1.61,.5v.19c.03,.53,.91,.89,1.52,.63,.6-.26,.66-1.02,.1-1.33Z" />
                        <path class="cls-86"
                            d="M1160.17,791.86c0,.08-.02,.15-.02,.23l.2,.24c-.16,.42-.2,1.03,.35,1.14,.15,.03,.32,.01,.48,0,.91-.12,1.82-.24,2.73-.37-.58-1.05-2.33-1.63-3.74-1.23Z" />
                        <path class="cls-86"
                            d="M1261.58,798.44c0,.07-.02,.15-.02,.23l.2,.24c-.16,.42-.2,1.03,.35,1.14,.15,.03,.32,.01,.48,0,.91-.12,1.82-.24,2.73-.37-.58-1.05-2.33-1.63-3.74-1.23Z" />
                        <path class="cls-86"
                            d="M1138.94,785.57c-.24,.27-.26,.61-.27,.93l-.15,.2c.68,.16,1.47-.01,1.83-.41,.27-.3,.24-.78-.22-.97-.41-.17-.95-.01-1.19,.26Z" />
                    </g>

                    <g id="arm" transform={`translate(0, ${$armY})`}>
                        <path class="cls-140"
                        d="M1358.26,886.25c-.05-.88-.09-1.75-.16-2.63l-12.06-.38c-41.71,59.45-167.43,80.82-303.46,46.94-117.19-29.19-208.38-91-237.13-153.64l-3.95,7h0c-5.45,13.86-5.27,29.32,.62,43,27.39,63.52,119.48,126.63,238.29,156.22,154.66,38.52,296.01,5.64,315.71-73.44,.78-3.13,1.33-6.29,1.71-9.46l.43-13.6Z" />
                        <path class="cls-138"
                            d="M1358.26,876.62c-.05-.87-.08-1.74-.16-2.61l-11.94-.38c-41.3,58.86-165.76,80.02-300.45,46.47-116.03-28.9-206.31-90.09-234.77-152.11l-9.9-.31-.77,24.45c10.41,70.81,109.83,146.79,243.3,180.03,153.13,38.14,293.07,5.58,312.57-72.71,.77-3.1,1.31-6.22,1.69-9.37l.42-13.47Z" />
                        <path class="cls-121"
                            d="M1346.18,873.64c-41.3,58.86-165.77,80.02-300.46,46.47-116.03-28.9-206.31-90.09-234.77-152.11l-9.07-.29c11.78,70.3,110.66,145.31,243.02,178.27,152.66,38.02,292.2,5.78,312.37-72l-11.1-.35Z" />
                    </g>
                    <g>
                        <g class="cls-155">
                            <rect class="cls-140" x="1042.89" y="649.36" width="284.39" height="24.39" rx="3.25"
                                ry="3.25" />
                        </g>
                        <g>
                            <rect class="cls-106" x="1043" y="639.82" width="277.07" height="24.53" />
                            <g>
                                <g>
                                    <rect class="cls-116" x="1244.32" y="645.64" width="12.53" height="18.7" />
                                    <rect class="cls-105" x="1256.85" y="643.06" width="12.53" height="21.29" />
                                    <rect class="cls-116" x="1302.23" y="654.31" width="12.53" height="10.04" />
                                </g>
                                <g>
                                    <rect class="cls-116" x="1297.51" y="639.82" width="12.53" height="18.7" />
                                    <rect class="cls-105" x="1284.98" y="639.82" width="12.53" height="21.29" />
                                    <rect class="cls-116" x="1067.32" y="645.64" width="12.53" height="18.7" />
                                    <rect class="cls-116" x="1141.55" y="654.31" width="12.53" height="10.04" />
                                    <rect class="cls-116" x="1239.6" y="639.82" width="12.53" height="10.04" />
                                    <rect class="cls-116" x="1174.03" y="649.17" width="12.53" height="15.17" />
                                    <rect class="cls-105" x="1079.84" y="643.06" width="12.53" height="21.29" />
                                    <rect class="cls-105" x="1186.55" y="644.27" width="12.53" height="20.07" />
                                </g>
                            </g>
                            <rect class="cls-50" x="1043.45" y="639.82" width="283.01" height="24.53" />
                        </g>
                    </g>
                    <g>
                        <circle class="cls-2" cx="1072.43" cy="599.28" r="17.28" />
                        <circle cx="1066.5" cy="605.21" r="6.91" />
                        <line class="cls-2" x1="1072.43" y1="616.56" x2="1072.43" y2="639.67" />
                        <circle class="cls-2" cx="1114.89" cy="599.28" r="17.28" />
                        <circle cx="1108.97" cy="605.21" r="6.91" />
                        <line class="cls-2" x1="1114.89" y1="616.56" x2="1114.89" y2="639.67" />
                    </g>
                </g>
            </g>
        </g>
    </g>
    <g id="sliertjes" opacity={scrollIndex == 2 ? 1 : 0}>
        <g>
            <path class="st147" d="M1497.4,411.6c-4.3-2.2-8.3-5.5-11-9.8c-1.3-2.2-2.3-4.7-2.5-7.3c-0.2-2.7,0.4-5.4,1.5-7.8
                c2.4-4.7,6.3-7.8,8-12c0.9-2,1.1-4.3,0.1-6.3c-1-2-2.9-3.7-4.6-5.3c2.1,1.2,4.3,2.5,5.8,4.6c0.8,1.1,1.3,2.4,1.4,3.8
                c0.1,1.4-0.1,2.7-0.5,4c-1.7,5.1-5.7,8.5-7.6,12.5c-1,2-1.5,4.1-1.4,6.3c0.1,2.2,0.8,4.4,1.8,6.4
                C1490.4,404.9,1493.7,408.5,1497.4,411.6z"/>
        </g>
        <g>
            <path class="st147" d="M1490.9,412.1c-3.8-0.5-7.4-2.3-10.2-5.2c-1.4-1.4-2.5-3.2-3.2-5.1c-0.7-1.9-1-4-0.9-6
                c0.3-4.1,2.1-7.8,4.1-11c2.1-3.2,4.6-5.9,5.2-9.7c0.3,1.9-0.1,3.9-0.8,5.7c-0.7,1.8-1.6,3.5-2.5,5.1c-1.6,3.3-3,6.6-3.2,10
                c-0.2,3.4,0.8,6.8,3,9.6C1484.5,408.3,1487.5,410.5,1490.9,412.1z"/>
        </g>
        <g>
            <path class="st147" d="M1498.5,406.8c-3.3-1-6.2-3.8-7.3-7.4c-0.3-0.9-0.5-1.8-0.5-2.8c0-0.9,0-1.9,0.1-2.8
                c0.1-0.9,0.3-1.8,0.5-2.7c0.3-0.8,0.5-1.7,0.9-2.5c1.3-3.3,3.2-6.2,4.7-9.1c0.8-1.5,1.5-3,2-4.5c0.5-1.6,0.8-3.2,0.9-4.9
                c0.5,1.7,0.6,3.4,0.4,5.2c-0.2,1.8-0.7,3.5-1.3,5.1c-1.2,3.3-2.9,6.3-4.1,9.3c-0.6,1.5-1.1,3-1.4,4.6c-0.2,0.8-0.2,1.5-0.3,2.3
                c0,0.8,0,1.5,0.1,2.3C1493.9,401.9,1495.8,404.8,1498.5,406.8z"/>
        </g>
    </g>
</svg>