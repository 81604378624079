<script>
  import LanguageSwitch from "./LanguageSwitch.svelte";
  export let currentLanguage;
  export let headerMode;
  export let initiative;
  export let scrollPercent;
</script>

<div class={"header " + headerMode}>
  <div class={"logo " + headerMode}>
    {#if headerMode == "big"}
      <img src="icons/tijd-logo.svg" alt="" />
    {:else}
      <a href="#start"><img src="icons/logo-text.svg" alt="" /></a>
    {/if}
  </div>
  <div class="switch-container">
    <LanguageSwitch bind:currentLanguage {headerMode} />
  </div>
  <div class="initiative-container">{initiative}</div>
</div>
{#if headerMode == "small"}
<div class="scroll-indicator-container">
    <div class="scroll-indicator" style:width={Math.round(scrollPercent*100)/100 + "%"}></div>
</div>
{/if}

<style>
  .header {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 10;
    padding: 12px;
    transition: height 0.5s, background-color 0.5s;
  }
  .header.big {
    height: 50px;
    background-color: #16294a;
  }
  .header.small {
    height: 50px;
    background-color: white;
  }
  .logo {
    float: left;
    transition: width 0.5s;
  }
  .logo.big {
    margin-top: -12px;
    width: 92px;
  }
  .logo.small {
    width: 80px;
  }
  .initiative-container {
    float: right;
    color: white;
    font-size: 0.8rem;
    padding-top: 7px;
    padding-right: 24px;
  }
  .switch-container {
    float: right;
    margin-right: 24px;
  }
  .scroll-indicator-container {
    width: 100%;
    height: 6px;
    background-color: #E3E3E3;
    position: fixed;
    left:0px;
    top:50px;
    z-index: 100;
  }
  .scroll-indicator {
    height: 100%;
    background-color: #E4032C;
  }
</style>
