<script>
    import { onDestroy, setContext } from "svelte";
    import { mapbox, key } from "./mapbox.js";
  
    export let scrollIndex;
  
    setContext(key, {
      getMap: () => map,
    });
  
    let container;
    let map;
  
    function load() {
      map = new mapbox.Map({
        container,
        style: "mapbox://styles/mediafin/cl95ijf2y007n14n3u5335rbw",
        bounds: [4.17868, 51.225, 4.42600,  51.4],
        interactive: false
      });
  
      map.on("load", (f) => {
        map.setRenderWorldCopies(false);
      });
    }
  
    function updateBounds() {
      const bounds = map.getBounds()
      geoJsonToFit.features[0].geometry.coordinates = [bounds._ne.lng, bounds._ne.lat]
      geoJsonToFit.features[1].geometry.coordinates = [bounds._sw.lng, bounds._sw.lat]
    }
    $: if (scrollIndex == 0 && map) {
      map.fitBounds([4.17868, 51.225, 4.42600,  51.4])
    }
  
    $: if (scrollIndex == 1 && map) {
      map.fitBounds([4.248446917833655, 51.28910066888454, 4.260494948953212,  51.29460630035231])
    }
    $: if (scrollIndex == 2 && map) {
      //map.fitBounds([4.3426544050953, 51.265419239483585, 4.387360280600037,  51.2851584563362])
      map.fitBounds([4.367940009844077, 51.28183669362239, 4.3709988618032405,  51.283290918437324])
    }
    $: if (scrollIndex == 3 && map) {
      //map.fitBounds([4.302728489410235, 51.24262933092975, 4.362982353276051,  51.279471476037216])
      map.fitBounds([4.312130841579915, 51.25996410531679, 4.323933967528983,  51.26592800520799])
    }
    $: if ((scrollIndex == 4) && map) {
      map.fitBounds([3.17238, 51.22245, 3.25416, 51.4])
    }
    $: if ((scrollIndex == 5) && map) {
      map.fitBounds([3.2413278280069324, 51.30064654996, 3.2497876615645733, 51.30618238055951])
    }
    $: if ((scrollIndex == 6) && map) {
      map.fitBounds([3.212187772850871, 51.316634783350466, 3.2220530862637253, 51.324871626079926])
    }
    $: if ((scrollIndex == 7) && map) {
      map.fitBounds([3.209525127748198, 51.345816958862244, 3.228483471946746, 51.357979106319156])
      //map.fitBounds([3.2149318650239196, 51.351623511108656, 3.217832114590373, 51.3532269145953])
    }
  
    onDestroy(() => {
      if (map) map.remove();
    });
  </script>
  
  <svelte:head>
    <link
      rel="stylesheet"
      href="https://unpkg.com/mapbox-gl/dist/mapbox-gl.css"
      on:load={load}
    />
  </svelte:head>
  
  <div bind:this={container}>
    {#if map}
      <slot />
    {/if}
  </div>
  
  <style>
    div {
      width: 100%;
      height: 100%;
    }
  </style>
  