<script>
  import Scroller from "@sveltejs/svelte-scroller";
  import ScrollerScene from "./ScrollerScene.svelte";
  import MapboxMapTransport from "./MapboxMapTransport.svelte";
  import SvgMapTransport from "./SvgMapTransport.svelte";
  import { geoMercator } from "d3-geo";

  export let copy;
  export let smallScreen;

  let index, offset, progress;

  let width, height;

  let geoJsonToFit = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [1, 0],
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [0, 1],
        },
      },
    ],
  };
  $: projection = geoMercator().fitSize([width, height], geoJsonToFit);

  const ports = [
    {
      id: "antwerp",
      coordinates: [4.37455, 51.26334],
    },
    {
      id: "zeebrugge",
      coordinates: [3.20772, 51.33969],
    },
  ];
</script>

<Scroller top={0} bottom={0.8} bind:index bind:offset bind:progress>
  <div
    class="scroll-graphic"
    slot="background"
    bind:clientWidth={width}
    bind:clientHeight={height}
  >
    <MapboxMapTransport scrollIndex={index} bind:geoJsonToFit />
    <div class="svg-map-container">
      <SvgMapTransport
        {width}
        {height}
        {projection}
        scrollIndex={index}
        {ports}
        {smallScreen}
      />
    </div>

    <div class="main-text">
        <h2 class="highlight">{copy.title}</h2>
        <p>{copy.text}</p>
    </div>

    {#each ports as port}
      <div
        class="poab-annotation"
        style:left={projection(port.coordinates)[0] + 20 + "px"}
        style:top={projection(port.coordinates)[1] - 24 + "px"}
      >
        {copy[port.id]}
      </div>
    {/each}

    {#if !smallScreen}
    {#each copy.countryLabels as label}
      <div
        class="country-label"
        style:left={projection(label.coordinates)[0] + 20 + "px"}
        style:top={projection(label.coordinates)[1] - 24 + "px"}>{label.name}
    </div>
    {/each}

    <div class="tile-container">
      {#each copy.scenes as transportmode, i}
        <div class="tile main" style:opacity={i == index ? 1 : 0.3}>
            <div><img src={`/icons/${transportmode.icon}.svg`} width='50px' alt=""/></div>
            <div>{transportmode.label}</div>
        </div>
      {/each}
    </div>
    
    {#if index == 3}
    <div class="water-tile-container">
      {#each copy.waterTypes as watertype}
        <div class="tile">
            <div><img src={`/icons/${watertype.icon}.svg`} width='50px' alt=""/></div>
            <div class="water-type-label">{watertype.label}</div>
        </div>
      {/each}
    </div>
    {/if}
    {/if}
  </div>

  <div slot="foreground">
    {#each copy.scenes as scene, i}
      <ScrollerScene sceneTitle={scene.id} sceneText={scene.label} invisible={true} marginLastScene={i == copy.scenes.length - 1}/>
    {/each}
  </div>
</Scroller>

<style>
.main-text {
    position: absolute;
    left: 5%;
    bottom: 5%;
    max-width: 400px;
    background-color: white;
    padding: 8px;
    opacity: 0.9;
}
  .scroll-graphic {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .svg-map-container {
    position: absolute;
    top: 0;
    left: 0;
  }
  .poab-annotation {
    position: absolute;
    color: white;
    background-color: #d02f35;
    font-weight: 400;
    font-size: 1.3rem;
    padding: 2px 12px;
    border-radius: 16px;
  }
  .country-label {
    position: absolute;
    color: white;
    background-color: black;
    font-size: 1.3rem;
    padding: 2px 12px;
    border-radius: 16px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%) 
  }
  .tile-container {
    position: absolute;
    right: calc(5% + 100px + 22px);
    top: 20%;
  }
  .water-tile-container {
    position: absolute;
    right: 5%;
    top: 20%;
  }
  .tile {
    background-color: white;
    border-radius: 4px;
    width: 116px;
    padding: 8px;
    margin: 4px;
    text-align: center;
    height: 66px;
  }
  .water-type-label {
    line-height: 1;
  }
</style>
