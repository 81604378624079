<script>
  import { geoPath, geoCircle } from "d3-geo";
  import { pie, arc, line, curveNatural } from "d3-shape";
  import { scaleSqrt } from "d3-scale";
  import { fade } from 'svelte/transition';

  export let width;
  export let height;
  export let scrollIndex;
  export let projection;
  export let ports;
  export let cities;
  export let internationalPorts;
  export let partners;
  export let trade;
  export let routes;
  export let copy;
  export let language;
  export let smallScreen;

  const poabCoordinates = [3.780875483891387, 51.26417347407489];

  $: path = geoPath().projection(projection);

  const sizeScale = scaleSqrt().domain([0, 122.8]).range([0, smallScreen ? 50 : 75]);

  $: pieFeatures = trade.features.map((f) => {
    let obj = {};
    obj.geometry = f.geometry;
    obj.properties = f.properties;
    obj.piedata = pie().sortValues(null)([f.properties.import, f.properties.export]);
    return obj;
  });

  const lineGenerator = line().curve(curveNatural);
</script>

<svg {width} {height}>
  {#if scrollIndex == 0}
    {#each ports as port}
      <circle
        cx={projection(port.coordinates)[0]}
        cy={projection(port.coordinates)[1]}
        r={10}
        fill={"#D02F35"}
        stroke={"white"}
        stroke-width={6}
        transition:fade
      />
    {/each}
  {/if}

  {#if scrollIndex == 1}
    {#each cities.features as city}
      <circle
        cx={projection(city.geometry.coordinates)[0]}
        cy={projection(city.geometry.coordinates)[1]}
        r={city.properties.city == "" ? 12 : 6}
        fill={city.properties.city == "" ? "#D02F35" : "#64263D"}
        stroke={"white"}
        stroke-width={city.properties.city == "" ? 4 : 1}
        transition:fade
      />
    {/each}
    {#each [250, 500, 750, 1000] as radius}
      <path
        d={path(
          geoCircle()
            .center(poabCoordinates)
            .radius((radius / (6371 * Math.PI * 2)) * 360)
            .precision(5)()
        )}
        fill={"none"}
        stroke={"#D02F35"}
        stroke-width={1.5}
        id={"path-" + radius}
        transition:fade
      />
      <text dy={-3} class="distance-annotation" transition:fade>
        <textPath
          href={"#path-" + radius}
          startOffset={radius / 2}
          side={"right"}>{radius + "km"}</textPath
        >
      </text>
    {/each}
  {/if}

  {#if scrollIndex == 2}
    {#each routes.features as route}
      <path
        d={lineGenerator(route.geometry.coordinates.map((d) => projection(d)))}
        fill="none"
        stroke-dasharray="4 4"
        stroke-width={1}
        stroke="white"
        transition:fade
      />
    {/each}
    {#each pieFeatures as continent}
      {#each continent.piedata as slice, i}
        <g
          transform={`translate(${
            projection(continent.geometry.coordinates)[0]
          }, ${projection(continent.geometry.coordinates)[1]})`}
          transition:fade
        >
          <path
            d={arc()
              .innerRadius(0)
              .outerRadius(sizeScale(continent.properties.total))
              .startAngle(slice.startAngle)
              .endAngle(slice.endAngle)()}
            fill={i == 0 ? "#D02F35" : "grey"}
            stroke={"white"}
            stroke-width={1}
            opacity={0.8}
          />
          {#if continent.properties.key == "europe"}
              <text
                class={'import-export'}
                x={arc()
                  .innerRadius(0)
                  .outerRadius(sizeScale(continent.properties.total))
                  .startAngle(slice.startAngle)
                  .endAngle(slice.endAngle).centroid()[0]}
                y={arc()
                  .innerRadius(0)
                  .outerRadius(sizeScale(continent.properties.total))
                  .startAngle(slice.startAngle)
                  .endAngle(slice.endAngle).centroid()[1]}
                text-anchor={'middle'}
              >{i == 0 ? copy.import : copy.export}</text>
          {/if}
        </g>
      {/each}
      <text
        x={projection(continent.geometry.coordinates)[0]}
        y={projection(continent.geometry.coordinates)[1]}
        dy={-sizeScale(continent.properties.total) - 24}
        text-anchor={"middle"}>{copy[continent.properties.key]}</text
      >

      <text
        x={projection(continent.geometry.coordinates)[0]}
        y={projection(continent.geometry.coordinates)[1]}
        dy={-sizeScale(continent.properties.total) - 4}
        text-anchor={"middle"}>{continent.properties.total + copy["ton"]}</text
      >
    {/each}
  {/if}

  {#if scrollIndex == 3}
    {#each partners.features as partner}
      {#if partner.properties.key != "benin"}
      <circle
        cx={projection(partner.geometry.coordinates)[0]}
        cy={projection(partner.geometry.coordinates)[1]}
        r={partner.properties.key == "poab" ? 8 : 5}
        fill={partner.properties.type == "partner"
          ? "#D02F35"
          : partner.properties.key == "poab"
          ? "#D02F35"
          : "none"}
        stroke={partner.properties.key == "poab" ? "white" : "#D02F35"}
        stroke-width={partner.properties.key == "poab" ? 4 : 2}
        transition:fade
      />
      {/if}
    {/each}

  {/if}

  {#if scrollIndex == 4}
    {#each internationalPorts.features as port}
      <circle
        cx={projection(port.geometry.coordinates)[0]}
        cy={projection(port.geometry.coordinates)[1]}
        r={port.properties.nl == "Antwerpen" ? 8 : 5}
        fill={port.properties.nl == "Antwerpen" ? "#D02F35" : "#64263D"}
        stroke={"white"}
        stroke-width={port.properties.nl == "Antwerpen" ? 4 : 2}
        transition:fade
      />
      <text
        class="port-label"
        x={projection(port.geometry.coordinates)[0]}
        dx={port.properties.alignment == "left" ? -8 : 8}
        y={projection(port.geometry.coordinates)[1]}
        dy={port.properties.en == "Guangzhou"
          ? -4
          : port.properties.en == "Hong Kong" || port.properties.en == "Shenzen"
            ?  8
            : 4}
        text-anchor={port.properties.alignment == "left" ? "end" : "start"}
        transition:fade
      >{port.properties.en == "Antwerp" ? "" : port.properties[language]}</text>
    {/each}
  {/if}
</svg>

<style>
  .distance-annotation {
    fill: #d02f35;
  }
  .import-export {
    font-weight: bold;
    fill: white;
    opacity: 0.8;
  }
  .port-label {
    color: black;
    font-size: 0.8rem;
  }
</style>
