<script>
    import Scroller from "@sveltejs/svelte-scroller";
    import ScrollerScene from './ScrollerScene.svelte';
    import MapboxMapSatellite from './MapboxMapSatellite.svelte'
    import {fade} from 'svelte/transition'

    export let scenes;
    let index = 0
    let offset, progress;

    let width, height

    $: images = scenes.map(d => d.img)

  </script>
  
  <Scroller top="{0}" bottom="{0.8}" bind:index bind:offset bind:progress>
    <div class="scroll-graphic" slot="background" bind:clientWidth={width} bind:clientHeight={height}>
      <div class="image-container">
        {#if images}
        {#each [images[index]] as src (index)}
          <img in:fade src={'images/' + src + '.jpg'}/>
        {/each}
        {/if}
      </div>
      <div class="map-container">
      <MapboxMapSatellite scrollIndex={index}/>
    </div>
    </div>
  
    <div slot="foreground">
        {#each scenes as scene}
            <ScrollerScene sceneTitle={scene.title} sceneText={scene.text}></ScrollerScene>
        {/each}
    </div>
  </Scroller>

  <style>
    .scroll-graphic {
        width: 100%;
        height: 100vh;
        position: relative;
    }
    .image-container {
      position: absolute;
      left:0;
      width: 33.3%;
      height: 100%;
      background-color: #434a59;
      border-right: 1px solid white;
    }
    .map-container {
      position: absolute;
      top: 0;
      left: 33.3%;
      width: 66.6%;
      height: 100%;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  </style>