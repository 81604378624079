<script>
  import { tweened } from 'svelte/motion';
  export let width;
  export let height;
  export let copy;

  const gasHeightTop = 730
  const gasHeightBottom = 760

  const gasHeight = tweened(gasHeightBottom, {
    duration: 3500
  })
  $:if($gasHeight == gasHeightBottom){
    gasHeight.set(gasHeightTop)
  }
  $:if($gasHeight == gasHeightTop){
    gasHeight.set(gasHeightBottom)
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
  .molenbladen {
  animation: rotate 5s linear infinite;
  transform-box: fill-box;
  transform-origin: 50% calc(50% + 10px)
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>

<svg {width} {height} viewBox="0 0 557.17 2027.6"
  ><defs
    >
    <linearGradient id="gas-gradient" x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stop-color="white" />
      <stop offset="100%" stop-color="#acb40d" />
    </linearGradient><style>
      text {
        font-family: Arial;
      }
      .class-1,
      .class-2,
      .class-3,
      .class-4,
      .class-5 {
        stroke-miterlimit: 10;
      }
      .class-1,
      .class-6 {
        fill: none;
      }
      .class-1,
      .class-6,
      .class-7,
      .class-8 {
        stroke: #414a5a;
        stroke-width: 2.98px;
      }
      .class-9 {
        fill: #f3cd96;
      }
      .class-10,
      .class-3,
      .class-8,
      .class-11 {
        fill: #fff;
      }
      .class-12,
      .class-2 {
        fill: #ffcd00;
      }
      .class-13,
      .class-7 {
        fill: #83d0f5;
      }
      .class-14,
      .class-4 {
        fill: #acb40d;
      }
      .class-15,
      .class-5 {
        fill: #414a5a;
      }
      .class-16 {
        fill: #527123;
      }
      .class-17 {
        font-size: 30px;
        letter-spacing: 0em;
        font-family: Roboto, Arial, Helvetica, sans-serif;
      }
      .class-2,
      .class-3,
      .class-4 {
        stroke-width: 2px;
      }
      .class-2,
      .class-3,
      .class-4,
      .class-5 {
        stroke: #fff;
      }
      .class-6,
      .class-7,
      .class-8 {
        stroke-linejoin: round;
      }
      .class-5 {
        stroke-width: 0.5px;
      }
      .class-18 {
        letter-spacing: 0.04em;
      }
      .class-18,
      .class-19 {
        fill: #1d1d1b;
      }
      .class-18,
      .class-19,
      .class-11 {
        font-size: 16.86px;
      }
      .class-19,
      .class-11 {
        letter-spacing: 0.04em;
      }
    </style></defs
  ><g id="TXT"
    ><g
      ><path
        class="class-10"
        d="M538.31,830.6H18.86c-10.41,0-18.86-8.44-18.86-18.86V18.86C0,8.44,8.44,0,18.86,0H538.31c10.41,0,18.86,8.44,18.86,18.86V811.74c0,10.41-8.44,18.86-18.86,18.86"
      /><path
        class="class-10"
        d="M538.31,2027.6H18.86c-10.41,0-18.86-8.44-18.86-18.86v-882.89c0-10.42,8.44-18.86,18.86-18.86H538.31c10.41,0,18.86,8.44,18.86,18.86v882.89c0,10.41-8.44,18.86-18.86,18.86"
      /><text class="class-17" transform="translate(387.75 70.84)" font-family="Roboto, Arial, Helvetica, sans-serif"
        ><tspan x="0" y="0">Zeebrugge</tspan></text
      ><text class="class-17" transform="translate(385.77 1160.84)" font-family="Roboto, Arial, Helvetica, sans-serif"
        ><tspan x="0" y="0">Antwerpen</tspan></text
      ><rect
        class="class-13"
        x="127.59"
        y="1320.94"
        width="40"
        height="377"
      /><rect
        class="class-12"
        x="127.59"
        y="161.94"
        width="40"
        height="1097"
      /><rect
        class="class-14"
        x="214.59"
        y="245.94"
        width="40"
        height="1507"
      /><rect
        class="class-9"
        x="389.59"
        y="1276.94"
        width="40"
        height="684.36"
      /><rect
        class="class-16"
        x="302.25"
        y="446.94"
        width="40"
        height="1360"
      /><g
        ><g
          ><path
            class="class-8"
            d="M448.31,1499.6H108.86c-10.41,0-18.86-8.44-18.86-18.86v-207.89c0-10.42,8.44-18.86,18.86-18.86H448.31c10.41,0,18.86,8.44,18.86,18.86v207.89c0,10.41-8.44,18.86-18.86,18.86"
          /><path
            class="class-8"
            d="M448.31,1499.6H108.86c-10.41,0-18.86-8.44-18.86-18.86v-207.89c0-10.42,8.44-18.86,18.86-18.86H448.31c10.41,0,18.86,8.44,18.86,18.86v207.89c0,10.41-8.44,18.86-18.86,18.86"
          /></g
        ><path
          class="class-7"
          d="M210.58,1474.93v-8.4h-4v-32.6c0-1.37,.01-2.74,.02-4.1,.01-2.7-1.08-4.53-3.73-5.47-2.54-.91-5.16-1.79-7.84-2.24-13.54-2.3-27.2-2.58-40.86-1.22-5.52,.55-11.04,1.63-16.38,3.01-4.18,1.08-4.9,2.56-4.9,6.44,0,19.59,0,16.6,0,36.19h-4.96v8.4h64.25"
        /><rect
          class="class-14"
          x="275.49"
          y="1400.94"
          width="50"
          height="59"
        /><rect
          class="class-16"
          x="336.49"
          y="1426.94"
          width="50"
          height="33"
        /><rect
          class="class-9"
          x="398.49"
          y="1421.94"
          width="23"
          height="38"
        /><g
          ><path
            class="class-1"
            d="M218.53,1459.79h45.59l-.02-27.85v-4.69h-9.07v-60.48c-.68-.06-1.23-.1-1.81-.15v-5.69c.41-.05,.74-.09,1.09-.13v-6.24h-3.46v-5.8c-.89-.04-1.67-.08-2.53-.12,0-2.77,.02-5.41,0-8.05-.05-5.05-3.47-8.45-8.5-8.45-4.07,0-8.14,0-12.22,0-4.73,.02-8.2,3.48-8.25,8.23-.02,1.84,0,3.69,0,5.53v3.42h-4.14c0-2.55,.02-5.04,0-7.52-.04-4.47-3.45-8.01-7.92-8.1-3.42-.07-6.84-.07-10.25,0-4.19,.1-7.7,3.49-7.77,7.61-.09,5.38-.01,10.75-.01,16.13,0,2.37,0,4.74,0,7.25-1.14,0-2.12,0-3.11,0-5.25,.02-8.65,3.43-8.65,8.67,0,4.75,0,9.51,0,14.26-8.49,.84-15.13,8-15.13,16.71,0,.42,.02,.84,.05,1.26-.04,.54-.07,1.09-.07,1.64m58.19,67.7h208.63v-8.77m-30.5-43.77h22.83v37.4h-22.83v-37.4Zm-13.28,3.14c0-13.37-10.84-24.21-24.21-24.21s-24.21,10.84-24.21,24.21c0,.44,.01,.88,.04,1.31h-.04v32.94h48.41v-32.94h-.04c.02-.43,.04-.87,.04-1.31m-69.45-75.85c0-3.45,1.9-5.33,5.33-5.34,.96,0,1.91,0,3,0v22.82c-2.37-1.77-5.23-2.93-8.33-3.24,0-4.75,0-9.5,0-14.25m8.33,42.68c0-13.37-10.84-24.21-24.21-24.21s-24.21,10.84-24.21,24.21c0,.44,.01,.88,.04,1.31h-.04v66.12h48.41v-66.12h-.04c.02-.43,.04-.87,.04-1.31"
          /><path
            class="class-6"
            d="M148.37,1409.99v-124.11h-15.36v124.11"
          /></g
        ></g
      ><circle class="class-2" cx="147.59" cy="161.3" r="30" /><g
        ><path
          class="class-8"
          d="M340.41,789.44h-124.28c-3.81,0-6.9-3.09-6.9-6.9v-97.63c0-3.81,3.09-6.9,6.9-6.9h124.28c3.81,0,6.9,3.09,6.9,6.9v97.63c0,3.81-3.09,6.9-6.9,6.9"
        /><path
          class="class-8"
          d="M340.41,789.44h-124.28c-3.81,0-6.9-3.09-6.9-6.9v-97.63c0-3.81,3.09-6.9,6.9-6.9h124.28c3.81,0,6.9,3.09,6.9,6.9v97.63c0,3.81-3.09,6.9-6.9,6.9"
        /></g
      ><g
        ><polygon
          fill="url(#gas-gradient)"
          points={`328.63 ${$gasHeight} 229.98 ${$gasHeight} 229.98 759.75 225.08 759.75 225.08 766.95 309.15 766.95 331.46 766.95 331.46 759.75 328.63 759.75 328.63 ${$gasHeight}`}
        /><path
          class="class-1"
          d="M331.46,766.95v-7.2h-2.83v-39.14c0-2.67-.45-3.7-3.04-4.44-3.32-.95-6.74-1.69-10.17-2.07-8.48-.94-16.96-.74-25.36,.84-.5,.09-.99,.22-1.48,.36v14.36h-9.51v-17.25c0-1.17,0-2.35,.01-3.52,0-2.31-.84-3.88-2.88-4.69-1.97-.78-3.99-1.54-6.06-1.93-10.47-1.97-13.13-2.21-23.69-1.04-4.27,.47-8.53,1.4-12.67,2.58-3.24,.93-3.79,2.2-3.79,5.53v50.43h-4.91v7.2h84.08"
        /></g
      ><g
        ><g
          ><path
            class="class-8"
            d="M340.41,490.88h-124.28c-3.81,0-6.9-3.09-6.9-6.9v-97.63c0-3.81,3.09-6.9,6.9-6.9h124.28c3.81,0,6.9,3.09,6.9,6.9v97.63c0,3.81-3.09,6.9-6.9,6.9"
          /><path
            class="class-8"
            d="M340.41,490.88h-124.28c-3.81,0-6.9-3.09-6.9-6.9v-97.63c0-3.81,3.09-6.9,6.9-6.9h124.28c3.81,0,6.9,3.09,6.9,6.9v97.63c0,3.81-3.09,6.9-6.9,6.9"
          /></g
        ><path
          class="class-15"
          d="M301.01,469.46c-5.11,0-7.52-1.67-10.31-3.61-3.03-2.1-6.17-4.28-12.44-4.28s-9.4,2.17-12.43,4.28c-2.07,1.44-4.03,2.79-7.09,3.35l-1.13,.2-4.44-8.07c-2.89-5.24-4.42-11.18-4.42-17.17v-17.29h9.09c4.04,0,7.32-3.28,7.32-7.32v-7.18h26.2v10.79h-19.01c-.32,1.3-.82,2.55-1.48,3.72h36.9v17.29c0,5.99-1.53,11.92-4.42,17.17l-.16,.3c1.26-.3,2.18-.94,3.77-2.04,.55-.38,1.19-.82,1.88-1.27,1.75-4.54,2.64-9.3,2.64-14.16v-21.01h-16.41v-10.79h6.82v-3.72h-21.79v-9.62c0-1.03-.83-1.86-1.86-1.86s-1.86,.84-1.86,1.86v9.62h-21.79v3.72h6.82v7.18c0,1.99-1.62,3.6-3.61,3.6h-12.81v21.01c0,6.61,1.69,13.17,4.88,18.97l3.85,6.98-4.17-1.69c-1.67-.68-3.05-1.64-4.39-2.57-2.76-1.92-5.61-3.89-10.84-4.23v3.73c4.05,.31,6.23,1.83,8.72,3.55,3.04,2.11,6.17,4.28,12.43,4.28s9.4-2.18,12.43-4.28c2.79-1.94,5.21-3.61,10.31-3.61s7.52,1.67,10.31,3.61c3.03,2.11,6.17,4.28,12.43,4.28s9.4-2.18,12.43-4.28c2.49-1.73,4.67-3.24,8.72-3.55v-3.73c-5.23,.34-8.08,2.31-10.84,4.23h0c-2.79,1.94-5.2,3.61-10.31,3.61Z"
        /></g
      ><rect
        class="class-12"
        x="168.59"
        y="195.94"
        width="40"
        height="100"
        transform="translate(434.53 57.36) rotate(90)"
      /><circle class="class-4" cx="234.92" cy="245.94" r="30" /><circle
        class="class-3"
        cx="234.59"
        cy="1767.44"
        r="30"
      /><circle class="class-3" cx="147.59" cy="1712.94" r="30" /><circle
        class="class-3"
        cx="409.59"
        cy="1976.3"
        r="30"
      /><circle class="class-3" cx="322.25" cy="1821.94" r="30" /><g
        ><path
          class="class-10"
          d="M220.38,843.94v4.15c0,3.22,2.62,5.85,5.84,5.85h8.22c1.16,0,2.11,.94,2.11,2.11v3.78c0,2.61,2.12,4.73,4.73,4.73h2.58c.65,0,1.18,.53,1.18,1.18v17.22c0,.65-.53,1.18-1.18,1.18h-2.97c.91,1.03,1.53,2.32,1.73,3.74h1.24c2.71,0,4.92-2.21,4.92-4.92v-17.22c0-2.72-2.21-4.92-4.92-4.92h-2.58c-.55,0-.99-.45-.99-.99v-3.78c0-3.22-2.62-5.85-5.85-5.85h-8.22c-1.16,0-2.1-.95-2.1-2.11v-4.15c0-1.16,.95-2.11,2.1-2.11h22.57v-3.74h-22.57c-3.22,0-5.84,2.62-5.84,5.85"
        /><path
          class="class-10"
          d="M235.55,884.13h-3.15c-.65,0-1.18-.53-1.18-1.18v-17.22c0-.65,.53-1.18,1.18-1.18h3.54c-.91-1.03-1.53-2.32-1.73-3.74h-1.8c-2.72,0-4.92,2.21-4.92,4.92v17.22c0,2.72,2.21,4.92,4.92,4.92h3.15c.55,0,.99,.45,.99,1v4c0,3.23,2.62,5.85,5.85,5.85h6.39v-3.74h-6.39c-1.16,0-2.1-.95-2.1-2.11v-4c0-2.62-2.13-4.74-4.74-4.74"
        /></g
      ><g
        ><path
          class="class-10"
          d="M308.05,843.94v4.15c0,3.22,2.62,5.85,5.84,5.85h8.22c1.16,0,2.11,.94,2.11,2.11v3.78c0,2.61,2.12,4.73,4.73,4.73h2.58c.65,0,1.18,.53,1.18,1.18v17.22c0,.65-.53,1.18-1.18,1.18h-2.97c.91,1.03,1.53,2.32,1.73,3.74h1.24c2.71,0,4.92-2.21,4.92-4.92v-17.22c0-2.72-2.21-4.92-4.92-4.92h-2.58c-.55,0-.99-.45-.99-.99v-3.78c0-3.22-2.62-5.85-5.85-5.85h-8.22c-1.16,0-2.1-.95-2.1-2.11v-4.15c0-1.16,.95-2.11,2.1-2.11h22.57v-3.74h-22.57c-3.22,0-5.84,2.62-5.84,5.85"
        /><path
          class="class-10"
          d="M323.22,884.13h-3.15c-.65,0-1.18-.53-1.18-1.18v-17.22c0-.65,.53-1.18,1.18-1.18h3.54c-.91-1.03-1.53-2.32-1.73-3.74h-1.8c-2.72,0-4.92,2.21-4.92,4.92v17.22c0,2.72,2.21,4.92,4.92,4.92h3.15c.55,0,.99,.45,.99,1v4c0,3.23,2.62,5.85,5.85,5.85h6.39v-3.74h-6.39c-1.16,0-2.1-.95-2.1-2.11v-4c0-2.62-2.13-4.74-4.74-4.74"
        /></g
      ><g
        ><path
          class="class-13"
          d="M172.29,1699.06h-4.15c-3.22,0-5.85,2.62-5.85,5.84v8.22c0,1.16-.94,2.11-2.11,2.11h-3.78c-2.61,0-4.73,2.12-4.73,4.73v2.58c0,.65-.53,1.18-1.18,1.18h-17.22c-.65,0-1.18-.53-1.18-1.18v-2.97c-1.03,.91-2.32,1.53-3.74,1.73v1.24c0,2.71,2.21,4.92,4.92,4.92h17.22c2.72,0,4.92-2.21,4.92-4.92v-2.58c0-.55,.45-.99,.99-.99h3.78c3.22,0,5.85-2.62,5.85-5.85v-8.22c0-1.16,.95-2.1,2.11-2.1h4.15c1.16,0,2.11,.95,2.11,2.1v22.57h3.74v-22.57c0-3.22-2.62-5.84-5.85-5.84"
        /><path
          class="class-13"
          d="M132.1,1714.24v-3.15c0-.65,.53-1.18,1.18-1.18h17.22c.65,0,1.18,.53,1.18,1.18v3.54c1.03-.91,2.32-1.53,3.74-1.73v-1.8c0-2.72-2.21-4.92-4.92-4.92h-17.22c-2.72,0-4.92,2.21-4.92,4.92v3.15c0,.55-.45,.99-1,.99h-4c-3.23,0-5.85,2.62-5.85,5.85v6.39h3.74v-6.39c0-1.16,.95-2.1,2.11-2.1h4c2.62,0,4.74-2.13,4.74-4.74"
        /></g
      ><g
        ><path
          class="class-9"
          d="M434.29,1962.06h-4.15c-3.22,0-5.85,2.62-5.85,5.84v8.22c0,1.16-.94,2.11-2.11,2.11h-3.78c-2.61,0-4.73,2.12-4.73,4.73v2.58c0,.65-.53,1.18-1.18,1.18h-17.22c-.65,0-1.18-.53-1.18-1.18v-2.97c-1.03,.91-2.32,1.53-3.74,1.73v1.24c0,2.71,2.21,4.92,4.92,4.92h17.22c2.72,0,4.92-2.21,4.92-4.92v-2.58c0-.55,.45-.99,.99-.99h3.78c3.22,0,5.85-2.62,5.85-5.85v-8.22c0-1.16,.95-2.1,2.11-2.1h4.15c1.16,0,2.11,.95,2.11,2.1v22.57h3.74v-22.57c0-3.22-2.62-5.84-5.85-5.84"
        /><path
          class="class-9"
          d="M394.1,1977.24v-3.15c0-.65,.53-1.18,1.18-1.18h17.22c.65,0,1.18,.53,1.18,1.18v3.54c1.03-.91,2.32-1.53,3.74-1.73v-1.8c0-2.72-2.21-4.92-4.92-4.92h-17.22c-2.72,0-4.92,2.21-4.92,4.92v3.15c0,.55-.45,.99-1,.99h-4c-3.23,0-5.85,2.62-5.85,5.85v6.39h3.74v-6.39c0-1.16,.95-2.1,2.11-2.1h4c2.62,0,4.74-2.13,4.74-4.74"
        /></g
      ><g
        ><path
          class="class-14"
          d="M252.08,1766.13h-15.65c-1.06,0-2.11-.27-3.04-.78l-6.58-4.4,6.97-3.67c.34-.18,.76-.18,1.1,0l6.89,3.63-4.33,2.79h6.91l.76-.49c.86-.49,1.38-1.4,1.38-2.39s-.56-1.96-1.47-2.44l-8.39-4.41c-1.44-.76-3.15-.76-4.59,0l-8.45,4.45c-.89,.47-1.46,1.39-1.48,2.4-.01,.97,.48,1.86,1.28,2.37l.04,.03h0s7.92,5.3,7.92,5.3l.12,.07c1.5,.85,3.21,1.31,4.95,1.31h15.65c4.03,0,8.34,1.77,8.34,6.74v7.04h-6.1v1.88c0,1.23-1.01,2.31-2.16,2.31-1.04,0-1.97-.89-2.13-1.96v-.34s0-1.88,0-1.88h-3.78v2.21c-.15,1.07-1.08,1.96-2.12,1.96-1.19,0-2.16-.97-2.16-2.16v-1.98h-3.75v1.98c0,3.26,2.65,5.91,5.91,5.91,1.53,0,2.95-.63,4.02-1.65,1.06,1.02,2.48,1.65,4.02,1.65,2.57,0,4.82-1.78,5.61-4.18h6.4v-10.79c0-6.27-4.86-10.49-12.09-10.49"
        /><path
          class="class-14"
          d="M226.74,1785.68c0,1.19-.97,2.16-2.16,2.16-1.05,0-1.97-.88-2.14-1.96v-.33s.01-1.88,.01-1.88h-3.78v2.21c-.15,1.07-1.08,1.96-2.13,1.96-1.19,0-2.16-.97-2.16-2.16v-1.87h-6.13v-7.19c0-4.96,4.31-6.73,8.34-6.73h12.49l-5.6-3.75h-6.89c-7.23,0-12.09,4.21-12.09,10.48v10.94h6.44c.78,2.35,3,4.04,5.6,4.04,1.54,0,2.95-.64,4.02-1.66,1.06,1.02,2.48,1.66,4.02,1.66,2.66,0,4.91-1.76,5.65-4.18h5.69v-3.75h-9.18v2.02Z"
        /></g
      ><path
        class="class-16"
        d="M343.7,1832.75h-43.47c-7.47,0-12.49-4.35-12.49-10.83v-4.1h4.22c.72,0,1.31-.59,1.31-1.31l.02-4.51h14.53v9.7h-3.87v-5.83h-6.8v.66c0,2.84-2.33,5.16-5.18,5.16h-.35v.22c0,5.13,4.45,6.95,8.62,6.95h43.47c2.47,0,4.15-1.03,5.95-3.67l1.4-3.51h-40.84v-3.87h46.55l-3.66,9.2-.11,.17c-1.73,2.58-4.34,5.55-9.28,5.55"
      /><g
        >
        <g class={'molenbladen'} transform-origin={'center'} transform-box={'fill-box'}><path
          class="class-15"
          d="M162.15,87.92c-.24,2.23-.98,4.38-2.17,6.29l16.24,8.73,3.11-5.79-17.17-9.23Z"
        /><path
          class="class-15"
          d="M147.21,71.29c1.38,0,2.74,.19,4.06,.56v-21.15h-6.57v20.8c.83-.14,1.67-.21,2.51-.21Z"
        /><path
          class="class-15"
          d="M134.52,94.33c-1.21-1.9-1.96-4.03-2.23-6.25l-17.32,9.06,3.04,5.82,16.5-8.64Z"
        />
        </g>
        <path
          class="class-15"
          d="M142.68,100.62l-8.28,63.41c-.12,.93,.16,1.88,.79,2.59,.62,.71,1.52,1.12,2.47,1.12h20.09c.94,0,1.84-.41,2.47-1.12,.62-.71,.91-1.65,.79-2.59l-8.27-63.76c-3.21,1.27-6.78,1.39-10.05,.35Zm-1.28,60.55l6.32-48.44,6.29,48.44h-12.61Z"
        /><path
          class="class-5"
          d="M147.21,78.31c-4.41,0-8,3.59-8,8s3.59,8,8,8,8-3.59,8-8-3.59-8-8-8Z"
        /></g
      ><text class="class-19" transform="translate(238.96 369.11) rotate(-90)"
        ><tspan x="0" y="0">{copy.hydrogen}</tspan></text
      ><text class="class-11" transform="translate(326.63 657.11) rotate(-90)"
        ><tspan x="0" y="0">{copy.hydrocarriers}</tspan></text
      ><text class="class-19" transform="translate(238.96 1625.43) rotate(-90)"
        ><tspan x="0" y="0">{copy.hydrogen}</tspan></text
      ><text class="class-19" transform="translate(413.96 1573.83) rotate(-90)"
        ><tspan x="0" y="0">CO₂</tspan></text
      ><text class="class-11" transform="translate(326.63 1690.29) rotate(-90)"
        ><tspan x="0" y="0">{copy.hydrocarriers}</tspan></text
      ><text class="class-18" transform="translate(151.96 336.11) rotate(-90)"
        ><tspan x="0" y="0">{copy.green}</tspan></text
      ><text class="class-19" transform="translate(151.96 1644.11) rotate(-90)"
        ><tspan x="0" y="0">{copy.chemicals}</tspan></text
      ></g
    ></g
  ></svg
>
