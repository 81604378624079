<script>
    import { onDestroy, setContext } from "svelte";
    import { mapbox, key } from "./mapbox.js";
  
    export let scrollIndex;
    export let geoJsonToFit;
  
    setContext(key, {
      getMap: () => map,
    });
  
    let container;
    let map;
  
    function load() {
      map = new mapbox.Map({
        container,
        style: "mapbox://styles/mediafin/cl94iakqn006514n3cjkllqwa/draft",
        bounds: [3.5, 49, 5, 52]
      });
  
      map.on("load", (f) => {
        map.setRenderWorldCopies(false);
        map.setPaintProperty('ne-10m-railroads', 'line-opacity', 1)
        map.setPaintProperty('ne-10m-railroads-bg', 'line-opacity', 1)
        updateBounds()
        map.on("viewreset", updateBounds);
        map.on("zoom", updateBounds);
        map.on("drag", updateBounds);
        map.on("move", updateBounds);
      });
    }
  
    function updateBounds() {
      const bounds = map.getBounds()
      geoJsonToFit.features[0].geometry.coordinates = [bounds._ne.lng, bounds._ne.lat]
      geoJsonToFit.features[1].geometry.coordinates = [bounds._sw.lng, bounds._sw.lat]
    }

    $: if (scrollIndex == 0 && map && map.isStyleLoaded()){
        map.setPaintProperty('ne-10m-railroads', 'line-opacity', 1)
        map.setPaintProperty('ne-10m-railroads-bg', 'line-opacity', 1)
        map.setPaintProperty('road-number-shield', 'text-opacity', 0)
        map.setPaintProperty('road-number-shield', 'icon-opacity', 0)
        map.setPaintProperty('road-motorway-trunk', 'line-opacity', 0)
        map.setPaintProperty('road-motorway-trunk-case', 'line-opacity', 0)
    }

    $: if (scrollIndex == 1 && map && map.isStyleLoaded()){
        map.setPaintProperty('ne-10m-railroads', 'line-opacity', 0)
        map.setPaintProperty('ne-10m-railroads-bg', 'line-opacity', 0)
        map.setPaintProperty('road-number-shield', 'text-opacity', 1)
        map.setPaintProperty('road-number-shield', 'icon-opacity', 1)
        map.setPaintProperty('road-motorway-trunk', 'line-opacity', 1)
        map.setPaintProperty('road-motorway-trunk-case', 'line-opacity', 1)
    }

    $: if (scrollIndex == 2 && map && map.isStyleLoaded()){
        map.setPaintProperty('ne-10m-railroads', 'line-opacity', 0)
        map.setPaintProperty('ne-10m-railroads-bg', 'line-opacity', 0)
        map.setPaintProperty('road-number-shield', 'text-opacity', 0)
        map.setPaintProperty('road-number-shield', 'icon-opacity', 0)
        map.setPaintProperty('road-motorway-trunk', 'line-opacity', 0)
        map.setPaintProperty('road-motorway-trunk-case', 'line-opacity', 0)
        map.setPaintProperty('water-thick', 'line-opacity', 0)
    }

    $: if (scrollIndex == 3 && map && map.isStyleLoaded()){
        map.setPaintProperty('ne-10m-railroads', 'line-opacity', 0)
        map.setPaintProperty('ne-10m-railroads-bg', 'line-opacity', 0)
        map.setPaintProperty('water-thick', 'line-opacity', 1)
    }
  
    onDestroy(() => {
      if (map) map.remove();
    });
  </script>
  
  <svelte:head>
    <link
      rel="stylesheet"
      href="https://unpkg.com/mapbox-gl/dist/mapbox-gl.css"
      on:load={load}
    />
  </svelte:head>
  
  <div bind:this={container}>
    {#if map}
      <slot />
    {/if}
  </div>
  
  <style>
    div {
      width: 100%;
      height: 100%;
    }
  </style>
  