<script>
  import { inview } from "svelte-inview";
  export let title;
  export let headerMode;

  const options = {rootMargin: '-100px'}
</script>

<div
  class="container"
  id="start"
  use:inview={options}
  on:enter={(event) => {
    const { inView, entry, scrollDirection, observer, node } = event.detail;
    headerMode = "big"
  }}
  on:leave={(event) => {
    const { inView, entry, scrollDirection, observer, node } = event.detail;
    headerMode = "small"
  }}
>
  <video autoplay muted loop
    ><source src="images/opening-video.mp4" type="video/mp4" /></video
  >

  <div class="text-overlay">
    <h1>{title}</h1>
  </div>

  <div class="scroll-down">
    <div>start</div>
    <img src="icons/scroll-down.svg" width="80px" alt="Scroll down indicator" />
  </div>
</div>

<div class="logo">
  <img src="icons/logo-text.svg" alt="">
</div>

<style>
  .container {
    height: 100vh;
    position: relative;
  }
  video {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    color: #e4032c;
    font-size: 4rem;
    font-weight: bold;
  }
  .scroll-down {
    position: absolute;
    left: calc(50% - 40px);
    bottom: 10%;
    text-align: center;
    font-size: 2rem;
    color: #e4032c;
  }
  .logo {
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 120px;
  }
</style>
