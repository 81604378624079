<script>
  import Scroller from "@sveltejs/svelte-scroller";
  import ScrollerScene from "./ScrollerScene.svelte";
  import EnvironmentGraphic from "./EnvironmentGraphic.svelte";
  import {fade} from 'svelte/transition';

  export let copy;
  export let smallScreen;
  let index, offset, progress;

  let width, height;
  $: scenes = copy.scenes;

</script>

<Scroller top={0} bottom={0.8} bind:index bind:offset bind:progress>
  <div
    class="scroll-graphic"
    slot="background"
    bind:clientWidth={width}
    bind:clientHeight={height}
  >
    <div class="graphic-container">
      <EnvironmentGraphic {width} {height} scrollIndex={index}/>
    </div>
    {#if !smallScreen || index == 0 }
    <div class={"intro-container"}>
      <div class={ smallScreen ? "intro-text small-screen" : "intro-text"} style:left={'40px'}
      style:top={'5%'}>
      <h2 class="highlight">{copy.title}</h2>
      <p>{copy.text}</p>
    </div>
    </div>
    {/if}
  </div>


  <div slot="foreground">
    {#each scenes as scene,i}
      <ScrollerScene sceneTitle={scene.title} sceneText={scene.text} invisible={true}/>
    {/each}
  </div>
</Scroller>

{#if index && index < 4}
<div class="annotation-container" transition:fade>
    {#each scenes as scene, i}
    {#if i > 0}
      <div
        class="annotation"
        style:left={scene.left}
        style:bottom={scene.bottom}
        style:top={scene.top}
        style:right={scene.right}
        style:opacity={i == index ? 0.9 : 0}
      >
        <h2>{scene.title}</h2>
        <p>{scene.text}</p>
        <a href={scene.ctalink} class="cta" target="_blank">{scene.ctatext}</a>
      </div>
      {/if}
    {/each}
</div>
{/if}

<style>
  .scroll-graphic {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .graphic-container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #EDCF9C;
  }
  .annotation-container {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .intro-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .intro-text.small-screen {
    background: white;
    padding: 8px;
    padding-bottom: 14px;
    border-radius: 4px;
    opacity: 0.9;
    transition: opacity 0.5s;
  }
  .intro-text {
    position: absolute;
    max-width: 400px;
    font-size: 1.3rem;
  }
  .intro-text p {
    font-weight: 300;
  }
  .annotation {
    position: absolute;
    max-width: 400px;
    background: white;
    padding: 8px;
    padding-bottom: 14px;
    border-radius: 4px;
    transition: opacity 0.5s;
    z-index: 100;
  }
  .cta {
    background-color: rgb(228, 3, 44);
    padding-bottom: 0.75rem;;
    padding: 6px;
    border-radius: 9999px;
    font-size: 0.8rem;
    font-weight: bold;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 6px;
    color: white;
    text-decoration: none;
  }
  .cta:hover {
    background-color: #ad0524;
  }
</style>
