<script>
  export let sceneTitle;
  export let sceneText;
  export let invisible;
  export let marginLastScene;
</script>

<section
  class={marginLastScene ? "scroller-scene extra-margin" : "scroller-scene"}
  style:visibility={invisible ? "hidden" : "visible"}
>
  <div class="scene-content">
    <h2 class="scene-title highlight">{sceneTitle}</h2>
    <p class="scene-text">{@html sceneText}</p>
  </div>
</section>

<style>
  .scroller-scene {
    height: 95vh;
    min-height: 600px;
    padding-top: 200px;
  }
  .scroller-scene.extra-margin {
    margin-bottom: 176px;
  }
  .scene-content {
    background-color: white;
    max-width: 420px;
    min-height: 150px;
    margin-left: 10%;
    padding: 4px 16px;
    border-radius: 6px;
    opacity: 0.85;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    font-weight: 300;
    font-size: 1.2rem;
    pointer-events: all;
  }
</style>
