<script>
    export let currentLanguage
    export let headerMode;
</script>

    <input id="nl-switch" type=radio bind:group={currentLanguage} name="langs" value={'nl'}>
    <label for="nl-switch" class={headerMode}>NL</label>

    <input id="en-switch" type=radio bind:group={currentLanguage} name="langs" value={'en'}>
    <label for="en-switch" class={headerMode}>EN</label>
    
<style>
input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
label {
    display: inline-block;
    padding: 4px 20px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.5s;
}
input[type="radio"]:not(:checked) + label.big {
    color: white;
}
input[type="radio"]:checked + label.big {
    font-weight: bold;
    color: white;
}
input[type="radio"]:not(:checked) + label.small {
    color: grey;
}
input[type="radio"]:checked + label.small {
    font-weight: bold;
    color: black;
}
</style>