<script>
  import { inview } from "svelte-inview";

  export let annotation;
  export let w;
  export let smallScreen

  let isInView;

  $: inviewOptions = {rootMargin: smallScreen ? "-150px" : "-300px" }
</script>

<div
  class={isInView ? "annotation-wrapper visible" : "annotation-wrapper"}
  use:inview={inviewOptions}
  on:enter={(event) => {
    const { inView, entry, scrollDirection, observer, node } = event.detail;
    isInView = inView;
  }}
        style:left={annotation.location == "left" && w > 1000
        ? "15%"
        : annotation.location == "left"
        ? "24px"
        : ""}
      style:right={annotation.location == "right" && w > 1000
        ? "15%"
        : annotation.location == "right"
        ? "24px"
        : ""}
      style:margin-top={annotation.y + "px"}
>
    <div
      class="annotation"
    >
      <p>{@html annotation.text}</p>
    </div>
</div>

<style>
  .annotation-wrapper {
    position: absolute;
    max-width: 250px;
    opacity: 0;
    transition: opacity 1s;
  }
  .annotation-wrapper.visible {
    opacity: 1;
  }
  .annotation {
    background: white;
    padding: 1rem 1rem;
    border-radius: 8px;
  }
</style>
