<script>
  import EnergyPortGraphic from "./EnergyPortGraphic.svelte";
  import EnergyGraphicAnnotation from "./EnergyGraphicAnnotation.svelte";
  import { inview } from "svelte-inview";
  import {fade} from 'svelte/transition';
  
  export let copy;
  export let w;
  export let back;
  export let smallScreen;

  let isInView;

  $: inviewOptions = {rootMargin: "-300px" }
</script>

<div
  class="text-graphic-container"
  use:inview={inviewOptions}
  on:change={(event) => {
    const { inView, entry, scrollDirection, observer, node } = event.detail;
    isInView = inView;
  }}>
  <div class="text-container">
    <h2 class="highlight">{copy.title}</h2>
    <p>{copy.text}</p>
  </div>

  <div class="graphic-container" bind:clientWidth={w}>
    <div class="graphic">
      <div class="svg-container">
        <EnergyPortGraphic width={557.17} height={2027.6} {copy} />
      </div>
      <div class="annotation-container">
        {#each copy.annotations as annotation, i}
          <EnergyGraphicAnnotation {w} {annotation} {smallScreen}/>
        {/each}
      </div>
    </div>
  </div>
  {#if isInView}
  <a href="#start" transition:fade>
  <div class="scroll-up">
    <img src="icons/scroll-up.svg" width="80px" alt="Back to beginning" />
    <div>{back}</div>
  </div>
</a>
{/if}
</div>



<style>
  .text-graphic-container {
    width: 100%;
    background-color: #ebbfb9;
    margin-top: 160px;
    padding-bottom: 160px;
    position: relative;
  }
  .text-container {
    max-width: 600px;
    margin: auto;
    padding-top: 48px;
    text-align: center;
    z-index: 20;
    font-size: 1.3rem;
  }
  .text-container p {
    font-weight: 300;
  }
  .graphic-container {
    position: relative;
  }
  .graphic {
    width: 100%;
    background-color: #ebbfb9;
  }
  .svg-container {
    width: 558px;
    margin: auto;
  }
  .annotation-container {
    width: 100%;
    height: 2027px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .scroll-up {
    position: fixed;
    right: 16px;
    bottom: 10%;
    text-align: center;
    font-size: 1.3rem;
    color: #414A5A;
    width: 100px;
  }
</style>
