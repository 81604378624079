<script context="module">
  import Viewport from "svelte-viewport-info";
  import {onMount} from "svelte"
</script>

<script>
  import copy from "./copy.js";
  import PortraitOverlay from "./PortraitOverlay.svelte";
  import Header from "./Header.svelte";
  import OpeningVideo from "./OpeningVideo.svelte";
  import BelgiumToWorldScroller from "./BelgiumToWorldScroller.svelte";
  import GoodsScroller from "./GoodsScroller.svelte";
  import MotorGraphic from "./MotorGraphic.svelte";
  import SatimageScroller from "./SatimageScroller.svelte";
  import TransportScroller from "./TransportScroller.svelte";
  import EnvironmentScroller from "./EnvironmentScroller.svelte";
  import EnergyGraphic from "./EnergyGraphic.svelte";

  let currentLanguage = "nl";
  $: languageCopy = copy[currentLanguage];

  let headerMode = "big";

  $: orientation = Viewport.Orientation;
  

	let yScroll = 0
  let yHeight = 0
  let h
  $: scrollPercent = yScroll/(h - yHeight)*100

  $: smallScreen = Viewport.Width < 900

</script>

<svelte:body
  on:orientationchangeend={() => {
    orientation = Viewport.Orientation;
  }}
/>

<svelte:window bind:scrollY={yScroll} bind:innerHeight={yHeight}/>

<div class="app-container" bind:clientHeight={h}>
  <Header bind:currentLanguage {headerMode} initiative={languageCopy.initiative} {scrollPercent}/>
  <OpeningVideo title={languageCopy.title} bind:headerMode />
  {#if orientation == "portrait"}
    <PortraitOverlay message={languageCopy.portraitmessage} />
  {/if}
  {#if orientation == "landscape"}
    <BelgiumToWorldScroller copy={languageCopy.belgiumToWorldScroller} language={currentLanguage} {smallScreen}/>
    <GoodsScroller copy={languageCopy.goodsScroller} {smallScreen}/>
    <MotorGraphic copy={languageCopy.motorGraphic} />
    <SatimageScroller scenes={languageCopy.satimageScroller.scenes} />
    <TransportScroller copy={languageCopy.transportScroller} {smallScreen}/>
    <EnvironmentScroller copy={languageCopy.environmentIllo} {smallScreen}/>
    <EnergyGraphic copy={languageCopy.energyGraphic} back={languageCopy.back} {smallScreen}/>
  {/if}
</div>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

  :global(*) {
    box-sizing: border-box;
  }
  :global(body) {
    padding: 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }
  :global(h2.highlight) {
    color: #d02f35;
  }
  :global(.cta) {
    background-color: rgb(228, 3, 44);
    padding-bottom: 0.75rem;;
    padding: 6px;
    border-radius: 9999px;
    font-size: 0.8rem;
    font-weight: bold;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 6px;
    color: white;
    text-decoration: none;
  }
  :global(.cta:hover) {
    background-color: #ad0524;
  }
  :global(.cta:visited) {
    color: white;
  }
</style>
