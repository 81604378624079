<script>
  import Scroller from "@sveltejs/svelte-scroller";
  import ScrollerScene from "./ScrollerScene.svelte";
  import MapboxMapToWorld from "./MapboxMapToWorld.svelte";
  import SvgMapToWorld from "./SvgMapToWorld.svelte";
  import { geoMercator } from "d3-geo";
  import cities from "./europe-cities.json";
  import internationalPorts from "./ports.json";
  import partners from "./poab-partners.json";
  import trade from "./poab-trade.json";
  import routes from "./poab-routes.json";

  export let copy;
  export let language;
  export let smallScreen;

  $: scenes = copy.scenes;

  let index, offset, progress;

  $: threshold = smallScreen ? 0.5 : 0.8;

  let width, height;

  let geoJsonToFit = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [1, 0],
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [0, 1],
        },
      },
    ],
  };

  $: projection = geoMercator().fitSize([width, height], geoJsonToFit);

  const ports = [
    {
      id: "antwerp",
      coordinates: [4.37455, 51.26334],
    },
    {
      id: "zeebrugge",
      coordinates: [3.20772, 51.33969],
    },
  ];
</script>

<Scroller
  {threshold}
  top={0}
  bottom={0.8}
  bind:index
  bind:offset
  bind:progress
>
  <div
    class="scroll-graphic"
    slot="background"
    bind:clientWidth={width}
    bind:clientHeight={height}
  >
    <MapboxMapToWorld scrollIndex={index} bind:geoJsonToFit />
    <div class="svg-map-container">
      <SvgMapToWorld
        {width}
        {height}
        {projection}
        scrollIndex={index}
        {ports}
        {cities}
        {internationalPorts}
        {partners}
        {trade}
        {routes}
        {copy}
        {language}
        {smallScreen}
      />
    </div>
    <div class="map-annotations" />
    {#if index == 0}
      {#each ports as port}
        <div
          class="poab-annotation"
          style:left={port.id == "zeebrugge"
            ? projection(port.coordinates)[0] + 20 + "px"
            : projection(port.coordinates)[0] - 146 + "px"}
          style:top={projection(port.coordinates)[1] - 24 + "px"}
        >
          {copy[port.id]}
        </div>
      {/each}
    {/if}

    {#if index == 1}
      {#each cities.features as city}
        <div
          class="city-annotation"
          style:left={city.properties.city == "Rotterdam"
            ? projection(city.geometry.coordinates)[0] - 30 + "px"
            : projection(city.geometry.coordinates)[0] + "px"}
          style:top={city.properties.city == "Brussels"
            ? projection(city.geometry.coordinates)[1] + 3 + "px"
            : projection(city.geometry.coordinates)[1] - 20 + "px"}
        >
          {language == "en" ? city.properties.city : city.properties.stad}
        </div>
      {/each}
    {/if}

    {#if index == 3}
      {#each partners.features.filter((d) => d.properties.type != "investment" || d.properties.key == "benin") as partner}
        <div
          class="partner-label"
          style:left={partner.properties.key == "slovakia" ||
          partner.properties.key == "hungary" ||
          partner.properties.key == "poland"
            ? projection(partner.geometry.coordinates)[0] + 35 + "px"
            : partner.properties.key == "ireland" ||
              partner.properties.key == "france"
            ? projection(partner.geometry.coordinates)[0] - 35 + "px"
            : partner.properties.key == "czechia" 
              ? projection(partner.geometry.coordinates)[0] + 35 + "px"
              : projection(partner.geometry.coordinates)[0] + "px"}
          style:top={partner.properties.key == "slovakia" || partner.properties.key == "hungary" || partner.properties.key == "poland"
            ? projection(partner.geometry.coordinates)[1] - 8 + "px"
            : partner.properties.key == "austria"
              ? projection(partner.geometry.coordinates)[1] + 9 + "px"
              : partner.properties.key == "uk"
                ? projection(partner.geometry.coordinates)[1] - 26 + "px"
                : partner.properties.key == "ireland" || partner.properties.key == "france"
                  ? projection(partner.geometry.coordinates)[1] - 8 + "px"
                    : partner.properties.key == "brazil" || partner.properties.key == "oman" || partner.properties.key == "benin"
                      ? projection(partner.geometry.coordinates)[1] + 4 + "px"
                      : partner.properties.key == "switzerland" 
                        ? projection(partner.geometry.coordinates)[1] + 20 + "px"
                        : partner.properties.key == "czechia" 
                          ? projection(partner.geometry.coordinates)[1] - 15 + "px"
                          : projection(partner.geometry.coordinates)[1] - 20 + "px"}
          style:color={partner.properties.type == "partner"
            ? "#D02F35"
            : "black"}
        >
          {partner.properties.key == "poab" ? "" : copy[partner.properties.key]}
        </div>
      {/each}
      {#each partners.features.filter((d) => d.properties.type == "investment") as investment}
        <div
          style:left={projection(investment.geometry.coordinates)[0] + 0 + "px"}
          style:top={projection(investment.geometry.coordinates)[1] - 20 + "px"}
          class="partner-label"
        >
          <img src="/icons/investment.svg" width="16px" />
        </div>
      {/each}

      {#if !smallScreen}
      <div class="legend">
        <div class="legend-item">
          <span class="dot filled" />
          {copy["partner"]}
        </div>
        <div class="legend-item">
          <span class="dot" />
          {copy["representative"]}
        </div>
        <div class="legend-item">
          <img
            src="/icons/investment.svg"
            width="16px"
            style:vertical-align={"bottom"}
          /> <span style:padding-bottom={"1.3em"}>{copy["investment"]}</span>
        </div>
      </div>
      {/if}
    {/if}

    {#if index == 4}
      {#each internationalPorts.features as port}
        <!--div
          class="city-annotation"
          style:left={projection(port.geometry.coordinates)[0] -24 + 'px'}
          style:top={projection(port.geometry.coordinates)[1] - 20 + 'px'}
          >{port.properties.Name}</div-->
      {/each}
    {/if}
  </div>

  <div slot="foreground">
    {#each scenes as scene, i}
      <ScrollerScene sceneTitle={scene.title} sceneText={scene.text} />
    {/each}
  </div>
</Scroller>

<style>
  .scroll-graphic {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .svg-map-container,
  .map-annotations {
    position: absolute;
    top: 0;
    left: 0;
  }
  .poab-annotation {
    position: absolute;
    color: white;
    background-color: #d02f35;
    font-weight: 400;
    font-size: 1.3rem;
    padding: 2px 12px;
    border-radius: 16px;
  }
  .city-annotation {
    position: absolute;
    color: black;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 0.8rem;
  }
  .partner-label {
    position: absolute;
    font-size: 0.8rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .legend {
    position: absolute;
    top: calc(5% + 40px);
    right: 5%;
    width: 200px;
  }
  .legend-item {
    background-color: white;
    width: 140px;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 12px;
  }
  :global(.dot) {
    height: 12px;
    width: 12px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid #d02f35;
  }
  :global(.dot.filled) {
    background-color: #d02f35;
  }
</style>
